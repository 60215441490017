export default {
	approvinglevels: [],
	isLoading: false,
	alert: false,
	alertMessage: '',
	alertType: '',
	headers: [
		{
			text: 'Record ID',
			align: 'left',
			sortable: false,
			value: 'id',
		},
		{ text: 'Level Code', value: 'level_code' },
		{ text: 'Level Name', value: 'level_name' },
		{ text: 'Minimum Amount', value: 'min_amount' },
		{ text: 'Maximum Amount', value: 'max_amount' },
		{ text: 'Actions', value: 'action', sortable: false },
	],
	columnHeaders: [
		{ label: 'Record ID', field: 'id' },
		{ label: 'Level Code', field: 'level_code' },
		{ label: 'Level Name', field: 'level_name' },
		{ label: 'Minimum Amount', field: 'min_amount' },
		{ label: 'Maximum Amount', field: 'max_amount' },
	],
	editedItem: {
		id: '',
		level_code: '',
		level_name: '',
		min_amount: '',
		max_amount: ''
	},
	defaultItem: {
		id: '',
		level_code: '',
		level_name: '',
		min_amount: '',
		max_amount: ''
	},
	dialog: false,
	editedIndex: -1,
	valid: false,
	formTitle: 'Edit entry',
};