export default {
	businesspartners: [],
	headers: [
		{
			text: 'Record ID',
			align: 'left',
			sortable: false,
			value: 'id',
		},
		{
			text: 'BP Name',
			align: 'left',
			sortable: false,
			value: 'name',
		},
		{ text: 'BP Code',value: 'bp_code' },
		// { text: 'Region Code', value: 'regn_code' },
		// { text: 'Zip Code', value: 'zip_code' },
		// { text: 'Branch Abbreviation', value: 'brnc_abbreviation' },
		// { text: 'Branch Phone', value: 'brnc_phone' },
		// { text: 'Branch Name', value: 'brnc_name' },
		// { text: 'Branch Address', value: 'brnc_address' },
		// { text: 'Cost Center', value: 'cost_ctr' },
		// { text: 'Branch Type', value: 'brnc_type' },
		// { text: 'Branch Status', value: 'brnc_stat' },
		// { text: 'Main Branch', value: 'main_brnc' },
		// { text: 'Expiry Date', value: 'expiry_date' },
		// { text: 'Setup Date', value: 'setup_date' },
		// { text: 'Active', value: 'active_flag' },
		// { text: 'Street', value: 'street' },
		// // { text: 'Barangay', value: 'barangay' },
		// { text: 'City', value: 'city' },
		// { text: 'Province', value: 'province' },
		// { text: 'Country', value: 'country' },
		// { text: 'Date Opened', value: 'date_opened' },
		// { text: 'Date Closed', value: 'date_closed' },
		// { text: 'BP ID Number', value: 'bp_idno' },
		// { text: 'Transfer Date', value: 'transfer_date' },
		// // { text: 'Remarks ', value: 'remarks' },
		// { text: 'RBOS Code', value: 'rbos_pcode' },
		// { text: 'BOS PCode', value: 'bos_pcode' },
		// { text: 'BSA PCode', value: 'bsa_pcode' },
		// { text: 'OPS Code', value: 'ops_code' },
		// { text: 'Branch Name Voyager', value: 'brnc_name_voyager' },
		// { text: 'Borrowed Rep ID No', value: 'borwd_repidno' },
		{ text: 'Payee Code', value: 'payee_code' },
		// { text: 'Department Flag', value: 'dept_flag' },
		{ text: 'Actions', value: 'action', sortable: false },
		
		// {
		// 	text: 'Payee Code',
		// 	align: 'left',
		// 	sortable: true,
		// 	value: 'payee_code',
		// },

		// { text: 'Payee Description', value: 'payee_desc' },
		// { text: 'Payee Type', value: 'payee_type' },
		// { text: 'Branch Code', value: 'brnc_code' },
		// { text: 'Department Code', value: 'dept_code' },
		// { text: 'Section Code', value: 'section_code' },
		// { text: 'Actions', value: 'action', sortable: false },
	],
	columnHeaders: [
		{ label: 'Record ID', field: 'id' },
		{ label: 'BP Name', field: 'name' },
		{ label: 'BP Code',field: 'bp_code' },
		{ label: 'Payee Code', field: 'payee_code' }
	],
	activeStatus: [
		{ value: '1', text: 'Active'},
		{ value: '0', text: 'Inactive'}
	],
	editedItem: {
		id: '',
		name: '',
		bp_code: '',
		class_code: '',
		// payee_code: '',
		brnc_code: '',
		cctr_code: '',
		status: '',
		// `batch_no`: '',
		pin: '',
		// verified: '',
		president: '',
		gen_manager: '',
		wu_person: '',
		wu_mobile: '',
		// vat_reg: '',
		bp_tin: '',
		risk_code: '',
		risk_eval_date: '',
		client_type: '',
		dpa_consent_tag: '',
		pep_tag: '',
		kyc_stat: '',
		house_unit: '',
		street: '',
		barangay: '',
		municipality: '',
		city: '',
		region: '',
		zip_code: '',
		country_id: '',
		contact_person: '',
		contact_num: '',
		fax: '',
		hold: '',
		email_address: '',
		tin: '',
		business_type: '',
		vat: '',
		atc_code: '',
		payee_banks: []
	},
	defaultItem: {
		id: '',
		name: '',
		bp_code: '',
		class_code: '',
		// payee_code: '',
		brnc_code: '',
		cctr_code: '',
		status: '',
		// batch_no: '',
		pin: '',
		// verified: '',
		president: '',
		gen_manager: '',
		wu_person: '',
		wu_mobile: '',
		// vat_reg: '',
		bp_tin: '',
		risk_code: '',
		risk_eval_date: '',
		client_type: '',
		dpa_consent_tag: '',
		pep_tag: '',
		kyc_stat: '',
		house_unit: '',
		street: '',
		barangay: '',
		municipality: '',
		city: '',
		region: '',
		zip_code: '',
		country_id: '',
		contact_person: '',
		contact_num: '',
		fax: '',
		hold: '',
		email_address: '',
		tin: '',
		business_type: '',
		vat: '',
		// atc_code: '',
		payee_banks: []
	},
	dialog: false,
	editedIndex: -1,
	valid: true,
	formTitle: 'Edit entry',
};