export default {
	coas: [],
	accountParents: [],
	headers: [
		{ text: 'Record ID', value: 'id', sortable: true },
		{
			text: 'Account Code',
			align: 'left',
			sortable: true,
			value: 'acct_code',
		},
		{ text: 'Description', value: 'acct_desc', sortable: true, },
		{ text: 'Account Type', value: 'acct_type' },
		{ text: 'Account Class', value: 'acct_class' },
		{ text: 'Account Group', value: 'acct_group' },
		{ text: 'Parent Account', value: 'acct_parent' },
		{ text: 'Status', value: 'status' },
		{ text: 'Actions', value: 'action', sortable: false },
	],
	columnHeaders: [
		{ label: 'Record ID', field: 'id' },
		{ label: 'Account Code', field: 'acct_code' },
		{ label: 'Description', field: 'acct_desc' },
		{ label: 'Account Type', field: 'acct_type' },
		{ label: 'Account Class', field: 'acct_class' },
		{ label: 'Account Group', field: 'acct_group' },
		{ label: 'Parent Account', field: 'acct_parent' },
		{ label: 'Status', field: 'status' }
	],
	accountGroup: [
		{ value: 'A', text: 'Asset' },
		{ value: 'L', text: 'Liability' },
		{ value: 'C', text: 'Capital' },
		{ value: 'E', text: 'Expenses' },
		{ value: 'I', text: 'Income' }
	],
	accountType: [
		// {value: 'M', text: 'Major'},
		{ value: 'G', text: 'General' },
		{ value: 'D', text: 'Detail' },
		{ value: 'P', text: 'Parent' },
		{ value: 'S', text: 'Subsidiary' }
	],
	accountClass: [
		{ value: 'B', text: 'Balance Sheet Account' },
		{ value: 'I', text: 'Income and Expense Account' }
	],
	editedItem: {
		id: '',
		acct_code: '',
		acct_desc: '',
		acct_type: '',
		acct_class: '',
		acct_group: '',
		acct_parent: '',
		acct_parent_desc: '',
		currency_id: 0
	},
	defaultItem: {
		id: '',
		acct_code: '',
		acct_desc: '',
		acct_type: '',
		acct_class: '',
		acct_group: '',
		acct_parent: '',
		acct_parent_desc: '',
		currency_id: 0
	},
	dialog: false,
	editedIndex: -1,
	valid: true,
	formTitle: 'Edit entry',
	coaSubs: [],
	coaCurr: [],
	coaData: []
};
