import Repository from '../Repository';

const resource = '/alphataxcode';

export default {
	get() {
		return Repository.get(`${resource}`);
	},

	getAlphataxcode(id) {
		return Repository.get(`${resource}/${id}`);
	},

	saveAlphataxcode(payload) {
		return Repository.post(`${resource}`, payload);
	},

	updateAlphataxcode(id, payload) {
		return Repository.put(`${resource}/${id}`, payload);
	},

	deleteAlphataxcode(id) {
		return Repository.delete(`${resource}/${id}`);
	},

	searchAlphataxcode(payload){
		return Repository.get(`${resource}`,payload);
	},
	
	getData(payload){
		return Repository.get(`${resource}?page=`+ payload);
	} 
};


