export default {
	useraccessprivileges: [],
	headers: [
		{
			text: 'Record ID',
			align: 'left',
			sortable: false,
			value: 'id',
		},
		{
			text: 'User ID',
			align: 'left',
			sortable: false,
			value: 'user_id',
		},
		{ text: 'Program Code', value: 'prog_code' },
		{ text: 'Access Level', value: 'access_level' },
		{ text: 'Actions', value: 'action', sortable: false },
	],
	accessLevel: [
		{ value: 'N', text: 'No Access'},
		{ value: 'S', text: 'Select'},
		{ value: 'I', text: 'Insert'},
		{ value: 'U', text: 'Update'},
		{ value: 'D', text: 'Delete'}
	],
	editedItem: {
		id: '',
		user_id: '',
		prog_code: '',
		aaccess_level: ''
	},
	defaultItem: {
		id: '',
		user_id: '',
		prog_code: '',
		aaccess_level: ''
	},
	dialog: false,
	editedIndex: -1,
	valid: true,
	formTitle: 'Edit entry',
};  