const sections = state => state.sections;
const headers = state => state.headers;
const editedItem = state => state.editedItem;
const defaultItem = state => state.defaultItem;
const dialog = state => state.dialog;
const editedIndex = state => state.editedIndex;
const valid = state => state.valid;
const formTitle = state => state.formTitle;
const isLoading = state => state.isLoading;

export default {
	sections,
	headers,
	editedItem,
	defaultItem,
	dialog,
	editedIndex,
	valid,
	formTitle,
	isLoading
};