import Repository from '../Repository';

const resource = '/regular-txn';

export default {
	getDataToCreate(){
		return Repository.get('data-to-create-regular-txn');
	},

	get() {
		return Repository.get(`${resource}`);
	},

	getSection(id) {
		return Repository.get(`${resource}/${id}`);
	},

	saveSection(payload) {
		return Repository.post(`${resource}`, payload);
	},

	updateSection(id, payload) {
		return Repository.put(`${resource}/${id}`, payload);
	},

	deleteregularTxns(id) {
		return Repository.delete(`${resource}/${id}`);
	},

	searchSection(payload){
		return Repository.get(`${resource}`,payload);
	},
	
	getData(payload){
		return Repository.get(`${resource}?page=`+ payload);
	} 
};