const users = state => state.users;
const headers = state => state.headers;
const columnHeaders = state => state.columnHeaders;
const sperUser = state => state.sperUser;
const editedItem = state => state.editedItem;
const defaultItem = state => state.defaultItem;
const dialog = state => state.dialog;
const editedIndex = state => state.editedIndex;

const valid = state => state.valid;
const formTitle = state => state.formTitle;
const isLoading = state => state.isLoading;

export default {
	users,
	columnHeaders,
	headers,
	sperUser,
	editedItem,
	defaultItem,
	dialog,
	editedIndex,
	valid,
	formTitle,
	isLoading
};