import Vue from 'vue';

const ACKNOWLEDGEMENTRECEIPT_UPDATED = (state, payload) => {
	state.acknowledgementreceipt = payload;

};

const ACKNOWLEDGEMENTRECEIPT_ADD = (state, payload) => {
	state.acknowledgementreceipt.data.push(payload);
};

const ACKNOWLEDGEMENTRECEIPT_DELETE = (state, ) => {
	state.acknowledgementreceipt.data.splice(state.editedIndex, 1);
};

const ACKNOWLEDGEMENTRECEIPT_ITEM_UPDATE = (state, payload) => {
	Vue.set(state.acknowledgementreceipt.data, state.editedIndex, payload);
};

const SET_EDITED_ITEM = (state,payload) =>{
	state.editedItem = payload;
};

const SET_DIALOG = (state,payload) =>{
	state.dialog = payload;
};

const SET_EDITED_INDEX = (state,payload) =>{
	state.editedIndex = payload;
};

const SET_VALID = (state,payload) => {
	state.valid = payload;
};

const SET_FORM_TITLE = (state,payload) => {
	state.formTitle = payload;
};


export default {
	ACKNOWLEDGEMENTRECEIPT_UPDATED,
	SET_EDITED_ITEM,
	SET_DIALOG,
	SET_EDITED_INDEX,
	ACKNOWLEDGEMENTRECEIPT_ADD,
	ACKNOWLEDGEMENTRECEIPT_DELETE,
	SET_VALID,
	SET_FORM_TITLE,
	ACKNOWLEDGEMENTRECEIPT_ITEM_UPDATE
};


