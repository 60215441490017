// import Vue from 'vue';

const SET_PO_NUMBER = (state, payload) => {
	state.PONumber = payload;
};

const UNSET_PO_NUMBER = (state) => {
	state.PONumber = '';
};

const VIEW_DIALOG = (state) => {
	state.viewDialog = true;
};

const CLOSE_DIALOG = (state) => {
	state.viewDialog = false;
};
const SET_PO_REQUEST = (state, payload) => {
	state.poRequest = payload;
};

export default {
	SET_PO_NUMBER,
	UNSET_PO_NUMBER,
	VIEW_DIALOG,
	CLOSE_DIALOG,
	SET_PO_REQUEST
};