const approvingofficers = state => state.approvingofficers;
const headers = state => state.headers;
const columnHeaders = state => state.columnHeaders;
const editedItem = state => state.editedItem;
const defaultItem = state => state.defaultItem;
const dialog = state => state.dialog;
const editedIndex = state => state.editedIndex;
const regulartxns = state => state.regulartxns;
const regulartxn = state => state.regulartxn;

const valid = state => state.valid;
const formTitle = state => state.formTitle;
const isLoading = state => state.isLoading;
const regTrxData = state => state.regTrxData;

export default {
	approvingofficers,
	columnHeaders,
	headers,
	editedItem,
	defaultItem,
	dialog,
	editedIndex,
	valid,
	formTitle,
	isLoading,
	regulartxns,
	regulartxn,
	regTrxData
};