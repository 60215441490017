<template>
  <div>
    <div id="printAction" class="action">
      <v-row justify="center" align="center">
        <v-spacer class="hidden-sm-and-down"></v-spacer>
        <v-col cols="6">
          <h4>Report Name: RFA </h4>
        </v-col>
        <v-col cols="4">
           <v-btn color="success" @click="exportExcel('printArea', 'rfa')"><v-icon left>mdi-microsoft-excel</v-icon> Export</v-btn>
        </v-col>
      </v-row>
    </div>
    <div id="printArea" ref="content" style="width: 768px; font-size: 12px; margin: auto;">
      <v-row>
        <v-col :cols="3">
        </v-col>
        <v-col cols="6" class="text-center">
          <p>
            <br>
            PETNET, INC.
            <br>
            32nd Floor East Offices Aguirre St. Legaspi Village Makati City
          </p>
        </v-col>
        <v-col :cols="3">
          <!-- <p>
            Page:
            <br>
            Status:
            </p> -->
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-center">
          <p>
            Revolving Fund Account
            <br>
            For the Period of {{getDateFormat(detail.date_from)}} to {{getDateFormat(detail.date_to)}}
          </p>
        </v-col>
      </v-row>
      <div style="outline: 1px solid black;width: 95%; margin: auto;"></div>
      <table id="table" style="width: 95%; margin: auto;" ref="table">
        <tr>
          <th>CODE</th>
          <th>BRANCH</th>
          <th>AS OF AMOUNT</th>
        </tr>
        <tr style="outline: 1px solid black; width: 100%; margin: 10px;">
        </tr>
		<tr v-for="(det, index) in rfaData.data1" :key="index">
			<td style="text-align: center">{{det.brnc_code}}</td>
			<td style="text-align: center">{{det.brnc_name}}</td>
			<td style="text-align: right">{{getAmountFormat(det.asofamount)}}</td>
		</tr>
      </table>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import numeral from 'numeral'; 
import { format } from 'date-fns';
export default {
	data: () => ({
		detail: {},
	}),
	mounted() {
		this.$store.dispatch('report/getRfa', this.detail);
	},
	created() {
		var app = this;
		app.detail = JSON.parse(atob(app.$route.params.data)); 
	},
	computed: {
		...mapGetters({
			rfaData: 'report/rfaData',
		}),
	},
	methods:{
		print() {
			window.print();
		},
		exportExcel(tableID, filename = '') {
			const date = Math.floor(Date.now() / 1000);
			var downloadLink;
			var dataType = 'application/vnd.ms-excel';

			// var x = document.getElementsByClassName('amount');
			// var i;
			// for (i = 0; i < x.length; i++) {
			// 	x[i].innerHTML = '\'' + x[i].innerHTML;
			// }
			
			var x = document.getElementsByClassName('description');
			var i;
			for (i = 0; i < x.length; i++) {
				x[i].innerHTML = x[i].innerHTML.replace(/[`~!@#$%^&*()|+=?;:'"<>]/gi, ' ');
			}
			var tableSelect = document.getElementById(tableID);
			var tableHTML = tableSelect.outerHTML.replace(/ /g, '%20');


			filename = filename ? filename + date + '.xls' : 'excel_data.xls';
			downloadLink = document.createElement('a');
			document.body.appendChild(downloadLink);

			if (navigator.msSaveOrOpenBlob) {
				var blob = new Blob(['\ufeff', tableHTML], {
					type: dataType
				});
				navigator.msSaveOrOpenBlob(blob, filename);
			} else {
				downloadLink.href = 'data:' + dataType + ', ' + tableHTML;
				downloadLink.download = filename;
				downloadLink.click();
			}
			
			// for (i = 0; i < x.length; i++) {
			// 	x[i].innerHTML = x[i].innerHTML.replace('\'', '');
			// }
		},
		getAmountFormat(amount){
			return numeral(amount).format('0,0.00');
		},
		getMonth(){
			var month = new Array();
			month[1] = 'January';
			month[2] = 'February';
			month[3] = 'March';
			month[4] = 'April';
			month[5] = 'May';
			month[6] = 'June';
			month[7] = 'July';
			month[8] = 'August';
			month[9] = 'September';
			month[10] = 'October';
			month[11] = 'November';
			month[12] = 'December';
			var n = month[this.detail.month];
			return n;
		},
		getDateFormat(date){
			var month = format(new Date(date), 'MMM');
			var day = format(new Date(date), 'dd');
			var year = format(new Date(date), 'yyyy');
			return month + ' ' + day +', '+ year;
		}
	}
};
</script>
<style scoped>
.report-template {
  width: 768px;
  /* border: 1px solid black; */
  font-size: 12px;
  margin: auto;
}
.tbl {
  width: 95%;
  margin: auto;
}
.code {
  text-align: center;
}
.amount {
  text-align: right;
}
.header {
  outline: 1px solid black;
  width: 100%;
  margin: 10px;
}
.total {
  text-align: right;
  font-weight: bold;
}
.action {
  padding: 20px;
}
.title {
  text-justify: center;
}
	@media print {
		body #printAction { 
			display:none; 
		}
		body #printArea { 
			display:block; 
		}
	} 
</style>