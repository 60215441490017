export default{
	tbalData: [],
	subsData: [],
	subsAnalysisData: [],
	profData: [],
	profDataSummary: [],
	billingList: [],
	arAgingInv: [],
	arAgingCust: [],
	rfaData: [],
	boaData: [],
	auditData: [],
	cibSubData: [],
	salesData: []
};