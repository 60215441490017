export default {
	dollarrates: [],
	headers: [
		{
			text: 'Record ID',
			align: 'left',
			sortable: false,
			value: 'id',
		},
		{
			text: 'Fiscal Year',
			align: 'left',
			sortable: false,
			value: 'fiscal_year',
		},
		{
			text: 'Period No',
			align: 'left',
			sortable: false,
			value: 'period',
		},
		{ text: 'Account Class', value: 'acct_class' },
		{ text: 'Currency', value: 'cur_desc' },
		{ text: 'Dollar Rate', value: 'dolr_rate' },
		// { text: 'Account Class', value: 'acct_class' },
		{ text: 'Actions', value: 'action', sortable: false },
	],
	columnHeaders: [
		{ label: 'Record ID', field: 'id' },
		{ label: 'Fiscal Year', field: 'fiscal_year' },
		{ label: 'Period No', field: 'period' },
		{ label: 'Account Class', field: 'acct_class' },
		{ label: 'Currency', field: 'cur_desc' },
		{ label: 'Dollar Rate', field: 'dolr_rate' },
	],
	fiscalYear: [
		{ value: 2019, text: '2019'},
		{ value: 2020, text: '2020'},
		{ value: 2021, text: '2021'},
		{ value: 2022, text: '2022'},
		{ value: 2023, text: '2023'},
	],
	periodNo: [
		{ value: 1, text: 'January'},
		{ value: 2, text: 'February'},
		{ value: 3, text: 'March'},
		{ value: 4, text: 'April'},
		{ value: 5, text: 'May'},
		{ value: 6, text: 'June'},
		{ value: 7, text: 'July'},
		{ value: 8, text: 'August'},
		{ value: 9, text: 'September'},
		{ value: 10, text: 'October'},
		{ value: 11, text: 'November'},
		{ value: 12, text: 'December'}
	],
	currencyCode: [
		{ value: 'C', text: 'Yuan'},
		{ value: 'D', text: 'Dollar'},
		{ value: 'E', text: 'Euro'},
		{ value: 'P', text: 'Peso'},
		{ value: 'Y', text: 'Yen'}
	],
	accountClass: [
		{ value: 'B', text: 'Balance Accounts'},
		{ value: 'I', text: 'Income and Expense Accounts'}
	],
	editedItem: {
		id: '',
		fiscal_year: '',
		period_no: '',
		dolr_rate: '',
		currency_id: '',
		acct_class: ''
	},
	defaultItem: {
		id: '',
		fiscal_year: '',
		period_no: '',
		dolr_rate: '',
		currency_id: '',
		acct_class: ''
	},
	dialog: false,
	editedIndex: -1,
	valid: true,
	formTitle: 'Edit entry',
	isLoading: false
};