<template>
  <v-main class="lighten-3">
    <div id="core-view">
      <v-container fluid class="content-container">
        <v-app-bc></v-app-bc>
        <v-fade-transition mode="out-in">
          <router-view />
        </v-fade-transition>
      </v-container>
    </div>
  </v-main>
</template>

<script>
import AppBreadCrumbsVue from './AppBreadCrumbs.vue';

export default {
	components: {
		'v-app-bc': AppBreadCrumbsVue,
	},
};
</script>

<style>
  .v-data-table {
    margin-bottom: 50px;
  }
</style>