export default {
	approvingofficers: [],
	isLoading: false,
	alert: false,
	alertMessage: '',
	alertType: '',
	headers: [
		{
			text: 'Record ID',
			align: 'left',
			sortable: false,
			value: 'id',
		},
		{
			text: 'User ID',
			align: 'left',
			sortable: false,
			value: 'user_id'
		},
		{ text: 'Approver Name', value: 'approver_name' },
		{ text: 'Currency', value: 'currency_id' },
		{ text: 'Minimum Amount', value: 'min_amount' },
		{ text: 'Maximum Amount', value: 'max_amount' },
		{ text: 'Actions', value: 'action', sortable: false },
	],
	columnHeaders: [
		{ label: 'Record ID', field: 'id' },
		{ label: 'User ID', field: 'user_id' },
		{ label: 'Approver Name', field: 'approver_name' },
		{ label: 'Currency', field: 'currency_id' },
		{ label: 'Minimum Amount', field: 'min_amount' },
		{ label: 'Maximum Amount', field: 'max_amount' },
	],
	editedItem: {
		id: '',
		user_id: '',
		approver_name: '',
		currency_id: '',
		min_amount: '',
		max_amount: ''
	},
	defaultItem: {
		id: '',
		user_id: '',
		approver_name: '',
		currency_id: '',
		min_amount: '',
		max_amount: ''
	},
	dialog: false,
	editedIndex: -1,
	valid: false,
	formTitle: 'Edit entry',
};