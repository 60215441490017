export default {
	roleaccess: [],
	headers: [
		{
			text: 'Record ID',
			align: 'left',
			sortable: false,
			value: 'id',
		},
		{
			text: 'Roles',
			align: 'left',
			sortable: false,
			value: 'roles',
		},
		{ text: 'Actions', value: 'action', sortable: false },
	],
	columnHeaders: [
		{ label: 'Record ID', field: 'id' },
		{ label: 'Roles', field: 'roles' },
	],
  
	editedItem: {
		id: '',
		roles: ''
	},
	defaultItem: {
		id: '',
		roles: ''
	},
	dialog: false,
	editedIndex: -1,
	valid: true,
	formTitle: 'Edit entry',
};