import Repository from '../Repository';
import axios from 'axios';

const resource = '/user';

export default {
	get() {
		return Repository.get(`${resource}`);
	},

	getUser(id) {
		return Repository.get(`${resource}/${id}`);
	},

	saveUser(payload) {
		return Repository.post(`${resource}`, payload);
	},

	updateUser(id, payload) {
		return Repository.put(`${resource}/${id}`, payload);
	},

	deleteUser(id) {
		return Repository.delete(`${resource}/${id}`);
	},

	searchUser(payload) {
		return Repository.get(`${resource}`, payload);
	},

	getDetails(id) {
		return Repository.get(`${resource}/${id}`);
	},

	getData(payload) {
		return Repository.get(`${resource}?page=`+ payload);
	} ,

	getUserHRIS(payload, token) {
		return axios.get(process.env.VUE_APP_HRIS_BASE_URL + '/employee/basicinfo/email/' + payload, {
			headers: {
				'Authorization': 'Bearer ' + token,
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			}
		});
	} 
};