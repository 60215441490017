<template>
  <div>
    <div id="printAction" class="action">
      <v-row justify="center" align="center">
        <v-spacer class="hidden-sm-and-down"></v-spacer>
        <v-col cols="6">
          <h4>Report Name: TRIAL BALANCE</h4>
        </v-col>
        <v-col cols="4">
          <!-- <v-btn color="primary"  @click="print"><v-icon left>mdi-printer</v-icon> Print</v-btn> -->
          <v-btn color="error" @click="exportReport"><v-icon left>mdi-file-pdf-outline</v-icon>Export</v-btn>
          <v-btn color="success" @click="exportExcel('printArea','trialbalance')"><v-icon left>mdi-microsoft-excel</v-icon>Export</v-btn>
        </v-col>
      </v-row>
    </div>
    <div id="printArea" ref="content" style="width: 768px; font-size: 12px; margin: auto;">
      <v-row>
        <v-col :cols="3">
          <p>
            Run time:
            <br>
            Run Date:
            <br>
            Run By:
            </p>
        </v-col>
        <v-col :cols="6" class="text-center">
          <p>
            <br>
            PETNET, INC.
            <br>
            32nd Floor East Offices Aguirre St. Legaspi Village Makati City
          </p>
        </v-col>
        <v-spacer />
      </v-row>
      <v-row>
        <v-col class="text-center" v-if="detail.type === 'ftm'">
          <p>
						TRIAL BALANCE
            <br>
            For the Month of {{getMonth()}}, {{detail.year}}
          </p>
        </v-col>
        <v-col class="text-center" v-else>
          <p>
						TRIAL BALANCE
            <br>
            For the Period of {{getDateFormat(detail.date_from)}} to {{getDateFormat(detail.date_to)}}
          </p>
        </v-col>
      </v-row>
      <div style="outline: 1px solid black;width: 95%; margin: auto;"></div>
      <table id="table" style="width: 95%; margin: auto;" ref="table">
        <tr>
          <th>CODE</th>
          <th>ACCOUNT DESCRIPTION</th>
          <th>DEBIT</th>
          <th>CREDIT</th>
        </tr>
        <tr style="outline: 1px solid black; width: 100%; margin: 10px;">
        </tr>
        <tr v-if="tBalData.details.length === 0">
          <td colspan="4" style="text-align: center">
            NO DATA FOUND
          </td>
        </tr>
        <tr v-for="details in tBalData.details" :key="details.id">
          <td style="text-align: center">{{details.account_code}}</td>
          <td>{{details.acct_desc}}</td>
          <td class="amount">{{getAmountFormat(details.cur_debit)}}</td>
          <td class="amount">{{getAmountFormat(details.cur_credit)}}</td>
        </tr>
        <br>
        <tr class="total" style="text-align: right;font-weight: bold;" v-if="tBalData.details.length !== 0">
          <td colspan="2">TOTAL:</td>
          <td class="amount">{{getAmountFormat(tBalData.total_credit)}}</td>
          <td class="amount">{{getAmountFormat(tBalData.total_debit)}}</td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
// import jsPDF from 'jspdf';
import html2pdf from 'html2pdf.js';
//import 'jspdf-autotable';
import { mapGetters } from 'vuex';
import numeral from 'numeral';
import { format } from 'date-fns';
export default {
	data: () => ({
		detail: {
			year: '',
			month: ''
		}
	}),
	mounted() {
		this.$store.dispatch('report/getTbalData', this.detail);
	},
	created() {
		var app = this;
		app.detail = JSON.parse(atob(app.$route.params.data)); 
	},
	computed: {
		...mapGetters({
			tBalData: 'report/tbalData',
		}),
	},
	watch: {
		tBalData: {
			handler(val){
				this.$emit('update:tbalData', val);
				// val.total_credit = this.getAmountFormat(val.total_credit);
				// val.total_credit = this.getAmountFormat(val.total_debit);
			}
		}
	},
	methods:{
		print() {
			window.print();
		},
		
		exportReport(){
			const type  = this.detail.type;
			let text  = '';
			let marginLeft = '';
			if (type === 'ftm') {
				text = 'For the Month of ' + this.getMonth() + ', ' + this.detail.year;
				marginLeft = 245;
			} else {
				text = 'For the Period of ' + this.getDateFormat(this.detail.date_from)+ ' to ' + this.getDateFormat(this.detail.date_to);
				marginLeft = 200;
			}

			const contentHtml = this.$refs.table;
			const date = Math.floor(Date.now() / 1000);
			//var source = document.body;
			html2pdf()
				.from(contentHtml)
				.set({
					margin:       [100, 0, 30, 30],
					filename:     'trial-balance' + date +'.pdf',
					image:        { type: 'jpeg',quality: 0.98 },
					html2canvas:  { dpi: 96, scale: 1, scrollX: 0, scrollY: 0, backgroundColor: '#FFF' },
					jsPDF:        { unit: 'pt', format: 'a4', orientation: 'p' },
					pagebreak: 		{ before: '.page-break', avoid: 'div' }
				})
				.toPdf()
				.get('pdf').then(function (pdf) {
					var totalPages = pdf.internal.getNumberOfPages();
                      
					for (let i = 1; i <= totalPages; i++) {
						pdf.setPage(i);
						pdf.setFontSize(9);
						pdf.text('Run Time: ', 30, 40);
						pdf.text('Run Date: ', 30, 50);
						pdf.text('Run By: ', 30, 60);
						// pdf.text('Page:   ' + i , pdf.internal.pageSize.getWidth() - 80, 50);
						// pdf.text('Status: ', pdf.internal.pageSize.getWidth() - 80, 60);
						pdf.text('PETNET INC.', 273, 50);
						pdf.text('32nd Floor East Offices Aguirre St. Legaspi Village Makati City', 180, 60);
						pdf.text('TRIAL BALANCE', 270, 80);
						pdf.text(text, marginLeft, 90);
						//pdf.text('width' + pdf.internal.pageSize.getWidth(), 50, 90); //595.28
						pdf.text('Page ' + i + ' of ' + totalPages, pdf.internal.pageSize.getWidth() - 100, pdf.internal.pageSize.getHeight() - 40);
					} 
				}).save();
		},
		exportExcel(tableID, filename = '') {
			const date = Math.floor(Date.now() / 1000);
			var downloadLink;
			var dataType = 'application/vnd.ms-excel';

			// var x = document.getElementsByClassName('amount');
			// var i;
			// for (i = 0; i < x.length; i++) {
			// 	x[i].innerHTML = '\'' + x[i].innerHTML;
			// }
			var tableSelect = document.getElementById(tableID);
			var tableHTML = tableSelect.outerHTML.replace(/ /g, '%20');


			filename = filename ? filename + date + '.xls' : 'excel_data.xls';
			downloadLink = document.createElement('a');
			document.body.appendChild(downloadLink);

			if (navigator.msSaveOrOpenBlob) {
				var blob = new Blob(['\ufeff', tableHTML], {
					type: dataType
				});
				navigator.msSaveOrOpenBlob(blob, filename);
			} else {
				downloadLink.href = 'data:' + dataType + ', ' + tableHTML;
				downloadLink.download = filename;
				downloadLink.click();
			}
			
			// for (i = 0; i < x.length; i++) {
			// 	x[i].innerHTML = x[i].innerHTML.replace('\'', '');
			// }

		},
		getAmountFormat(amount){
			return numeral(amount).format('0,0.00');
		},
		getMonth(){
			var month = new Array();
			month[1] = 'January';
			month[2] = 'February';
			month[3] = 'March';
			month[4] = 'April';
			month[5] = 'May';
			month[6] = 'June';
			month[7] = 'July';
			month[8] = 'August';
			month[9] = 'September';
			month[10] = 'October';
			month[11] = 'November';
			month[12] = 'December';
			var n = month[this.detail.month];
			return n;
		},
		getDateFormat(date){
			var month = format(new Date(date), 'MMM');
			var day = format(new Date(date), 'dd');
			var year = format(new Date(date), 'yyyy');
			return month + ' ' + day +', '+ year;
		}
	}
};
</script>
<style scoped>
.report-template {
  width: 768px;
  /* border: 1px solid black; */
  font-size: 12px;
  margin: auto;
}
.tbl {
  width: 95%;
  margin: auto;
}
.code {
  text-align: center;
}
.amount {
  text-align: right;
}
.header {
  outline: 1px solid black;
  width: 100%;
  margin: 10px;
}
.total {
  text-align: right;
  font-weight: bold;
}
.action {
  padding: 20px;
}
.title {
  text-justify: center;
}
.amount {
	text-align: right;
}
	@media print {
		body #printAction { 
			display:none; 
		}
		body #printArea { 
			display:block; 
		}
	}
</style>