<template>
  <div>
    <div id="printAction" class="action">
      <v-row justify="center" align="center">
        <v-spacer class="hidden-sm-and-down"></v-spacer>
        <v-col cols="6">
          <h4>Report Name: AR Aging</h4>
        </v-col>
        <v-col cols="4">
         <v-btn color="success" @click="exportExcel('printArea','subsidiary')"><v-icon left>mdi-microsoft-excel</v-icon>Export</v-btn>
        </v-col>
      </v-row>
    </div>
    <div id="printArea" ref="content" style="width: 1248px; font-size: 12px; margin: auto;">
      <v-row>
        <v-col cols="1" class="text-center"></v-col>
        <v-col cols="4" class="">
          <p>
            <br>
            PETNET, INC.
            <br>
            Aging Report Schedule
            <br>
            As of {{getDateFormat(detail.date_to)}}
          </p>
        </v-col>
        <v-spacer />
      </v-row>
      <div class="tbl">
        <table id="table" style="border: 1px solid black;width: 95%; margin: auto;" ref="table">
          <tr>
              <th>Customer/Client Name</th>
              <th>Outstanding Balance	</th>
              <th>0-30 Days	</th>
              <th>31-60 Days	</th>
              <th>61-90 Days	</th>
              <th>91-120 Days	</th>
              <th>Above 120 Days	</th>
              <th>Total Outstanding Balance</th>
          </tr>
			<tr v-for="(sub, index) in arAgingCust" :key="index">
				<td>
					{{index}}
				</td>
				<td class="amount">{{getAmountFormat(sub.total_remaining_balance)}}</td>
				<td class="amount">{{sub.current_date}}</td>
				<td class="amount">{{sub.next_date}}</td>
				<td class="amount">{{sub.next_date2}}</td>
				<td class="amount">{{sub.next_date3}}</td>
				<td class="amount">{{sub.last_date}}</td>
				<td class="amount">{{getAmountFormat(sub.total_remaining_balance)}}</td>
			</tr>	
        </table>
      </div>
	<v-row>
		<v-col cols="1"></v-col>
        <v-col cols="5">
          <p style="font-size: 10px">
            <br>
            Company Name: PETNET, INC.
            <br>
            Address: East Offices Building 114 Aguirre St. Legaspi Village,Makati City
			<br>
			VAT Reg TIN: 201-200-777-000
			<br>
			System: Enterprise Resource Planning
			<br>
			Version: 1.0.0.
          </p>
        </v-col>
        <v-col cols="5">
          <p style="font-size: 10px">
            <br>
            Generated By: {{generated_by}}
            <br>
			Generated Date: {{generated_date}}
			<br>
			Generated Time: {{generated_time}}
          </p>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
// import jsPDF from 'jspdf';
import html2pdf from 'html2pdf.js';
//import 'jspdf-autotable';
import { mapGetters } from 'vuex';
import numeral from 'numeral';
import { format } from 'date-fns'; 
export default {
	data: () => ({
		detail: {},
		generated_by: '',
		generated_time: new Date().toLocaleTimeString(),
		generated_date: new Date().toLocaleDateString()
	}),
	mounted() {
		this.$store.dispatch('report/getARAgingCustomer', this.detail);
	},
	created() {
		var app = this;
		let data = JSON.parse(atob(app.$route.params.data)); 
		app.generated_by = data.user;
		app.detail = {
			'payee_id': data.payee_id,
			'date_from': data.date_from,
			'date_to': data.date_to
		};
	},
	computed: {
		...mapGetters({
			arAgingCust: 'report/arAgingCust',
			currUser: 'auth/currUser'
			
		}),
	},
	watch: {
		arAgingCust: {
			handler(val){
				let current_date = this.getTotalAmount(val[Object.keys(val)]['0'].filter(details => details.aged_days <= 30));
				let next_date = this.getTotalAmount(val[Object.keys(val)]['0'].filter(details => details.aged_days > 30 && details.aged_days < 60));
				let next_date2 = this.getTotalAmount(val[Object.keys(val)]['0'].filter(details => details.aged_days > 60 && details.aged_days <= 90));
				let next_date3 = this.getTotalAmount(val[Object.keys(val)]['0'].filter(details => details.aged_days > 90 && details.aged_days <= 120));
				let last_date = this.getTotalAmount(val[Object.keys(val)]['0'].filter(details => details.aged_days > 120));
				val[Object.keys(val)]['current_date'] = current_date;
				val[Object.keys(val)]['next_date'] = next_date;
				val[Object.keys(val)]['next_date2'] = next_date2;
				val[Object.keys(val)]['next_date3'] = next_date3;
				val[Object.keys(val)]['last_date'] = last_date;
				numeral(val[Object.keys(val)]['total_remaining_balance']).format('0,0.00');
			}
		}
	},
	methods:{
		print() {
			window.print();
		},
		getTotalAmount(header){
			let amount = 0;
			for(var i=0; i < header.length; i++){
				amount += parseInt(header[i]['gross']);
			}
			return numeral(amount).format('0,0.00');
		},
		exportReport(){
			const contentHtml = this.$refs.table;
			const date = Math.floor(Date.now() / 1000);
			//var source = document.body;
			html2pdf()
				.from(contentHtml)
				.set({
					margin:       [80, 0, 30, 0],
					filename:     'subsidiary' + date +'.pdf',
					image:        { type: 'jpeg',quality: 0.98 },
					html2canvas:  { dpi: 96, scale: 1, scrollX: 0, scrollY: 0, backgroundColor: '#FFF' },
					jsPDF:        { unit: 'pt', format: 'a4', orientation: 'l' },
					pagebreak: { before: '.page-break', avoid: 'img' }
				})
				.toPdf()
				.get('pdf').then(function (pdf) {
					var totalPages = pdf.internal.getNumberOfPages();
                      
					for (let i = 1; i <= totalPages; i++) {
						pdf.setPage(i);
						pdf.setFontSize(9);
						pdf.text('PETNET, INC.', 10, 40);
						pdf.text('32nd Floor East Offices Aguirre St. Legaspi Village Makati City', 10, 50);
						pdf.text('Page ' + i + ' of ' + totalPages, pdf.internal.pageSize.getWidth() - 100, pdf.internal.pageSize.getHeight() - 40);
					} 
				}).save();
		},
		exportExcel(tableID, filename = '') {
			const date = Math.floor(Date.now() / 1000);
			var downloadLink;
			var dataType = 'application/vnd.ms-excel';

			// var x = document.getElementsByClassName('amount');
			// var i;
			// for (i = 0; i < x.length; i++) {
			// 	x[i].innerHTML = '\'' + x[i].innerHTML;
			// }
			var x = document.getElementsByClassName('description');
			var i;
			for (i = 0; i < x.length; i++) {
				x[i].innerHTML = x[i].innerHTML.replace(/[`~!@#$%^&*()|+=?;:'"<>]/gi, ' ');
			}
			var tableSelect = document.getElementById(tableID);
			var tableHTML = tableSelect.outerHTML.replace(/ /g, '%20');


			filename = filename ? filename + date + '.xls' : 'excel_data.xls';
			downloadLink = document.createElement('a');
			document.body.appendChild(downloadLink);

			if (navigator.msSaveOrOpenBlob) {
				var blob = new Blob(['\ufeff', tableHTML], {
					type: dataType
				});
				navigator.msSaveOrOpenBlob(blob, filename);
			} else {
				downloadLink.href = 'data:' + dataType + ', ' + tableHTML;
				downloadLink.download = filename;
				downloadLink.click();
			}
			
			// for (i = 0; i < x.length; i++) {
			// 	x[i].innerHTML = x[i].innerHTML.replace('\'', '');
			// }
		},
		getAmountFormat(amount){
			return numeral(amount).format('0,0.00');
		},
		getMonth(){
			var month = new Array();
			month[1] = 'January';
			month[2] = 'February';
			month[3] = 'March';
			month[4] = 'April';
			month[5] = 'May';
			month[6] = 'June';
			month[7] = 'July';
			month[8] = 'August';
			month[9] = 'September';
			month[10] = 'October';
			month[11] = 'November';
			month[12] = 'December';
			var n = month[this.detail.month];
			return n;
		},
		getDateFormat(date){
			var month = format(new Date(date), 'MMMM');
			var day = format(new Date(date), 'dd');
			var year = format(new Date(date), 'yyyy');
			return month + ' ' + day +', '+ year;
		}
	}
};
</script>
<style scoped>
.report-template {
  width: 768px;
  /* border: 1px solid black; */
  font-size: 12px;
  margin: auto;
}
.tbl {
  width: 95%;
  margin: auto;
  overflow: auto; 
  white-space: nowrap;
}
.code {
  text-align: center;
}
.amount {
  text-align: right;
}
.header {
  outline: 1px solid black;
  width: 100%;
  margin: 10px;
}
.total {
  text-align: right;
  font-weight: bold;
}
.action {
  padding: 20px;
}
.title {
  text-justify: center;
}
	@media print {
		body #printAction { 
			display:none; 
		}
    body #printArea { 
			display:block; 
		}
	} 
</style>