<template>
	<div>
		<div id="printAction">
			<v-btn color="primary" @click="printDocument"><v-icon left>mdi-printer</v-icon> Print This Form</v-btn>
		</div>
		<div id="printArea">
			<div style="padding-top: 20px;margin: auto;height: 500px; width: 600px; font-family: sans-serif;font-size: 12px; line-height: 20px;" >
            <div>
                <div style="float:left;margin:0%;border-right: 1px solid black;text-align: center; border: 1px solid black;width: 198px;height: 65px;">
                  <img class="petnet-logo" src="../../../../assets/petnet.png" alt="Petnet ERP">
                </div>
                <div style="float:left;margin:0%;padding-left: 5px; width: 195px; font-size: 10px;border-top: 1px solid black;border-bottom: 1px solid black;padding-top:10px;padding-bottom: 0px">
                    <p style="line-height: 15px;margin-bottom:8px">
                        East Office Building 114 Aguirre St.
                        <br>
                        Legaspi Village, Makati City, Philippines
                        <br>
                        VAT Registered TIN: 201-200-777-0000
                    </p>
                </div>
                <div style="float:right;margin:0%;width: 207px; padding-right: 5px; padding-top: 27.5px;padding-bottom: 15.5px;border-bottom: 1px solid black;border-right: 1px solid black;border-top: 1px solid black;">
                    <h2 style="text-align: right;">BILLING INVOICE</h2>
                </div>
                <div style="float:left;width:600px;border-left: 1px solid black;border-right: 1px solid black;border-bottom: 1px solid black;">
					<div style="margin: 20px;font-size: 10px; ">
						<p v-if="documentHeaders[0].accounting_status === 20"><b>CANCELLED INVOICE</b></p>
                        <table>
                            <tr>
                                <td style="width:80%">Customer Name:  {{payee_desc}}</td>
                                <td>Billing Number: {{documentHeaders[0].billing_invoice_no}}</td>
                            </tr>
                            <tr>
                                <td>Business Style:	{{business_style}}</td>
                                <td>Billing Date: {{getDateFormat(documentHeaders[0].billing_date)}}</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td style="width: 320px;">Address:  {{address}}</td>
                                <td>Term: {{documentHeaders[0].payment_term === 0 ? 'Due Immediately': documentHeaders[0].payment_term}}</td>
                            </tr>
                            <tr>
                                <td>TIN:  {{tin}}</td>
                                <td>Due Date: {{getDateFormat(documentHeaders[0].due_date)}}</td>
                            </tr>
                        </table>
                        <br>
                        <div style="float: left;width:100px; border: 1px solid black; padding: 0px; text-align: center;">
                            <b>No.</b>
                        </div>
                        <div style="float: left;width:354px; border-top: 1px solid black;border-bottom: 1px solid black; padding: 0px; text-align: center;">
                            <b>Description</b>
                        </div>
                        <div style="float: left;width:100px; border: 1px solid black; padding: 0px; text-align: center;">
                            <b>Amount</b>
                        </div>
                        <div style="float: left;width:100px; height: 150px;border: 1px solid black; padding: 0px; text-align: center;border-top: 0px; line-height: 10px">
                            <table style="text-align:right;width:100%">
								<tr v-for="(detail, detail_key) in getDetails(documentHeaders[0].details)" :key="detail_key">
									<td>{{detail_key + 1}}</td>
								</tr>
							</table>
                        </div>
                        <div style="float: left;width:354px; height: 150px;border-bottom: 1px solid black; padding: 0px;border-top: 0px; line-height: 10px">
                            <table style="width:100%">
								<tr v-for="(detail, detail_key) in getDetails(documentHeaders[0].details)" :key="detail_key">
									<td>{{getServiceName(detail.billing_service_id)}} - {{documentHeaders[0].remarks}}</td>
								</tr>
							</table>
                        </div>
                        <div style="float: left;width:100px; height: 150px; border: 1px solid black; padding: 0px; text-align: center;border-top: 0px; line-height: 10px">
							<table style="text-align:right;width:100%">
								<tr v-for="(detail, detail_key) in getDetails(documentHeaders[0].details)" :key="detail_key">
									<td>{{formatAmount(detail.debit_amount)}}</td>
								</tr>
							</table>
                        </div>
                        <div style="float: left;width:454.5px; height: 90px;padding-left: 310px; padding-right: 10px;">
                            <p style="text-align: left;line-height: 15px; margin-top: 3px;margin-bottom:0px">
                                Total
                                <br>
                                Vatable Sales
                                <br>
                                VAT Exempt Sales
                                <br>
                                Zero-rated Sales
                                <br>
                                Add: Value Added Tax
                                <br>
                            </p>
							<p style="text-align: left;font-weight:bold;margin-top:0px;line-height:10px">
                                Total Due
                            </p>
                        </div>
                        <div style="float: left;width:100px; height: 15px; border: 1px solid black; padding: 0px; text-align: right;border-top: 0px">
                            {{total}}
                        </div>
                        <div style="float: left;width:100px; height: 15px; border: 1px solid black; padding: 0px; text-align: right;border-top: 0px">
                           {{service}}
                        </div>
                        <div style="float: left;width:100px; height: 15px; border: 1px solid black; padding: 0px; text-align: right;border-top: 0px">
                            
                        </div>
                        <div style="float: left;width:100px; height: 15px; border: 1px solid black; padding: 0px; text-align: right;border-top: 0px">
                            
                        </div>
                        <div style="float: left;width:100px; height: 15px; border: 1px solid black; padding: 0px; text-align: right;border-top: 0px">
                            {{vat}}
                        </div>
                        <div style="font-weight:bold;float: left;width:100px; height: 15px; border: 1px solid black; padding: 0px; text-align: right;border-top: 0px">
                            {{total}}
                        </div>
                        <p>
                            Prepared By: {{getUser(documentHeaders[0].prepared_by)}}
                            <br>
                            Approved By: {{getUser(documentHeaders[0].accounting_approved_by)}}
                        </p>
                    </div>
                </div>
                <div style="float:left;width:600px;border-left: 1px solid black;border-right: 1px solid black;border-bottom: 1px solid black;">
                    <div style="margin: 20px;margin-top:0px;font-size: 10px;">
                        <div style="width: 60%; float: left;">
                            <p style="line-height: 15px;">
                                <b style="font-size: 10px;margin: 0px;">Send payment to: </b> 
                                <br>
                                PETNET, Inc.
                                <br>
                                114 East Offices Building
                                <br>
                                Aguirre St. Makati City 
                            </p>
                        </div>
                        <div style="width: 40%; float: right;">
                            <p style="line-height: 15px;">
                                <b style="font-size: 10px;">Special Instructions: </b>
                                <br>
                            </p>
                        </div>
                    </div>
                </div>
                <div style="float:left;width:600px;border-left: 1px solid black;border-right: 1px solid black;border-bottom: 1px solid black;">
                    <div style="margin: 20px;margin-top:0px;font-size: 8px;text-align: center; line-height: 0px;">
                        <p style="font-style: italic;padding-top: 10px">THIS DOCUMENT IS SYSTEM GENERATED.</p>
                        <p>THIS DOCUMENT IS NOT VALID FOR CLAIM OF INPUT VAT.</p>
                        <!-- <p>THIS IS A DUPLICATE/REPRINTED COPY.</p> -->
                        <br>
                        <table style="width: 200px; text-align: left; line-height: 12px;">
                            <tr>
                                <td colspan="2" style="font-size:7px">CAS Acknowledgment Certificate No. {{cas_no}}</td>
                            </tr>
                            <tr>
                                <td colspan="2" style="width: 40px;font-size:7px">Issue Date: {{issue_date}}</td>
                            </tr>
                            <tr>
                                <td style="font-size:7px">Series:</td>
                                <td style="font-size:7px">BI-HOB0000001 - BI-HOB9999999</td>
                            </tr>
                        </table>
                        <br>
                        <p style="margin-bottom: 0px; padding-top:10px;">THIS INVOICE SHALL BE VALID FOR FIVE (5) YEARS FROM THE DATE OF PERMIT TO USE.</p>
                    </div>
                </div>
            </div>
            
        </div>
		</div>
	</div>
</template>
<script>
import { mapGetters } from 'vuex';
import numeral from 'numeral';
import { format } from 'date-fns';
import numbertoWords from 'number-to-words';
export default {
	name: 'printBilling',data() {
		return {
			billingDetail:{},
			documentHeaders: [],
			address: '',
			tin: '',
			business_style: '',
			payee_desc: '',
			vat: 0,
			service: 0,
			total: 0,
			userData: [],
			nextPage: '',
			cas_no: '',
			issue_date: ''
		};
	},
	watch: {
		trxbillingDetails: {
			handler(val) {
				this.documentHeaders = val;
				this.getPayeeName(val[0].payee_id);
				let vat_total = this.getBillingDetails(val[0].details, 'Vat Amount');
				let vat = this.getTotals(vat_total,'credit_amount');
				this.vat = vat;
				let service_total = this.getBillingDetails(val[0].details, 'Income Amount');
				let service = this.getTotals(service_total,'credit_amount');
				this.service = service;
				let total_amount = this.getBillingDetails(val[0].details, 'Total Amount');
				let total = this.getTotals(total_amount,'debit_amount');
				this.total = total;
			}
		},
		users: {
			handler(){
				this.getData();
			}
		}
	},
	computed: {
		...mapGetters({
			payees: 'payefile/payefiles',
			trxbillingDetails: 'trxbilling/printDetails',
			coas: 'coa/coas',
			alphataxcodes: 'alphataxcode/alphataxcodes',
			services: 'services/services',
			users: 'user/users',
		}),
	},
	created() {
		var app = this;
		app.billingDetail = JSON.parse(atob(app.$route.params.data)); 
		this.issue_date = process.env.VUE_APP_ISSUE_DATE;
		this.cas_no = process.env.VUE_APP_CAS_NO;
	},
	mounted(){
		this.init();
	},
	methods: {
		async getData(){
			this.userData = this.users.data;
			this.nextPage = this.users.next_page_url;
			let page = 2;

			while (!this.users.data){
				await this.$store.dispatch('users/getData', page).then(response => {
					this.nextPage = response.data.data.next_page_url;
					response.data.data.data.forEach(details => {
						this.userData.push(details);
					});
				});
				page++;
			}
		},
		getDetails(data){
			let details  = data.filter(det => det.remarks === 'Total Amount');
			return details;
		},
		getTotals(data,amount){
			let total_amount = data.reduce((sum, details) => {
				if (details.amount === '') {
					details.amount = 0;
				}

				return sum + parseFloat(details[amount].replace(/,/g, ''));
			}, 0);
			return numeral(total_amount).format('0,0.00');

		},
		getBillingDetails(data, type){
			return data.filter(det => det.remarks === type);
		},
		printDocument() {
			window.print();
		},
		init(){
			this.$store.dispatch('user/getUsers');
			this.$store.dispatch('payefile/getPayefileDropdown');
			this.$store.dispatch('alphataxcode/getAlphataxcodes');
			this.$store.dispatch('services/getServices');
			this.$store.dispatch('trxbilling/getORARData', this.billingDetail.billing_request_no );


		},
		getDateFormat(date){
			return format(new Date(date), 'MM-d-yyyy');
		},
		formatAmount(amount){
			return numeral(amount).format('0,0.00');
		},
		getNumberFormat(){
			let amount = 0;
			let details = this.documentHeaders[0].details.filter(det => det.acct_desc !== 'Creditable Withholding Tax');
			details.map(det=> {
				let total = parseFloat(det.debit_amount) + parseFloat(det.credit_amount);
				amount += total;
			});
			return numeral(amount).format('0,0.00');
		},
		getAmountinWords(){
			let amount = this.getAmountDue();
			amount = amount.replace(',','');
			let amountWord = numbertoWords.toWords(amount); 
			let newAmount = amountWord.replace(/,/g, '');
			
			let cent = '';
			let arr = numeral(amount).format('00.00').split('.');
			if(arr[1] != '00'){
				cent = ' & '+ arr[1] + '/100';
			}
			return newAmount.toUpperCase() + ' ' + this.getCurrency() + ' ' + cent + ' ONLY.';
		},
		async getPayeeName(id){
			await this.$store.dispatch('payefile/getPayeeDet', id).then(response => {
				let data = response.data.data;
				this.address = data[0].house_unit + ' ' + data[0].street + data[0].municipality + ' ' + data[0].city; 
				this.business_style = data[0].business_style;
				this.tin = data[0].tin;
				this.payee_desc = data[0].payee_desc;
			});
			// const getPayee = this.payees.filter(payee =>payee.id === id);

			// if (getPayee) {
			// 	return getPayee[0].payee_desc;
			// }
		},
		getLessVat(){
			if(this.documentHeaders[0].vat == 1){
				let amount = this.getTotalSale();
				amount = amount.replace(',','');
				let lessVat = (parseFloat(amount)/1.12)*0.12;
				return numeral(lessVat).format('0,0.00');
			} else {
				return '-';
			}
		},
		getAmountTotal(){
			let amount = this.getTotalSale();
			amount = amount.replace(',','');
			let lessVat = this.getLessVat() != '-' ? this.getLessVat().replace(',','') : 0;
			//lessVat = lessVat.replace(',','');
			let amountTotal = parseFloat(amount) - parseFloat(lessVat);

			return numeral(amountTotal).format('0,0.00');
		},
		getTotalDue(){
			let amount = this.getNumberFormat();

			return numeral(amount).format('0,0.00');
		},
		getServiceName(id){
			let service = this.services.filter(det =>  det.id === id );
			return service[0].billing_service_name;
		},
		getTotalLessVat(){
			let amount = 0;
			let details = this.documentHeaders[0].details.filter(det => det.acct_desc === 'Creditable Withholding Tax');
			if(details.length > 0){
				details.map(det=> {
					let total = parseFloat(det.debit_amount) + parseFloat(det.credit_amount);
					amount += total;
				});
				return numeral(amount).format('0,0.00');
			} else {
				return '-';
			}
		},
		getAmountDue(){
			let amount = this.getNumberFormat();

			// amount = amount.replace(',','');
			// let lessVat = this.getTotalLessVat();
			// lessVat = lessVat.replace(',','');
			// let amountTotal = parseFloat(amount) - parseFloat(lessVat);

			return numeral(amount).format('0,0.00');
		},
		getVatSale(){
			let amount = this.getAmountTotal();
			
			return numeral(amount).format('0,0.00');
		},
		getVatAmount(){
			let amount = this.getLessVat();
			if (amount != '-'){
				return numeral(amount).format('0,0.00');
			} else {
				return '-';
			}
		},
		getTotalSale(){
			let vat = this.getTotalLessVat() != '-' ? parseFloat(this.getTotalLessVat().replace(',','')) : 0;
			let amount = parseFloat(this.getAmountDue().replace(',','')) + vat;
			
			return numeral(amount).format('0,0.00');
		},
		getCurrency(){
			let curr_id = this.documentHeaders[0].currency_id;
			let currency = 'PESOS';
			switch (curr_id) {
			case 2:
				currency = 'DOLLARS';
				break;
			case 4:
				currency = 'YENS';
				break;
			case 5:
				currency = 'SINGAPOREAN DOLLAR';
				break;
			default:
				currency =  'PESOS';
				break;
			}

			return currency;
		},
		getUser(id){
			let user = this.userData.filter(det => det.id === id);
			if(user.length !== 0){
				return user[0].user_first_name + ' ' + user[0].user_last_name;
			} else {
				return '';
			}
		}
	}
};
</script>
<style scoped>
	

	/* .cheque-date {
		right: 260px;
		top: 130px;
    font-size: 25px;
    font-family: arial;
	} */
	.petnet-logo {
		width: 180px;
		margin: auto;
		margin-top: 10px;
	}

	@media print {
		body #printAction { 
			display:none; 
		}

    body #printArea { 
			display:block; 
		}
		body .receipt-file {
			display: none;
		}

		body #documentNumber {
			display: none;
		}
	}
</style>