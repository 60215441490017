export default {
	bankheaders: [],
	headers: [
		{
			text: 'Record ID',
			align: 'left',
			sortable: false,
			value: 'id',
		},
		{
			text: 'Bank Code',
			align: 'left',
			sortable: false,
			value: 'bank_code',
		},
		{ text: 'Bank Name', value: 'bank_name' },
		{ text: 'Actions', value: 'action', sortable: false },
	],
	columnHeaders: [
		{ label: 'Record ID', field: 'id' },
		{ label: 'Bank Code', field: 'bank_code' },
		{ label: 'Bank Name', field: 'bank_name' },
	],
	editedItem: {
		id: '',
		bank_code: '',
		bank_name: ''
	},
	defaultItem: {
		id: '',
		bank_code: '',
		bank_name: ''
	},
	dialog: false,
	editedIndex: -1,
	valid: true,
	formTitle: 'Edit entry',
};