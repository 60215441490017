const isAuthenticated = state => state.isAuthenticated;
const currUser = state => state.currUser;
const roleAccess = state => state.roleAccess;
const authStatus = state => state.status;
const token = state => state.token;
const isLoading = state => state.isLoading;
const isLoadingEmail = state => state.isLoadingEmail;
const isLoadingOkta = state => state.isLoadingOkta;
const error = state => state.error;
const showResult = state => state.showResult;
const result = state => state.result;
const hrisToken = state => state.hrisToken;

export default {
	isAuthenticated,
	currUser,
	authStatus,
	token,
	isLoadingEmail,
	isLoading,
	error,
	showResult,
	result,
	roleAccess,
	hrisToken,
	isLoadingOkta
};