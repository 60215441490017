<template>
  <div>
    <div id="printAction" class="action">
      <v-row justify="center" align="center">
        <v-spacer class="hidden-sm-and-down"></v-spacer>
        <v-col cols="6">
          <h4>Report Name: BILLING LIST</h4>
        </v-col>
        <v-col cols="4">
           <v-btn color="success" @click="exportExcel('printArea', 'billing-list')"><v-icon left>mdi-microsoft-excel</v-icon> Export</v-btn>
        </v-col>
      </v-row>
    </div>
    <div id="printArea" ref="content" style="width: 1200px; font-size: 12px; margin: auto;">
      <v-row>
        <v-col cols="6">
          <p>
            <br>
            PETNET, INC.
            <br>
            SUMMARY OF BILLING AND COLLECTION
			<br>
			{{getDateFormat(detail.date_from)}} TO {{getDateFormat(detail.date_to)}}
          </p>
        </v-col>
      </v-row>
	<br>
      
      <table id="table" style="width: 95%; margin: auto; border: 1px solid black" ref="table">
        <tr>
          <th>Name</th>
          <th>Billing Number</th>
          <th>Billing Date</th>
          <th>Term</th>
          <th>Due Date</th>
          <th>Description</th>
          <th>Amount Billed</th>
          <th>Status</th>
          <th>Date Collected</th>
          <th>Amount Collected</th>
          <th>Amount Uncollected</th>
        </tr>
		<tr v-for="details in filteredItems" :key="details.id">
          <td>{{details.name}}</td>
          <td style="text-align: right;">{{details.billing_number}}</td>
          <td >{{date(details.billing_date)}}</td>
          <td style="text-align: right;">{{details.term}}</td>
          <td>{{date(details.due_date)}}</td>
          <td>{{details.description}}</td>
          <td style="text-align: right;">{{details.amount_billed}}</td>
          <td>{{getStatus(details.status)}}</td>
          <td style="text-align: center;">{{details.date_collected}}</td>
          <td style="text-align: right;">{{(!details.amount_collected ? "0.00" : details.amount_collected)}}</td>
          <td style="text-align: right;">{{(!details.amount_uncollected ? "0.00" : details.amount_uncollected)}}</td>
		</tr>
        
      </table>
	<br>
	<v-row>
		<v-col cols="1"></v-col>
        <v-col cols="5">
          <p style="font-size: 10px">
            <br>
            Company Name: PETNET, INC.
            <br>
            Address: East Offices Building 114 Aguirre St. Legaspi Village,Makati City
			<br>
			VAT Reg TIN: 201-200-777-000
			<br>
			System: Enterprise Resource Planning
			<br>
			Version: 1.0.0.
          </p>
        </v-col>
        <v-col cols="5">
          <p style="font-size: 10px">
            <br>
            Generated By: {{generated_by}}
            <br>
			Generated Date: {{generated_date}}
			<br>
			Generated Time: {{generated_time}}
          </p>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import numeral from 'numeral'; 
import { format } from 'date-fns';
export default {
	data: () => ({
		detail: {
			payee_id: '',
			status: '',
			date_from: '',
			date_to:''
		},
		generated_by: '',
		generated_time: new Date().toLocaleTimeString(),
		generated_date: new Date().toLocaleDateString()
	}),
	mounted() {
		this.$store.dispatch('report/getBillingList', this.detail);
		this.$store.dispatch('payefile/getPayefileDropdown'); 
	},
	created() {
		var app = this;
		let data = JSON.parse(atob(app.$route.params.data)); 
		app.generated_by = data.user;
		app.detail = {
			'payee_id': data.payee_id,
			'status': data.status,
			'date_from': data.date_from,
			'date_to': data.date_to
		};
	},
	computed: {
		...mapGetters({
			billingList: 'report/billingList',
			payees: 'payefile/payefiles',
			currUser: 'auth/currUser'
		}),
		filteredItems(){
			let filteredItem = [];
			let data = this.billingList;
			data.forEach(element => {
				if(element.collections.length !== 0){
					let collection_date = '';
					element.collections.forEach(e => {
						if(collection_date === ''){
							collection_date = this.date(e.collection_date);
						} else {
							collection_date = collection_date + ',' + this.date(e.collection_date);
						}
					});
					element.date_collected = collection_date;
				} else {
					element.date_collected = '';
				}
				filteredItem.push(element);
			});
			return filteredItem;
		}
	},
	methods:{
		print() {
			window.print();
		},
		exportExcel(tableID, filename = '') {
			const date = Math.floor(Date.now() / 1000);
			var downloadLink;
			var dataType = 'application/vnd.ms-excel';
			
			var x = document.getElementsByClassName('description');
			var i;
			for (i = 0; i < x.length; i++) {
				x[i].innerHTML = x[i].innerHTML.replace(/[`~!@#$%^&*()|+=?;:'"<>]/gi, ' ');
			}
			var tableSelect = document.getElementById(tableID);
			var tableHTML = tableSelect.outerHTML.replace(/ /g, '%20');


			filename = filename ? filename + date + '.xls' : 'excel_data.xls';
			downloadLink = document.createElement('a');
			document.body.appendChild(downloadLink);

			if (navigator.msSaveOrOpenBlob) {
				var blob = new Blob(['\ufeff', tableHTML], {
					type: dataType
				});
				navigator.msSaveOrOpenBlob(blob, filename);
			} else {
				downloadLink.href = 'data:' + dataType + ', ' + tableHTML;
				downloadLink.download = filename;
				downloadLink.click();
			}
			
			// for (i = 0; i < x.length; i++) {
			// 	x[i].innerHTML = x[i].innerHTML.replace('\'', '');
			// }
		},
		getAmountFormat(amount){
			return numeral(amount).format('0,0.00');
		},
		getDateFormat(date){
			var month = format(new Date(date), 'MMMM');
			var day = format(new Date(date), 'dd');
			var year = format(new Date(date), 'yyyy');
			return month.toUpperCase() + ' ' + day +', '+ year;
		},
		date(date){
			return format(new Date(date), 'yyyy-MM-dd');
		},
		getAnalysis(code){
			let name = '';
			this.payees.filter(det => {
				if(det.payee_code == code){
					name = det.payee_desc;
				}
			});
			return name;
		},
		getStatus(stat){
			switch(stat){
			case 3:
				return 'For Approval 1';
			case 4:
				return 'For Approval 2';
			case 5:
				return 'For Approval 3';
			case 7:
				return 'Approved';
			} 
		}
	}
};
</script>
<style scoped>
.report-template {
  width: 768px;
  /* border: 1px solid black; */
  font-size: 12px;
  margin: auto;
}
.tbl {
  width: 95%;
  margin: auto;
}
.code {
  text-align: center;
}
.amount {
  text-align: right;
}
.header {
  outline: 1px solid black;
  width: 100%;
  margin: 10px;
}
.total {
  text-align: right;
  font-weight: bold;
}
.action {
  padding: 20px;
}
.title {
  text-justify: center;
}
	@media print {
		body #printAction { 
			display:none; 
		}
		body #printArea { 
			display:block; 
		}
	} 
</style>