import Repository from '../Repository';

const resource = 'trx/po-header';

export default {
	getPONumber(payload) {
		return Repository.post(`${'/trx/po-number'}`, payload);
	},
	submitPO(payload) {
		return Repository.post(`${resource}`, 
			payload,
			{
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}
		);
	},
	getGridPO(payload) {
		return Repository.get(`${resource}?po_date=`+ payload);
	},
	// getPO(payload) {
	// 	return Repository.get(`${resource}/`+ payload);
	// },
	// getDetailPO() {
	// 	return Repository.get('/trx/po-detail');
	// },
	getPO(payload) {
		return Repository.post('trx/purchase-order/'+ payload);
	},
	updateRequestStatus(payload) {
		console.log(payload);
		return Repository.post(`${resource}/`+payload.po_number, payload);
	},
	editPO(payload) {
		console.log(payload);
		return Repository.post(`${resource}/`+payload.po_number, 
			payload.data,
			{
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}
		);
	},
	getDetail(payload){
		return Repository.get(`${resource}/`+payload);
	}
};