const trxdisbs = state => state.trxdisbs;
const trxdisbDetails = state => state.trxdisbDetails;
const trxdisbPrintDetails = state => state.trxdisbPrintDetails;
const transactionResults = state => state.transactionResults;
const headers = state => state.headers;
const headersCashReceipt = state => state.headersCashReceipt;
const batchNumber = state => state.batchNumber;
const documentNumber = state => state.documentNumber;
const documentDate = state => state.documentDate;
const atcCode = state => state.atcCode;
const dollarRate = state => state.dollarRate;
const vAt = state => state.vAt;
const bankCode = state => state.bankCode;
const bankAccount = state => state.bankAccount;
const amount = state => state.amount;
const payeeId = state => state.payeeId;
const editedItem = state => state.editedItem;
const defaultItem = state => state.defaultItem;
const dialog = state => state.dialog;
const editedIndex = state => state.editedIndex;
const valid = state => state.valid;
const formTitle = state => state.formTitle;
const editDialog = state => state.editDialog;
const viewDialog = state => state.viewDialog;
const preparer = state => state.preparer;
const attachmentDialog = state => state.attachmentDialog;
const dist_data = state => state.dist_data;
const newDocNum = state => state.newDocNum;
const success = state => state.success;
const trxdisbDetailed = state => state.trxdisbDetailed;
const birFormDetails = state => state.birFormDetails;
const printDetails = state => state.printDetails;
const csvFileUploads = state => state.csvFileUploads;
const or_number = state => state.or_number;
const ar_number = state => state.ar_number;
const po_details = state => state.po_details;
const uploadEntries = state => state.uploadEntries;
const ereceiptDetails = state => state.ereceiptDetails;

export default {
	trxdisbs,
	trxdisbDetails,
	trxdisbPrintDetails,
	transactionResults,
	headers,
	headersCashReceipt,
	batchNumber,
	documentNumber,
	documentDate,
	atcCode,
	dollarRate,
	vAt,
	bankCode,
	bankAccount,
	amount,
	payeeId,
	editedItem,
	defaultItem,
	dialog,
	editedIndex,
	valid,
	formTitle,
	editDialog,
	viewDialog,
	attachmentDialog,
	preparer,
	dist_data,
	newDocNum,
	trxdisbDetailed,
	success,
	birFormDetails,
	printDetails,
	csvFileUploads,
	po_details,
	uploadEntries,
	ar_number,
	or_number,
	ereceiptDetails
};