import Vue from 'vue';
import { RepositoryFactory } from '../../../api_repo/RepositoryFactory';

const sectionsRepository = RepositoryFactory.get('section');

const getSections = (context) => {
	context.commit('SET_ISLOADING',true);
	sectionsRepository.get()
		.then((response) => {
			context.commit('SECTIONS_UPDATED', response.data.data);
			context.commit('SET_ISLOADING',false);
		})
		.catch((error) => {
			context.commit('SET_ISLOADING',false);
			Vue.swal.fire({
				type: 'error',
				title: 'Failed getting data',
				text: error.response.data.message,
				timer: 2000
			});
		});    
};

const getSectionDropdown = (context) => {
	sectionsRepository.get()
		.then((response) => {
			context.commit('SECTIONS_UPDATED', response.data.data);
		});    
};


const saveSection = (context,payload) => {
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	sectionsRepository.saveSection(payload)
		.then((response) => {
			//context.commit('SECTIONS_ADD',response.data.data);
			context.commit('dept/DEPTS_SECTION_ADDED',response.data.data,{ root: true });
			context.commit('app/SET_IS_LOADING',false,{ root: true });
			Vue.swal.fire({
				type: 'success',
				title: 'Your work has been saved',
				text: response.data.data.section_name + '(' + response.data.data.section_code + ') added.',
				timer: 2000
			});
			context.commit('SET_DIALOG', false);
		})
		.catch((error) => {
			context.commit('app/SET_IS_LOADING',false,{ root: true });
			var newErrorMessage = '';
			if (error.response.data.errors) {
				newErrorMessage = getError(error.response.data.errors);
			} else {
				newErrorMessage = error.response.data.message;
			}
			Vue.swal
				.fire({
					type: 'error',
					title: 'Saving failed!!',
					html: newErrorMessage
				}); 
		});
};

const updateSection = (context,payload) => {
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	sectionsRepository.updateSection(payload.id,payload)
		.then((response) => {
			context.commit('app/SET_IS_LOADING',false,{ root: true });
			context.commit('dept/DEPTS_SECTION_UPDATE',response.data.data,{ root: true });
			//context.commit('SECTIONS_ITEM_UPDATE',response.data.data);
			Vue.swal.fire({
				type: 'success',
				title: 'Your work has been updated',
				text: response.data.data.section_name + '(' + response.data.data.section_code + ') updated.',
				timer: 2000,
				// position: "bottom",
				// backdrop: "linear-gradient(yellow, orange)",
			});
			context.commit('SET_DIALOG', false);
		})
		.catch((error) => {
			context.commit('app/SET_IS_LOADING',false,{ root: true });
			var newErrorMessage = '';
			if (error.response.data.errors) {
				newErrorMessage = getError(error.response.data.errors);
			} else {
				newErrorMessage = error.response.data.message;
			}
			Vue.swal
				.fire({
					type: 'error',
					title: 'Saving failed!!',
					html: newErrorMessage
				}); 
		});
};

const deleteSection = (context,payload) => {  
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	sectionsRepository.deleteSection(payload.section_id)
		.then((response) => {
			// context.commit('SECTIONS_DELETE',response.data.data);
			context.commit('dept/DEPTS_SECTION_DELETE', false, { root: true });
			context.commit('app/SET_IS_LOADING',false,{ root: true });
			Vue.swal.fire({
				type: 'success',
				title: 'Delete successful',
				text: response.data.data.section_name + '(' + response.data.data.section_code + ') deleted.',
				timer: 2000
			});
		})
		.catch((error) => {
			context.commit('app/SET_IS_LOADING',false,{ root: true });
			Vue.swal.fire({
				type: 'error',
				title: 'Delete fail',
				text: error.response.data.message,
				timer: 2000
			});
		});
};

const getData = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true , { root: true });
	return new Promise((resolve, reject) => {
		sectionsRepository.getData(payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING', false , { root: true });
				resolve(response);
			}, error => {
				context.commit('app/SET_IS_LOADING', false , { root: true });
				reject(error);
			});
	});
};

const getError = (payload) => {
	var myObject = payload;
	var newerror = [];
	for (var name in myObject) {
		var replacement = name.split('.')[2];
		if(replacement == undefined){
			replacement = name;
		}
		var errors = payload[name][0];
		var errorDesc = errors.replace(name, replacement);
		var replacedash = errorDesc.replace('_', ' ');
		newerror.push(replacedash);
	}
	newerror.sort();
	var current = null;
	var cnt = 0;
	for (var i = 0; i < newerror.length; i++) {
		var errormessage;
		if (newerror[i] != current) {
			if (cnt > 0) {
				errormessage +=current + '<br>';
			}
			current = newerror[i];
			cnt = 1;
		} else {
			cnt++;
		}
	}
	if (cnt > 0) {
		errormessage +=
						current + '<br>';
	}
	return errormessage.replace('undefined', '');
};

const setEditedItems = (context,payload) => {
	context.commit('SET_EDITED_ITEM',payload);
};

const setDialog = (context,payload) => {
	context.commit('SET_DIALOG',payload);
};

const setEditedIndex = (context,payload) => {
	context.commit('SET_EDITED_INDEX',payload);
};

const setValid = (context,payload) => {
	context.commit('SET_VALID',payload);
};

const setformTitle = (context,payload) => {
	context.commit('SET_FORM_TITLE',payload);
};

const setisLoading = (context,payload) => {
	context.commit('SET_ISLOADING',payload);
};

export default {
	getSections,
	getSectionDropdown,
	saveSection,
	updateSection,
	deleteSection,
	setEditedItems,
	setDialog,
	setEditedIndex,
	setValid,
	setformTitle,
	setisLoading,
	getError,
	getData
};