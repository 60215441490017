import Vue from 'vue';

const TRXDISBS_SHOW_DETAIL = (state, payload) => {
	state.trxdisbDetails = payload;
};

const TRXDISBS_UPDATED = (state, payload) => {
	state.trxdisbs = payload;
};

const TRXDISBS_ADD = (state, payload) => {
	state.trxdisbs =  Object.values(state.trxdisbs);
	state.trxdisbs.push(payload);
};

const TRXDISBS_DELETE = (state, payload) => {
	let index = state.trxdisbs.indexOf(payload);
	state.trxdisbs.splice(index, 1);
};

const TRXDISBS_ITEM_UPDATE = (state, payload) => {
	Vue.set(state.trxdisbs, state.editedIndex, payload);
};

const TRXDISBS_EDIT_MODAL_VIEW = (state,payload) => {
	state.editDialog = payload;
};

const TRXDISBS_DETAIL_MODAL_VIEW = (state,payload) => {
	state.viewDialog = payload;
};

const TRXDISBS_ATTACHMENT_MODAL_VIEW = (state,payload) => {
	state.attachmentDialog = payload;
};

const TRXDISBS_EDIT_PROMPT_DIALOG = (state,payload) => {
	state.updateDisbursement = payload;
};

const SET_EDITED_INDEX = (state, payload) => {
	state.editedIndex = payload;
};

const SET_PREPARER = (state, payload) => {
	state.preparer = payload;
};

const SET_DIST_DATA = (state, payload) => {
	state.dist_data = payload;
};

const TRXDISBS_SHOW_PRINT_DETAIL = (state, payload) => {
	state.trxdisbPrintDetails = payload;
};

const SHOW_FORM_2307_DETAIL = (state, payload) => {
	state.birFormDetails = payload;
};

const TRXDISBS_DETAIL = (state, payload) => {
	state.trxdisbDetailed = payload;
};

const SET_SUCCESS = (state, payload) => {
	state.success = payload;
};

const TRX_SEARCH_RESULT = (state, payload) => {
	state.transactionResults = payload;
};

const SET_PRINT_DETAILS = (state, payload) => {
	state.printDetails = payload;
};

const SHOW_UPLOADED_CSV_FILES = (state, payload) => {
	state.csvFileUploads = payload;
};

const SET_AR_NUMBER = (state, payload) => {
	state.ar_number = payload;
};

const SET_OR_NUMBER = (state, payload) => {
	state.or_number = payload;
};

const TRXDISBS_PO_DETAILS = (state, payload) => {
	state.po_details = payload;
};

const SET_UPLOAD_ENTRIES = (state, payload) => {
	state.uploadEntries = payload;
};

const SET_ERECEIPT_DETAILS = (state, payload) => {
	state.ereceiptDetails = payload;
};

export default {
	TRXDISBS_UPDATED,
	TRXDISBS_ADD,
	TRXDISBS_DELETE,
	TRXDISBS_ITEM_UPDATE,
	TRXDISBS_SHOW_DETAIL,
	TRXDISBS_EDIT_MODAL_VIEW,
	TRXDISBS_DETAIL_MODAL_VIEW,
	TRXDISBS_ATTACHMENT_MODAL_VIEW,
	TRXDISBS_EDIT_PROMPT_DIALOG,
	TRX_SEARCH_RESULT,
	SET_EDITED_INDEX,
	SET_PREPARER,
	SET_DIST_DATA,
	TRXDISBS_SHOW_PRINT_DETAIL,
	TRXDISBS_DETAIL,
	SET_SUCCESS,
	SHOW_FORM_2307_DETAIL,
	SET_PRINT_DETAILS,
	SHOW_UPLOADED_CSV_FILES,
	TRXDISBS_PO_DETAILS,
	SET_UPLOAD_ENTRIES,
	SET_AR_NUMBER,
	SET_OR_NUMBER,
	SET_ERECEIPT_DETAILS
};