const alphataxcodes = state => state.alphataxcodes;
const headers = state => state.headers;
const columnHeaders = state => state.columnHeaders;
const atcCode = state => state.atcCode;
const editedItem = state => state.editedItem;
const defaultItem = state => state.defaultItem;
const dialog = state => state.dialog;
const editedIndex = state => state.editedIndex;
const valid = state => state.valid;
const formTitle = state => state.formTitle;

export default {
	alphataxcodes,
	columnHeaders,
	headers,
	atcCode,
	editedItem,
	defaultItem,
	dialog,
	editedIndex,
	valid,
	formTitle
};