import Repository from '../Repository';
import axios from 'axios';

const resource = '/payefile';

export default {
	get() {
		return Repository.get(`${resource}`);
	},

	getPayefile(id) {
		return Repository.get(`${resource}/${id}`);
	},

	savePayefile(payload) {
		return Repository.post(`${resource}`, payload);
	},

	updatePayefile(id, payload) {
		return Repository.put(`${resource}/${id}`, payload);
	},

	deletePayefile(id) {
		return Repository.delete(`${resource}/${id}`);
	},

	searchPayefile(payload){
		return Repository.get(`${resource}`,payload);
	},
	
	getData(payload){
		return Repository.get(`${resource}?page=`+ payload);
	} ,

	getAddress(payload, token) {
		return axios.get(process.env.VUE_APP_HRIS_BASE_URL + '/' + payload.url + '/' + payload.data, {
			headers: {
				'Authorization': 'Bearer ' + token,
			}
		});
	} 
};


