import Repository from '../Repository';

const resource = '/approver';

export default {
	get() {
		return Repository.get(`${resource}`);
	},

	saveApprovingOfficer(payload) {
		return Repository.post(`${resource}`, payload);
	},

	updateApprovingOfficer(id, payload) {
		return Repository.put(`${resource}/${id}`, payload);
	},

	deleteApprovingOfficer({id}) {
		return Repository.delete(`${resource}/${id}`);
	},

	getDetails(id) {
		return Repository.get(`${resource}/${id}`);
	},

	getData(payload) {
		return Repository.get(`${resource}?page=`+ payload);
	},

};