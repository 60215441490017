const useraccessprivileges = state => state.useraccessprivileges;
const headers = state => state.headers;
const userId = state => state.userId;
const progCode = state => state.progCode;
const accessLevel = state => state.accessLevel;
const editedItem = state => state.editedItem;
const defaultItem = state => state.defaultItem;
const dialog = state => state.dialog;
const editedIndex = state => state.editedIndex;
const valid = state => state.valid;
const formTitle = state => state.formTitle;


export default {
	useraccessprivileges,
	headers,
	userId,
	progCode,
	accessLevel,
	editedItem,
	defaultItem,
	dialog,
	editedIndex,
	valid,
	formTitle
};