<template>
  <div>
    <div id="printAction" class="action">
      <v-row justify="center" align="center">
        <v-spacer class="hidden-sm-and-down"></v-spacer>
        <v-col cols="6">
          <h4>Report Name: SUBSIDIARY</h4>
        </v-col>
        <v-col cols="4">
          <!-- <v-btn color="primary"  @click="print"><v-icon left>mdi-printer</v-icon> Print</v-btn> -->
          <!-- <v-btn color="error" @click="exportReport"><v-icon left>mdi-file-pdf-outline</v-icon>Export</v-btn> -->
          <v-btn color="success" @click="exportExcel('printArea','subsidiary')"><v-icon left>mdi-microsoft-excel</v-icon>Export</v-btn>
        </v-col>
      </v-row>
    </div>
    <div id="printArea" ref="content" style="width: 1248px; font-size: 12px; margin: auto;">
      <v-row>
        <v-col cols="12" class="text-center">
          <p>
            <br>
            PETNET, INC.
            <br>
            32nd Floor East Offices Aguirre St. Legaspi Village Makati City
          </p>
        </v-col>
        <v-spacer />
      </v-row>
      <v-row>
        <v-col class="text-center">
          <p>
            SUBSIDIARY BY ACCOUNT
            <br>
            For the Period of {{getDateFormat(detail.date_from)}} to {{getDateFormat(detail.date_to)}}
          </p>
        </v-col>
      </v-row>
      <!-- <div style="outline: 1px solid black;width: 95%; margin: auto;"></div> -->
      <div class="tbl">
        <table id="table" style="width: 95%; margin: auto;" ref="table">
          <tr style="outline: 1px solid black; width: 100%; margin: 10px;">
          <tr>
            <th>TRANSACTION TYPE</th>
            <th>ACCT CODE</th>
            <th>ACCT DESC</th>
            <th>BRANCH</th>
            <th>PAYEE CODE</th>
            <th>DATE</th>
            <th>DOC No.</th>
            <th>PARTICULARS</th>
            <th>DESCRIPTION</th>
            <th>DEBIT</th>
            <th>CREDIT</th>
            <th>RATE</th>
            <th>PHP DEBIT</th>
            <th>PHP CREDIT</th>
            <th>PREPARER</th>
            <th>EDIT DATE</th>
            <th>BATCH NUMBER</th>
            <th>REFERENCE NUMBER</th>
          </tr>
          <tr style="outline: 1px solid black; width: 100%; margin: 10px;">
          </tr>
          
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <th>Beg Bal</th>
            <td></td>
            <th class="amount">{{getAmountFormat(SubsData.data2.beginning_cur_debit)}}</th>
            <td></td>
            <td></td>
            <th class="amount">{{getAmountFormat(SubsData.data2.beginning_peso_debit)}}</th>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr v-for="details in SubsData.data1" :key="details.id">
            <td>{{details.transaction_type}}</td>
            <td>{{details.acct_code}}</td>
            <td>{{details.acct_desc}}</td>
            <td>{{details.branch}}</td>
            <td>{{details.payee_code}}</td>
            <td>{{details.document_date}}</td>
            <td>{{details.document_number}}</td>
            <td class="description">{{details.particulars}}</td>
            <td class="description">{{details.description}}</td>
            <td class="amount">{{getAmountFormat(details.debit)}}</td>
            <td class="amount">{{getAmountFormat(details.credit)}}</td>
            <td style="text-align:right">{{details.rate}}</td>
            <td class="amount">{{getAmountFormat(details.php_da)}}</td>
            <td class="amount">{{getAmountFormat(details.php_ca)}}</td>
            <td>{{details.edit_by}}</td>
            <td>{{details.edit_date}}</td>
            <td>{{details.batch_number}}</td>
            <td>{{details.reference_number}}</td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <th>Total</th>
            <td></td>
            <th class="amount">{{getAmountFormat(SubsData.data2.total_debit)}}</th>
            <th class="amount">{{getAmountFormat(SubsData.data2.total_credit)}}</th>
            <td></td>
            <th class="amount">{{getAmountFormat(SubsData.data2.total_php_debit)}}</th>
            <th class="amount">{{getAmountFormat(SubsData.data2.total_php_credit)}}</th>
            <td></td>
            <td></td>
          </tr>
          <tr></tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <th>Movement For the Month</th>
            <td></td>
            <th class="amount">{{getAmountFormat(SubsData.data2.total_move_ftm_debit)}}</th>
            <td></td>
            <td></td>
            <th class="amount">{{getAmountFormat(SubsData.data2.total_move_ftm_ca)}}</th>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr></tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <th>Ending Balance</th>
            <td></td>
            <th class="amount">{{getAmountFormat(SubsData.data2.ending_cur_debit)}}</th>
            <th></th>
            <td></td>
            <th class="amount">{{getAmountFormat(SubsData.data2.ending_peso_debit)}}</th>
            <th></th>
            <td></td>
            <td></td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
// import jsPDF from 'jspdf';
import html2pdf from 'html2pdf.js';
//import 'jspdf-autotable';
import { mapGetters } from 'vuex';
import numeral from 'numeral';
import { format } from 'date-fns'; 
export default {
	data: () => ({
		detail: {}
	}),
	mounted() {
		this.$store.dispatch('report/getSubsData', this.detail);
	},
	created() {
		var app = this;
		app.detail = JSON.parse(atob(app.$route.params.data)); 
		// app.detail = {
		// 	'account_code': 1,
		// 	'cost_center': 1,
		// 	'book_type': 'sample',
		// 	'transaction_type': 'business',
		// 	'payee': 'EM50991',
		// 	'document_date': '2020-12-01',
		// 	'department': 'General Services Department',
		// 	'date_from': '2020-08-24',
		// 	'date_to': '2021-10-14'
		// };
	},
	computed: {
		...mapGetters({
			SubsData: 'report/subsData',
		}),
	},
	methods:{
		print() {
			window.print();
		},
		exportReport(){
			const contentHtml = this.$refs.table;
			const date = Math.floor(Date.now() / 1000);
			//var source = document.body;
			html2pdf()
				.from(contentHtml)
				.set({
					margin:       [80, 0, 30, 0],
					filename:     'subsidiary' + date +'.pdf',
					image:        { type: 'jpeg',quality: 0.98 },
					html2canvas:  { dpi: 96, scale: 1, scrollX: 0, scrollY: 0, backgroundColor: '#FFF' },
					jsPDF:        { unit: 'pt', format: 'a4', orientation: 'l' },
					pagebreak: { before: '.page-break', avoid: 'img' }
				})
				.toPdf()
				.get('pdf').then(function (pdf) {
					var totalPages = pdf.internal.getNumberOfPages();
                      
					for (let i = 1; i <= totalPages; i++) {
						pdf.setPage(i);
						pdf.setFontSize(9);
						pdf.text('PETNET, INC.', 10, 40);
						pdf.text('32nd Floor East Offices Aguirre St. Legaspi Village Makati City', 10, 50);
						pdf.text('Page ' + i + ' of ' + totalPages, pdf.internal.pageSize.getWidth() - 100, pdf.internal.pageSize.getHeight() - 40);
					} 
				}).save();
		},
		exportExcel(tableID, filename = '') {
			const date = Math.floor(Date.now() / 1000);
			var downloadLink;
			var dataType = 'application/vnd.ms-excel';

			// var x = document.getElementsByClassName('amount');
			// var i;
			// for (i = 0; i < x.length; i++) {
			// 	x[i].innerHTML = '\'' + x[i].innerHTML;
			// }
			var x = document.getElementsByClassName('description');
			var i;
			for (i = 0; i < x.length; i++) {
				x[i].innerHTML = x[i].innerHTML.replace(/[`~!@#$%^&*()|+=?;:'"<>]/gi, ' ');
			}
			var tableSelect = document.getElementById(tableID);
			var tableHTML = tableSelect.outerHTML.replace(/ /g, '%20');


			filename = filename ? filename + date + '.xls' : 'excel_data.xls';
			downloadLink = document.createElement('a');
			document.body.appendChild(downloadLink);

			if (navigator.msSaveOrOpenBlob) {
				var blob = new Blob(['\ufeff', tableHTML], {
					type: dataType
				});
				navigator.msSaveOrOpenBlob(blob, filename);
			} else {
				downloadLink.href = 'data:' + dataType + ', ' + tableHTML;
				downloadLink.download = filename;
				downloadLink.click();
			}
			
			// for (i = 0; i < x.length; i++) {
			// 	x[i].innerHTML = x[i].innerHTML.replace('\'', '');
			// }
		},
		getAmountFormat(amount){
			return numeral(amount).format('0,0.00');
		},
		getMonth(){
			var month = new Array();
			month[1] = 'January';
			month[2] = 'February';
			month[3] = 'March';
			month[4] = 'April';
			month[5] = 'May';
			month[6] = 'June';
			month[7] = 'July';
			month[8] = 'August';
			month[9] = 'September';
			month[10] = 'October';
			month[11] = 'November';
			month[12] = 'December';
			var n = month[this.detail.month];
			return n;
		},
		getDateFormat(date){
			var month = format(new Date(date), 'MMM');
			var day = format(new Date(date), 'dd');
			var year = format(new Date(date), 'yyyy');
			return month + ' ' + day +', '+ year;
		}
	}
};
</script>
<style scoped>
.report-template {
  width: 768px;
  /* border: 1px solid black; */
  font-size: 12px;
  margin: auto;
}
.tbl {
  width: 95%;
  margin: auto;
  overflow: auto; 
  white-space: nowrap;
}
.code {
  text-align: center;
}
.amount {
  text-align: right;
}
.header {
  outline: 1px solid black;
  width: 100%;
  margin: 10px;
}
.total {
  text-align: right;
  font-weight: bold;
}
.action {
  padding: 20px;
}
.title {
  text-justify: center;
}
	@media print {
		body #printAction { 
			display:none; 
		}
    body #printArea { 
			display:block; 
		}
	} 
</style>