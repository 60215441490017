<template>
	<v-breadcrumbs :items="breadcrumbList">
		<template v-slot:divider>
			<v-icon>mdi-forward</v-icon>
		</template>
	</v-breadcrumbs>
</template>
<script>
export default {
	data () {
		return {
			breadcrumbList: [],
		};
	},
	mounted () { this.updateList(); },
	watch: { '$route' () { this.updateList(); } },
	methods: {
		updateList () { this.breadcrumbList = this.$route.meta.breadcrumb; }
	}
};
</script>