export default {
	payeebanks: [],
	headers: [
		{
			text: 'Record ID',
			align: 'left',
			sortable: false,
			value: 'id',
		},
		{
			text: 'Account ID No',
			align: 'left',
			sortable: false,
			value: 'acct_idno',
		},
		{ text: 'BP ID No', value: 'bp_idno' },
		{ text: 'Account Type', value: 'acct_type' },
		{ text: 'Account Currency', value: 'acct_cur' },
		{ text: 'Account Number', value: 'acct_no' },
		{ text: 'Account Name', value: 'acct_name' },
		{ text: 'Signatory', value: 'signatory' },
		{ text: 'Bank Code', value: 'bank_code' },
		{ text: 'BPI OS', value: 'bpi_os' },
		{ text: 'Start Date', value: 'start_date' },
		{ text: 'End Date', value: 'end_date' },
		{ text: 'Dep Branch', value: 'dep_branch' },
		{ text: 'Print BP ID No', value: 'print_bp_idno' },
		{ text: 'Actions', value: 'action', sortable: false },
	],
	acctType: [
		{ value: 'SAV', text: 'Savings Account'},
		{ value: 'CHE', text: 'Checking Account'},
	],
	acctCur: [
		{ value: 'PESO', text: 'Peso' },
		{ value: 'DOLLAR', text: 'Dollar'},
	],
	editedItem: {
		id: '',
		acct_idno: '',
		bp_idno: '',
		acct_type: '',
		acct_cur: '',
		acct_no: '',
		acct_name: '',
		signatory: '',
		bank_code: '',
		bpi_os: '',
		start_date: '',
		end_date: '',
		dep_branch: '',
		print_bp_idno: ''
	},
	defaultItem: {
		id: '',
		acct_idno: '',
		bp_idno: '',
		acct_type: '',
		acct_cur: '',
		acct_no: '',
		acct_name: '',
		signatory: '',
		bank_code: '',
		bpi_os: '',
		start_date: '',
		end_date: '',
		dep_branch: '',
		print_bp_idno: ''
	},
	dialog: false,
	editedIndex: -1,
	valid: true,
	formTitle: 'Edit entry',
};