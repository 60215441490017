<template>
	<v-container>
		<v-card>
			<v-card-title align="left" class="headline primary disbursement-title">
				<h5>Active Transactions</h5>
			</v-card-title>
			<v-data-table
				:headers="headers"
				:items="filteredItems"
				sort-by="document_number"
				class="elevation-1"
				:search="search"
			>
				<template v-slot:top>
					<v-toolbar flat color="white">
						<v-toolbar-title>{{trxnStatusName}}</v-toolbar-title>
						<v-spacer></v-spacer>
						<v-layout wrap>
						<v-flex md3 />
						<v-spacer/>
						<v-flex xs12 sm6 md3>
							<v-text-field append-icon="mdi-calendar" label="Month and Year" required v-model="month_year" @click="month_year_picker = true" hide-details />
							<v-dialog width="25%" v-if="month_year_picker" v-model="month_year_picker"> 
								<v-date-picker
									v-model="month_year"
									type="month"
									@change="month_year_picker = false; getData()"
								></v-date-picker>
							</v-dialog>
						</v-flex>
						<v-spacer/>
						<v-flex xs12 sm6 md5>
							<v-text-field
								v-model="search"
								append-icon="search"
								label="Search"
								single-line
								hide-details
							></v-text-field>
						</v-flex>
						</v-layout>
					</v-toolbar>
				</template>
				<template v-slot:item.total_amount="{ item }">
					<span>{{formatAmount(item.total_amount)}}</span>
				</template>
				<template v-slot:item.action="{ item }">
					<v-icon  v-if="getAccess(item.status, item.preparer)" color="primary" class="mr-2" small @click="update(item)"
						>mdi-restore</v-icon
					>
					<v-icon
						color="grey"
						class="mr-2"
						v-else
						small
					>
						mdi-restart-off
					</v-icon>
				</template>
			</v-data-table>
		</v-card>
	</v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { format, getYear, getMonth, parseISO } from 'date-fns';
// import io from 'socket.io-client'; 
import numeral from 'numeral';
import jsonToFormData from 'json-form-data';

export default {
	data() {
		return {
			module_name: 'Active Transactions',
			role_access: 1,
			trxnStatus:20,
			trxnStatusName: 'List',
			search: '',
			month_year: '',
			month_year_picker: false,
			isApprover: false,
			restoreDisbursement: false,
			isSamePreparer: false,
			selectedBatch: '',
			selectedDocType: '',
			batchDetails: [],
			documentDetails:[],
			fileDetails: [],
		};
	},
	computed: {
		...mapGetters({
			trxdisbs: 'trxdisb/trxdisbs',
			trxdisbDetails: 'trxdisb/trxdisbDetails',
			headers: 'trxdisb/headers',
			valid: 'trxdisb/valid',
			currUser: 'auth/currUser',
			editedIndex: 'trxdisb/editedIndex'
		}),
		years() {
			const year = new Date().getFullYear();
			const generateYears = Array.from({ length: year - 1900 },(value, index) => 1901 + index);
			generateYears.sort();
			return generateYears.reverse();
		},
		filteredItems() {
			let txn = this.trxdisbs;
			let txnFiltered = [];

			txn = Object.values(txn);

			txn.filter(transaction => {
				if (this.isApprover === false) {
					return transaction.is_confidential !== 1;
				}
			});

			if (txn !== undefined) {
				txn.forEach(element => {
					if (element !== null) {
						if(element.processing === 1){
							if(this.currUser.max_amount){
								txnFiltered.push(element);
							} else {
								if(element.preparer === this.currUser.user_last_name + ', ' + this.currUser.user_first_name){
									txnFiltered.push(element);
								}
							}
						}
					}
				});	
			}
			txnFiltered.forEach(i => {
				i.date_encoded = format(parseISO(i.date_encoded), 'yyyy-MM-dd');
				if (i.document_date !== undefined) {
					var count = i.document_date.split('-').length;
					if(count > 3){
						i.date = 'Have multiple dates';
					} else {
						i.date = i.document_date.replace(/,/g,'');
					}
				}
			});
			return txnFiltered;
		}
	},
	watch: {
		editedIndex: {
			handler(val) {
				this.$emit('update:editedIndex', val);
			}
		},
	},
	mounted() {
		const that = this;
		let date = format(new Date(), 'yyyy-MM-dd');
		let month = getMonth(parseISO(date)) + 1;
		let year = getYear(parseISO(date));
		let i = (month.toString().length == 2 ? '-' : '-0');
		that.month_year = year + i + month;
		this.$store.dispatch('trxdisb/getTrxdisbs', {document_date: that.month_year + '-01'});

		let roles = this.currUser.roleaccess;
		if(roles.length > 0){
			let roleaccess = roles.filter(r => r.module_name === this.module_name);
			if(roleaccess.length > 0){
				this.role_access = roleaccess[0].access_level;
			}
		}
	},
	methods: {
		getAccess(status, preparer){
			let access = false;
			if(this.role_access > 2 && status > 1 && status < 6 && this.currUser.max_amount){
				access = true;
			} else {
				if( this.currUser.user_last_name + ', ' + this.currUser.user_first_name === preparer && (status === 1 || status === 6)){
					access = true;
				}
			}
			return access;
		},	
		formatAmount(amount){
			return numeral(amount).format('0,0.00');
		},
		update(item) {
			this.$store.commit('trxdisb/TRXDISBS_DELETE', item);
			//this.$store.dispatch('coa/setEditedItems', Object.assign({}, item));
			let data = {};
			data.batch_number = item.batch_number;
			data.value = {
				processing : 0,
				document_type : item.document_type,
				status : item.status,
				_method: 'PUT'
			};

			var options = {
				initialFormData: new FormData(),
				showLeafArrayIndexes: true,
				includeNullValues: false,
				mapping: function(value) {
					if (typeof value === 'boolean') {
						return +value ? '1': '0';
					}
					return value;
				}
			};

			let convertedFormData = jsonToFormData(data.value, options);
			this.$store.dispatch('trxdisb/updateTrxdisbStatus', {batch_number: data.batch_number, value: convertedFormData});
		},
		getData(){
			this.$store.dispatch('trxdisb/getTrxdisbs', {document_date: this.month_year + '-01'});
		}
	},
};



</script>

<style scoped>
	.disbursement-title h5 {
		color: #FFF;
		font-size: 25px;
	}
	
	.date-filter {
		display: flex;
		flex-direction: row;
		position: relative;
		top: 10.5px; 
		right: 10px;
	}
	.date-filter-picker {
		width: 150px;
	}
	.date-filter-picker:first-child {
		margin-right: 10px;
	}

	.document-card-title {
		padding: 0;
	}
</style>
