<template>
	<div>
		<div class="container" v-if="ereceiptDetails[0].is_or_ar == 1">
			<table class="info-table">
				<tr>
					<td>
						<img src="../../../../assets/perahub_logo.png" class="logo">
					</td>
					<td class="info-right right-header">
						<h2>SERVICE INVOICE</h2><br>
						<p><b>HEAD OFFICE</b></p>
						<p>{{ereceiptDetails[0].or_number}}</p>
						<b>Preview</b>
					</td>
				</tr>
				<tr>
					<td colspan="2">
						<p><b>PETNET INC.</b></p>
						<p>East Offices Bldg., 114 Aguirre St., Legaspi Village, Makati City</p>
						<p>VAT Registered TIN: 201-200-777-0000</p>
					</td>
				</tr>
			</table>
			<div class="yellow-divider"></div>
			<div class="body">
				<div class="personal-info">
					<p><strong>Customer Name:</strong> {{ereceiptDetails[0].payee_desc}} <p>
					<div class="divider"></div>
					<p><strong>Address:</strong> {{ereceiptDetails[0].address}} <p>
					<div class="divider"></div>
					<p><strong>Customer Tin No.:</strong> {{ereceiptDetails[0].tin}} <p>
					<div class="divider"></div>
					<p><strong>SC/PWD/NAAC/SOLO PARENT/MOV AWARDEE ID NO.:</strong><p>
					<div class="divider"></div>
					<p><strong>NATURE OF TRANSACTION:</strong> {{ereceiptDetails[0].remarks}} </p>
					<div class="divider"></div>
				</div>
				<div class="details">
					<div class="computation">
						<table class="table-computation">
							<tr>
								<td><p>Unit/Quantity</p></td>
								<td><p class="bold-text amount">1</p></td>
							</tr>
							<tr>
								<td><p>Unit Cost</p></td>
								<td><p class="bold-text amount">{{getNumberFormat(ereceiptDetails[0].amount)}}</p></td>
							</tr>
							<tr>
								<td><p>TOTAL SALES</p></td>
								<td><p class="bold-text amount">{{getNumberFormat(ereceiptDetails[0].amount)}}</p></td>
							</tr>
							<tr>
								<td><p>VATable</p></td>
								<td><p class="bold-text amount">{{ ereceiptDetails[0].vat == 1 ? getNumberFormat(ereceiptDetails[0].amount  / 1.12) : getNumberFormat(0) }}</p></td>
							</tr>
							<tr>
								<td><p>VAT Exempt</p></td>
								<td><p class="amount">{{ ereceiptDetails[0].vat == 2 ? getNumberFormat(ereceiptDetails[0].amount) : getNumberFormat(0) }}</p></td>
							</tr>
							<tr>
								<td><p>VAT Zero-Rated</p></td>
								<td><p class="amount">{{ ereceiptDetails[0].vat == 3 ? getNumberFormat(ereceiptDetails[0].amount) : getNumberFormat(0) }}</p></td>
							</tr>
							<tr>
								<td><p>Discount\ PWD</p></td>
								<td><p class="amount">{{getNumberFormat(0)}}</p></td>
							</tr>
							<tr>
								<td><p>VAT Amount</p></td>
								<td><p class="amount"> {{ ereceiptDetails[0].vat == 1 ? getNumberFormat((ereceiptDetails[0].amount / 1.12) * 0.12) : 0.00 }}</p></td>
							</tr>
								<tr v-if="vat == 0">
									<td><p class="bold-text">TOTAL</p></td>
									<td><p class="amount bold-text">{{getNumberFormat(ereceiptDetails[0].amount)}}</p></td>
								</tr>
								<tr v-if="vat != 0">
									<td><p>Total</p></td>
									<td><p class="amount">{{getNumberFormat(ereceiptDetails[0].amount)}}</p></td>
								</tr>
								<tr v-if="vat != 0">
									<td><p>Less: Withholding Tax</p></td>
									<td><p class="amount">{{getNumberFormat(getwhold(ereceiptDetails[0]))}}</p></td>
								</tr>
								<tr v-if="vat != 0">
									<td><p class="bold-text">NET Amount</p></td>
									<td><p class="amount bold-text">{{getNumberFormat(ereceiptDetails[0].amount - getwhold(ereceiptDetails[0]))}}</p></td>
								</tr>
						</table>
					</div>
				</div>
				<div class="footer">
					<table class="company-details">
						<tr class="tr-company">
							<td class="td-branch">
								<p>Prepared by:</p>
								<p>{{ereceiptDetails[0].prepared_by}}</p>
								<p></p>
							</td>
							<td class="td-company">
								<p class="bold-text"></p>
							</td>
							<td class="td-company">
								<p>{{ereceiptDetails[0].payee_desc}}</p>
								<p>Customer Name & Signature</p>
							</td>
						</tr>
					</table>
					<table class="footer-table">
						<tr>
							<td class="footer-info">
								<div>
									<p class="customer">DATE/TIME: </p>
								</div>
							</td>
							<td class="footer-info">
								<div class="official-receipt">
									<p class="bold-text">THIS SERVES AS AN OFFICIAL RECEIPT</p>
									<p>Series No. 000000000001 to 999999999999</p>
									<p>CAS Acknowledgment Certificate No. <p>
									<p>Date Issued: <p>
									<p style="font-style: italic;">This document is system generated.</p>
								</div>
							</td>
						</tr>
					</table>
				</div>
			</div>
		</div>
		<div class="container" v-else>
			<table class="info-table">
				<tr>
					<td>
						<img src="../../../../assets/perahub_logo.png" class="logo">
					</td>
					<td class="info-right right-header">
						<h2>ACKNOWLEDGMENT RECEIPT</h2><br>
						<p><b>HEAD OFFICE</b></p>
						<p>{{ereceiptDetails[0].ar_number}}</p>
						<b>Preview</b>
					</td>
				</tr>
				<tr>
					<td colspan="2">
						<p><b>PETNET INC.</b></p>
						<p>East Offices Bldg., 114 Aguirre St., Legaspi Village, Makati City</p>
						<p>VAT Registered TIN: 201-200-777-0000</p>
					</td>
				</tr>
			</table>
			<div class="yellow-divider"></div>
			<div class="body">
				<div class="personal-info">
					<p><strong>Customer Name:</strong> {{ereceiptDetails[0].payee_desc}} <p>
					<div class="divider"></div>
					<p><strong>Address:</strong> {{ereceiptDetails[0].address}} <p>
					<div class="divider"></div>
					<p><strong>Customer Tin No.:</strong> {{ereceiptDetails[0].tin}} <p>
					<div class="divider"></div>
					<p><strong>SC/PWD/NAAC/SOLO PARENT/MOV AWARDEE ID NO.:</strong><p>
					<div class="divider"></div>
					<p><strong>NATURE OF TRANSACTION:</strong> {{ereceiptDetails[0].remarks}} </p>
					<div class="divider"></div>
				</div>
				<div class="details">
					<div class="computation">
						<table class="table-computation">
							<tr>
								<td><p>Unit/Quantity</p></td>
								<td><p class="bold-text amount">1</p></td>
							</tr>
							<tr>
								<td><p>Unit Cost</p></td>
								<td><p class="bold-text amount">{{getNumberFormat(ereceiptDetails[0].amount)}}</p></td>
							</tr>
							<tr>
								<td><p>TOTAL SALES</p></td>
								<td><p class="amount bold-text">{{getNumberFormat(ereceiptDetails[0].amount)}}</p></td>
							</tr>
							<tr>
								<td><p>Discount\ PWD</p></td>
								<td><p class="amount bold-text">{{getNumberFormat(0)}}</p></td>
							</tr>
							<tr>
								<td><p class="bold-text">TOTAL</p></td>
								<td><p class="amount bold-text">{{getNumberFormat(ereceiptDetails[0].amount)}}</p></td>
							</tr>
						</table>
					</div>
				</div>
				<div class="footer">
					<table class="company-details">
						<tr class="tr-company">
							<td class="td-branch">
								<p>Prepared by:</p>
								<p>{{ereceiptDetails[0].prepared_by}}</p>
								<p></p>
							</td>
							<td class="td-company">
								<p class="bold-text"></p>
							</td>
							<td class="td-company">
								<p>{{ereceiptDetails[0].payee_desc}}</p>
								<p>Customer Name & Signature</p>
							</td>
						</tr>
					</table>
					<table class="footer-table">
						<tr>
							<td class="footer-info">
								<div>
									<p class="customer">DATE/TIME: </p>
								</div>
							</td>
							<td class="footer-info">
								<div class="official-receipt">
									<p class="bold-text">THIS SERVES AS AN ACKNOWLEDGMENT RECEIPT</p>
									<p class="bold-text">THIS DOCUMENT IS NOT VALID FOR CLAIM OF INPUT TAX</p>
									<p>Series No. 000000000001 to 999999999999</p>
									<p>CAS Acknowledgment Certificate No. <p>
									<p>Issue Date: <p>
									<p style="font-style: italic;">This document is system generated.</p>
								</div>
							</td>
						</tr>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { mapGetters } from 'vuex';
import numeral from 'numeral';
import { format } from 'date-fns';
import numbertoWords from 'number-to-words';

export default {
	name: 'printEReceipt',
	data() {
		return {
			details: ''
		};
	},
	computed: {
		...mapGetters({
			ereceiptDetails: 'trxdisb/ereceiptDetails',
		}),
	},
	created() {
		var app = this;
		app.details = JSON.parse(atob(app.$route.params.data)); 
		console.log(app.details);
	},
	mounted() {
		this.init();
	},
	methods: {
		printDocument() {
			window.print();
		},
		init(){
			let payload = {};

			if (this.details.table === 'cash_receipt'){
				payload.batch_number = this.details.batch_number;
				payload.url = '/trx/receipt-detail';
				this.$store.dispatch('trxdisb/getReceiptDetail', payload);
			} else if (this.details.table === 'collection'){
				payload.batch_number = this.details.collection_number;
				payload.url = '/trx/receipt-coldetail';
				this.$store.dispatch('trxdisb/getReceiptDetail', payload);
			}
		},
		getDateFormat(date){
			return format(new Date(date), 'MM-d-yyyy');
		},
		getNumberFormat(amount){
			return numeral(amount).format('0,0.00');
		},
		getAmountinWords(amount){
			let amountWord = numbertoWords.toWords(amount); 
			let newAmount = amountWord.replace(/,/g, '');
			return newAmount.toUpperCase();
		},
		getPayeeName(id){
			const getPayee = this.payees.filter(payee=> payee.id=== id);

			if (getPayee) {
				return getPayee[0].payee_desc;
			}
		},
		getwhold (data) {
			let amount = 0;

			switch(data.vat) {
			case '1' :
				amount = (data.amount / 1.12) * (data.tax_percent/100);
				break;
			case '2' :
				amount = data.amount * (data.tax_percent/100);
				break;
			case '3' :
				amount = data.amount * (data.tax_percent/100);
				break;
			}

			return amount;
		}
	}
};
</script>
<style scoped>
p {
    margin: 5px;
}
.container {
    font-family: Arial, sans-serif;
    width: 50%;
    padding: 3rem;
    font-size: 10px;
	margin: auto;
	border: 1px solid black;
}
.logo {
    height: 70px;
}
.right-header {
    text-align: right;
    line-height: 10px;
}
p {
	margin-left: 0;
	margin-right: 0;
}
.body {
    width: 100%;
    margin: auto;
}
.yellow-divider {
    width: 100%;
    background-color: #FDD100;
    height: 5px;
    margin-top: 5px;
    margin-bottom: 10px;
}
.cut-divider {
    width: 100%;
    border-bottom: 2px dashed black;
}
.cut-logo {
    width: 35px;
}
.personal-info {
    text-transform: uppercase;
    margin-bottom: 10px;
}
.divider {
    border-top: 1px solid black;
}
.computation {
    width: 75%;
    margin: auto;
}
.amount {
    text-align: right;
}
.table {
    width: 100%;
}
.table-computation {
    width: 100%;
    line-height: 5px;
}
.bold-text {
    font-weight: bolder;
}
.company-details, .td-company, .tr-company{ 
    border: 1px solid black;
    border-collapse: collapse;
}
.company-details {
    width: 100%;
    font-size: 8px;
    line-height: 10px;
}
.td-company {
    width: 30%;
    padding-left: 10px;
}
.td-branch {
    width: 15%;
    padding-left: 10px;
    line-height: 1;
}
/* .td-min {
    width: 15%;
    padding-left: 10px;
} */
.footer {
    margin-top: 20px;
    line-height: 3px;
} 
.official-receipt {
    margin-top: 10px;
    text-align: right;
    font-size: 7px;
}  
.customer {
    font-size: 7px;
}
.footer-info {
    width: 50%;
}
.footer-table{
    width: 100%;
}
.info-left {
    width: 25%;
}
.info-right {
    width: 75%;
}
.info-table {
    width: 100%;
}
.cancel{
    align-items: center;
    display: flex;
    justify-content: center;
    left: 0px;
    position: absolute;
    top: 0px;
    height: 100%;
    width: 100%;
}
.cancel-text
{
    color:lightgrey;
    font-size:75px;
    transform:rotate(315deg);
    -webkit-transform:rotate(315deg);
}
</style>