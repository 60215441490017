export default{
	sections: [],
	isLoading: false,
	headers: [
		{
			text: 'Section Code',
			align: 'left',
			sortable: true,
			value: 'section_code',
		},
		{ text: 'Section Name', value: 'section_name', sortable: true, },
		// { text: 'Department Code', value: 'dept_code'},
		// { text: 'Edit By', value: 'edit_by' },
		{ text: 'Actions', value: 'action', sortable: false },
	],
	editedItem: {
		id: '',
		section_code: '',
		section_name: '',
		dept_code: '',
		edit_by: '1'
	},
	defaultItem: {
		id: '',
		section_code: '',
		section_name: '',
		dept_code: '',
		edit_by: '1'
	},
	dialog: false,
	editedIndex: -1,
	valid: true,
	formTitle: 'Edit entry',
};