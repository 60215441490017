export default {
	acknowledgementreceipt: [],
	headers: [
		{
			text: 'Record ID',
			align: 'left',
			sortable: false,
			value: 'id',
		},
		{ text: 'Series From', value: 'series_from' },
		{ text: 'Series To', value: 'series_to' },
		{ text: 'Actions', value: 'action', sortable: false },
	],
	columnHeaders: [
		{ label: 'Record ID', field: 'id' },
		{ label: 'Series From', field: 'series_from' },
		{ label: 'Series To', field: 'series_to' },
	],
	editedItem: {
		id: '',
		series_from: '',
		series_to: '',
	},
	defaultItem: {
		id: '',
		series_from: '',
		series_to: '',
	},
	dialog: false,
	editedIndex: -1,
	valid: true,
	formTitle: 'Edit entry',
};  