import Repository from '../Repository';
import RepositoryDownload from '../RepositoryDownload';

const resource = 'trx/billing';

export default {
	get(payload) {
		return Repository.get(`${resource}`, {params: payload});
	},

	getBilling(id) {
		return Repository.post(`${resource}-header/${id}`);
	},
	saveBilling(payload) {
		return Repository.post(`${resource}`, 
			payload,
			{
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}
		);
	},
	updateBilling(id, payload) {
		return Repository.post(`${resource}/${id}`,
			payload,
			{
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}
		);
	},
	updateBillingStatus(id, payload) {
		return Repository.post(`${resource}/${id}`,payload);
	},
	updateBillingAccountingStatus(id, payload) {
		return Repository.put(`${'/updateAcctngStatus'}/${id}`,payload);
	},
	deleteBilling(id) {
		return Repository.delete(`${resource}/${id}`);
	},
	searchBilling(payload) {
		return Repository.get(`${resource}`, payload);
	},
	getBIRFormData(batch_number) {
		return Repository.post(`${'/trx/print-2307'}/${batch_number}`);
	},
	getNumber(resource, payload){
		return Repository.post(`${resource}`, payload);
	},
	getDocumentNumber(resource, payload){
		return Repository.post(`${resource}`, payload);
	},
	getDocumentNumberCheque(resource){
		return Repository.post(`${resource}`);
	},
	getPrintData(payload){
		return Repository.post(`${'/trx/print-dm'}/${payload}`);
	},
	getPrintReceiptData(payload){
		return Repository.post(`${'/trx/print-orar'}/${payload}`);
	},
	getDetail(payload){
		return Repository.post(`${resource}/${payload}`);
	},
	uploadHeaderIS(payload) {
		return Repository.post(`${'/trx/up-header-is'}`, payload,
			{
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}
		);
	},
	uploadDetailIS(payload) {
		return Repository.post(`${'/trx/up-detail-is'}`, payload,
			{
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}
		);
	},
	uploadHeaderPayroll(payload) {
		return Repository.post(`${'/trx/up-header-p'}`, payload,
			{
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}
		);
	},
	uploadDetailPayroll(payload) {
		return Repository.post(`${'/trx/up-detail-p'}`, payload,
			{
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}
		);		
	},
	getCSVUploads() {
		return Repository.get(`${'/trx/csv-upload'}`);
	},
	
	getData(payload){
		return Repository.get(`${resource}?page=`+ payload);
	},
	
	getCSVUploadPages(payload) {
		return Repository.get(`${'/trx/csv-upload?page='}` + payload);
	},
	getByBillNo(payload) {
		return Repository.get(`${'/searchBilInvoice'}`, {params: payload});
	},
	getUncollectedInvoice(){
		return Repository.get(`${'/getUncollectedBill'}`);
	},
	sendMail(payload){
		return Repository.post(`${'/trx/send-mail'}`, payload);

	},
	voidBilling(payload){
		return Repository.post(`${'/trx/bilvoid'}`, payload,
			{
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}
		);
	},

	getReceiptDetail(payload) {
		return RepositoryDownload.post(`${'trx/receipt-bildetail'}/${payload}`);
	},
};