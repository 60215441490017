import Vue from 'vue';
import { RepositoryFactory } from '../../../api_repo/RepositoryFactory';

const coasRepository = RepositoryFactory.get('coa');

const getCoas = (context) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	setTimeout(() => {
		coasRepository.get()
			.then((response) => {
				context.commit('COAS_UPDATED', response.data.data);
				context.commit('app/SET_IS_LOADING', false, { root: true });
			})
			.catch(() => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
			});

	}, 1500);
};

const getCoaDropdown = (context) => {
	let data = { params: {acct_type: 'S'}};
	setTimeout(() => {
		coasRepository.getSubAccount(data)
			.then((response) => {
				context.commit('SET_COA_SUBS', response.data.data);
			})
			.catch(() => {
			});

	}, 1500);
};


const getCoaData = (context, payload) => {
	let data = { params: {acct_type: 'S', page: payload}};
	context.commit('app/SET_IS_LOADING', true , { root: true });
	return new Promise((resolve, reject) => {
		coasRepository.getSubAccount(data)
			.then((response) => {
				context.commit('app/SET_IS_LOADING', false , { root: true });
				resolve(response);
			}, error => {
				context.commit('app/SET_IS_LOADING', false , { root: true });
				reject(error);
			});
	});
};

const saveCoa = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	//console.log(payload);
	setTimeout(() => {
		coasRepository.saveCoa(payload)
			.then((response) => {
				context.commit('COAS_ADD', response.data.data);
				context.commit('app/SET_IS_LOADING', false, { root: true });
				context.commit('app/SET_SNACK_BAR',
					{ 'status': true, 'message': `Your work has been successful!${response.data.data.acct_desc}(${response.data.data.acct_code}) added.`, 'messagetype': 'success' }, { root: true });

			})
			.catch((error) => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
				context.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'Saving failed! ' + error.response.data.message, 'messagetype': 'error' }, { root: true });
			});
	}, 1500);
};

const updateCoa = (context, payload) => {
	//console.log(payload);
	context.commit('app/SET_IS_LOADING', true, { root: true });
	setTimeout(() => {
		coasRepository.updateCoa(payload.id, payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
				context.commit('COAS_ITEM_UPDATE', response.data.data);
				context.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'Your work has been successful! ' + response.data.data.acct_desc + '(' + response.data.data.acct_code + ') updated.', 'messagetype': 'success' }, { root: true });
			})
			.catch((error) => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
				context.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'Update failed! ' + error.response.data.message, 'messagetype': 'error' }, { root: true });
			});
	}, 1500);
};

const deleteCoa = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	setTimeout(() => {
		coasRepository.deleteCoa(payload.id)
			// eslint-disable-next-line no-unused-vars
			.then((response) => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
				context.commit('COAS_DELETE');
				context.commit('app/SET_SNACK_BAR', { 'status': true, 
					'message': `Your work has been successful! ${payload.acct_desc} ( ${payload.acct_code}) deleted.`, 'messagetype': 'success' }, { root: true });
			})
			.catch((error) => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
				context.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'Delete failed! ' + error.response.data.message, 'messagetype': 'error' }, { root: true });
			});
	}, 1500);
};

const getDetails = (context, payload) => {
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	coasRepository.getDetails(payload)
		.then((response) => {
			context.commit('SET_EDITED_ITEM', response.data.data.target);
			context.commit('app/SET_IS_LOADING',false,{ root: true });
		})
		.catch(() => {
			context.commit('app/SET_IS_LOADING',false,{ root: true });
		});    
};

const getData = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true , { root: true });
	return new Promise((resolve, reject) => {
		coasRepository.getData(payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING', false , { root: true });
				resolve(response);
			}, error => {
				context.commit('app/SET_IS_LOADING', false , { root: true });
				reject(error);
			});
	});
};

const getDataPages = (context,payload) => {
	context.commit('app/SET_IS_LOADING', true , { root: true });
	setTimeout(() => {
		coasRepository.getData(payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING', false , { root: true });
				context.commit('COAS_UPDATED', response.data.data);
			})
			// eslint-disable-next-line
			.catch(() => {
				context.commit('app/SET_IS_LOADING', false , { root: true });
			});
	}, 1500);
};

const setEditedItems = (context, payload) => {
	context.commit('SET_EDITED_ITEM', payload);
};

const setDialog = (context, payload) => {
	context.commit('SET_DIALOG', payload);
};

const setEditedIndex = (context, payload) => {
	context.commit('SET_EDITED_INDEX', payload);
};

const setValid = (context, payload) => {
	context.commit('SET_VALID', payload);
};

const setformTitle = (context, payload) => {
	context.commit('SET_FORM_TITLE', payload);
};

const setAccountParents = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	if (payload.acct_type == 'M') {
		context.commit('SET_ACCOUNT_PARENTS', [{ 'acct_code': '0', 'acct_desc': 'NONE' }]);
		context.commit('app/SET_IS_LOADING', false, { root: true });
	}
	else {
		coasRepository.getParents(payload)
			.then((response) => {
				context.commit('SET_ACCOUNT_PARENTS', response.data.data);
				context.commit('app/SET_IS_LOADING', false, { root: true });
			})
			.catch((error) => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
				Vue.swal.fire({
					type: 'error',
					title: 'Failed getting data',
					text: error.response.data.message,
					timer: 2000
				});
			});
	}
};

const setAccountCode = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	coasRepository.getAccountCode(payload)
		.then((response) => {
			context.commit('SET_ACCOUNT_CODE', response.data.data);
			context.commit('app/SET_IS_LOADING', false, { root: true });
		})
		.catch((error) => {
			context.commit('app/SET_IS_LOADING', false, { root: true });
			Vue.swal.fire({
				type: 'error',
				title: 'Failed getting data',
				text: error.response.data.message,
				timer: 2000
			});
		});
};

const setAccountClass = (context, payload) => {
	context.commit('SET_ACCOUNT_CLASS', payload);
};

const setAccountGroup = (context, payload) => {
	context.commit('SET_ACCOUNT_GROUP', payload);
};

const clearEditedItems = (context) => {
	context.commit('SET_ACCOUNT_CLASS', '');
	context.commit('SET_ACCOUNT_GROUP', '');
	context.commit('SET_ACCOUNT_CODE', '');
	context.commit('SET_ACCOUNT_DESC', '');
};

const setAccountParentDesc = (context,payload) => {
	context.commit('SET_ACCOUNT_PARENT_DESC',payload.acct_code);
	context.commit('SET_ACCOUNT_PARENT_CODE',payload.acct_desc);
};

export default {
	getCoas,
	getCoaDropdown,
	saveCoa,
	updateCoa,
	deleteCoa,
	setEditedItems,
	setDialog,
	setEditedIndex,
	setValid,
	setformTitle,
	setAccountParents,
	setAccountCode,
	setAccountClass,
	setAccountGroup,
	clearEditedItems,
	setAccountParentDesc,
	getDetails,
	getDataPages,
	getData,
	getCoaData
};
