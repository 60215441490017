const trxintegrated = state => state.trxintegrated;
const trxintegratedDetails = state => state.trxintegratedDetails;
const headers = state => state.headers;
const dialog = state => state.dialog;
const editedIndex = state => state.editedIndex;
const valid = state => state.valid;
const formTitle = state => state.formTitle;
const editDialog = state => state.editDialog;
const viewDialog = state => state.viewDialog;

export default {
	trxintegrated,
	trxintegratedDetails,
	headers,
	dialog,
	editedIndex,
	valid,
	formTitle,
	editDialog,
	viewDialog
};