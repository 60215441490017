import Repository from '../Repository';

const resource = '/transaction';

export default {
	get() {
		return Repository.get(`${resource}`);
	},

	getTransactione(id) {
		return Repository.get(`${resource}/${id}`);
	},

	saveTransaction(payload) {
		return Repository.post(`${resource}`, payload);
	},

	updateTransaction(id, payload) {
		return Repository.put(`${resource}/${id}`, payload);
	},

	deleteTransaction(id) {
		return Repository.delete(`${resource}/${id}`);
	},

	searchTransaction(payload){
		return Repository.get(`${resource}`,payload);
	}
};