import { render, staticRenderFns } from "./subsidiary.vue?vue&type=template&id=702d57c2&scoped=true&"
import script from "./subsidiary.vue?vue&type=script&lang=js&"
export * from "./subsidiary.vue?vue&type=script&lang=js&"
import style0 from "./subsidiary.vue?vue&type=style&index=0&id=702d57c2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "702d57c2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCol,VIcon,VRow,VSpacer})
