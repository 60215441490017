import Vue from 'vue';

const APPROVINGLEVEL_UPDATED = (state, payload) => {
	state.approvinglevels = payload;
};

const APPROVINGLEVEL_ADD = (state, payload) => {
	state.approvinglevels.push(payload);
};

const APPROVINGLEVEL_DELETE = (state) => {
	state.approvinglevels.splice(state.editedIndex, 1);
};

const APPROVINGLEVEL_ITEM_UPDATE = (state, payload) => {
	Vue.set(state.approvinglevels, payload.index, payload.data);
};

const SET_EDITED_ITEM = (state,payload) =>{
	state.editedItem = payload;
};

const SET_DIALOG = (state,payload) =>{
	state.dialog = payload;
};

const SET_EDITED_INDEX = (state,payload) =>{
	state.editedIndex = payload;
};

const SET_VALID = (state,payload) => {
	state.valid = payload;
};

const SET_FORM_TITLE = (state,payload) => {
	state.formTitle = payload;
};

const SET_ISLOADING = (state,payload) => {
	state.isLoading = payload;
};

export default {
	APPROVINGLEVEL_UPDATED,
	APPROVINGLEVEL_ADD,
	APPROVINGLEVEL_DELETE,
	APPROVINGLEVEL_ITEM_UPDATE,
	SET_EDITED_ITEM,
	SET_DIALOG,
	SET_EDITED_INDEX,
	SET_VALID,
	SET_FORM_TITLE,
	SET_ISLOADING
};