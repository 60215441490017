<template>
	<div>
		<div id="printAction">
			<v-btn color="primary" @click="printDocument"><v-icon left>mdi-printer</v-icon> Print This Form</v-btn>
		</div>
		<div id="printArea">
			<div class="cheque-container">
				<p id="chequeDate" class="cheque-date">{{getDateFormat(documentHeaders[0].document_date)}}</p>
				<p id="amountNumber" class="cheque-amount-number">{{getNumberFormat(documentHeaders[0].cheque_amount)}}</p>
				<p id="accountName" class="cheque-account-name">{{getPayeeName(documentHeaders[0].payee_id)}}</p>
				<p id="amountText" class="cheque-amount-text">{{getAmountinWords(documentHeaders[0].cheque_amount)}}</p>
				<img class="cheque-file" src="@/assets/checks/bdo.png" />
			</div>
		</div>
	</div>
</template>
<script>
import { mapGetters } from 'vuex';
import numeral from 'numeral';
import { format } from 'date-fns';
import numbertoWords from 'number-to-words';

export default {
	name: 'printChequeTrxdisb',
	data() {
		return {
			disbDetail:{},
			documentHeaders: []
		};
	},
	watch: {
		trxdisbDetails: {
			handler(val) {
				this.documentHeaders = val;
			}
		},
		coas: {
			handler() {
				this.getDataCoas();
			}
		}
	},
	computed: {
		...mapGetters({
			trxdisbDetails: 'trxdisb/trxdisbDetails',
			coas: 'coa/coaSubs',
			payees: 'payefile/payefiles',
			coaData: 'coa/coaData'
		}),
	},
	created() {
		var app = this;
		app.disbDetail = JSON.parse(atob(app.$route.params.data)); 
	},
	mounted() {
		this.init();
	},
	methods: {
		printDocument() {
			window.print();
		},
		init(){
			this.$store.dispatch('payefile/getPayefileDropdown');
			this.$store.dispatch('trxdisb/getTrxdisb', this.disbDetail.batch_number);
			this.$store.dispatch('coa/getCoaDropdown');
		},
		getDateFormat(date){
			return format(new Date(date), 'MM-d-yyyy');
		},
		getNumberFormat(amount){
			return numeral(amount).format('0,0.00');
		},
		getAmountinWords(amount){
			let amountWord = numbertoWords.toWords(amount); 
			let newAmount = amountWord.replace(/,/g, '');
			let cent = '';
			let arr = numeral(amount).format('00.00').split('.');
			if(arr[1] != '00'){
				cent = ' & '+ arr[1] + '/100';
			}
			return newAmount.toUpperCase() + ' ' + this.getCurrency() + ' ' + cent + ' ONLY.';
		},
		getPayeeName(id){
			const getPayee = this.payees.filter(payee=> payee.id=== id);

			if (getPayee) {
				return getPayee[0].payee_desc;
			}
		},
		getCurrency(){
			let curr_id = this.documentHeaders[0].currency_id;
			let currency = 'PESOS';
			switch (curr_id) {
			case 2:
				currency = 'DOLLARS';
				break;
			case 4:
				currency = 'YENS';
				break;
			case 5:
				currency = 'SINGAPOREAN DOLLAR';
				break;
			default:
				currency =  'PESOS';
				break;
			}

			return currency;
		},
		async getDataCoas(){
			if(this.coaData.length === 0){
				this.dataCoas = this.coas.data;
				this.nextPageCoas = this.coas.next_page_url;
				let page = 2;

				while (this.nextPageCoas !== null){
					await this.$store.dispatch('coa/getCoaData', page).then(response => {
						this.nextPageCoas = response.data.data.next_page_url;
						response.data.data.data.forEach(details => {
							this.dataCoas.push(details);
						});
					});
					page++;
				}
				this.$store.commit('coa/SET_COA_DATA', this.dataCoas);
			}
		},
	}
};
</script>
<style scoped>
	.cheque-file {
		width: 100%;
	}

	.cheque-container {
		position: relative;
		width: 1024px;
	}
	
	.cheque-date,
	.cheque-amount-number,
	.cheque-account-name,
	.cheque-amount-text {
		position: absolute;
		font-weight: bolder;
	}

	.cheque-date {
		right: 165px;
    top: 70px;
    font-size: 15px;
	}

	.cheque-amount-number {
		left: 770px;
    top: 103px;
		font-size: 15px;
	}

	.cheque-account-name {
		top: 105px;
    left: 150px;
	}

	.cheque-amount-text {
		top: 145px;
    left: 105px;
		font-size: 10px;
	}

	/* .cheque-date {
		right: 260px;
		top: 130px;
    font-size: 25px;
    font-family: arial;
	} */

	@media print {
		body #printAction { 
			display:none; 
		}

    body #printArea { 
			display:block; 
		}
		body .cheque-file {
			display: none;
		}
	}
</style>