<template>
	<div>
		<div id="printAction">
			<v-btn color="primary" @click="printDocument"><v-icon left>mdi-printer</v-icon> Print This Form</v-btn>
		</div>
		<div id="printArea">
			<div class="cheque-container">
				<p id="documentNumber" class="document-number">{{documentHeaders[0].document_number}}</p>
				<p id="receiptDate" class="receipt-date">{{getDateFormat(documentHeaders[0].document_date)}}</p>
				<p id="amountNumber" class="receipt-amount-number">{{getAmountDue()}}</p>
				<p id="accountName" class="receipt-account-name">{{documentHeaders[0].payee_desc}}</p> 
				<p id="amountText" class="receipt-amount-text">{{getAmountinWords()}}</p>
				<img class="receipt-file" src="@/assets/receipt/AR.png" />
			</div>
		</div>
	</div>
</template>
<script>
import { mapGetters } from 'vuex';
import numeral from 'numeral';
import { format } from 'date-fns';
import numbertoWords from 'number-to-words';

export default {
	name: 'printChequeTrxdisb',
	data() {
		return {
			disbDetail:{},
			documentHeaders: []
		};
	},
	watch: {
		trxdisbDetails: {
			handler(val) {
				this.documentHeaders = val;
			}
		}
	},
	computed: {
		...mapGetters({
			payees: 'payefile/payefiles',
			trxdisbDetails: 'trxdisb/printDetails',
			coas: 'coa/coas',
			currUser: 'auth/currUser',
		}),
	},
	created() {
		var app = this;
		app.disbDetail = JSON.parse(atob(app.$route.params.data)); 
	},
	mounted() {
		this.init();
	},
	methods: {
		printDocument() {
			window.print();
		},
		init(){
			this.$store.dispatch('payefile/getPayefileDropdown');
			this.$store.dispatch('trxdisb/getORARData', this.disbDetail.batch_number);
		},
		getDateFormat(date){
			return format(new Date(date), 'MM-d-yyyy');
		},
		getNumberFormat(){
			let amount = 0;
			let details = this.documentHeaders[0].details.filter(det => det.acct_desc !== 'Creditable Withholding Tax');
			details.map(det=> {
				let total = parseFloat(det.debit_amount) + parseFloat(det.credit_amount);
				amount += total;
			});
			return numeral(amount).format('0,0.00');
		},
		getAmountinWords(){
			let amount = this.getAmountDue();
			amount = amount.replace(',','');
			let amountWord = numbertoWords.toWords(amount); 
			let newAmount = amountWord.replace(/,/g, '');
			
			let cent = '';
			let arr = numeral(amount).format('00.00').split('.');
			if(arr[1] != '00'){
				cent = ' & '+ arr[1] + '/100';
			}
			return newAmount.toUpperCase() + ' ' + this.getCurrency() + ' ' + cent + ' ONLY.';
		},
		getPayeeName(id){
			const getPayee = this.payees.filter(payee=> payee.id=== id);

			if (getPayee) {
				return getPayee[0].payee_desc;
			}
		},
		getLessVat(){
			let amount = this.getNumberFormat();
			amount = amount.replace(',','');
			let lessVat = (parseFloat(amount)/1.12)*0.12;

			return numeral(lessVat).format('0,0.00');
		},
		getAmountTotal(){
			let amount = this.getNumberFormat();
			amount = amount.replace(',','');
			let lessVat = this.getLessVat();
			lessVat = lessVat.replace(',','');
			let amountTotal = parseFloat(amount) - parseFloat(lessVat);

			return numeral(amountTotal).format('0,0.00');
		},
		getTotalDue(){
			let amount = this.getNumberFormat();

			return numeral(amount).format('0,0.00');
		},
		getTotalLessVat(){
			let amount = this.getAmountTotal();
			amount = amount.replace(',','');
			let lessVat = parseFloat(amount)*0.02;

			return numeral(lessVat).format('0,0.00');
		},
		getAmountDue(){
			let amount = this.getNumberFormat();

			// amount = amount.replace(',','');
			// let lessVat = this.getTotalLessVat();
			// lessVat = lessVat.replace(',','');
			// let amountTotal = parseFloat(amount) - parseFloat(lessVat);

			return numeral(amount).format('0,0.00');
		},
		getVatSale(){
			let amount = this.getAmountTotal();
			
			return numeral(amount).format('0,0.00');
		},
		getVatAmount(){
			let amount = this.getLessVat();
			
			return numeral(amount).format('0,0.00');
		},
		getTotalSale(){
			let amount = this.getNumberFormat();
			
			return numeral(amount).format('0,0.00');
		},
		getCurrency(){
			let curr_id = this.documentHeaders[0].currency_id;
			let currency = 'PESOS';
			switch (curr_id) {
			case 2:
				currency = 'DOLLARS';
				break;
			case 4:
				currency = 'YENS';
				break;
			case 5:
				currency = 'SINGAPOREAN DOLLAR';
				break;
			default:
				currency =  'PESOS';
				break;
			}

			return currency;
		}
	}
};
</script>
<style scoped>
	.receipt-file {
		width: 70%;
	}

	.cheque-container {
		position: relative;
		width: 1024px;
	}
	
	.receipt-date,
	.receipt-amount-text,
	.receipt-amount-number,
	.document-number,
	.receipt-account-name {
		position: absolute;
		font-weight: bolder;
	}
	
	.document-number{
		left: 535px;
		top: 97px;
    font-size: 25px;
	}

	.receipt-date {
		left: 520px;
		top: 145px;
    font-size: 11px;
	}

	.receipt-amount-number {
		left: 580px;
		top: 235px;
		font-size: 11px;
	}

	.receipt-account-name {
		top: 183px;
    left: 182px;
		font-size: 11px;
	}

	.receipt-amount-text {
		top: 235px;
    left: 182px;
		font-size: 10px;
	}

	/* .cheque-date {
		right: 260px;
		top: 130px;
    font-size: 25px;
    font-family: arial;
	} */

	@media print {
		body #printAction { 
			display:none; 
		}

    body #printArea { 
			display:block; 
		}
		body .receipt-file {
			display: none;
		}

		body #documentNumber {
			display: none;
		}
	}
</style>