<template>
	<div>
		<div id="printAction">
			<v-btn color="primary" @click="printDocument"><v-icon left>mdi-printer</v-icon> Print This Form</v-btn>
		</div>
		<div id="printArea">
			<div class="receipt-container">
				<p id="documentNumber" class="document-number">{{documentHeaders[0].document_number}}</p>
				<p id="receiptDate" class="receipt-date">{{getDateFormat(documentHeaders[0].document_date)}}</p>
				<p id="amountNumber" class="receipt-amount-number">{{getTotalSale()}}</p>
				<p id="accountName" class="receipt-account-name">{{documentHeaders[0].payee_desc}}</p> 
				<div class="amount">
					<p id="amountText" class="receipt-amount-text">{{getAmountinWords()}}</p>
				</div>
				<p id="address" class="address">{{documentHeaders[0].address}}</p>
				<p id="remarks" class="remarks">{{documentHeaders[0].remarks}}</p>
				<p class="sett-amount">{{getTotalSale()}}</p>
				<p class="amount-less-vat">{{getLessVat()}}</p>
				<p class="amount-total">{{getAmountTotal()}}</p>
				<p class="amount-total-due">{{getTotalSale()}}</p>
				<p class="amount-total-less-vat">{{getTotalLessVat()}}</p>
				<p class="amount-due">{{getAmountDue()}}</p>
				<p class="amount-vat-sale">{{getVatSale()}}</p>
				<p class="vat-amount">{{getVatAmount()}}</p>
				<p class="total-sale">{{getTotalSale()}}</p>
				<img class="receipt-file" src="@/assets/receipt/OR.png" />
			</div>
		</div>
	</div>
</template>
<script>
import { mapGetters } from 'vuex';
import numeral from 'numeral';
import { format } from 'date-fns';
import numbertoWords from 'number-to-words';

export default {
	name: 'printChequeTrxdisb',
	data() {
		return {
			disbDetail:{},
			documentHeaders: []
		};
	},
	watch: {
		trxdisbDetails: {
			handler(val) {
				this.documentHeaders = val;
			}
		}
	},
	computed: {
		...mapGetters({
			payees: 'payefile/payefiles',
			trxdisbDetails: 'trxdisb/printDetails',
			coas: 'coa/coas',
			alphataxcodes: 'alphataxcode/alphataxcodes',
		}),
	},
	created() {
		var app = this;
		app.disbDetail = JSON.parse(atob(app.$route.params.data)); 
	},
	mounted() {
		this.init();
	},
	methods: {
		printDocument() {
			window.print();
		},
		init(){
			this.$store.dispatch('payefile/getPayefileDropdown');
			this.$store.dispatch('alphataxcode/getAlphataxcodes');
			this.$store.dispatch('trxdisb/getORARData', this.disbDetail.batch_number);
		},
		getDateFormat(date){
			return format(new Date(date), 'MM-d-yyyy');
		},
		getNumberFormat(){
			let amount = 0;
			let details = this.documentHeaders[0].details.filter(det => det.acct_desc !== 'Creditable Withholding Tax');
			details.map(det=> {
				let total = parseFloat(det.debit_amount) + parseFloat(det.credit_amount);
				amount += total;
			});
			return numeral(amount).format('0,0.00');
		},
		getAmountinWords(){
			let amount = this.getTotalSale();
			amount = amount.replace(',','');
			let amountWord = numbertoWords.toWords(amount); 
			let newAmount = amountWord.replace(/,/g, '');
			
			let cent = '';
			let arr = numeral(amount).format('00.00').split('.');
			if(arr[1] != '00'){
				cent = ' & '+ arr[1] + '/100';
			}
			return newAmount.toUpperCase() + ' ' + this.getCurrency() + ' ' + cent + ' ONLY.';
		},
		getPayeeName(id){
			const getPayee = this.payees.filter(payee=> payee.id=== id);

			if (getPayee) {
				return getPayee[0].payee_desc;
			}
		},
		getLessVat(){
			if(this.documentHeaders[0].vat == 1){
				let amount = this.getAmountTotal();
				amount = amount.replace(',','');
				let lessVat = parseFloat(amount)*0.12;
				return numeral(lessVat).format('0,0.00');
			} else {
				return '-';
			}
		},
		getAmountTotal(){
			let amount = this.getAmountDue();
			amount = amount.replace(',','');
			let amountTotal = parseFloat(amount)/1.1;

			return numeral(amountTotal).format('0,0.00');
		},
		getTotalDue(){
			let amount = this.getNumberFormat();

			return numeral(amount).format('0,0.00');
		},
		getTotalLessVat(){
			let amount = this.getAmountTotal();
			let total = parseFloat(amount.replace(',','')) * 0.02;
			return numeral(total).format('0,0.00');
		},
		getAmountDue(){
			let amount = this.getNumberFormat();

			// amount = amount.replace(',','');
			// let lessVat = this.getTotalLessVat();
			// lessVat = lessVat.replace(',','');
			// let amountTotal = parseFloat(amount) - parseFloat(lessVat);

			return numeral(amount).format('0,0.00');
		},
		getVatSale(){
			let amount = this.getAmountTotal();
			
			return numeral(amount).format('0,0.00');
		},
		getVatAmount(){
			let amount = this.getLessVat();
			if (amount != '-'){
				return numeral(amount).format('0,0.00');
			} else {
				return '-';
			}
		},
		getTotalSale(){
			let amount = this.getAmountTotal();
			let total_amount = parseFloat(amount.replace(/,/g, '')) * 1.12;
			
			return numeral(total_amount).format('0,0.00');
		},
		getCurrency(){
			let curr_id = this.documentHeaders[0].currency_id;
			let currency = 'PESOS';
			switch (curr_id) {
			case 2:
				currency = 'DOLLARS';
				break;
			case 4:
				currency = 'YENS';
				break;
			case 5:
				currency = 'SINGAPOREAN DOLLAR';
				break;
			default:
				currency =  'PESOS';
				break;
			}

			return currency;
		}
	}
};
</script>
<style scoped>
	.receipt-file {
		width: 70%;
	}

	.receipt-container {
		position: relative;
		width: 1024px;
	}
	
	.receipt-date,
	.receipt-amount-text,
	.receipt-amount-number,
	.receipt-account-name,
	.sett-amount,
	.amount-less-vat,
	.amount-total,
	.amount-total-due,
	.amount-total-less-vat,
	.amount-due,
	.amount-vat-sale,
	.vat-amount,
	.total-sale,
	.address,
	.amount,
	.document-number,
	.remarks {
		position: absolute;
		font-weight: bolder;
	}

	.receipt-date {
		right: 390px;
		top: 95px;
    font-size: 11px;
	}
	.document-number{
		left: 539px;
		top: 23px;
    font-size: 25px;
	}
	.receipt-amount-number {
		right: 380px;
    top: 245px;
		font-size: 11px;
	}

	.receipt-account-name {
		top: 198px;
    left: 440px;
		font-size: 11px;
	}

	.receipt-amount-text {
		font-size: 8px;
	}

	.address {
		font-size: 11px;
    left: 440px;
    top: 261px;
	}

	.remarks {
		font-size: 11px;
    left: 440px;
    top: 277px;
	}

	.sett-amount {
		top: 178px;
		right: 765px;
		font-size: 11px;  
	}

	.amount-less-vat {
		top: 195px;
		right: 765px;
		font-size: 11px;  
	}

	.amount-total {
		top: 212px;
		right: 765px;
		font-size: 11px;  
	}

	.amount-total-due {
		top: 246px;
		right: 765px;
		font-size: 11px;  
	}

	.amount-total-less-vat {
		top: 263px;
		right: 765px;
		font-size: 11px;  
	}

	.amount-due {
		top: 280px;
		right: 765px;
		font-size: 11px;  
	}

	.amount-vat-sale {
		top: 314px;
		right: 765px;
		font-size: 11px;  
	}

	.vat-amount {
		top: 365px;
		right: 765px;
		font-size: 11px;  
	}

	.total-sale {
		top: 382px;
		right: 765px;
		font-size: 11px;  
	}

	.amount {
		top: 230px;
    left: 435px;
		width: 250px;
		line-height: 90%;
	}


	/* .receipt-date {
		right: 260px;
		top: 130px;
    font-size: 21px;
    font-family: arial;
	} */

	@media print {
		body #printAction { 
			display:none; 
		}

    body #printArea { 
			display:block; 
		}
		body .receipt-file {
			display: none;
		}
		body #documentNumber {
			display: none;
		}
	}
</style>