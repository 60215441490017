<template>
  <div>
    <div id="printAction" class="action">
      <v-row >
        <v-spacer class="hidden-sm-and-down"></v-spacer>
        <v-col cols="10">
          <h4>Report Name: Books of Account</h4>
        </v-col>
        <v-col cols="2">
           <v-btn color="success" @click="exportExcel('printArea', 'boa')"><v-icon left>mdi-microsoft-excel</v-icon> Export</v-btn>
        </v-col>
      </v-row>
    </div>
    <div id="printArea" ref="content" style="width: 90%; font-size: 12px; margin: auto;">
      <v-row>
        <v-col cols="9">
          <p>
            <b>BOOKS OF ACCOUNT</b>
			<br>
			<b>{{report_name}}</b>
            <br>
            <b>{{getDateFormat(detail.date_from)}} TO {{getDateFormat(detail.date_to)}}</b>
          </p>
        </v-col>
        <v-col cols="3">
          <img class="petnet-logo" src="../../../../assets/petnet2.png" alt="Petnet ERP">
        </v-col>
      </v-row>
      <div id="tableDiv">
      <table id="table" style="width: 95%; margin: auto;" ref="table">
        <tr>
          <th>DATE</th>
          <th>DOCUMENT NUMBER</th>
          <th>ANALYSIS</th>
          <th>ACCOUNT CODE</th>
          <th>ACCOUNT NAME</th>
          <th>REMARKS</th>
          <th>DEBIT</th>
          <th>CREDIT</th>
        </tr>
        <tr style="outline: 1px solid black; width: 100%; margin: 10px;">
        </tr>
		<tr v-for="(det, index) in boaData.data1" :key="index">
			<td style="text-align: center">{{det.date}}</td>
			<td style="text-align: center">{{det.document_number}}</td>
			<td>{{det.analysis}}</td>
			<td style="text-align: center">{{det.account_code}}</td>
			<td>{{det.account_name}}</td>
			<td class="description">{{det.remarks}}</td>
			<td style="text-align: right">{{getAmountFormat(det.debit_amount)}}</td>
			<td style="text-align: right">{{getAmountFormat(det.credit_amount)}}</td>
		</tr>
		<tr></tr>
		<tr>
			<td colspan="6"><b>TOTAL</b></td>
			<td style="text-align: right"><b>{{getAmountFormat(boaData.data2.total_debit)}}</b></td>
			<td style="text-align: right"><b>{{getAmountFormat(boaData.data2.total_credit)}}</b></td>
		</tr>
      </table>
	</div>
      <v-row>
        <v-col cols="9">
          <p>
            <br>
            Company: PETNET, INC.
            <br>
            Address: East Offices Building Aguirre St. Legaspi Village, Makati City
			<br>
			VAT Reg TIN: 201-200-777-000
			<br>
			System: Enterprise Resource Planning
			<br>
			Version: 1.0.0
          </p>
        </v-col>
        <v-col cols="3">
          <p>
            <br>
            Generated by: {{generated_by}}
            <br>
            Generated Date: {{generated_date}}
			<br>
			Generated Time: {{generated_time}}
          </p>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import numeral from 'numeral'; 
import { format } from 'date-fns';
export default {
	data: () => ({
		detail: {},
		report_name: '',
		generated_by: '',
		generated_time: new Date().toLocaleTimeString(),
		generated_date: new Date().toLocaleDateString()

	}),
	mounted() {
		this.init(this.detail.trans_type, this.detail);
	},
	created() {
		var app = this;
		app.detail = JSON.parse(atob(app.$route.params.data)); 
		app.generated_by = app.detail.user;
	},
	computed: {
		...mapGetters({
			boaData: 'report/boaData',
		}),
	},
	methods:{
		print() {
			window.print();
		},
		exportExcel(tableID, filename = '') {
			const date = Math.floor(Date.now() / 1000);
			var downloadLink;
			var dataType = 'application/vnd.ms-excel';

			// var x = document.getElementsByClassName('amount');
			// var i;
			// for (i = 0; i < x.length; i++) {
			// 	x[i].innerHTML = '\'' + x[i].innerHTML;
			// }
			
			var x = document.getElementsByClassName('description');
			var i;
			for (i = 0; i < x.length; i++) {
				x[i].innerHTML = x[i].innerHTML.replace(/[`~!@#$%^&*()|+=?;:'"<>]/gi, ' ');
			}
			var tableSelect = document.getElementById(tableID);
			var tableHTML = tableSelect.outerHTML.replace(/ /g, '%20');


			filename = filename ? filename + date + '.xls' : 'excel_data.xls';
			downloadLink = document.createElement('a');
			document.body.appendChild(downloadLink);

			if (navigator.msSaveOrOpenBlob) {
				var blob = new Blob(['\ufeff', tableHTML], {
					type: dataType
				});
				navigator.msSaveOrOpenBlob(blob, filename);
			} else {
				downloadLink.href = 'data:' + dataType + ', ' + tableHTML;
				downloadLink.download = filename;
				downloadLink.click();
			}
			
			// for (i = 0; i < x.length; i++) {
			// 	x[i].innerHTML = x[i].innerHTML.replace('\'', '');
			// }
		},
		getAmountFormat(amount){
			return numeral(amount).format('0,0.00');
		},
		getMonth(){
			var month = new Array();
			month[1] = 'January';
			month[2] = 'February';
			month[3] = 'March';
			month[4] = 'April';
			month[5] = 'May';
			month[6] = 'June';
			month[7] = 'July';
			month[8] = 'August';
			month[9] = 'September';
			month[10] = 'October';
			month[11] = 'November';
			month[12] = 'December';
			var n = month[this.detail.month];
			return n;
		},
		getDateFormat(date){
			var month = format(new Date(date), 'MMM');
			var day = format(new Date(date), 'dd');
			var year = format(new Date(date), 'yyyy');
			var d = month + ' ' + day +', '+ year;
			return d.toUpperCase();
		},
		init(type, data){
			// this.$store.dispatch('report/getBoaje', data);
			delete data.trans_type;
			delete data.user;
			switch(type) {
			case 'DM':
				this.$store.dispatch('report/getBoaDisb', data);
				this.report_name = 'DISBURSEMENT';
				break;
			case 'CR':
				this.$store.dispatch('report/getBoaCr', data);
				this.report_name = 'CASH RECIEPT';
				break;
			case 'JE':
				this.$store.dispatch('report/getBoaje', data);
				this.report_name = 'JOURNAL ENTRY';
				break;
			case 'PO':
				this.$store.dispatch('report/getBoaPo', data);
				this.report_name = 'PURCHASE ORDER';
				break;
			}
		}
	}
};
</script>
<style scoped>
.report-template {
  width: 768px;
  /* border: 1px solid black; */
  font-size: 12px;
  margin: auto;
}
.tbl {
  width: 95%;
  margin: auto;
}
.code {
  text-align: center;
}
.amount {
  text-align: right;
}
.header {
  outline: 1px solid black;
  width: 100%;
  margin: 10px;
}
.total {
  text-align: right;
  font-weight: bold;
}
.action {
  padding: 20px;
}
.petnet-logo {
    width: 60%;
}
.title {
  text-justify: center;
}
#tableDiv {
	overflow-y: auto;
}
	@media print {
		body #printAction { 
			display:none; 
		}
		body #printArea { 
			display:block; 
		}
	} 
</style>