import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueSweetalert2  from 'vue-sweetalert2';
import GSignInButton from 'vue-google-signin-button'; 
import store from './store';
import './plugins/chartist.js'; 
import Echo from 'laravel-echo'; 
import VueCookies from 'vue-cookies';
import VueExcelXlsx from 'vue-excel-xlsx';



Vue.config.productionTip = false;

Vue.use(VueAxios, axios);
Vue.use(VueSweetalert2);
Vue.use(VueCookies);
Vue.use(VueExcelXlsx);

window.io = require('socket.io-client');
 
let authData = Vue.$cookies.get('auth-data');
let token = '';
if(authData){
	token = authData.token;
}
// export var echo_instance = new Echo({
// 	broadcaster: 'socket.io',
// 	host: process.env.VUE_APP_SOCKET_URL,
// 	forceTLS: true,
// 	auth: {
// 		headers: { 
// 			Authorization: `Bearer ${authData.token}`,
// 			withCredentials: true,
// 			Accept:'application/json', 
// 		}
// 	}
// }); 

export var echo_instance = new Echo({
	broadcaster: 'socket.io',
	host:  process.env.VUE_APP_SOCKET_URL,
	authEndpoint:   process.env.VUE_APP_SOCKET_URL,
	rejectUnauthorized: false,
	// authEndpoint:  process.env.VUE_APP_SOCKET_AUTHENTICATION,
	authHost: process.env.VUE_APP_BASE_URL,
	disableStats: true,
	forceTLS: true,
	auth: {
		headers: {
			Authorization: `Bearer ${token}`,
			withCredentials: true,
			Accept:'application/json',  
		}
	}
});    

Vue.prototype.$echo = echo_instance;
Vue.config.productionTip = false;  
Vue.use(VueAxios, axios);
Vue.use(VueSweetalert2); 
new Vue({
	router,
	store,
	vuetify, 
	GSignInButton,
	render: h => h(App)
}).$mount('#app');
