import { RepositoryFactory } from '../../../api_repo/RepositoryFactory';

const useraccessprivilegesRepository = RepositoryFactory.get('useraccessprivilege');

const getUseraccessprivileges = (context) => {
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	useraccessprivilegesRepository.get()
		.then((response) => {
			context.commit('USERACCESSPRIVILEGES_UPDATED', response.data.data);
			context.commit('app/SET_IS_LOADING',false,{ root: true });
		})
		.catch(() => {
			context.commit('app/SET_IS_LOADING',false,{ root: true });
            
		});
};

const saveUseraccessprivilege = (context,payload) => {
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		useraccessprivilegesRepository.saveUseraccessprivilege(payload)
			.then((response) => {
				context.commit('USERACCESSPRIVILEGES_ADD',response.data.data);    
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ' + response.data.data.user_id + '(' + response.data.data.prog_code + ') added.','messagetype':'success'},{ root: true });
			})
			.catch((error) => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Saving failed! ' + error.response.data.message,'messagetype':'error'},{ root: true });  
			});
	}, 1500);
};


const updateUseraccessprivilege = (context,payload) => {
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		useraccessprivilegesRepository.updateUseraccessprivilege(payload.id,payload)
			.then((response) => {
				context.commit('USERACCESSPRIVILEGES_ITEM_UPDATE',response.data.data);
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ' + response.data.data.user_id + '(' + response.data.data.prog_code + ') updated.','messagetype':'success'},{ root: true });            
			})
			.catch((error) => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Update failed! ' + error.response.data.message,'messagetype':'error'},{ root: true });  
			});
	}, 1500);
};

const deleteUseraccessprivilege = (context,payload) => {
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		useraccessprivilegesRepository.deleteUseraccessprivilege(payload.id)
			.then((response) => {
				context.commit('USERACCESSPRIVILEGES_DELETE',response.data.data);
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ' + response.data.data.user_id + '(' + response.data.data.prog_code + ') deleted.','messagetype':'success'},{ root: true });            
			})
			.catch((error) => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Delete failed! ' + error.response.data.message,'messagetype':'error'},{ root: true });  
			});
	}, 1500);
};


const setEditedItems = (context,payload) => {
	context.commit('SET_EDITED_ITEM',payload);
};

const setDialog = (context,payload) => {
	context.commit('SET_DIALOG',payload);
};

const setEditedIndex = (context,payload) => {
	context.commit('SET_EDITED_INDEX',payload);
};

const setValid = (context,payload) => {
	context.commit('SET_VALID',payload);
};

const setformTitle = (context,payload) => {
	context.commit('SET_FORM_TITLE',payload);
};


export default {
	getUseraccessprivileges,
	saveUseraccessprivilege,
	updateUseraccessprivilege,
	deleteUseraccessprivilege,
	setEditedItems,
	setDialog,
	setEditedIndex,
	setValid,
	setformTitle
};
