<template>
  <v-app id="login" style="background-image: url('https://cdn.vuetifyjs.com/images/backgrounds/vbanner.jpg')">
  <!-- <v-app id="login" style="background-image: '/src/assets/petnet.png')"> -->
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md3 lg3>
            <v-card class="elevation-4 pl-2 pr-2">
              <v-card-text>
                <div class="layout column align-center">
					<br>
					<br> 
                  <img class="erp-logo" src="../assets/ERP.png" alt="Petnet ERP">
                  <!-- <img class="petnet-logo" src="../assets/petnet2.png" alt="Petnet ERP"> -->
                  <!-- <h1 class="display-2 font-weight-thin mb-4">PETNET</h1> -->
                  <!-- <h4 class="subheading">Enterprise Resource Planning</h4> -->
                </div>
                <!-- <v-form>
                  <v-text-field
                    append-icon="person"
                    name="login"
                    label="Login"
                    type="text"
                    v-model="user_id"
                    :error="error"
                    :rules="[rules.required]"/>
                  <v-text-field
                    :type="hidePassword ? 'password' : 'text'"
                    :append-icon="hidePassword ? 'visibility_off' : 'visibility'"
                    name="password"
                    label="Password"
                    id="password"
                    :rules="[rules.required]"
                    v-model="password"
                    :error="error"
                    @click:append="hidePassword = !hidePassword"/> -->
									<!-- <v-autocomplete
										v-model="company_code"
										:items="companies"
										editable
										hide-details
										item-text="bank_name"
										item-value="id"
										label="Company" /> -->
                <!-- </v-form> -->
              <!-- </v-card-text> -->
							<!-- <vue-recaptcha 
								sitekey="6LfEOKMZAAAAAD7uTf3eadFWs7qcVpa3n0B_kew2" 
								:loadRecaptchaScript="true"
								@verify="verifyCaptcha"
								@expired="expireCaptcha"
								class="recaptcha"
							></vue-recaptcha> -->
              <!-- <v-card-text>
				<v-btn block color="primary" class="btn" @click="loginSubmit" :loading="loading">Log In With Petnet Account</v-btn>
				OR
				<v-btn color="success" class="btn" block :loading="loadingEmail">
				<g-signin-button
					:params="googleSignInParams"
					@success="onSignInSuccess"
					@error="onSignInError">
					Log In With Google
				</g-signin-button> 
				</v-btn> -->
				<br>
				<br>
				Use your credentials to access the ERP system
				<br>
				<br>
				<v-btn dark color="primary" class="btn" block outlined :loading="loadingOkta" @click="loginOkta"> 
					<v-icon
						dark
						left
					>
						mdi-circle-outline
					</v-icon>
					Okta
				</v-btn>
				or
				<v-btn dark color="primary" right block class="btn" :loading="loadingEmail" @click="loginEmail"> 
					<v-icon
					left
						dark
					>
						mdi-google
					</v-icon>
					Google
				</v-btn>
				<br>
				<br>
              </v-card-text>
              <!-- <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn block color="primary" @click="forgotPassword" text>Forgot Password</v-btn>
              </v-card-actions> -->
							
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import GSignInButton from 'vue-google-signin-button';
// eslint-disable-next-line no-unused-vars
//import axios from 'axios';
// import VueRecaptcha from 'vue-recaptcha';
import Vue from 'vue';
//import VueCookies from 'vue-cookies';

Vue.use(GSignInButton);
export default {
	// components: { VueRecaptcha },
	data: () => ({ 
		googleSignInParams: {
			client_id: process.env.VUE_APP_CLIENT_ID,
			scope:'email',
			fetch_basic_profile:true
		},
		hidePassword: true,
		rules: {
			required: value => !!value || 'Required.'
		},
		user_id: '',
		password: '',
		token: '',
		isCaptchaVerified: true,
		company: [
			{value: '1', text: 'Petnet'},
			{value: '2', text: 'Better Way'},
			{value: '3', text: 'City Savings Bank'},
			{value: '4', text: 'Banana Pay'}
		]
	}),
	computed: {
		...mapGetters({
			loading: 'auth/isLoading',
			loadingEmail: 'auth/isLoadingEmail',
			loadingOkta: 'auth/isLoadingOkta',
			error: 'auth/error',
			//companies: 'company/companies'
		}),
	},
	mounted() {
		this.init();
		window.addEventListener('message', this.onMessage, false);
		//this.$store.dispatch('company/getCompanies');
	},
	beforeMount() {
		window.removeEventListener('message', this.onMessage);
	},
	beforeDestroy() {
		window.removeEventListener('message', this.onMessage);
	},
	methods: {
		loginOkta () {
			this.$store.dispatch('auth/doLoginWithOkta');
		},
		loginEmail () {
			this.$store.dispatch('auth/doLoginWithEmail');
		},
		onMessage (event) {
			const parentURL = process.env.VUE_APP_ERP_URL;
			if (event.origin == parentURL) {
				if (event.data.type === 'success') {
					if(typeof event.data.data.user === 'string'){
						let data = event.data.data.user.replace(/&quot;/gi, '"');
						event.data.data.user = JSON.parse(data);
						localStorage.setItem('hris-token', event.data.data.hris_token);
					}
					this.$store.dispatch('auth/setLoginOkta', event.data.data);
				} else {
					this.$store.commit('auth/AUTH_ERROR');
					this.$store.commit('app/SET_SNACK_BAR',{'status':true,'message': event.data.message ,'messagetype':'error'},{ root: true });
				}
			} else {
				this.$store.commit('auth/AUTH_ERROR');
			}
		},
		init() {
			//localStorage.removeItem('auth-data');
			//VueCookies.$cookies.remove('auth-data');
			this.$store.dispatch('auth/setIsAuthenticated',false);

			
		},
		verifyCaptcha(response){
		
			this.token = response;
			this.isCaptchaVerified = true;
		},
		expireCaptcha() {
			// this.$refs.recaptcha.reset();
			this.isCaptchaVerified = true;
		},
		loginSubmit() {
			const { user_id, password } = this;

			// axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
			// axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

			// axios.post('https://www.google.com/recaptcha/api/siteverify', {
			// 	secret: '6LfEOKMZAAAAAMdRRiffOyOU4hBu3Uu5O4SVGKbV',
			// 	response: this.token,
			// }).then((response) => {
			// 	console.log(response.data.message);
			// }).catch((err) => {
			// 	console.log(err);
			// });
			
			if (this.isCaptchaVerified === true) {
				if (!user_id || !password) {
					this.$store.dispatch('app/setSnackBar',{'status': true,'message':'Email and Password cannot be null.','messagetype':'error'});
					return;
				}
				
				this.$store.dispatch('auth/doLogin', { user_id, password, 'default': 1 }).then(() => {
					this.$router.push('/');
				});
			} else {
				this.$store.dispatch('app/setSnackBar',{'status': true,'message':'Please Verify Captcha First.','messagetype':'error'});
			}

			
		},
		forgotPassword(){
			alert('forgot password');
		},
		onSignInSuccess (googleUser) { 
			var app = this;
			const profile = googleUser.getBasicProfile();  
			app.profileUser = googleUser;
			// app.parseProfileGoogleSignIn(profile.getEmail(),googleUser.getAuthResponse().id_token); 
			app.emailAddressFromGoogle = profile.getEmail(); 

			// this.$store.dispatch('auth/doLoginEmail', { 'email': app.emailAddressFromGoogle, 'default': 0, 'token': googleUser.getAuthResponse().id_token}).then(() => {
			// 	this.$router.push('/');
			// });
			this.$store.dispatch('auth/doHrisLogin', { 'login_type': 2, 'is_erp': 1, 'google_id_token': googleUser.getAuthResponse().id_token}).then((response) => {
				Vue.$cookies.set('hris-token', response.data.access_token);
				this.$store.dispatch('auth/doLoginEmail', { 'email': app.emailAddressFromGoogle, 'default': 0, 'token': response.data.access_token}).then(() => {
					this.$router.push('/');
				});
			});

		},
		onSignInError () {
			this.$store.dispatch('app/setSnackBar',{'status': true,'message':'Invalid email account.','messagetype':'error'});
		},
	}
};
</script>

<style scoped lang="css">
  #login {
    height: 50%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
	text-align: center;
    content: "";
    z-index: 0;
		background: rgb(2,0,36) !important;
    background: linear-gradient(130deg, rgba(2,0,36,1) 0%, rgba(26,39,145,1) 35%, rgba(0,224,255,1) 100%) !important;
  }

  .secondary{
    background-image: url('https://cdn.vuetifyjs.com/images/parallax/material.jpg')
  }

  .petnet-logo {
    height: 80%;
    width: 30%;
	}

  .erp-logo {
    width: 90%;
	}

	.recaptcha {
		padding-left: 45px;
	}
	.btn {
    text-transform: unset !important;
	}
</style>