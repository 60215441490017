import Repository from '../Repository';

const resource = '/bankheader';

export default {
	get() {
		return Repository.get(`${resource}`);
	},

	getBankheader(id) {
		return Repository.get(`${resource}/${id}`);
	},

	saveBankheader(payload) {
		return Repository.post(`${resource}`, payload);
	},

	updateBankheader(id, payload) {
		return Repository.put(`${resource}/${id}`, payload);
	},

	deleteBankheader(id) {
		return Repository.delete(`${resource}/${id}`);
	},

	searchBankheader(payload){
		return Repository.get(`${resource}`,payload);
	},
	
	getData(payload){
		return Repository.get(`${resource}?page=`+ payload);
	} 
};