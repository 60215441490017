export default {
	module: [],
	headers: [
		{
			text: 'Record ID',
			align: 'left',
			sortable: false,
			value: 'id',
		},
		{
			text: 'Module',
			align: 'left',
			sortable: false,
			value: 'module_name',
		},
		{
			text: 'Module Type',
			align: 'left',
			sortable: false,
			value: 'module_type',
		},
		{ text: 'Actions', value: 'action', sortable: false },
	],
	columnHeaders: [
		{ label: 'Record ID', field: 'id' },
		{ label: 'Module', field: 'module_name' },
		{ label: 'Module Type', field: 'module_type' },
	],
  
	editedItem: {
		id: '',
		module: ''
	},
	defaultItem: {
		id: '',
		module: ''
	},
	dialog: false,
	editedIndex: -1,
	valid: true,
	formTitle: 'Edit entry',
};