import Vue from 'vue';

const BILLING_SHOW_DETAIL = (state, payload) => {
	state.billingDetails = payload;
};

const BILLING_UPDATED = (state, payload) => {
	state.billings = payload;
};

const BILLING_ADD = (state, payload) => {
	state.billings =  Object.values(state.billings);
	state.billings.push(payload);
};

const BILLING_DELETE = (state, payload) => {
	let index = state.billings.indexOf(payload);
	state.billings.splice(index, 1);
};

const BILLING_ITEM_UPDATE = (state, payload) => {
	Vue.set(state.billings, state.editedIndex, payload);
};

const BILLING_EDIT_MODAL_VIEW = (state,payload) => {
	state.editDialog = payload;
};
const SET_EDITED_ITEM = (state,payload) => {
	state.editedItem = payload;
};

const BILLING_DETAIL_MODAL_VIEW = (state,payload) => {
	state.viewDialog = payload;
};

const BILLING_ATTACHMENT_MODAL_VIEW = (state,payload) => {
	state.attachmentDialog = payload;
};

const BILLING_EDIT_PROMPT_DIALOG = (state,payload) => {
	state.updateDisbursement = payload;
};

const UNCOLLECTED_BILLING = (state, payload) => {
	state.uncollectedBilling = payload;
};

const UPDATE_TABLE_BILLING = (state, payload) => {
	state.editedItem.accouting_status = payload;
	Vue.set(state.billings, state.editedIndex, state.editedItem);
};


const UPDATE_TABLE_BILLING_ACCOUNTING = (state, payload) => {
	state.editedItem.accounting_status = payload.accounting_status;
	Vue.set(state.billings, state.editedIndex, state.editedItem);
};

const SET_EDITED_INDEX = (state, payload) => {
	state.editedIndex = payload;
};

const SET_PREPARER = (state, payload) => {
	state.preparer = payload;
};

const SET_DIST_DATA = (state, payload) => {
	state.dist_data = payload;
};

const BILLING_SHOW_PRINT_DETAIL = (state, payload) => {
	state.billingPrintDetails = payload;
};

const SHOW_FORM_2307_DETAIL = (state, payload) => {
	state.birFormDetails = payload;
};

const BILLING_DETAIL = (state, payload) => {
	state.billingDetailed = payload;
};

const SET_SUCCESS = (state, payload) => {
	state.success = payload;
};

const TRX_SEARCH_RESULT = (state, payload) => {
	state.transactionResults = payload;
};

const SET_PRINT_DETAILS = (state, payload) => {
	state.printDetails = payload;
};

const SHOW_UPLOADED_CSV_FILES = (state, payload) => {
	state.csvFileUploads = payload;
};



const SET_DIALOG = (state,payload) =>{
	state.dialog = payload;
};
const SET_VALID = (state,payload) => {
	state.valid = payload;
};

const SET_FORM_TITLE = (state,payload) => {
	state.formTitle = payload;
};

const SET_BILLING_REQUEST_NUMBER = (state, payload) => {
	state.billingRequestNumber = payload;
};

const SET_BILLING_NUMBER = (state, payload) => {
	state.billingNumber = payload;
};

export default {
	BILLING_UPDATED,
	BILLING_ADD,
	BILLING_DELETE,
	BILLING_ITEM_UPDATE,
	BILLING_SHOW_DETAIL,
	BILLING_EDIT_MODAL_VIEW,
	BILLING_DETAIL_MODAL_VIEW,
	BILLING_ATTACHMENT_MODAL_VIEW,
	BILLING_EDIT_PROMPT_DIALOG,
	TRX_SEARCH_RESULT,
	SET_EDITED_INDEX,
	SET_PREPARER,
	SET_DIST_DATA,
	BILLING_SHOW_PRINT_DETAIL,
	BILLING_DETAIL,
	SET_SUCCESS,
	SHOW_FORM_2307_DETAIL,
	SET_PRINT_DETAILS,
	SHOW_UPLOADED_CSV_FILES,
	SET_DIALOG,
	SET_FORM_TITLE,
	SET_VALID,
	UNCOLLECTED_BILLING,
	UPDATE_TABLE_BILLING,
	SET_EDITED_ITEM,
	UPDATE_TABLE_BILLING_ACCOUNTING,
	SET_BILLING_REQUEST_NUMBER,
	SET_BILLING_NUMBER
};