import { RepositoryFactory } from '../../../api_repo/RepositoryFactory';
import router from '../../../router';
import Vue from 'vue';

const authRepository = RepositoryFactory.get('auth');

const doLogin = (context,payload) => new Promise((resolve,reject) => {
	context.commit('AUTH_REQUEST');
	setTimeout(() => {
		authRepository.login(payload)
			.then((response) => {
				localStorage.setItem('auth-data',JSON.stringify(response.data.data));
				Vue.$cookies.set('auth-data', response.data.data);
				context.commit('AUTH_SUCCESS',response.data.data);
				resolve(response);
			})
			.catch(() => {
				context.commit('AUTH_ERROR','Invalid username or password');
				context.commit('app/SET_SNACK_BAR',{'status':true,'message':'Invalid username or password','messagetype':'error'},{ root: true });
				reject();
			});
	}, 2000);
});

const doLoginEmail = (context,payload) => new Promise((resolve,reject) => {
	context.commit('AUTH_REQUEST_EMAIL');
	setTimeout(() => {
		authRepository.login(payload)
			.then((response) => {
				localStorage.setItem('auth-data',JSON.stringify(response.data.data));
				Vue.$cookies.set('auth-data', response.data.data);
				context.commit('AUTH_SUCCESS_EMAIL',response.data.data);
				resolve(response);
			})
			.catch(() => {
				context.commit('AUTH_ERROR','Invalid Email Account');
				context.commit('app/SET_SNACK_BAR',{'status':true,'message':'Invalid Email Account','messagetype':'error'},{ root: true });
				reject();
			});
	}, 2000);
});

const setLoginOkta = (context,payload) => {
	context.commit('AUTH_REQUEST_OKTA');
	setTimeout(() => {
		localStorage.setItem('auth-data',JSON.stringify(payload));
		Vue.$cookies.set('auth-data', payload);
		context.commit('AUTH_SUCCESS_OKTA', payload);
		router.push('/');
	}, 2000);
};

const getCurrentUser = (context) => {
	authRepository.get_current_user()
		.then((response) => {
			context.commit('SET_CURRENT_USER',response.data.data.user);
		})
		.catch((error) => {
			context.commit('SET_LOGIN_STOP',error.response.data.message);
			this.doLogout();
		});
};

const doLogout = () => {
	authRepository.logout()
		.then(() => {
			this.removeStorage;
		})
		.catch(() => {
			//console.log(error);
		});
};

const removeStorage = (context) => {
	// Vue.$cookies.remove('hris-token');
	localStorage.removeItem('hris-token');
	localStorage.removeItem('auth-data');
	Vue.$cookies.remove('auth-data');
	context.commit('AUTH_LOGOUT');
	localStorage.removeItem('dist_data');
	context.commit('trxdisb/SET_DIST_DATA','',{ root: true });
	context.commit('trxbilling/SET_BILLING_REQUEST_NUMBER','',{ root: true });
	context.commit('trxbilling/SET_BILLING_NUMBER','',{ root: true });
	context.commit('trxdisb/SET_OR_NUMBER','',{ root: true });
	context.commit('trxdisb/SET_AR_NUMBER','',{ root: true });
	context.commit('trxcollection/SET_COLLECTION_NUMBER','',{ root: true });

	// context.commit('trxpurchaseorder/UNSET_PO_NUMBER', { root: true });
};

const doLoginWithOkta = (context) => new Promise((resolve, reject) => {
	context.commit('AUTH_REQUEST_OKTA');
	const newWindow = openWindow('', 'message');
  
	setTimeout(() => {
		authRepository.loginOkta()
			.then((response) => {
				context.commit('SET_ISLOADING', false);
				const urlAccountSelection = response.data;
  
				newWindow.location.href = urlAccountSelection;
				resolve(response);
			})
			.catch((err) => {
				context.commit('SET_ISLOADING', false);
				reject(err.response);
			});
	}, 2000);
});
const doLoginWithEmail = (context) => new Promise((resolve, reject) => {
	context.commit('AUTH_REQUEST_EMAIL');
	const newWindow = openWindow('', 'message');
  
	setTimeout(() => {
		authRepository.loginEmail()
			.then((response) => {
				context.commit('SET_ISLOADING', false);
				const urlAccountSelection = response.data;
  
				newWindow.location.href = urlAccountSelection;
				resolve(response);
			})
			.catch((err) => {
				context.commit('SET_ISLOADING', false);
				reject(err.response);
			});
	}, 2000);
});


function openWindow (url, title, options = {}) {
	if (typeof url === 'object') {
		options = url;
		url = '';
	}
  
	options = { url, title, width: 600, height: 720, ...options };
  
	const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screen.left;
	const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screen.top;
	const width = window.innerWidth || document.documentElement.clientWidth || window.screen.width;
	const height = window.innerHeight || document.documentElement.clientHeight || window.screen.height;
  
	options.left = ((width / 2) - (options.width / 2)) + dualScreenLeft;
	options.top = ((height / 2) - (options.height / 2)) + dualScreenTop;
  
	const optionsStr = Object.keys(options).reduce((acc, key) => {
		acc.push(`${key}=${options[key]}`);
		return acc;
	}, []).join(',');
  
	const newWindow = window.open(url, title, optionsStr);
  
	if (window.focus) {
		newWindow.focus();
	}
  
	return newWindow;
}

const setCurrUser = (context,payload) => {
	context.commit('SET_CURRENT_USER',payload);
};

const setUserID = (context, payload) => {
	context.commit('SET_USER_ID',payload);
};

const setPassword = (context, payload) => {
	context.commit('SET_PASSWORD',payload);
};

const authError = (context, payload) => {
	context.commit('AUTH_ERROR',payload);
};

const setIsAuthenticated = (context,payload) => {
	context.commit('SET_ISAUTHENTICATED',payload);
};
const doHrisLogin = (context,payload) => new Promise((resolve,reject) => {
	context.commit('AUTH_REQUEST_EMAIL');
	setTimeout(() => {
		authRepository.loginHRIS(payload)
			.then((response) => {
				Vue.$cookies.set('hris-token', response.data.access_token);
				console.log(response.data.access_token);
				resolve(response);
			})
			.catch(() => {
				context.commit('AUTH_ERROR','Invalid username or password');
				context.commit('app/SET_SNACK_BAR',{'status':true,'message':'Invalid username or password','messagetype':'error'},{ root: true });
				reject();
			});
	}, 2000);
});

export default{
	doLogin,
	doLogout,
	getCurrentUser,
	setUserID,
	setPassword,
	setCurrUser,
	authError,
	setIsAuthenticated,
	doLoginEmail,
	doHrisLogin,
	doLoginWithOkta,
	setLoginOkta,
	removeStorage,
	doLoginWithEmail
};