import Vue from 'vue';


const ALPHATAXCODES_UPDATED = (state, alphataxcodes) => {
	state.alphataxcodes = alphataxcodes;
};

const ALPHATAXCODES_ADD = (state, payload) => {
	state.alphataxcodes.data.push(payload);
}; 

const ALPHATAXCODES_DELETE = (state, payload) => {
	let index = state.alphataxcodes.data.indexOf(payload);
	state.alphataxcodes.data.splice(index,1);
};

const ALPHATAXCODES_ITEM_UPDATE = (state, payload) => {
	Vue.set(state.alphataxcodes.data, state.editedIndex, payload);
};

const SET_EDITED_ITEM = (state,payload) =>{
	state.editedItem = payload;
};

const SET_DIALOG = (state,payload) =>{
	state.dialog = payload;
};

const SET_EDITED_INDEX = (state,payload) =>{
	state.editedIndex = payload;
};


const SET_VALID = (state,payload) => {
	state.valid = payload;
};

const SET_FORM_TITLE = (state,payload) => {
	state.formTitle = payload;
};


export default {
	ALPHATAXCODES_UPDATED,
	SET_EDITED_ITEM,
	SET_DIALOG,
	SET_EDITED_INDEX,
	ALPHATAXCODES_ADD,
	ALPHATAXCODES_DELETE,
	SET_VALID,
	SET_FORM_TITLE,
	ALPHATAXCODES_ITEM_UPDATE
};