import Vue from 'vue';

const APPROVINGOFFICER_UPDATED = (state, payload) => {
	state.approvingofficers = payload;
};

const APPROVINGOFFICER_ADD = (state, payload) => {
	state.approvingofficers.data.push(payload);
};

const APPROVINGOFFICER_DELETE = (state) => {
	state.approvingofficers.data.splice(state.editedIndex, 1);
};

const APPROVINGOFFICER_ITEM_UPDATE = (state, payload) => {
	Vue.set(state.approvingofficers.data, payload.index, payload.data);
};

const SET_EDITED_ITEM = (state,payload) =>{
	state.editedItem = payload;
};

const SET_DIALOG = (state,payload) =>{
	state.dialog = payload;
};

const SET_EDITED_INDEX = (state,payload) =>{
	state.editedIndex = payload;
};

const SET_VALID = (state,payload) => {
	state.valid = payload;
};

const SET_FORM_TITLE = (state,payload) => {
	state.formTitle = payload;
};

const SET_ISLOADING = (state,payload) => {
	state.isLoading = payload;
};

export default {
	APPROVINGOFFICER_UPDATED,
	APPROVINGOFFICER_ADD,
	APPROVINGOFFICER_DELETE,
	APPROVINGOFFICER_ITEM_UPDATE,
	SET_EDITED_ITEM,
	SET_DIALOG,
	SET_EDITED_INDEX,
	SET_VALID,
	SET_FORM_TITLE,
	SET_ISLOADING
};