import Vue from 'vue';

const BUSINESSPARTNERS_UPDATED = (state, businesspartners) => {
	state.businesspartners = businesspartners;

};
  
const BUSINESSPARTNERS_ADD = (state, payload) => {
	state.businesspartners.data.push(payload);
};

const BUSINESSPARTNERS_DELETE = (state) => {
	state.businesspartners.data.splice(state.editedIndex,1);
};

const SET_EDITED_ITEM = (state,payload) =>{
	state.editedItem = payload;
};

const SET_DIALOG = (state,payload) =>{
	state.dialog = payload;
};

const SET_EDITED_INDEX = (state,payload) =>{
	state.editedIndex = payload;
};

const BUSINESSPARTNERS_ITEM_UPDATE = (state, payload) => {
	Vue.set(state.businesspartners.data, state.editedIndex, payload);
};

const SET_VALID = (state,payload) => {
	state.valid = payload;
};

const SET_FORM_TITLE = (state,payload) => {
	state.formTitle = payload;
};

const SET_BUSIPART_PAYEEBANKS = (state, payload) => {
	state.editedItem.payee_banks.push(payload);
};

export default {
	BUSINESSPARTNERS_UPDATED,
	SET_EDITED_ITEM,
	SET_DIALOG,
	SET_EDITED_INDEX,
	SET_VALID,
	SET_FORM_TITLE,
	BUSINESSPARTNERS_ADD,
	BUSINESSPARTNERS_DELETE,
	BUSINESSPARTNERS_ITEM_UPDATE,
	SET_BUSIPART_PAYEEBANKS
};