import { RepositoryFactory } from '../../../api_repo/RepositoryFactory';

const trxintegratedRepository = RepositoryFactory.get('integrated');

const getIntegratedTransaction = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	console.log(payload);
	trxintegratedRepository.get(payload)
		.then((response) => {
			context.commit('TRX_INTEGRATED', response.data.data);
			context.commit('app/SET_IS_LOADING', false, { root: true });
		})
		.catch(() => {
			context.commit('app/SET_IS_LOADING', false, { root: true });
		});
};

const deleteIntegratedTransaction = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	setTimeout(() => {
		trxintegratedRepository.delete(payload)
			.then((response) => {
				context.commit('SET_DETAIL_MODAL_VIEW', false);
				context.commit('TRX_DELETE_INTEGRATED');
				context.commit('app/SET_IS_LOADING', false, { root: true });
				context.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'Successfully Delete Batch Number ' + response.data.data.batch_number, 'messagetype': 'success' }, { root: true });
			})
			.catch((error) => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
				context.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'Delete failed! ' + error.response.data.message, 'messagetype': 'error' }, { root: true });
			});
	}, 1500);
};

const getDataPages = (context,payload) => {
	context.commit('app/SET_IS_LOADING', true , { root: true });
	setTimeout(() => {
		trxintegratedRepository.getData(payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING', false , { root: true });
				context.commit('TRX_INTEGRATED', response.data.data);
			})
			// eslint-disable-next-line
			.catch(() => {
				context.commit('app/SET_IS_LOADING', false , { root: true });
			});
	}, 1500);
};

const getDetails = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	setTimeout(() => {
		trxintegratedRepository.getDetail(payload)
			.then((response) => {
				context.commit('SET_DETAIL_MODAL_VIEW', true);
				context.commit('TRX_INTEGRATED_DETAIL', response.data.data);
				context.commit('app/SET_IS_LOADING', false, { root: true });
			})
			.catch(() => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
			});
	}, 1500);
};
const setTimer = (context) => {
	setTimeout(() => {
		context.commit('app/SET_IS_LOADING', true, { root: true });

	}, 5000);
	context.commit('app/SET_IS_LOADING', false, { root: true });
};

const setEditedItems = (context, payload) => {
	context.commit('SET_EDITED_ITEM', payload);
};

const setEditDialog = (context, payload) => {
	context.commit('TRXDISBS_EDIT_MODAL_VIEW', payload);
};

const setViewDialog = (context, payload) => {
	context.commit('SET_DETAIL_MODAL_VIEW', payload);
};

const setAttachmentDialog = (context, payload) => {
	context.commit('TRXDISBS_ATTACHMENT_MODAL_VIEW', payload);
};

const setEditedIndex = (context, payload) => {
	context.commit('SET_EDITED_INDEX', payload);
};

const setPreparer = (context,payload) => {
	context.commit('SET_PREPARER',payload);
};

export default {
	getIntegratedTransaction,
	deleteIntegratedTransaction,
	setEditedItems,
	setEditDialog,
	setViewDialog,
	setAttachmentDialog,
	setEditedIndex,
	setPreparer,
	setTimer,
	getDataPages,
	getDetails
};