import Vue from 'vue';

const ROLEACCESS_UPDATED = (state, roleaccess) => {
	state.roleaccess = roleaccess;
};

const ROLEACCESS_ADD = (state, payload) => {
	state.roleaccess.push(payload);
}; 

const ROLEACCESS_DELETE = (state, payload) => {
	let index = state.roleaccess.indexOf(payload);
	state.roleaccess.splice(index,1);
};

const ROLEACCESS_ITEM_UPDATE = (state, payload) => {
	Vue.set(state.roleaccess, state.editedIndex, payload);
};

const SET_EDITED_ITEM = (state,payload) =>{
	state.editedItem = payload;
};

const SET_DIALOG = (state,payload) =>{
	state.dialog = payload;
};

const SET_EDITED_INDEX = (state,payload) =>{
	state.editedIndex = payload;
};

const SET_VALID = (state,payload) => {
	state.valid = payload;
};

const SET_FORM_TITLE = (state,payload) => {
	state.formTitle = payload;
};


export default {
	ROLEACCESS_UPDATED,
	SET_EDITED_ITEM,
	SET_DIALOG,
	SET_EDITED_INDEX,
	ROLEACCESS_ADD,
	ROLEACCESS_DELETE,
	SET_VALID,
	SET_FORM_TITLE,
	ROLEACCESS_ITEM_UPDATE
};