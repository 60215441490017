import { RepositoryFactory } from '../../../api_repo/RepositoryFactory';
import Vue from 'vue';
import router from '../../../router';

const trxCollectionRepository = RepositoryFactory.get('collection');
const getCollection = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	trxCollectionRepository.get(payload)
		.then((response) => {
			context.commit('COLLECTION_UPDATED', response.data.data);
			context.commit('app/SET_IS_LOADING', false, { root: true });
		})
		.catch(() => {
			context.commit('app/SET_IS_LOADING', false, { root: true });
		});
};

const getCollectionNumber = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	setTimeout(() => {
		trxCollectionRepository.getNumber('/trx/collection-number', payload)
			.then((response) => {
				context.commit('SET_COLLECTION_NUMBER', response.data.data.collection_number);
				context.commit('app/SET_IS_LOADING', false, { root: true });
				
				router.push('/trx-collection/create');
			})
			.catch(() => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
			});
	}, 1500);
};

const getDetail = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	context.commit('trxcollection/COLLECTION_DETAIL_MODAL_VIEW', false, { root: true });
	setTimeout(() => {
		trxCollectionRepository.getCollection(payload)
			.then((response) => {
				context.commit('trxcollection/COLLECTION_DETAIL_MODAL_VIEW', true, { root: true });
				context.commit('COLLECTION_SHOW_DETAIL', response.data.data);
				context.commit('app/SET_IS_LOADING', false, { root: true });
			})
			.catch(() => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
			});
	}, 1500);
};

const getCollectionPrintDM = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	context.commit('trxcollection/COLLECTION_DETAIL_MODAL_VIEW', false, { root: true });
	setTimeout(() => {
		trxCollectionRepository.getPrintData(payload)
			.then((response) => {
				context.commit('trxcollection/COLLECTION_DETAIL_MODAL_VIEW', true, { root: true });
				context.commit('COLLECTION_SHOW_PRINT_DETAIL', response.data);
				context.commit('app/SET_IS_LOADING', false, { root: true });
			})
			.catch(() => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
			});
	}, 1500);
};

const getArchivedCollection = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	setTimeout(() => {
		trxCollectionRepository.getCollection(payload)
			.then((response) => {
				context.commit('COLLECTION_SHOW_DETAIL', response.data.data);
				context.commit('app/SET_IS_LOADING', false, { root: true });
			})
			.catch(() => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
			});
	}, 1500);
};

const editCollection = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	context.commit('trxcollection/COLLECTION_EDIT_MODAL_VIEW', false, { root: true });
	setTimeout(() => {
		trxCollectionRepository.getCollection(payload)
			.then((response) => {
				let headers = response.data.data;
				let response_data = response.data.data;

				if ((headers[0].document_type === 'CW' && headers[0].is_pdc === 1) || (headers[0].document_type === 'CV' && headers[0].is_pdc === 1)) {
					headers[0].number_of_cheques = headers.length - 1;
					response_data = [headers[0]];
				}

				context.commit('COLLECTION_SHOW_DETAIL', response_data);
				context.commit('trxcollection/COLLECTION_EDIT_MODAL_VIEW', true, { root: true });
				// context.commit('app/SET_IS_LOADING', false, { root: true });
			})
			.catch(() => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
			});
	}, 1500);
};

const saveCollection = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	setTimeout(() => {
		trxCollectionRepository.saveCollection(payload.value)
			.then(() => {
				if (payload.type === 1){
					context.commit('trxdisb/SET_OR_NUMBER', '', { root: true });
				} else if (payload.type === 2){
					context.commit('trxdisb/SET_AR_NUMBER', '', { root: true });
				}
				context.commit('SET_COLLECTION_NUMBER', '');
				context.commit('COLLECTION_ADD', payload);
				context.commit('app/SET_IS_LOADING', false, { root: true });
				context.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'The transaction entry has been added to the list successfully.', 'messagetype': 'success' }, { root: true });
				context.commit('SET_SUCCESS', true);
				window.location.href = '/trx-collection';
				
				// }, 5000);
			}).catch((error) => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
				context.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'Saving failed! ' + error.response.data.message, 'messagetype': 'error' }, { root: true });
				var newErrorMessage = '';
				if (error.response.data.errors) {
					var myObject = error.response.data.errors;
					var newerror = [];
					for (var name in myObject) {
						var replacement = name.split('.')[2];
						if(replacement == undefined){
							replacement = name;
						}
						var errors = error.response.data.errors[name][0];
						var errorDesc = errors.replace(name, replacement);
						var replacedash = errorDesc.replace('_', ' ');
						newerror.push(replacedash);
					}
					newerror.sort();
					var current = null;
					var cnt = 0;
					for (var i = 0; i < newerror.length; i++) {
						var errormessage;
						if (newerror[i] != current) {
							if (cnt > 0) {
								errormessage +=
                  current + '<br>';
							}
							current = newerror[i];
							cnt = 1;
						} else {
							cnt++;
						}
					}
					if (cnt > 0) {
						errormessage +=
              current + '<br>';
					}
					newErrorMessage = errormessage.replace('undefined', '');
				} else {
					newErrorMessage = 'Please try again later.';
				}
				Vue.swal
					.fire({
						type: 'error',
						title: 'Failed to save!',
						html: newErrorMessage
					});
			});
	}, 1500);
};

const updateCollection = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	// context.commit('trxcollection/COLLECTION_EDIT_MODAL_VIEW', true, { root: true });
	setTimeout(() => {
		trxCollectionRepository.updateCollection(payload.batch_number, payload.value)
			.then((response) => {
				context.commit('COLLECTION_ITEM_UPDATE', response.data.data);
				context.commit('trxcollection/COLLECTION_EDIT_MODAL_VIEW', false, { root: true });
				// context.commit('COLLECTION_EDIT_PROMPT_DIALOG', false, { root: true });
				context.commit('app/SET_IS_LOADING', false, { root: true });
				context.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'The transaction entry was updated successfully', 'messagetype': 'success' }, { root: true });
			})
			.catch((error) => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
				context.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'Update failed! ' + error.response.data.message, 'messagetype': 'error' }, { root: true });
			});
	}, 1500);
};

const updateCollectionStatus = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	setTimeout(() => {
		trxCollectionRepository.updateCollectionStatus(payload.id, payload.value)
			.then((response) => {
				// context.commit('COLLECTION_ITEM_UPDATE', response.data.data);
				console.log(response);
				context.commit('app/SET_IS_LOADING', false, { root: true });
				context.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'Status updated successfully', 'messagetype': 'success' }, { root: true });
				context.commit('COLLECTION_DETAIL_MODAL_VIEW', false);
				window.location.href = '/trx-collection';
			})
			.catch((error) => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
				context.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'Update failed! ' + error.response.data.message, 'messagetype': 'error' }, { root: true });
			});
	}, 1500);
};
const updateCollectionAccountingStatus = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	setTimeout(() => {
		trxCollectionRepository.updateCollectionAccountingStatus(payload.id, payload.value)
			.then((response) => {
				// context.commit('COLLECTION_ITEM_UPDATE', response.data.data);
				console.log(response);
				context.commit('app/SET_IS_LOADING', false, { root: true });
				context.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'Status updated successfully', 'messagetype': 'success' }, { root: true });
				context.commit('COLLECTION_DETAIL_MODAL_VIEW', false);
			})
			.catch((error) => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
				context.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'Update failed! ' + error.response.data.message, 'messagetype': 'error' }, { root: true });
			});
	}, 1500);
};


const deleteCollection = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	setTimeout(() => {
		trxCollectionRepository.deleteCollection(payload.id)
			.then((response) => {
				context.commit('COLLECTION_DELETE', response.data.data);
				context.commit('app/SET_IS_LOADING', false, { root: true });
				context.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'The transaction entry has been deleted successfully', 'messagetype': 'success' }, { root: true });
			})
			.catch((error) => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
				context.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'Delete failed! ' + error.response.data.message, 'messagetype': 'error' }, { root: true });
			});
	}, 1500);
};

const getDetails = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	trxCollectionRepository.getCollection(payload)
		.then((response) => {
			context.commit('app/SET_IS_LOADING', false, { root: true });
			let data = JSON.parse(response.data.data[0].upload_file);
			data.forEach(detail => {
				detail.seen = false;
			});
			context.commit('COLLECTION_DETAIL', data);
		}).catch((error) => {
			context.commit('app/SET_IS_LOADING', false, { root: true });
			context.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'Failed! ' + error.response.data.message, 'messagetype': 'error' }, { root: true });
		});
};

const getData = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true , { root: true });
	return new Promise((resolve, reject) => {
		trxCollectionRepository.getData(payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING', false , { root: true });
				resolve(response);
			}, error => {
				context.commit('app/SET_IS_LOADING', false , { root: true });
				reject(error);
			});
	});
};
const getCollectionData = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	return new Promise((resolve, reject) => {
		trxCollectionRepository.getCollection(payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
				setTimeout(() => {
					resolve(response);
				}, 2000);
			}, error => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
				reject(error);
			});
	});
};

const getORARData = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	return new Promise((resolve, reject) => {
		trxCollectionRepository.getPrintReceiptData(payload)
			.then(({ data }) => {
				resolve(data);
				context.commit('app/SET_IS_LOADING', false, { root: true });
			}).catch((err) => {
				reject(err);
				context.commit('app/SET_IS_LOADING', false, { root: true });
			});
	});
};

const voidCollection = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	setTimeout(() => {
		trxCollectionRepository.voidCollection(payload)
			.then((response) => {
				console.log(response);
				context.commit('app/SET_IS_LOADING', false, { root: true });
				context.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'The transaction entry has been voided successfully', 'messagetype': 'success' }, { root: true });
				window.location.href = '/trx-collection';
			})
			.catch((error) => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
				context.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'Void failed! ' + error.response.data.message, 'messagetype': 'error' }, { root: true });
			});
	}, 1500);
};

const setTimer = (context) => {
	setTimeout(() => {
		context.commit('app/SET_IS_LOADING', true, { root: true });

	}, 5000);
	context.commit('app/SET_IS_LOADING', false, { root: true });
};

const setEditedItems = (context, payload) => {
	context.commit('SET_EDITED_ITEM', payload);
};

const setEditDialog = (context, payload) => {
	context.commit('COLLECTION_EDIT_MODAL_VIEW', payload);
};

const setViewDialog = (context, payload) => {
	context.commit('COLLECTION_DETAIL_MODAL_VIEW', payload);
};

const setAttachmentDialog = (context, payload) => {
	context.commit('COLLECTION_ATTACHMENT_MODAL_VIEW', payload);
};

const setEditedIndex = (context, payload) => {
	context.commit('SET_EDITED_INDEX', payload);
};

const setPreparer = (context,payload) => {
	context.commit('SET_PREPARER',payload);
};

const setDialog = (context,payload) => {
	context.commit('SET_DIALOG',payload);
};

const setValid = (context,payload) => {
	context.commit('SET_VALID',payload);
};

const setformTitle = (context,payload) => {
	context.commit('SET_FORM_TITLE',payload);
};

const setCollectionNumber = (context,payload) => {
	context.commit('SET_COLLECTION_NUMBER',payload);
};


// const setValid = (context, payload) => {
// 	context.commit('SET_VALID', payload);
// };

// const setformTitle = (context, payload) => {
// 	context.commit('SET_FORM_TITLE', payload);
// };

// const setUpdatedTrxnDisbs = (context,payload)=>{
// 	context.commit('COLLECTION_UPDATED', payload);
// };

// const setSelectedCollection = (context,payload)=>{
// 	context.commit('COLLECTION_SHOW_DETAIL', payload);
// };

export default {
	getCollection,
	editCollection,
	saveCollection,
	updateCollection,
	updateCollectionStatus,
	deleteCollection,
	setEditedItems,
	setEditDialog,
	setViewDialog,
	setAttachmentDialog,
	setEditedIndex,
	getArchivedCollection,
	setPreparer,
	setTimer,
	getCollectionPrintDM,
	getDetails,
	getDetail,
	getORARData,
	getData,
	setDialog,
	setformTitle,
	setValid,
	updateCollectionAccountingStatus,
	getCollectionData,
	setCollectionNumber,
	getCollectionNumber,
	voidCollection
};
