import { RepositoryFactory } from '../../../api_repo/RepositoryFactory';

const payefileRepository = RepositoryFactory.get('payefile');
const purchaseOrderRepository = RepositoryFactory.get('trxpurchaseorder');

const getPayefiles = () => {
	return new Promise((resolve, reject) => {
		payefileRepository.get()
			.then((response) => {
				resolve(response);
			}, error => {
				reject(error);
			});
	});
};

const getPayeeFile = (context, payload) => {
	return new Promise((resolve, reject) => {
		payefileRepository.getPayefile(payload)
			.then((response) => {
				resolve(response);
			}, error => {
				reject(error);
			});
	});
};

const getPONumber = (context, payload) => {
	return new Promise((resolve, reject) => {
		purchaseOrderRepository.getPONumber(payload)
			.then((response) => {
				resolve(response);
			}, error => {
				reject(error);
			});
	});
};


const submitPO = (context, payload) => {
	return new Promise((resolve, reject) => {
		purchaseOrderRepository.submitPO(payload)
			.then((response) => {
				resolve(response);
			}, error => {
				reject(error);
			});
	});
};

const getGridPO = (context, payload) => {
	return new Promise((resolve, reject) => {
		purchaseOrderRepository.getGridPO(payload)
			.then((response) => {
				resolve(response);
			}, error => {
				reject(error);
			});
	});
};

const getDetailPO = () => {
	return new Promise((resolve, reject) => {
		purchaseOrderRepository.getDetailPO()
			.then((response) => {
				resolve(response);
			}, error => {
				reject(error);
			});
	});
};
const getDetail = (context, payload) => {
	return new Promise((resolve, reject) => {
		purchaseOrderRepository.getDetail(payload)
			.then((response) => {
				resolve(response);
			}, error => {
				reject(error);
			});
	});
};

const getPO = (context, payload) => {
	return new Promise((resolve, reject) => {
		purchaseOrderRepository.getPO(payload)
			.then((response) => {
				resolve(response);
			}, error => {
				reject(error);
			});
	});
};

const updateRequestStatus = (context, payload) => {
	console.log(payload);
	return new Promise((resolve, reject) => {
		purchaseOrderRepository.updateRequestStatus(payload)
			.then((response) => {
				resolve(response);
			}, error => {
				reject(error);
			});
	});
};

const editPO = (context, payload) => {
	return new Promise((resolve, reject) => {
		purchaseOrderRepository.editPO(payload)
			.then((response) => {
				resolve(response);
			}, error => {
				reject(error);
			});
	});
};



export default {
	getPayeeFile,
	getPONumber,
	submitPO,
	getGridPO,
	getDetailPO,
	getPayefiles,
	getPO,
	updateRequestStatus,
	editPO,
	getDetail
};