import Repository from '../Repository';

const resource = '/company';

export default {
	get() {
		return Repository.get(`${resource}`);
	},

	getCompany(id) {
		return Repository.get(`${resource}/${id}`);
	},

	saveCompany(payload) {
		return Repository.post(`${resource}`, payload);
	},

	updateCompany(id, payload) {
		return Repository.put(`${resource}/${id}`, payload);
	},

	deleteCompany(id) {
		return Repository.delete(`${resource}/${id}`);
	},
	
	searchCompany(payload){
		return Repository.get(`${resource}`,payload);
	},

	getDetails(id) {
		return Repository.get(`${resource}/${id}`);
	},
	
	getData(payload){
		return Repository.get(`${resource}?page=`+ payload);
	} 
};