import Repository from '../Repository';

const resource = '/bplocation';

export default {
	get() {
		return Repository.get(`${resource}`);
	},

	getBplocation(id) {
		return Repository.get(`${resource}/${id}`);
	},

	saveBplocation(payload) {
		return Repository.post(`${resource}`, payload);
	},

	updateBplocation(id, payload) {
		return Repository.put(`${resource}/${id}`, payload);
	},

	deleteBplocation(id) {
		return Repository.delete(`${resource}/${id}`);
	},
	searchBplocation(payload){
		return Repository.get(`${resource}`,payload);
	}
};