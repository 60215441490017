export default {
	users: [],
	isLoading: false,
	alert: false,
	alertMessage: '',
	alertType: '',
	headers: [
		{
			text: 'Record ID',
			align: 'left',
			sortable: false,
			value: 'id',
		},
		{
			text: 'User ID',
			align: 'left',
			sortable: false,
			value: 'user_id'
		},
		//{ text: 'Super User', value: 'sper_user' },
		{ text: 'Department Code', value: 'dept_code' },
		// { text: 'Branch Code', value: 'brnc_code' },
		// { text: 'Start Date', value: 'start_date' },
		//{ text: 'End Date', value: 'end_date' },
		{ text: 'User First Name', value: 'user_first_name' },
		// { text: 'User Middle Name', value: 'user_middle_name' },
		{ text: 'User Last Name', value: 'user_last_name' },
		// { text: 'Job Title Code', value: 'jobt_code' },
		{ text: 'Actions', value: 'action', sortable: false },
	],
	columnHeaders: [
		{ label: 'Record ID', field: 'id', },
		{ label: 'User ID', field: 'user_id' },
		{ label: 'Department Code', field: 'dept_code' },
		{ label: 'User First Name', field: 'user_first_name' },
		{ label: 'User Last Name', field: 'user_last_name' }
	],
	sperUser: [
		{ value: '1', text: 'Yes'},
		{ value: '0', text: 'No'}
	],
	editedItem: {
		id: '',
		user_id: '',
		sper_user: '',
		dept_code: '',
		brnc_code: '',
		start_date: '',
		end_date: '',
		user_first_name: '',
		user_middle_name: '',
		user_last_name: '',
		password: '',
		approver: '',
		jobt_code: ''
	},
	defaultItem: {
		id: '',
		user_id: '',
		sper_user: '',
		dept_code: '',
		brnc_code: '',
		start_date: '',
		end_date: '',
		user_first_name: '',
		user_middle_name: '',
		user_last_name: '',
		jobt_code: ''
	},
	dialog: false,
	editedIndex: -1,
	valid: true,
	formTitle: 'Edit entry',
};