import Vue from 'vue';

const BPBANKS_UPDATED = (state, bpbanks) => {
	state.bpbanks = bpbanks;

};
  
const BPBANKS_ADD = (state, payload) => {
	state.bpbanks.push(payload);
};

const BPBANKS_DELETE = (state) => {
	state.bpbanks.splice(state.editedIndex,1);
};

const SET_EDITED_ITEM = (state,payload) =>{
	state.editedItem = payload;
};

const SET_DIALOG = (state,payload) =>{
	state.dialog = payload;
};

const SET_EDITED_INDEX = (state,payload) =>{
	state.editedIndex = payload;
};

const BPBANKS_ITEM_UPDATE = (state, payload) => {
	Vue.set(state.bpbanks, state.editedIndex, payload);
};

const SET_VALID = (state,payload) => {
	state.valid = payload;
};

const SET_FORM_TITLE = (state,payload) => {
	state.formTitle = payload;
};

export default {
	BPBANKS_UPDATED,
	SET_EDITED_ITEM,
	SET_DIALOG,
	SET_EDITED_INDEX,
	SET_VALID,
	SET_FORM_TITLE,
	BPBANKS_ADD,
	BPBANKS_DELETE,
	BPBANKS_ITEM_UPDATE
};