import { RepositoryFactory } from '../../../api_repo/RepositoryFactory';
import router from '../../../router';
import Vue from 'vue';
import trxbillingRepository from '../../../api_repo/modules/trxbillingRepository';

const trxBillingRepository = RepositoryFactory.get('billing');
const getBillings = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	trxBillingRepository.get(payload)
		.then((response) => {
			context.commit('BILLING_UPDATED', response.data.data);
			context.commit('app/SET_IS_LOADING', false, { root: true });
		})
		.catch(() => {
			context.commit('app/SET_IS_LOADING', false, { root: true });
		});
};

const getBilling = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	context.commit('trxbilling/BILLING_DETAIL_MODAL_VIEW', false, { root: true });
	setTimeout(() => {
		trxBillingRepository.getBilling(payload)
			.then((response) => {
				context.commit('BILLING_SHOW_DETAIL', response.data.data);
				context.commit('app/SET_IS_LOADING', false, { root: true });
			})
			.catch(() => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
			});
	}, 1500);
};

const getTransaction = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	setTimeout(() => {
		trxBillingRepository.getBilling(payload)
			.then((response) => {
				context.commit('TRX_SEARCH_RESULT', response.data.data);				
				context.commit('app/SET_IS_LOADING', false, { root: true });
			})
			.catch(() => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
			});
	}, 1500);
};

const getArchivedBilling = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	setTimeout(() => {
		trxBillingRepository.getBilling(payload)
			.then((response) => {
				context.commit('BILLING_SHOW_DETAIL', response.data.data);
				context.commit('app/SET_IS_LOADING', false, { root: true });
			})
			.catch(() => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
			});
	}, 1500);
};

const getRequestNumber = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	setTimeout(() => {
		trxbillingRepository.getNumber('/trx/request-number', payload)
			.then((response) => {
				context.commit('SET_BILLING_REQUEST_NUMBER', response.data.data.request_number);
				context.commit('app/SET_IS_LOADING', false, { root: true });
				
				router.push('/trx-billing/create');
			})
			.catch(() => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
			});
	}, 1500);
};

const getBillingNumber = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	setTimeout(() => {
		trxbillingRepository.getNumber('/trx/billing-invoice-number', payload)
			.then((response) => {
				context.commit('SET_BILLING_NUMBER', response.data.data.billing_invoice_number);
				context.commit('app/SET_IS_LOADING', false, { root: true });
			})
			.catch(() => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
			});
	}, 1500);
};

const editBilling = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	context.commit('trxbilling/BILLING_EDIT_MODAL_VIEW', false, { root: true });
	setTimeout(() => {
		trxBillingRepository.getBilling(payload)
			.then((response) => {
				let headers = response.data.data;
				let response_data = response.data.data;

				if ((headers[0].document_type === 'CW' && headers[0].is_pdc === 1) || (headers[0].document_type === 'CV' && headers[0].is_pdc === 1)) {
					headers[0].number_of_cheques = headers.length - 1;
					response_data = [headers[0]];
				}

				context.commit('BILLING_SHOW_DETAIL', response_data);
				context.commit('trxbilling/BILLING_EDIT_MODAL_VIEW', true, { root: true });
				// context.commit('app/SET_IS_LOADING', false, { root: true });
			})
			.catch(() => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
			});
	}, 1500);
};

const saveBilling = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	setTimeout(() => {
		trxBillingRepository.saveBilling(payload)
			.then(() => {
				context.commit('SET_BILLING_REQUEST_NUMBER', '');
				context.commit('BILLING_ADD', payload);
				context.commit('app/SET_IS_LOADING', false, { root: true });
				context.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'The transaction entry has been added to the list successfully.', 'messagetype': 'success' }, { root: true });
				context.commit('SET_SUCCESS', true);
				window.location.href = '/trx-billing';
				
				// }, 5000);
			}).catch((error) => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
				context.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'Saving failed! ' + error.response.data.message, 'messagetype': 'error' }, { root: true });
				var newErrorMessage = '';
				if (error.response.data.errors) {
					var myObject = error.response.data.errors;
					var newerror = [];
					for (var name in myObject) {
						var replacement = name.split('.')[2];
						if(replacement == undefined){
							replacement = name;
						}
						var errors = error.response.data.errors[name][0];
						var errorDesc = errors.replace(name, replacement);
						var replacedash = errorDesc.replace('_', ' ');
						newerror.push(replacedash);
					}
					newerror.sort();
					var current = null;
					var cnt = 0;
					for (var i = 0; i < newerror.length; i++) {
						var errormessage;
						if (newerror[i] != current) {
							if (cnt > 0) {
								errormessage +=
                  current + '<br>';
							}
							current = newerror[i];
							cnt = 1;
						} else {
							cnt++;
						}
					}
					if (cnt > 0) {
						errormessage +=
              current + '<br>';
					}
					newErrorMessage = errormessage.replace('undefined', '');
				} else {
					newErrorMessage = 'Please try again later.';
				}
				Vue.swal
					.fire({
						type: 'error',
						title: 'Failed to save!',
						html: newErrorMessage
					});
			});
	}, 1500);
	//window.location.href = '/Billing';
};

const updateBilling = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	setTimeout(() => {
		trxBillingRepository.updateBilling(payload.id, payload.value)
			.then((response) => {
				// context.commit('UPDATE_TABLE_BILLING_ACCOUNTING', response.data.data);
				console.log(response);
				context.commit('SET_BILLING_NUMBER', '');
				context.commit('app/SET_IS_LOADING', false, { root: true });
				context.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'Status updated successfully', 'messagetype': 'success' }, { root: true });
				window.location.href = '/trx-billing';
			})
			.catch((error) => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
				context.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'Update failed! ' + error.response.data.message, 'messagetype': 'error' }, { root: true });
			});
	}, 1500);
};

const updateBillingStatus = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	setTimeout(() => {
		trxBillingRepository.updateBillingStatus(payload.id, payload.value)
			.then((response) => {
				// context.commit('UPDATE_TABLE_BILLING', payload.value.accounting_status);
				console.log(response);
				context.commit('app/SET_IS_LOADING', false, { root: true });
				context.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'Status updated successfully', 'messagetype': 'success' }, { root: true });
				context.commit('BILLING_DETAIL_MODAL_VIEW', false);
				window.location.href = '/trx-billing';
			})
			.catch((error) => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
				context.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'Update failed! ' + error.response.data.message, 'messagetype': 'error' }, { root: true });
			});
	}, 1500);
};
const updateBillingAccountingStatus = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	setTimeout(() => {
		trxBillingRepository.updateBillingStatus(payload.id, payload.value)
			.then((response) => {
				// context.commit('BILLING_ITEM_UPDATE', response.data.data);
				context.commit('UPDATE_TABLE_BILLING_ACCOUNTING', payload.value);
				console.log(response);
				context.commit('app/SET_IS_LOADING', false, { root: true });
				context.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'Status updated successfully', 'messagetype': 'success' }, { root: true });
				context.commit('BILLING_DETAIL_MODAL_VIEW', false);
				window.location.href = '/trx-billing';
			})
			.catch((error) => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
				context.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'Update failed! ' + error.response.data.message, 'messagetype': 'error' }, { root: true });
			});
	}, 1500);
};

const getEreceiptData = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	return new Promise((resolve, reject) => {
		trxBillingRepository.getReceiptDetail(payload)
			.then(({ data }) => {
				resolve(data);
				context.commit('app/SET_IS_LOADING', false, { root: true });
			}).catch((err) => {
				reject(err);
				context.commit('app/SET_IS_LOADING', false, { root: true });
			});
	});
};

const deleteBilling = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	setTimeout(() => {
		trxBillingRepository.deleteBilling(payload.id)
			.then((response) => {
				context.commit('BILLING_DELETE', response.data.data);
				context.commit('app/SET_IS_LOADING', false, { root: true });
				context.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'The transaction entry has been deleted successfully', 'messagetype': 'success' }, { root: true });
			})
			.catch((error) => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
				context.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'Delete failed! ' + error.response.data.message, 'messagetype': 'error' }, { root: true });
			});
	}, 1500);
};

const voidBilling = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	setTimeout(() => {
		trxBillingRepository.voidBilling(payload)
			.then((response) => {
				console.log(response);
				context.commit('app/SET_IS_LOADING', false, { root: true });
				context.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'The transaction entry has been voided successfully', 'messagetype': 'success' }, { root: true });
				window.location.href = '/trx-billing';
			})
			.catch((error) => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
				context.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'Delete failed! ' + error.response.data.message, 'messagetype': 'error' }, { root: true });
			});
	}, 1500);
};

const getDetails = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	trxBillingRepository.getBilling(payload)
		.then((response) => {
			context.commit('app/SET_IS_LOADING', false, { root: true });
			let data = JSON.parse(response.data.data[0].upload_file);
			data.forEach(detail => {
				detail.seen = false;
			});
			context.commit('BILLING_DETAIL', data);
		}).catch((error) => {
			context.commit('app/SET_IS_LOADING', false, { root: true });
			context.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'Failed! ' + error.response.data.message, 'messagetype': 'error' }, { root: true });
		});
};
const getORARData = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	setTimeout(() => {
		trxBillingRepository.getBilling(payload)
			.then((response) => {
				context.commit('SET_PRINT_DETAILS', response.data.data);
				context.commit('app/SET_IS_LOADING', false, { root: true });
			})
			.catch(() => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
			});
	}, 1500);
};

const getData = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true , { root: true });
	return new Promise((resolve, reject) => {
		trxBillingRepository.getData(payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING', false , { root: true });
				resolve(response);
			}, error => {
				context.commit('app/SET_IS_LOADING', false , { root: true });
				reject(error);
			});
	});
};

const getDataPages = (context,payload) => {
	context.commit('app/SET_IS_LOADING', true , { root: true });
	setTimeout(() => {
		trxBillingRepository.getCSVUploadPages(payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING', false , { root: true });
				context.commit('SHOW_UPLOADED_CSV_FILES', response.data.data);
			})
			// eslint-disable-next-line
			.catch(() => {
				context.commit('app/SET_IS_LOADING', false , { root: true });
			});
	}, 1500);
};

const getBillingData = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	return new Promise((resolve, reject) => {
		trxBillingRepository.getBilling(payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
				setTimeout(() => {
					resolve(response);
				}, 2000);
			}, error => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
				reject(error);
			});
	});
};

const uncollectedBillings = (context) => {
	context.commit('app/SET_IS_LOADING', true , { root: true });
	setTimeout(() => {
		trxBillingRepository.getUncollectedInvoice()
			.then((response) => {
				context.commit('app/SET_IS_LOADING', false , { root: true });
				context.commit('UNCOLLECTED_BILLING', response.data.data);
			})
			// eslint-disable-next-line
			.catch(() => {
				context.commit('app/SET_IS_LOADING', false , { root: true });
			});
	}, 1500);

};

const sendEmail = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true , { root: true });
	setTimeout(() => {
		trxBillingRepository.sendMail(payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING', false , { root: true });
				context.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'Successfully sent an email for billing ' + response.data.data.billing_invoice_no + '.', 'messagetype': 'success' }, { root: true });
				context.commit('SET_SUCCESS', true);
			})
			// eslint-disable-next-line
			.catch(() => {
				context.commit('app/SET_IS_LOADING', false , { root: true });
				context.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'Unable to send an email! ', 'messagetype': 'error' }, { root: true });
			});
	}, 1500);

};
const setTimer = (context) => {
	setTimeout(() => {
		context.commit('app/SET_IS_LOADING', true, { root: true });

	}, 5000);
	context.commit('app/SET_IS_LOADING', false, { root: true });
};

const setEditedItems = (context, payload) => {
	context.commit('SET_EDITED_ITEM', payload);
};

const setEditDialog = (context, payload) => {
	context.commit('BILLING_EDIT_MODAL_VIEW', payload);
};

const setViewDialog = (context, payload) => {
	context.commit('BILLING_DETAIL_MODAL_VIEW', payload);
};

const setAttachmentDialog = (context, payload) => {
	context.commit('BILLING_ATTACHMENT_MODAL_VIEW', payload);
};

const setEditedIndex = (context, payload) => {
	context.commit('SET_EDITED_INDEX', payload);
};

const setPreparer = (context,payload) => {
	context.commit('SET_PREPARER',payload);
};

const setDialog = (context,payload) => {
	context.commit('SET_DIALOG',payload);
};

const setValid = (context,payload) => {
	context.commit('SET_VALID',payload);
};

const setformTitle = (context,payload) => {
	context.commit('SET_FORM_TITLE',payload);
};

export default {
	getBillings,
	getBilling,
	getTransaction,
	editBilling,
	saveBilling,
	updateBilling,
	updateBillingStatus,
	deleteBilling,
	setEditedItems,
	setEditDialog,
	setViewDialog,
	setAttachmentDialog,
	setEditedIndex,
	getArchivedBilling,
	setPreparer,
	setTimer,
	getDetails,
	getORARData,
	getData,
	getDataPages,
	setDialog,
	setformTitle,
	setValid,
	getBillingData,
	updateBillingAccountingStatus,
	uncollectedBillings,
	getRequestNumber,
	getBillingNumber,
	sendEmail,
	voidBilling,
	getEreceiptData

};