export default {
	alphataxcodes: [],
	headers: [
		{
			text: 'ATC Code',
			align: 'left',
			sortable: true,
			value: 'atc_code',
		},
		{ text: 'Description', value: 'atc_desc', sortable: true, },  
		{ text: 'ATC Rate', value: 'atc_rate' },
		{ text: 'Actions', value: 'action', sortable: false },
	],
	columnHeaders: [
		{ label: 'ATC Code', field: 'atc_code' },
		{ label: 'Description', field: 'atc_desc' },  
		{ label: 'ATC Rate', field: 'atc_rate' },
		{ label: 'Actions', field: 'action' },
	],
	editedItem: {
		id: '',
		atc_code: '',
		atc_desc: '',
		atc_rate: ''
	},
	defaultItem: {
		id: '',
		atc_code: '',
		atc_desc: '',
		atc_rate: ''
	},
	dialog: false,
	editedIndex: -1,
	valid: true,
	formTitle: 'Edit entry'
};