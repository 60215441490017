import Vue from 'vue';

const REGULARTXNS_UPDATED = (state, payload) => {
	state.regulartxns = payload;
};

const REGULARTXNS_SHOW_DETAIL = (state, payload) => {
	state.regulartxn = payload;
};

const REGULARTXNS_ADD = (state, payload) => {
	state.regulartxns.data.push(payload);
};

const REGULARTXNS_ITEM_UPDATE = (state, payload) => {
	Vue.set(state.regulartxns.data, state.editedIndex, payload);
};

const APPROVINGOFFICER_UPDATED = (state, payload) => {
	state.approvingofficers = payload;
};

const APPROVINGOFFICER_ADD = (state, payload) => {
	state.approvingofficers.push(payload);
};

const REGULARTXNS_DELETE = (state) => {
	state.regulartxns.data.splice(state.editedIndex, 1);
};

const APPROVINGOFFICER_ITEM_UPDATE = (state, payload) => {
	Vue.set(state.approvingofficers, payload.index, payload.data);
};

const SET_EDITED_ITEM = (state,payload) =>{
	state.editedItem = payload;
};

const SET_DIALOG = (state,payload) =>{
	state.dialog = payload;
};

const SET_EDITED_INDEX = (state,payload) =>{
	state.editedIndex = payload;
};

const SET_VALID = (state,payload) => {
	state.valid = payload;
};

const SET_FORM_TITLE = (state,payload) => {
	state.formTitle = payload;
};

const SET_ISLOADING = (state,payload) => {
	state.isLoading = payload;
};

export default {
	APPROVINGOFFICER_UPDATED,
	APPROVINGOFFICER_ADD,
	REGULARTXNS_DELETE,
	APPROVINGOFFICER_ITEM_UPDATE,
	REGULARTXNS_UPDATED,
	REGULARTXNS_ADD,
	REGULARTXNS_SHOW_DETAIL,
	REGULARTXNS_ITEM_UPDATE,
	SET_EDITED_ITEM,
	SET_DIALOG,
	SET_EDITED_INDEX,
	SET_VALID,
	SET_FORM_TITLE,
	SET_ISLOADING
};