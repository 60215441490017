import Vue from 'vue';
import { RepositoryFactory } from '../../../api_repo/RepositoryFactory';

const deptsRepository = RepositoryFactory.get('dept');

const getDepts = (context) => {
	context.commit('SET_ISLOADING',true);
	deptsRepository.get()
		.then((response) => {
			context.commit('DEPTS_UPDATED', response.data.data);
			context.commit('SET_ISLOADING',false);
		})
		.catch((error) => {
			context.commit('SET_ISLOADING',false);
			Vue.swal.fire({
				type: 'error',
				title: 'Failed getting data',
				text: error.response.data.message,
				timer: 2000
			});
		});    
};

const getDeptDropdown = (context) => {
	deptsRepository.get()
		.then((response) => {
			context.commit('DEPTS_UPDATED', response.data.data);
		});    
};

const getDeptDetails = (context, payload) => {
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	deptsRepository.getDept(payload)
		.then((response) => {
			context.commit('SET_EDITED_ITEM', response.data.data[0]);
			context.commit('app/SET_IS_LOADING',false,{ root: true });
		})
		.catch(() => {
			context.commit('app/SET_IS_LOADING',false,{ root: true });
		});    
};

const saveDept = (context,payload) => {
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	deptsRepository.saveDept(payload)
		.then((response) => {
			context.commit('DEPTS_ADD',response.data.data[0]);
			context.commit('app/SET_IS_LOADING',false,{ root: true });
			Vue.swal.fire({
				type: 'success',
				title: 'Your work has been saved',
				text: response.data.data[0].dept_desc + '(' + response.data.data[0].dept_code + ') added.',
				timer: 2000
			});
			context.commit('SET_DIALOG',false);
		})
		.catch((error) => {
			context.commit('app/SET_IS_LOADING',false,{ root: true });
			var newErrorMessage = '';
			if (error.response.data.errors) {
				newErrorMessage = getError(error.response.data.errors);
			} else {
				newErrorMessage = error.response.data.message;
			}
			Vue.swal
				.fire({
					type: 'error',
					title: 'Saving failed!!',
					html: newErrorMessage
				});  
		});
};

const updateDept = (context,payload) => {
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	deptsRepository.updateDept(payload.id,payload)
		.then((response) => {
			context.commit('SET_ERROR', false);
			context.commit('app/SET_IS_LOADING',false,{ root: true });
			context.commit('DEPTS_ITEM_UPDATE', response.data.data[0]);
			Vue.swal.fire({
				type: 'success',
				title: 'Your work has been updated',
				text: response.data.data[0].dept_desc + '(' + response.data.data[0].dept_code + ') updated.',
				timer: 2000,
			});
			//context.commit('SET_DIALOG', false);
		})
		.catch((error) => {
			context.commit('SET_ERROR', true);
			context.commit('app/SET_IS_LOADING',false,{ root: true });
			payload.updated.map(details =>{
				context.commit('SET_DEPTS_SECTION',details);
			});
			
			var newErrorMessage = '';
			if (error.response.data.errors) {
				newErrorMessage = getError(error.response.data.errors);
			} else {
				newErrorMessage = error.response.data.message;
			}
			Vue.swal
				.fire({
					type: 'error',
					title: 'Saving failed!!',
					html: newErrorMessage
				});
		});
};

const deleteDept = (context,payload) => {  
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	deptsRepository.deleteDept(payload.id)
		.then((response) => {
			context.commit('app/SET_IS_LOADING',false,{ root: true });
			context.commit('DEPTS_DELETE',response.data.data);
			Vue.swal.fire({
				type: 'error',
				title: 'Delete successful',
				text: payload.dept_desc + '(' + payload.dept_code + ') deleted.',
				timer: 2000
			});
		})
		.catch((error) => {
			context.commit('app/SET_IS_LOADING',false,{ root: true });
			Vue.swal.fire({
				type: 'error',
				title: 'Delete fail',
				text: error.response.data.message,
				timer: 2000
			});
		});
};

const getDataPages = (context,payload) => {
	context.commit('app/SET_IS_LOADING', true , { root: true });
	setTimeout(() => {
		deptsRepository.getData(payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING', false , { root: true });
				context.commit('DEPTS_UPDATED', response.data.data);
			})
			// eslint-disable-next-line
			.catch(() => {
				context.commit('app/SET_IS_LOADING', false , { root: true });
			});
	}, 1500);
};

const getData = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true , { root: true });
	return new Promise((resolve, reject) => {
		deptsRepository.getData(payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING', false , { root: true });
				resolve(response);
			}, error => {
				context.commit('app/SET_IS_LOADING', false , { root: true });
				reject(error);
			});
	});
};

const setEditedItems = (context,payload) => {
	context.commit('SET_EDITED_ITEM',payload);
};

const setDialog = (context,payload) => {
	context.commit('SET_DIALOG',payload);
};

const getError = (payload) => {
	var myObject = payload;
	var newerror = [];
	for (var name in myObject) {
		var replacement = name.split('.')[2];
		if(replacement == undefined){
			replacement = name;
		}
		var errors = payload[name][0];
		var errorDesc = errors.replace(name, replacement);
		var replacedash = errorDesc.replace('_', ' ');
		newerror.push(replacedash);
	}
	newerror.sort();
	var current = null;
	var cnt = 0;
	for (var i = 0; i < newerror.length; i++) {
		var errormessage;
		if (newerror[i] != current) {
			if (cnt > 0) {
				errormessage +=current + '<br>';
			}
			current = newerror[i];
			cnt = 1;
		} else {
			cnt++;
		}
	}
	if (cnt > 0) {
		errormessage +=
						current + '<br>';
	}
	return errormessage.replace('undefined', '');
};

const setEditedIndex = (context,payload) => {
	context.commit('SET_EDITED_INDEX',payload);
};

const setValid = (context,payload) => {
	context.commit('SET_VALID',payload);
};

const setformTitle = (context,payload) => {
	context.commit('SET_FORM_TITLE',payload);
};

const setisLoading = (context,payload) => {
	context.commit('SET_ISLOADING',payload);
};

export default {
	getDepts,
	getDeptDropdown,
	saveDept,
	updateDept,
	deleteDept,
	setEditedItems,
	setDialog,
	setEditedIndex,
	setValid,
	setformTitle,
	setisLoading,
	getDeptDetails,
	getError,
	getDataPages,
	getData
};