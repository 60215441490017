import Vue from 'vue';

const BRANCHES_UPDATED = (state, branches) => {
	state.branches = branches;
};
  
const BRANCHES_ADD = (state, payload) => {
	state.branches.push(payload);
};

const BRANCHES_DELETE = (state) => {
	state.branches.splice(state.editedIndex,1);
};

const BRANCHES_ITEM_UPDATE = (state, payload) => {
	Vue.set(state.branches, state.editedIndex, payload);
};

const SET_EDITED_ITEM = (state,payload) =>{
	state.editedItem = payload;
};

const SET_DIALOG = (state,payload) =>{
	state.dialog = payload;
};

const SET_EDITED_INDEX = (state,payload) =>{
	state.editedIndex = payload;
};

const SET_VALID = (state,payload) => {
	state.valid = payload;
};

const SET_FORM_TITLE = (state,payload) => {
	state.formTitle = payload;
};

const SET_BRANCH_PAYEEBANKS = (state, payload) => {
	state.editedItem.payee_banks.push(payload);
};

export default {
	BRANCHES_UPDATED,
	SET_EDITED_ITEM,
	SET_DIALOG,
	SET_EDITED_INDEX,
	SET_VALID,
	SET_FORM_TITLE,
	BRANCHES_ADD,
	BRANCHES_DELETE,
	BRANCHES_ITEM_UPDATE,
	SET_BRANCH_PAYEEBANKS
};