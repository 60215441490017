const acctperiodmains = state => state.acctperiodmains;
const fiscalYear = state => state.fiscalYear;
const headers = state => state.headers;
const columnHeaders = state => state.columnHeaders;
const periodNo = state => state.periodNo;
const disbursement = state => state. disbursement;
const cashReceipt = state => state. cash_receipt;
// const bstatAe = state => state. bstatAe;
const generalJournal = state => state. general_journal;
const editedItem = state => state.editedItem;
const defaultItem = state => state.defaultItem;
const dialog = state => state.dialog;
const editedIndex = state => state.editedIndex;
const valid = state => state.valid;
const formTitle = state => state.formTitle;


export default {
	acctperiodmains,
	columnHeaders,
	headers,
	fiscalYear,
	periodNo,
	disbursement,
	cashReceipt,
	// bstatAe,
	generalJournal,
	editedItem,
	defaultItem,
	dialog,
	editedIndex,
	valid,
	formTitle
};