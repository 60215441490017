// eslint-disable-next-line
import Vue from 'vue';
import { RepositoryFactory } from '../../../api_repo/RepositoryFactory';

const transactionsRepository = RepositoryFactory.get('transaction');

const getTransactions = (context) => {
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	transactionsRepository.get()
		.then((response) => {
			context.commit('TRANSACTIONS_UPDATED', response.data.data);
			context.commit('app/SET_IS_LOADING',false,{ root: true });
		})
		.catch(() => {
			context.commit('app/SET_IS_LOADING',false,{ root: true });
            
		});
};

const saveTransaction = (context,payload) => {
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		transactionsRepository.saveTransaction(payload)
			.then((response) => {
				context.commit('TRANSACTIONS_ADD',response.data.data);    
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ' + response.data.data.trns_code + '(' + response.data.data.trns_desc + ') added.','messagetype':'success'},{ root: true });
			})
			.catch((error) => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Saving failed! ' + error,'messagetype':'error'},{ root: true });  
			});
	}, 1500);
};


const updateTransaction = (context,payload) => {
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		transactionsRepository.updateTransaction(payload.id,payload)
			.then((response) => {
				context.commit('TRANSACTIONS_ITEM_UPDATE',response.data.data);
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ' + response.data.data.trns_code + '(' + response.data.data.trns_desc + ') updated.','messagetype':'success'},{ root: true });            
			})
			.catch((error) => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Update failed! ' + error.response.data.message,'messagetype':'error'},{ root: true });  
			});
	}, 1500);
};


const deleteTransaction = (context,payload) => {
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		transactionsRepository.deleteTransaction(payload.id)
			.then((response) => {
				context.commit('TRANSACTIONS_DELETE',response.data.data);
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ' + response.data.data.trns_code + '(' + response.data.data.trns_desc + ') deleted.','messagetype':'success'},{ root: true });            
			})
			.catch((error) => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Delete failed! ' + error.response.data.message,'messagetype':'error'},{ root: true });  
			});
	}, 1500);
};


const setEditedItems = (context,payload) => {
	context.commit('SET_EDITED_ITEM',payload);
};

const setDialog = (context,payload) => {
	context.commit('SET_DIALOG',payload);
};

const setEditedIndex = (context,payload) => {
	context.commit('SET_EDITED_INDEX',payload);
};

const setValid = (context,payload) => {
	context.commit('SET_VALID',payload);
};

const setformTitle = (context,payload) => {
	context.commit('SET_FORM_TITLE',payload);
};
const setIsLoading = (context,payload) => {
	context.commit('SET_ISLOADING',payload);
};


export default {
	getTransactions,
	saveTransaction,
	updateTransaction,
	deleteTransaction,
	setEditedItems,
	setDialog,
	setEditedIndex,
	setValid,
	setformTitle,
	setIsLoading
    
};
