import Vue from 'vue';

const VOUCHERSERIES_UPDATED = (state, payload) => {
	state.voucherseries = payload;

};

const VOUCHERSERIES_ADD = (state, payload) => {
	state.voucherseries.data.push(payload);
};

const VOUCHERSERIES_DELETE = (state, ) => {
	state.voucherseries.data.splice(state.editedIndex, 1);
};

const VOUCHERSERIES_ITEM_UPDATE = (state, payload) => {
	Vue.set(state.voucherseries.data, state.editedIndex, payload);
};

const SET_EDITED_ITEM = (state,payload) =>{
	state.editedItem = payload;
};

const SET_DIALOG = (state,payload) =>{
	state.dialog = payload;
};

const SET_EDITED_INDEX = (state,payload) =>{
	state.editedIndex = payload;
};

const SET_VALID = (state,payload) => {
	state.valid = payload;
};

const SET_FORM_TITLE = (state,payload) => {
	state.formTitle = payload;
};


export default {
	VOUCHERSERIES_UPDATED,
	SET_EDITED_ITEM,
	SET_DIALOG,
	SET_EDITED_INDEX,
	VOUCHERSERIES_ADD,
	VOUCHERSERIES_DELETE,
	SET_VALID,
	SET_FORM_TITLE,
	VOUCHERSERIES_ITEM_UPDATE
};


