const businesspartners = state => state.businesspartners;
const headers = state => state.headers;
const columnHeaders = state => state.columnHeaders;
const activeStatus = state => state.activeStatus;
const editedItem = state => state.editedItem;
const defaultItem = state => state.defaultItem;
const dialog = state => state.dialog;
const editedIndex = state => state.editedIndex;
const formTitle = state => state.formTitle;
const valid = state => state.valid;

export default {
	businesspartners,
	activeStatus,
	columnHeaders,
	headers,
	editedItem,
	defaultItem,
	dialog,
	editedIndex,
	formTitle,
	valid
};