const companies = state => state.companies;
const headers = state => state.headers;
const companyStatus = state => state.companyStatus;
const editedItem = state => state.editedItem;
const defaultItem = state => state.defaultItem;
const dialog = state => state.dialog;
const editedIndex = state => state.editedIndex;
const valid = state => state.valid;
const formTitle = state => state.formTitle;
const isLoading = state => state.isLoading;

export default {
	companies,
	headers,
	companyStatus,
	editedItem,
	defaultItem,
	dialog,
	editedIndex,
	valid,
	formTitle,
	isLoading
};