const depts = state => state.depts;
const headers = state => state.headers;
const columnHeaders = state => state.columnHeaders;
const deptStatus = state => state.deptStatus;
const editedItem = state => state.editedItem;
const defaultItem = state => state.defaultItem;
const dialog = state => state.dialog;
const editedIndex = state => state.editedIndex;
const valid = state => state.valid;
const formTitle = state => state.formTitle;
const isLoading = state => state.isLoading;
const error = state => state.error;
const editedIndexSection = state => state.editedIndexSection;

export default {
	depts,
	columnHeaders,
	headers,
	deptStatus,
	editedItem,
	defaultItem,
	dialog,
	editedIndex,
	valid,
	formTitle,
	isLoading,
	editedIndexSection,
	error
};