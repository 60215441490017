<template>
	<v-container>
		<div id="printAction">
			<v-btn color="primary" @click="printDocument"><v-icon left>mdi-printer</v-icon> Print This Form</v-btn>
		</div>
		<div v-if="disbDetail.document_type =='DM'">
			<!-- {{disbDetail}} -->
			<div style="width:820px;font-family: Courier, 'Lucida Console', monospace; margin-top:50px;">
				<p style="line-height: 3">{{getDate()}}</p>
				
				<p style="line-height: 1.3">
				{{documentPrintHeaders[0].contact_person.toUpperCase()}} <br/>
				{{documentPrintHeaders[0].cont_person_posn.toUpperCase()}} <br/>
				{{documentPrintHeaders[0].bank_name.toUpperCase()}}<br/>
				{{documentPrintHeaders[0].bank_branch.toUpperCase()}}<br/>
				{{documentPrintHeaders[0].bank_address.toUpperCase()}}<br/>
				</p>
				<p style="line-height: 3">Sir/Madam:</p>

				<p style="line-height: 1">This is to authorize {{documentPrintHeaders[0].bank_name}}({{documentPrintHeaders[0].bank_branch.toUpperCase()}}) to debit PETNET Inc. {{documentPrintHeaders[0].bank_account_type}} {{documentPrintHeaders[0].bank_acct}} for {{getAmountinWords(documentPrintHeaders)}} PESOS ONLY (P {{getTotalAmount(documentPrintHeaders)}}), to be credited to the following:</p>
				<div style="width:750px;">
					<table class="adjustment-table">
						<thead>
							<tr>
								<th style="text-align: center;">Name</th>
								<th>SA/CA#</th>
								<th style="text-align: right;">Amount</th>
							</tr>
						</thead>
						<tbody>
							<tr
								v-for="(doc_headers) in documentPrintHeaders" :key="doc_headers.header_key"
							>
								<td style="text-align: left;">{{getPayeeName(doc_headers.details[0].payee_id)}}</td>
								<td>{{doc_headers.payee_account_number}}</td>
								<td style="text-align: right;">{{formatAmount(doc_headers.total_amount)}}</td>
							</tr>
							<tr>
								<td></td>
								<td></td>
								<td style="text-align: right;text-decoration-line: underline; text-decoration-style: double;">{{getTotalAmount(documentPrintHeaders)}}</td>
							</tr>
						</tbody>
					</table>
				</div>
				<br/>
				<p>Thank you for your usual and prompt attention.</p>
				<br/>
				<p>Very Truly yours,</p>
				<br/><br/>
				<div style="width: 300px;border-top:2px solid black; float:left">
					<p style="text-align: center;"> AUTHORIZED SIGNATORY</p>
				</div>
				<div style="width: 300px;border-top:2px solid black; float:right">
					<p style="text-align: center;"> AUTHORIZED SIGNATORY</p>
				</div>
				<br/><br/><br/>
				<p style="font-size: small;">
					{{documentPrintHeaders[0].batch_number}} / {{documentPrintHeaders[0].preparer}} <br/>
					{{format_date(documentPrintHeaders[0].document_date)}}
				</p>
			</div>
		</div>
		<div id="printArea" v-else-if="disbDetail.document_type == 'JE'">
			<h3>Journal Details for Batch No. {{disbDetail.batch_number}}</h3>
			<br>
			<div v-for="(doc_headers) in documentHeaders" :key="doc_headers.header_key">
				<h4>Document No. {{doc_headers.batch_number}}</h4>
				<p>Document Date: {{formatDate(doc_headers.document_date)}}</p>
				<br>
				<h5><b>Adjustment Entries:</b></h5>
				<table class="adjustment-table">
					<thead>
						<tr>
							<th style="text-align: left;">#</th>
							<th>Account</th>
							<th style="text-align: right;">Debit</th>
							<th style="text-align: right;">Credit</th>
						</tr>
					</thead>
					<tbody>
						<tr
							v-for="(detail, index) in doc_headers.details"
							:key="index"
						>
							<td style="text-align: left;">{{index + 1}}</td>
							<td>{{getAccountName(detail.account_code)}}</td>
							<td style="text-align: right;">{{formatAmount(detail.debit_amount)}}</td>
							<td style="text-align: right;">{{formatAmount(detail.credit_amount)}}</td>
						</tr>
					</tbody>
				</table>
				<br>
				<h4>Total Amount: {{formatAmount(doc_headers.amount)}}</h4>
			</div>
		</div>
		<div id="printArea" v-else>
			<h3>Disbursement Details for Batch No. {{disbDetail.batch_number}}</h3>
			<br>
			<div v-for="(doc_headers) in documentHeaders" :key="doc_headers.header_key">
				<h4>Document No. {{doc_headers.batch_number}}</h4>
				<p>Document Date: {{formatDate(doc_headers.document_date)}}</p>
				<br>
				<h5><b>Adjustment Entries:</b></h5>
				<table class="adjustment-table">
					<thead>
						<tr>
							<th style="text-align: left;">#</th>
							<th>Account</th>
							<th style="text-align: right;">Debit</th>
							<th style="text-align: right;">Credit</th>
						</tr>
					</thead>
					<tbody>
						<tr
							v-for="(detail) in doc_headers.details"
							:key="detail.detail_key"
						>
							<td style="text-align: left;">{{detail.id}}</td>
							<td>{{getAccountName(detail.account_code)}}</td>
							<td style="text-align: right;">{{formatAmount(detail.debit_amount)}}</td>
							<td style="text-align: right;">{{formatAmount(detail.credit_amount)}}</td>
						</tr>
					</tbody>
				</table>
				<br>
				<h4>Total Amount: {{formatAmount(doc_headers.amount)}}</h4>
			</div>
		</div>
	</v-container>
</template>
<script>
import { mapGetters } from 'vuex';
import numeral from 'numeral';
import numbertoWords from 'number-to-words';
import { format } from 'date-fns';

export default {
	name: 'printTrxdisb',
	data() {
		return {
			disbDetail:{},
			documentHeaders: [],
			documentPrintHeaders: [],
			bankDetails: [],
			dataCoas: [],
			nextPageCoas: '',
		};
	},
	watch: {
		trxdisbDetails: {
			handler(val) {
				this.documentHeaders = val;
			}
		},
		trxdisbPrintDetails: {
			handler(val) {
				this.documentPrintHeaders = val;
			}
		},
		coas: {
			handler(){
				this.getDataCoas();
			}
		},
	},
	computed: {
		...mapGetters({
			trxdisbDetails: 'trxdisb/trxdisbDetails',
			trxdisbPrintDetails: 'trxdisb/trxdisbPrintDetails',
			coas: 'coa/coaSubs',
			payees: 'payefile/payefiles'
		}),
	},
	created() {
		var app = this;
		app.disbDetail = JSON.parse(atob(app.$route.params.data)); 
	},
	mounted() {
		this.init();
	},
	methods: {
		init() {
			this.$store.dispatch('payefile/getPayefileDropdown');
			this.$store.dispatch('coa/getCoaDropdown');
			this.$store.dispatch('trxdisb/getTrxdisb', this.disbDetail.batch_number);
			this.$store.dispatch('trxdisb/getTrxdisbPrintDM', this.disbDetail.batch_number);
		},
		getAccountName(id) {
			const getAccount = this.dataCoas.filter(coa => coa.acct_code === id);

			if (getAccount.length!==0) {
				return getAccount[0].acct_desc;
			}
			
		},
		getPayeeName(id){
			const getPayee = this.payees.filter(payee=> payee.id=== id);

			if (getPayee) {
				return getPayee[0].payee_desc;
			}
		},
		printDocument() {
			window.print();
		},
		formatAmount(amount){
			return numeral(amount).format('0,0.00');
		},
		formatDate(date){
			return format(new Date(date), 'yyyy-MM-d');
		},
		getDate(){
			let date = new Date();
			var month = format(new Date(date), 'MMM');
			var year = format(new Date(date), 'yyyy');
			return month + ' ___, '+ year;
		},
		format_date(date){
			return format(new Date(date), 'd MMM yyyy');
		},
		getAmountinWords(header){
			let amount = 0;
			for(var i=0; i < header.length; i++){
				amount += parseInt(header[i]['total_amount']);
			}
			let amountWord = numbertoWords.toWords(amount); 
			let newAmount = amountWord.replace(/,/g, '');
			return newAmount.toUpperCase();
		},
		getTotalAmount(header){
			let amount = 0;
			for(var i=0; i < header.length; i++){
				amount += parseInt(header[i]['total_amount']);
			}
			return numeral(amount).format('0,0.00');
		},
		getUpperCase(name){
			return name.toUpperCase();
		},
		getcurrencySign(){

		},
		getCurrencyCode(){

		},
		async getDataCoas(){
			this.dataCoas = this.coas.data;
			this.nextPageCoas = this.coas.next_page_url;
			let page = 2;

			while (this.nextPageCoas !== null){
				await this.$store.dispatch('coa/getCoaData', page).then(response => {
					this.nextPageCoas = response.data.data.next_page_url;
					response.data.data.data.forEach(details => {
						this.dataCoas.push(details);
					});
				});
				page++;
			}
			this.$store.commit('coa/SET_COA_DATA', this.dataCoas);
		},
	}
};
</script>
<style scoped>
	.adjustment-table {
		width: 100%;
		text-align: center;
	}
	@media print {
		body #printAction { 
			display:none; 
		}

    body #printArea { 
			display:block; 
		}
	tr:last-child td:last-child {
    text-decoration-line: underline;
}	
	}
</style>