export default {
	regionalheads: [],
	headers: [
		{
			text: 'Record ID',
			align: 'left',
			sortable: false,
			value: 'id',
		},
		{
			text: 'Regional Head Code',
			align: 'left',
			sortable: false,
			value: 'rh_code',
		},
		{ text: 'Regional Head Name', value: 'rh_name' },
		{ text: 'Actions', value: 'action', sortable: false },
	],
  
	editedItem: {
		id: '',
		rh_code: '',
		rh_name: ''
	},
	defaultItem: {
		id: '',
		rh_code: '',
		rh_name: ''
	},
	dialog: false,
	editedIndex: -1,
	valid: true,
	formTitle: 'Edit entry',
};