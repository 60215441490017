<template>
  <div>
    <div id="printAction" class="action">
      <v-row justify="center" align="center">
        <v-spacer class="hidden-sm-and-down"></v-spacer>
        <v-col cols="6">
          <h4>Report Name: PROFITABILITY</h4>
        </v-col>
        <v-col cols="4">
          <!-- <v-btn color="primary"  @click="print"><v-icon left>mdi-printer</v-icon> Print</v-btn> -->
          <!-- <v-btn color="error" @click="exportReport"><v-icon left>mdi-file-pdf-outline</v-icon>Export</v-btn> -->
          <v-btn color="success" @click="exportExcel('printArea','profitability')"><v-icon left>mdi-microsoft-excel</v-icon>Export</v-btn>
        </v-col>
      </v-row>
    </div>
    <div id="printArea" ref="content" style="width: 1248px; font-size: 12px; margin: auto;">
      <v-row>
        <v-col cols="12" class="text-center">
          <p>
            <br>
            PETNET, INC.
            <br>
            32nd Floor East Offices Aguirre St. Legaspi Village Makati City
          </p>
        </v-col>
        <v-spacer />
      </v-row>
      <v-row>
        <v-col class="text-center" v-if="detail.type === 'ftm'">
          <p>
						PROFITABILITY
            <br>
            For the Month of {{getMonth()}}, {{detail.year}}
          </p>
        </v-col>
        <v-col class="text-center" v-else>
          <p>
						PROFITABILITY
            <br>
            For the Period of {{getDateFormat(detail.date_from)}} to {{getDateFormat(detail.date_to)}}
          </p>
        </v-col>
      </v-row>
      <div class="tbl">
        <!-- <div style="outline: 1px solid black;width: 95%; margin: auto;"></div> -->
        <table id="table" style="width: 95%; margin: auto;" ref="table">
          <thead>
            <tr>
              <th>CODE</th>
              <th>BRANCH NAME</th>
              <th>DATE OPEN</th>
              <th>TRANSACTIONS</th>
              <th>GRSS COMM DOLLAR</th>
              <th>GRSS COMM PESO</th>
              <th>OTHER REMCO</th>
              <th>REALIZED</th>
              <th>FOREX</th>
              <th>AVP INCOME</th>
              <th>DIGITAL</th>
              <th>DEPED LOANS</th>
              <th>PENSION LOANS</th>
              <th>Other LSG Products</th>
              <th>OTHER INCOME</th>
              <th>VAT</th>
              <th>TOTAL</th>
              <th>CCF</th>
              <th>NET</th>
              <th>SALARIES AND WAGES</th>
              <th>RETIREMENT</th>
              <th>MEDICAL/OTH ER EMP BENEFITS</th>
              <th>RENTAL FEE</th>
              <th>RETAINER FER</th>
              <th>FREIGHT/POST AGE/DELIVERY</th>
              <th>TELEPHONE/COMMUNICATION</th>
              <th>DEPRECIATION/AMORTIZATION</th>
              <th>REPAIR AND MAINTENANCE</th>
              <th>STATIONARY/PRINTING/SUPPLIES</th>
              <th>SUBSCRIPTION AND MEMBERSHIP FEE</th>
              <th>REPRESENTATION/ENTERTAINMENT</th>
              <th>TRANSPORTAION/TRAVEL</th>
              <th>TRAINING/SEMINARS/CONFERENCE</th>
              <th>BANK CHARGES</th>
              <th>LIGHT/WATER</th>
              <th>INSURANCE EXPENSE</th>
              <th>TAXES/LICENSES</th>
              <th>PROFESSIONAL/LEGAL/AUDIT FEE</th>
              <th>INTEREST EXPENSE</th>
              <th>MISCELLANEOUS EXPENSE</th>
              <th>LOSS ON FRAUD</th>
              <th>SECURITY AND JANITORIAL SERVICES</th>
              <th>COMPUTER NETWORK SYSTEM</th>
              <th>DIRECTORS PER BONUS</th>
              <th>DIRECTORS PER DIEM</th>
              <th>INCENTIVE EXPENSE</th>
              <th>INCENTIVE EXPENSE - DEPED</th>
              <th>INCENTIVE EXPENSE - PENSION</th>
              <th>INCENTIVE EXPENSE - LGU/DAB</th>
              <th>MARKETING EXPENSE</th>
              <th>CONSULTANCY FEE</th>
              <th>COMMISSION EXPENSE</th>
              <th>UNREALIZED FOREX GAIN</th>
              <th>PROVISION FOR IMPAIRMENT</th>
              <th>TOTAL EXPENSE</th>
              <th>NIBT</th>
              <th>LESS 25%</th>
              <th>NIAT</th>
              <th>EBITDA</th>
              <th>DATE CLOSED</th>
            </tr>
          </thead>
          <tbody v-for="(det, index) in group.details" :key="index">
            <tr>
              <td v-if="index==2" colspan="5"><b>Business Partners:</b></td>
              <td v-if="index==3" colspan="5"><b>Mobile App:</b></td>
              <td v-if="index==4" colspan="5"><b>Cancelled Branches:</b></td>
              <td v-if="index==5" colspan="5"><b>Departments:</b></td>
              </tr>
            <tr v-for="sub in det" :key="sub.id">
              <td>{{sub.branch_code}}</td>
              <td>{{sub.branch_name}}</td>
              <td class="date">{{sub.date_open}}</td>
              <td class="amount">{{getAmountFormat(sub.transactions)}}</td>
              <td class="amount">{{getAmountFormat(sub.gross_comm_dollar)}}</td>
              <td class="amount">{{getAmountFormat(sub.gross_comm_peso)}}</td>
              <td class="amount">{{getAmountFormat(sub.other_remco)}}</td>
              <td class="amount">{{getAmountFormat(sub.realized)}}</td>
              <td class="amount">{{getAmountFormat(sub.forex)}}</td>
              <td class="amount">{{getAmountFormat(sub.avp_income)}}</td>
              <td class="amount">{{getAmountFormat(sub.digital)}}</td>
              <td class="amount">{{getAmountFormat(sub.deped_loan)}}</td>
              <td class="amount">{{getAmountFormat(sub.pension_loan)}}</td>
              <td class="amount">{{getAmountFormat(sub.motorcycle_loan)}}</td>
              <td class="amount">{{getAmountFormat(sub.other_income)}}</td>
              <td class="amount">{{getAmountFormat(sub.vat)}}</td>
              <td class="amount">{{getAmountFormat(sub.total)}}</td>
              <td class="amount">{{getAmountFormat(sub.ccf)}}</td>
              <td class="amount">{{getAmountFormat(sub.net)}}</td>
              <td class="amount">{{getAmountFormat(sub.salaries_wages)}}</td>
              <td class="amount">{{getAmountFormat(sub.retirement)}}</td>
              <td class="amount">{{getAmountFormat(sub.medical_emp_benefits)}}</td>
              <td class="amount">{{getAmountFormat(sub.rental_fee)}}</td>
              <td class="amount">{{getAmountFormat(sub.retainer_fee)}}</td>
              <td class="amount">{{getAmountFormat(sub.freight_postage_delivery)}}</td>
              <td class="amount">{{getAmountFormat(sub.telephone_communication)}}</td>
              <td class="amount">{{getAmountFormat(sub.depreciation_amortization)}}</td>
              <td class="amount">{{getAmountFormat(sub.repair_maintenance)}}</td>
              <td class="amount">{{getAmountFormat(sub.stationary_printing_supplies)}}</td>
              <td class="amount">{{getAmountFormat(sub.sub_membership_fee)}}</td>
              <td class="amount">{{getAmountFormat(sub.representation_entertainment)}}</td>
              <td class="amount">{{getAmountFormat(sub.transportation_travel)}}</td>
              <td class="amount">{{getAmountFormat(sub.train_sem_conference)}}</td>
              <td class="amount">{{getAmountFormat(sub.bank_charges)}}</td>
              <td class="amount">{{getAmountFormat(sub.light_water)}}</td>
              <td class="amount">{{getAmountFormat(sub.insurance_expense)}}</td>
              <td class="amount">{{getAmountFormat(sub.taxes_license)}}</td>
              <td class="amount">{{getAmountFormat(sub.prof_legal_audit_fee)}}</td>
              <td class="amount">{{getAmountFormat(sub.interest_expense)}}</td>
              <td class="amount">{{getAmountFormat(sub.misc_expense)}}</td>
              <td class="amount">{{getAmountFormat(sub.loss_fraud)}}</td>
              <td class="amount">{{getAmountFormat(sub.security_janitorial_service)}}</td>
              <td class="amount">{{getAmountFormat(sub.computer_network_system)}}</td>
              <td class="amount">{{getAmountFormat(sub.directors_per_bonus)}}</td>
              <td class="amount">{{getAmountFormat(sub.directors_per_diem)}}</td>
              <td class="amount">{{getAmountFormat(sub.incentive_expense)}}</td>
              <td class="amount">{{getAmountFormat(sub.bad_debts)}}</td>
              <td class="amount">{{getAmountFormat(sub.incentive_expense_wubs)}}</td>
              <td class="amount">{{getAmountFormat(sub.leasing_expense)}}</td>
              <td class="amount">{{getAmountFormat(sub.marketing_expense)}}</td>
              <td class="amount">{{getAmountFormat(sub.consultancy_fee)}}</td>
              <td class="amount">{{getAmountFormat(sub.commission_expense)}}</td>
              <td class="amount">{{getAmountFormat(sub.unrealized_forex_gain)}}</td>
              <td class="amount">{{getAmountFormat(sub.provision_for_impairment)}}</td>
              <td class="amount">{{getAmountFormat(sub.total_expense)}}</td>
              <td class="amount">{{getAmountFormat(sub.nibt)}}</td>
              <td class="amount">{{getAmountFormat(sub.less_thirty)}}</td>
              <td class="amount">{{getAmountFormat(sub.niat)}}</td>
              <td class="amount">{{getAmountFormat(sub.ebitda)}}</td>
              <td style="text-align:right">{{getDateCloseFormat(sub.date_closed)}}</td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>Total:</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_transactions)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_gross_comm_dollar)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_gross_comm_peso)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_other_remco)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_realized)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_forex)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_avp_income)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_digital)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_deped_loan)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_pension_loan)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_motorcycle_loan)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_other_income)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_vat)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_total)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_ccf)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_net)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_salaries_wages)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_retirement)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_medical_emp_benefits)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_rental_fee)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_retainer_fee)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_freight_postage_delivery)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_telephone_communication)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_depreciation_amortization)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_repair_maintenance)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_stationary_printing_supplies)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_sub_membership_fee)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_representation_entertainment)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_transportation_travel)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_train_sem_conference)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_bank_charges)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_light_water)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_insurance_expense)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_taxes_license)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_prof_legal_audit_fee)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_interest_expense)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_misc_expense)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_loss_fraud)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_security_janitorial_service)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_computer_network_system)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_directors_per_bonus)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_directors_per_diem)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_incentive_expense)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_bad_debts)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_incentive_expense_wubs)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_leasing_expense)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_marketing_expense)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_consultancy_fee)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_commission_expense)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_unrealized_forex_gain)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_provision_for_impairment)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_total_expense)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_nibt)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_less_thirty)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_niat)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_ebitda)}}</td>
            </tr>
            <br>
          </tbody>
          <tbody>
            <tr>
              <td></td>
              <td></td>
              <td>Grand Total:</td>
              <td class="amount">{{getAmountFormat(group.grand_total.grand_total_transactions)}}</td>
              <td class="amount">{{getAmountFormat(group.grand_total.grand_total_gross_comm_dollar)}}</td>
              <td class="amount">{{getAmountFormat(group.grand_total.grand_total_gross_comm_peso)}}</td>
              <td class="amount">{{getAmountFormat(group.grand_total.grand_total_other_remco)}}</td>
              <td class="amount">{{getAmountFormat(group.grand_total.grand_total_realized)}}</td>
              <td class="amount">{{getAmountFormat(group.grand_total.grand_total_forex)}}</td>
              <td class="amount">{{getAmountFormat(group.grand_total.grand_total_avp_income)}}</td>
              <td class="amount">{{getAmountFormat(group.grand_total.grand_total_digital)}}</td>
              <td class="amount">{{getAmountFormat(group.grand_total.grand_total_deped_loan)}}</td>
              <td class="amount">{{getAmountFormat(group.grand_total.grand_total_pension_loan)}}</td>
              <td class="amount">{{getAmountFormat(group.grand_total.grand_total_motorcycle_loan)}}</td>
              <td class="amount">{{getAmountFormat(group.grand_total.grand_total_other_income)}}</td>
              <td class="amount">{{getAmountFormat(group.grand_total.grand_total_vat)}}</td>
              <td class="amount">{{getAmountFormat(group.grand_total.grand_total_total)}}</td>
              <td class="amount">{{getAmountFormat(group.grand_total.grand_total_ccf)}}</td>
              <td class="amount">{{getAmountFormat(group.grand_total.grand_total_net)}}</td>
              <td class="amount">{{getAmountFormat(group.grand_total.grand_total_salaries_wages)}}</td>
              <td class="amount">{{getAmountFormat(group.grand_total.grand_total_retirement)}}</td>
              <td class="amount">{{getAmountFormat(group.grand_total.grand_total_medical_emp_benefits)}}</td>
              <td class="amount">{{getAmountFormat(group.grand_total.grand_total_rental_fee)}}</td>
              <td class="amount">{{getAmountFormat(group.grand_total.grand_total_retainer_fee)}}</td>
              <td class="amount">{{getAmountFormat(group.grand_total.grand_total_freight_postage_delivery)}}</td>
              <td class="amount">{{getAmountFormat(group.grand_total.grand_total_telephone_communication)}}</td>
              <td class="amount">{{getAmountFormat(group.grand_total.grand_total_depreciation_amortization)}}</td>
              <td class="amount">{{getAmountFormat(group.grand_total.grand_total_repair_maintenance)}}</td>
              <td class="amount">{{getAmountFormat(group.grand_total.grand_total_stationary_printing_supplies)}}</td>
              <td class="amount">{{getAmountFormat(group.grand_total.grand_total_sub_membership_fee)}}</td>
              <td class="amount">{{getAmountFormat(group.grand_total.grand_total_representation_entertainment)}}</td>
              <td class="amount">{{getAmountFormat(group.grand_total.grand_total_transportation_travel)}}</td>
              <td class="amount">{{getAmountFormat(group.grand_total.grand_total_train_sem_conference)}}</td>
              <td class="amount">{{getAmountFormat(group.grand_total.grand_total_bank_charges)}}</td>
              <td class="amount">{{getAmountFormat(group.grand_total.grand_total_light_water)}}</td>
              <td class="amount">{{getAmountFormat(group.grand_total.grand_total_insurance_expense)}}</td>
              <td class="amount">{{getAmountFormat(group.grand_total.grand_total_taxes_license)}}</td>
              <td class="amount">{{getAmountFormat(group.grand_total.grand_total_prof_legal_audit_fee)}}</td>
              <td class="amount">{{getAmountFormat(group.grand_total.grand_total_interest_expense)}}</td>
              <td class="amount">{{getAmountFormat(group.grand_total.grand_total_misc_expense)}}</td>
              <td class="amount">{{getAmountFormat(group.grand_total.grand_total_loss_fraud)}}</td>
              <td class="amount">{{getAmountFormat(group.grand_total.grand_total_security_janitorial_service)}}</td>
              <td class="amount">{{getAmountFormat(group.grand_total.grand_total_computer_network_system)}}</td>
              <td class="amount">{{getAmountFormat(group.grand_total.grand_total_directors_per_bonus)}}</td>
              <td class="amount">{{getAmountFormat(group.grand_total.grand_total_directors_per_diem)}}</td>
              <td class="amount">{{getAmountFormat(group.grand_total.grand_total_incentive_expense)}}</td>
              <td class="amount">{{getAmountFormat(group.grand_total.grand_total_bad_debts)}}</td>
              <td class="amount">{{getAmountFormat(group.grand_total.grand_total_incentive_expense_wubs)}}</td>
              <td class="amount">{{getAmountFormat(group.grand_total.grand_total_leasing_expense)}}</td>
              <td class="amount">{{getAmountFormat(group.grand_total.grand_total_marketing_expense)}}</td>
              <td class="amount">{{getAmountFormat(group.grand_total.grand_total_consultancy_fee)}}</td>
              <td class="amount">{{getAmountFormat(group.grand_total.grand_total_commission_expense)}}</td>
              <td class="amount">{{getAmountFormat(group.grand_total.grand_total_unrealized_forex_gain)}}</td>
              <td class="amount">{{getAmountFormat(group.grand_total.grand_total_provision_for_impairment)}}</td>
              <td class="amount">{{getAmountFormat(group.grand_total.grand_total_total_expense)}}</td>
              <td class="amount">{{getAmountFormat(group.grand_total.grand_total_nibt)}}</td>
              <td class="amount">{{getAmountFormat(group.grand_total.grand_total_less_thirty)}}</td>
              <td class="amount">{{getAmountFormat(group.grand_total.grand_total_niat)}}</td>
              <td class="amount">{{getAmountFormat(group.grand_total.grand_total_ebitda)}}</td>
            </tr>
          </tbody>
          <br>
          <tbody v-for="(det, index) in summary.details" :key="index">
            <tr>
              <td v-if="index==6" colspan="5"><b>CSD AND TREASURY:</b></td>
              <td v-if="index==7" colspan="5"><b>Loans Summary:</b></td>
            </tr>
            <tr v-for="sub in det" :key="sub.id">
              <td>{{sub.branch_code}}</td>
              <td>{{sub.branch_name}}</td>
              <td class="date">{{sub.date_open}}</td>
              <td class="amount">{{getAmountFormat(sub.transactions)}}</td>
              <td class="amount">{{getAmountFormat(sub.gross_comm_dollar)}}</td>
              <td class="amount">{{getAmountFormat(sub.gross_comm_peso)}}</td>
              <td class="amount">{{getAmountFormat(sub.other_remco)}}</td>
              <td class="amount">{{getAmountFormat(sub.realized)}}</td>
              <td class="amount">{{getAmountFormat(sub.forex)}}</td>
              <td class="amount">{{getAmountFormat(sub.avp_income)}}</td>
              <td class="amount">{{getAmountFormat(sub.digital)}}</td>
              <td class="amount">{{getAmountFormat(sub.deped_loan)}}</td>
              <td class="amount">{{getAmountFormat(sub.pension_loan)}}</td>
              <td class="amount">{{getAmountFormat(sub.motorcycle_loan)}}</td>
              <td class="amount">{{getAmountFormat(sub.other_income)}}</td>
              <td class="amount">{{getAmountFormat(sub.vat)}}</td>
              <td class="amount">{{getAmountFormat(sub.total)}}</td>
              <td class="amount">{{getAmountFormat(sub.ccf)}}</td>
              <td class="amount">{{getAmountFormat(sub.net)}}</td>
              <td class="amount">{{getAmountFormat(sub.salaries_wages)}}</td>
              <td class="amount">{{getAmountFormat(sub.retirement)}}</td>
              <td class="amount">{{getAmountFormat(sub.medical_emp_benefits)}}</td>
              <td class="amount">{{getAmountFormat(sub.rental_fee)}}</td>
              <td class="amount">{{getAmountFormat(sub.retainer_fee)}}</td>
              <td class="amount">{{getAmountFormat(sub.freight_postage_delivery)}}</td>
              <td class="amount">{{getAmountFormat(sub.telephone_communication)}}</td>
              <td class="amount">{{getAmountFormat(sub.depreciation_amortization)}}</td>
              <td class="amount">{{getAmountFormat(sub.repair_maintenance)}}</td>
              <td class="amount">{{getAmountFormat(sub.stationary_printing_supplies)}}</td>
              <td class="amount">{{getAmountFormat(sub.sub_membership_fee)}}</td>
              <td class="amount">{{getAmountFormat(sub.representation_entertainment)}}</td>
              <td class="amount">{{getAmountFormat(sub.transportation_travel)}}</td>
              <td class="amount">{{getAmountFormat(sub.train_sem_conference)}}</td>
              <td class="amount">{{getAmountFormat(sub.bank_charges)}}</td>
              <td class="amount">{{getAmountFormat(sub.light_water)}}</td>
              <td class="amount">{{getAmountFormat(sub.insurance_expense)}}</td>
              <td class="amount">{{getAmountFormat(sub.taxes_license)}}</td>
              <td class="amount">{{getAmountFormat(sub.prof_legal_audit_fee)}}</td>
              <td class="amount">{{getAmountFormat(sub.interest_expense)}}</td>
              <td class="amount">{{getAmountFormat(sub.misc_expense)}}</td>
              <td class="amount">{{getAmountFormat(sub.loss_fraud)}}</td>
              <td class="amount">{{getAmountFormat(sub.security_janitorial_service)}}</td>
              <td class="amount">{{getAmountFormat(sub.computer_network_system)}}</td>
              <td class="amount">{{getAmountFormat(sub.directors_per_bonus)}}</td>
              <td class="amount">{{getAmountFormat(sub.directors_per_diem)}}</td>
              <td class="amount">{{getAmountFormat(sub.incentive_expense)}}</td>
              <td class="amount">{{getAmountFormat(sub.bad_debts)}}</td>
              <td class="amount">{{getAmountFormat(sub.incentive_expense_wubs)}}</td>
              <td class="amount">{{getAmountFormat(sub.leasing_expense)}}</td>
              <td class="amount">{{getAmountFormat(sub.marketing_expense)}}</td>
              <td class="amount">{{getAmountFormat(sub.consultancy_fee)}}</td>
              <td class="amount">{{getAmountFormat(sub.commission_expense)}}</td>
              <td class="amount">{{getAmountFormat(sub.unrealized_forex_gain)}}</td>
              <td class="amount">{{getAmountFormat(sub.provision_for_impairment)}}</td>
              <td class="amount">{{getAmountFormat(sub.total_expense)}}</td>
              <td class="amount">{{getAmountFormat(sub.nibt)}}</td>
              <td class="amount">{{getAmountFormat(sub.less_thirty)}}</td>
              <td class="amount">{{getAmountFormat(sub.niat)}}</td>
              <td class="amount">{{getAmountFormat(sub.ebitda)}}</td>
              <td style="text-align:right">{{getDateCloseFormat(sub.date_closed)}}</td>
            </tr>
            <tr v-if="index != 8 && index != 9">
              <td></td>
              <td></td>
              <td>Total:</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_transactions)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_gross_comm_dollar)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_gross_comm_peso)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_other_remco)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_realized)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_forex)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_avp_income)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_digital)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_deped_loan)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_pension_loan)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_motorcycle_loan)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_other_income)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_vat)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_total)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_ccf)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_net)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_salaries_wages)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_retirement)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_medical_emp_benefits)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_rental_fee)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_retainer_fee)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_freight_postage_delivery)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_telephone_communication)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_depreciation_amortization)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_repair_maintenance)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_stationary_printing_supplies)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_sub_membership_fee)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_representation_entertainment)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_transportation_travel)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_train_sem_conference)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_bank_charges)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_light_water)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_insurance_expense)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_taxes_license)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_prof_legal_audit_fee)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_interest_expense)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_misc_expense)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_loss_fraud)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_security_janitorial_service)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_computer_network_system)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_directors_per_bonus)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_directors_per_diem)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_incentive_expense)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_bad_debts)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_incentive_expense_wubs)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_leasing_expense)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_marketing_expense)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_consultancy_fee)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_commission_expense)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_unrealized_forex_gain)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_provision_for_impairment)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_total_expense)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_nibt)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_less_thirty)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_niat)}}</td>
              <td class="amount">{{getAmountFormat(group.subtotal[index].total_ebitda)}}</td>
            </tr>
            <br v-if="index!=7">
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
// import jsPDF from 'jspdf';
import html2pdf from 'html2pdf.js';
//import 'jspdf-autotable';
import { mapGetters } from 'vuex';
import numeral from 'numeral'; 
import { format } from 'date-fns';
export default {
	data: () => ({
		detail: {
			year: '',
			month: '',
		},
		summary: [],
		group:[]
	}),
	mounted() {
		this.$store.dispatch('report/getProfitabilityData', this.detail);
		this.$store.dispatch('report/getProfitabilityDataSummary', this.detail);
	},
	created() {
		var app = this;
		app.detail = JSON.parse(atob(app.$route.params.data)); 
	},
	computed: {
		...mapGetters({
			profData: 'report/profData',
			profDataSummary: 'report/profDataSummary'
		}),
	},
	watch: {
		profData: {
			handler(val) {
				this.getGroup(val);
			}
		},
		profDataSummary: {
			handler(val) {
				this.getSummary(val);
			}
		},
	},
	methods:{
		print() {
			window.print();
		},
		exportReport(){
			const contentHtml = this.$refs.table;
			const date = Math.floor(Date.now() / 1000);
			//var source = document.body;
			html2pdf()
				.from(contentHtml)
				.set({
					margin:       [80, 0, 30, 0],
					filename:     'profitability' + date +'.pdf',
					image:        { type: 'jpeg',quality: 0.98 },
					html2canvas:  { dpi: 96, scale: 1, scrollX: 0, scrollY: 0, backgroundColor: '#FFF' },
					jsPDF:        { unit: 'pt', format: 'a4', orientation: 'l' },
					pagebreak: { before: '.page-break', avoid: 'img' }
				})
				.toPdf()
				.get('pdf').then(function (pdf) {
					var totalPages = pdf.internal.getNumberOfPages();
                      
					for (let i = 1; i <= totalPages; i++) {
						pdf.setPage(i);
						pdf.setFontSize(9);
						pdf.text('PETNET, INC.', 10, 40);
						pdf.text('32nd Floor East Offices Aguirre St. Legaspi Village Makati City', 10, 50);
						pdf.text('Page ' + i + ' of ' + totalPages, pdf.internal.pageSize.getWidth() - 100, pdf.internal.pageSize.getHeight() - 40);
					} 
				}).save();
		},
		exportExcel(tableID, filename = '') {
			const date = Math.floor(Date.now() / 1000);
			var downloadLink;
			var dataType = 'application/vnd.ms-excel';

			// var x = document.getElementsByClassName('amount');
			// var i;
			// for (i = 0; i < x.length; i++) {
			// 	x[i].innerHTML = '\'' + x[i].innerHTML;
			// }
			var tableSelect = document.getElementById(tableID);
			var tableHTML = tableSelect.outerHTML.replace(/ /g, '%20');


			filename = filename ? filename + date + '.xls' : 'excel_data.xls';
			downloadLink = document.createElement('a');
			document.body.appendChild(downloadLink);

			if (navigator.msSaveOrOpenBlob) {
				var blob = new Blob(['\ufeff', tableHTML], {
					type: dataType
				});
				navigator.msSaveOrOpenBlob(blob, filename);
			} else {
				downloadLink.href = 'data:' + dataType + ', ' + tableHTML;
				downloadLink.download = filename;
				downloadLink.click();
			}
			
			// for (i = 0; i < x.length; i++) {
			// 	x[i].innerHTML = x[i].innerHTML.replace('\'', '');
			// }

		},
		getAmountFormat(amount){
			return numeral(amount).format('0,0.00');
		},
		getMonth(){
			var month = new Array();
			month[1] = 'January';
			month[2] = 'February';
			month[3] = 'March';
			month[4] = 'April';
			month[5] = 'May';
			month[6] = 'June';
			month[7] = 'July';
			month[8] = 'August';
			month[9] = 'September';
			month[10] = 'October';
			month[11] = 'November';
			month[12] = 'December';
			var n = month[this.detail.month];
			return n;
		},
		getGroup(data){
			if(data){
				this.group = data;
				let arr = [6,7,8,9];
				//delete this.summary.grand_total;
        
				// delete this.group.details[7];
				// delete this.group.subtotal[7];
				// delete this.group.details[8];
				// delete this.group.subtotal[8];
				// delete this.group.details[9];
				// delete this.group.subtotal[9];
				arr.forEach(ar => {
					delete this.group.details[ar];
				});
			}
		},
		getDateFormat(date){
			var month = format(new Date(date), 'MMM');
			var day = format(new Date(date), 'dd');
			var year = format(new Date(date), 'yyyy');
			return month + ' ' + day +', '+ year;
		},
		getDateCloseFormat(date){
			let newDate = '';
			if(date && date != '0000-00-00 00:00:00'){
				newDate = format(new Date(date), 'MM-d-yyyy');
			}
			return newDate;
		},
		getSummary(val){
			if(val){
				this.summary = val;
				let arr = [1,2,3,4,5];
				//delete this.summary.grand_total;
				arr.forEach(ar => {
					delete this.summary.details[ar];
				});
				// delete this.group.details[1];
				// delete this.group.subtotal[1];
				// delete this.group.details[2];
				// delete this.group.subtotal[2];
				// delete this.group.details[3];
				// delete this.group.subtotal[3];
				// delete this.group.details[4];
				// delete this.group.subtotal[4];
				// delete this.group.details[5];
				// delete this.group.subtotal[5];
				// delete this.group.details[6];
				// delete this.group.subtotal[6];
			}
		},
	}
};
</script>
<style scoped>
.report-template {
  width: 768px;
  /* border: 1px solid black; */
  font-size: 12px;
  margin: auto;
}
th {
  padding-left: 10px;
  padding-right: 10px;
}
.tbl {
  width: 95%;
  margin: auto;
  overflow: auto; 
  white-space: nowrap;
}
.code {
  text-align: center;
}
.amount {
  text-align: right;
}
.header {
  outline: 1px solid black;
  width: 100%;
  margin: 10px;
}
.total {
  text-align: right;
  font-weight: bold;
}
.action {
  padding: 20px;
}
.title {
  text-justify: center;
}
.amount {
  text-align: right;
}
.date {
  text-align: center;
}
	@media print {
		body #printAction { 
			display:none; 
		}
    body #printArea { 
			display:block; 
		}
	} 
</style>