// import VueCookies from 'vue-cookies';

export default{
	// isAuthenticated: VueCookies.get('auth-data') || false,
	// token: VueCookies.get('auth-data') || {},
	// roleAccess: VueCookies.get('role-access') || {},
	isAuthenticated: JSON.parse(localStorage.getItem('auth-data')) || false,
	token: JSON.parse(localStorage.getItem('auth-data')) || {},
	currUser : {},
	isLoading: false,
	isLoadingEmail: false,
	isLoadingOkta: false,
	error: false,
	showResult: false,
	result: '',
	hrisToken: localStorage.getItem('hris-token'),
};