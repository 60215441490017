import { RepositoryFactory } from '../../../api_repo/RepositoryFactory';

const dollarratesRepository = RepositoryFactory.get('dollarrate');

const getDollarrates = (context) => {
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	dollarratesRepository.get()
		.then((response) => {
			context.commit('DOLLARRATES_UPDATED', response.data.data);
			context.commit('app/SET_IS_LOADING',false,{ root: true });
		})
		.catch(() => {
			context.commit('app/SET_IS_LOADING',false,{ root: true });
        
		});
};

const saveDollarrate = (context,payload) => {
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		dollarratesRepository.saveDollarrate(payload)
			.then((response) => {
				context.commit('DOLLARRATES_ADD',response.data.data);    
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ' + response.data.data.fiscal_year + '(' + response.data.data.period_no + ') added.','messagetype':'success'},{ root: true });
			})
			.catch((error) => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Saving failed! ' + error.response.data.message,'messagetype':'error'},{ root: true });  
			});
	}, 1500);
};

const updateDollarrate = (context,payload) => {
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		dollarratesRepository.updateDollarrate(payload.id,payload)
			.then((response) => {
				context.commit('DOLLARRATES_ITEM_UPDATE',response.data.data);
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ' + response.data.data.fiscal_year + '(' + response.data.data.period_no + ') updated.','messagetype':'success'},{ root: true });            
			})
			.catch((error) => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Update failed! ' + error.response.data.message,'messagetype':'error'},{ root: true });  
			});
	}, 1500);
};


const deleteDollarrate = (context,payload) => {
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		dollarratesRepository.deleteDollarrate(payload.id)
			.then((response) => {
				context.commit('DOLLARRATES_DELETE',response.data.data);
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ' + response.data.data.fiscal_year + '(' + response.data.data.period_no + ') deleted.','messagetype':'success'},{ root: true });            
			})
			.catch((error) => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Delete failed! ' + error.response.data.message,'messagetype':'error'},{ root: true });  
			});
	}, 1500);
};

const getDollarDetail = (context, payload) => {
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		dollarratesRepository.getDollarrate(payload.id)
			.then((response) => {
				context.commit('SET_EDITED_ITEM',response.data.data);
				context.commit('app/SET_IS_LOADING',false,{ root: true });
			})
			.catch((error) => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Retrieve failed! ' + error.response.data.message,'messagetype':'error'},{ root: true });  
			});
	}, 1500);
};

const getDataPages = (context,payload) => {
	context.commit('app/SET_IS_LOADING', true , { root: true });
	setTimeout(() => {
		dollarratesRepository.getData(payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING', false , { root: true });
				context.commit('DOLLARRATES_UPDATED', response.data.data);
			})
			// eslint-disable-next-line
			.catch(() => {
				context.commit('app/SET_IS_LOADING', false , { root: true });
			});
	}, 1500);
};

const getData = (context,payload) => {
	context.commit('app/SET_IS_LOADING', true , { root: true });
	return new Promise((resolve, reject) => {
		dollarratesRepository.getData(payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING', false , { root: true });
				resolve(response);
			}, error => {
				context.commit('app/SET_IS_LOADING', false , { root: true });
				reject(error);
			});
	});
};

const setEditedItems = (context,payload) => {
	context.commit('SET_EDITED_ITEM',payload);
};

const setDialog = (context,payload) => {
	context.commit('SET_DIALOG',payload);
};

const setEditedIndex = (context,payload) => {
	context.commit('SET_EDITED_INDEX',payload);
};

const setValid = (context,payload) => {
	context.commit('SET_VALID',payload);
};

const setformTitle = (context,payload) => {
	context.commit('SET_FORM_TITLE',payload);
};

export default {
	getDollarrates,
	saveDollarrate,
	updateDollarrate,
	deleteDollarrate,
	setEditedItems,
	setDialog,
	setEditedIndex,
	setValid,
	setformTitle,
	getDollarDetail,
	getDataPages,
	getData
};