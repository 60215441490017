import Vue from 'vue';

const SERVICES_UPDATED = (state, services) => {
	state.services = services;
};

const SERVICES_ADD = (state, payload) => {
	state.services.push(payload);
}; 

const SERVICES_DELETE = (state, payload) => {
	let index = state.services.indexOf(payload);
	state.services.splice(index,1);
};

const SERVICES_ITEM_UPDATE = (state, payload) => {
	Vue.set(state.services, state.editedIndex, payload);
};

const SET_EDITED_ITEM = (state,payload) =>{
	state.editedItem = payload;
};

const SET_DIALOG = (state,payload) =>{
	state.dialog = payload;
};

const SET_EDITED_INDEX = (state,payload) =>{
	state.editedIndex = payload;
};

const SET_VALID = (state,payload) => {
	state.valid = payload;
};

const SET_FORM_TITLE = (state,payload) => {
	state.formTitle = payload;
};


export default {
	SERVICES_UPDATED,
	SET_EDITED_ITEM,
	SET_DIALOG,
	SET_EDITED_INDEX,
	SERVICES_ADD,
	SERVICES_DELETE,
	SET_VALID,
	SET_FORM_TITLE,
	SERVICES_ITEM_UPDATE
};