const SET_CURRENT_USER = (state, payload) => {
	state.currUser = payload[0];
};

const AUTH_REQUEST = (state) => {
	state.status = 'loading';
	state.isLoading = true;
};

const AUTH_REQUEST_EMAIL = (state) => {
	state.status = 'loading';
	state.isLoadingEmail = true;
};
const AUTH_REQUEST_OKTA = (state) => {
	state.status = 'loading';
	state.isLoadingOkta = true;
};

const AUTH_SUCCESS = (state, token) => {
	state.status = 'success';
	state.token = token;
	state.isAuthenticated = true;
	state.currUser = token.user[0];
	state.isLoading = false;
};

const AUTH_SUCCESS_EMAIL = (state, token) => {
	state.status = 'success';
	state.token = token;
	state.isAuthenticated = true;
	state.currUser = token.user[0];
	state.isLoadingEmail = false;
};

const AUTH_SUCCESS_OKTA = (state, token) => {
	state.status = 'success';
	state.token = token;
	state.isAuthenticated = true;
	state.currUser = token.user[0];
	state.hris_token = token.hris_token;
	state.isLoadingOkta = false;
};

const AUTH_ERROR = (state) => {
	state.isAuthenticated = false;
	state.isLoading = false;
	state.isLoadingEmail = false;
	state.isLoadingOkta = false;
	state.error = false;
};

const AUTH_LOGOUT = (state) => {
	state.isAuthenticated = false;
};

const SET_ISLOADING = (state, payload) => {
	state.isLoading = payload;
	state.isLoadingEmail = payload;
	state.isLoadingOkta = payload;
};

const SET_SHOWRESULT = (state, payload) => {
	state.showResult = payload;
};

const SET_ISAUTHENTICATED = (state, payload) => {
	state.isAuthenticated = payload;
};

export default {
	AUTH_REQUEST,
	AUTH_SUCCESS,
	AUTH_ERROR,
	SET_CURRENT_USER,
	AUTH_LOGOUT,
	SET_ISLOADING,
	SET_SHOWRESULT,
	SET_ISAUTHENTICATED,
	AUTH_SUCCESS_EMAIL,
	AUTH_REQUEST_EMAIL,
	AUTH_SUCCESS_OKTA,
	AUTH_REQUEST_OKTA
};