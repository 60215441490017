import Vue from 'vue';
import { RepositoryFactory } from '../../../api_repo/RepositoryFactory';

const payefilesRepository = RepositoryFactory.get('payefile');

const getPayefiles = (context) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	payefilesRepository.get()
		.then((response) => {
			context.commit('PAYEFILES_UPDATED', response.data.data);
			context.commit('app/SET_IS_LOADING', false, { root: true });
		})
		.catch(() => {
			context.commit('app/SET_IS_LOADING', false, { root: true });

		});
};

const getPayefileDropdown = ({commit}) => new Promise((resolve, reject) => {
	payefilesRepository.get()
		.then((response) => {
			commit('PAYEFILES_UPDATED', response.data.data);
			resolve(response.data.data);
		})
		.catch((error) => {
			commit('app/SET_IS_LOADING', false, { root: true });
			reject(error);
		});
});

const savePayefile = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	setTimeout(() => {
		payefilesRepository.savePayefile(payload)
			.then((response) => {
				context.commit('PAYEFILES_ADD', response.data.data[0]);
				context.commit('SET_DEFAULT_ITEMS');
				context.commit('app/SET_IS_LOADING', false, { root: true });
				context.commit('SET_DIALOG', false);
				context.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'Your work has been successful! ' + response.data.data[0].payee_code + '(' + response.data.data[0].payee_desc + ') added.', 'messagetype': 'success' }, { root: true });
			})
			.catch((error) => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
				context.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'Saving failed! ' + error, 'messagetype': 'error' }, { root: true });
				var newErrorMessage = '';
				if (error.response.data.errors) {
					newErrorMessage = getError(error.response.data.errors);
				} else {
					newErrorMessage = error.response.data.message;
				}
				Vue.swal
					.fire({
						type: 'error',
						title: 'Saving failed!!',
						html: newErrorMessage
					}); 
			});
	}, 1500);
};


const updatePayefile = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	setTimeout(() => {
		payefilesRepository.updatePayefile(payload.id, payload)
			.then((response) => {
				context.commit('PAYEFILES_ITEM_UPDATE', response.data.data[0]);
				context.commit('SET_DEFAULT_ITEMS');
				context.commit('app/SET_IS_LOADING', false, { root: true });
				context.commit('SET_DIALOG', false);
				context.commit('SET_PAYEEBANK_DIALOG', false);
				context.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'Your work has been successful! ' + response.data.data[0].payee_code + '(' + response.data.data[0].payee_desc + ') updated.', 'messagetype': 'success' }, { root: true });
			})
			.catch((error) => {
				payload.updated.map(details =>{
					context.commit('SET_PAYEE_PAYEEBANKS',details);
				});
				context.commit('app/SET_IS_LOADING', false, { root: true });
				context.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'Update failed! ' + error.response.data.message, 'messagetype': 'error' }, { root: true });
				var newErrorMessage = '';
				if (error.response.data.errors) {
					newErrorMessage = getError(error.response.data.errors);
				} else {
					newErrorMessage = error.response.data.message;
				}
				Vue.swal
					.fire({
						type: 'error',
						title: 'Saving failed!!',
						html: newErrorMessage
					}); 
			});
	}, 1500);
};


const deletePayefile = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	setTimeout(() => {
		payefilesRepository.deletePayefile(payload.id)
			.then((response) => {
				context.commit('PAYEFILES_DELETE', response.data.data);
				context.commit('app/SET_IS_LOADING', false, { root: true });
				context.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'Your work has been successful! ' + payload.payee_code + '(' + payload.payee_desc + ') deleted.', 'messagetype': 'success' }, { root: true });
			})
			.catch((error) => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
				context.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'Delete failed! ' + error.response.data.message, 'messagetype': 'error' }, { root: true });
			});
	}, 1500);
};
const getPayeeDetails = (context, payload) => {
	//alert(payload);
	context.commit('app/SET_IS_LOADING', true, { root: true });
	payefilesRepository.getPayefile(payload)
		.then((response) => {
			context.commit('SET_EDITED_ITEM', response.data.data[0]);
			context.commit('app/SET_IS_LOADING', false, { root: true });
		})
		.catch(() => {
			context.commit('app/SET_IS_LOADING', false, { root: true });
		});
};

const getPayeeDet = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	return new Promise((resolve, reject) => {
		payefilesRepository.getPayefile(payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
				setTimeout(() => {
					resolve(response);
				}, 2000);
			}, error => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
				reject(error);
			});
	});
};

const getData = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true , { root: true });
	return new Promise((resolve, reject) => {
		payefilesRepository.getData(payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING', false , { root: true });
				resolve(response);
			}, error => {
				context.commit('app/SET_IS_LOADING', false , { root: true });
				reject(error);
			});
	});
};

const getDataPages = (context,payload) => {
	context.commit('app/SET_IS_LOADING', true , { root: true });
	setTimeout(() => {
		payefilesRepository.getData(payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING', false , { root: true });
				context.commit('PAYEFILES_UPDATED', response.data.data);
			})
			// eslint-disable-next-line
			.catch(() => {
				context.commit('app/SET_IS_LOADING', false , { root: true });
			});
	}, 1500);
};

const getError = (payload) => {
	var myObject = payload;
	var newerror = [];
	for (var name in myObject) {
		var replacement = name.split('.')[2];
		if(replacement == undefined){
			replacement = name;
		}
		var errors = payload[name][0];
		var errorDesc = errors.replace(name, replacement);
		var replacedash = errorDesc.replace('_', ' ');
		newerror.push(replacedash);
	}
	newerror.sort();
	var current = null;
	var cnt = 0;
	for (var i = 0; i < newerror.length; i++) {
		var errormessage;
		if (newerror[i] != current) {
			if (cnt > 0) {
				errormessage +=current + '<br>';
			}
			current = newerror[i];
			cnt = 1;
		} else {
			cnt++;
		}
	}
	if (cnt > 0) {
		errormessage +=
						current + '<br>';
	}
	return errormessage.replace('undefined', '');
};
const getAddress = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true , { root: true });
	return new Promise((resolve, reject) => {
		payefilesRepository.getAddress(payload.data, payload.token)
			.then((response) => {
				context.commit('app/SET_IS_LOADING', false , { root: true });
				resolve(response);
			}, error => {
				context.commit('app/SET_IS_LOADING', false , { root: true });
				reject(error);
			});
	});
};

const setEditedItems = (context, payload) => {
	context.commit('SET_EDITED_ITEM', payload);
};

const setDialog = (context, payload) => {
	context.commit('SET_DIALOG', payload);
};

const setEditedIndex = (context, payload) => {
	context.commit('SET_EDITED_INDEX', payload);
};

const setValid = (context, payload) => {
	context.commit('SET_VALID', payload);
};

const setformTitle = (context, payload) => {
	context.commit('SET_FORM_TITLE', payload);
};
const setIsLoading = (context, payload) => {
	context.commit('SET_ISLOADING', payload);
};


export default {
	getPayefiles,
	getPayefileDropdown,
	savePayefile,
	updatePayefile,
	deletePayefile,
	setEditedItems,
	setDialog,
	setEditedIndex,
	setValid,
	setformTitle,
	setIsLoading,
	getPayeeDetails,
	getError,
	getDataPages,
	getData,
	getPayeeDet,
	getAddress
};
