const collections = state => state.collections;
const collectionDetails = state => state.collectionDetails;
const collectionPrintDetails = state => state.collectionPrintDetails;
const transactionResults = state => state.transactionResults;
const headers = state => state.headers;
const headersCollection = state => state.headersCollection;
const batchNumber = state => state.batchNumber;
const documentNumber = state => state.documentNumber;
const documentDate = state => state.documentDate;
const atcCode = state => state.atcCode;
const dollarRate = state => state.dollarRate;
const vAt = state => state.vAt;
const bankCode = state => state.bankCode;
const bankAccount = state => state.bankAccount;
const amount = state => state.amount;
const payeeId = state => state.payeeId;
const editedItem = state => state.editedItem;
const defaultItem = state => state.defaultItem;
const dialog = state => state.dialog;
const editedIndex = state => state.editedIndex;
const valid = state => state.valid;
const formTitle = state => state.formTitle;
const editDialog = state => state.editDialog;
const viewDialog = state => state.viewDialog;
const preparer = state => state.preparer;
const attachmentDialog = state => state.attachmentDialog;
const dist_data = state => state.dist_data;
const newDocNum = state => state.newDocNum;
const success = state => state.success;
const collectionDetailed = state => state.collectionDetailed;
const birFormDetails = state => state.birFormDetails;
const printDetails = state => state.printDetails;
const csvFileUploads = state => state.csvFileUploads;
const collectionNumber = state => state.collectionNumber;

export default {
	collections,
	collectionDetails,
	collectionPrintDetails,
	transactionResults,
	headers,
	headersCollection,
	batchNumber,
	documentNumber,
	documentDate,
	atcCode,
	dollarRate,
	vAt,
	bankCode,
	bankAccount,
	amount,
	payeeId,
	editedItem,
	defaultItem,
	dialog,
	editedIndex,
	valid,
	formTitle,
	editDialog,
	viewDialog,
	attachmentDialog,
	preparer,
	dist_data,
	newDocNum,
	collectionDetailed,
	success,
	birFormDetails,
	printDetails,
	csvFileUploads,
	collectionNumber
};