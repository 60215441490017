import { RepositoryFactory } from '../../../api_repo/RepositoryFactory';

const banksRepository = RepositoryFactory.get('bank');

const getBanks = (context) => {
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		banksRepository.get()
			.then((response) => {
				context.commit('BANKS_UPDATED', response.data.data);
				context.commit('app/SET_IS_LOADING',false,{ root: true });
			})
			.catch(() => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
			});
	}, 1500);    
};

const saveBank = (context,payload) => {
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		banksRepository.saveBank(payload)
			.then((response) => {
				context.commit('BANKS_ADD',response.data.data);
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ' + response.data.data.bank_name + '(' + response.data.data.bank_code + ') added.','messagetype':'success'},{ root: true });
			})
			.catch((error) => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Saving failed! ' + error.response.data.message,'messagetype':'error'},{ root: true });  
			});
	}, 1500);
};

const updateBank = (context,payload) => {
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		banksRepository.updateBank(payload.id,payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('BANKS_ITEM_UPDATE',response.data.data);
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ' + response.data.data.bank_name + '(' + response.data.data.bank_code + ') updated.','messagetype':'success'},{ root: true });
			})
			.catch((error) => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Update failed! ' + error.response.data.message,'messagetype':'error'},{ root: true });  
			});
	}, 1500);
};

const deleteBank = (context,payload) => {
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		banksRepository.deleteBank(payload.id)
			.then((response) => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('BANKS_DELETE',response.data.data);
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ' + response.data.data.bank_name + '(' + response.data.data.bank_code + ') deleted.','messagetype':'success'},{ root: true });
			})
			.catch((error) => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Delete failed! ' + error.response.data.message,'messagetype':'error'},{ root: true });
			});
	}, 1500);
};

const getBankDetails = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	banksRepository.getBank(payload)
		.then((response) => {
			context.commit('SET_EDITED_ITEM', response.data.data);
			context.commit('app/SET_IS_LOADING', false, { root: true });
		})
		.catch(() => {
			context.commit('app/SET_IS_LOADING', false, { root: true });
		});
};


const getDataPages = (context,payload) => {
	context.commit('app/SET_IS_LOADING', true , { root: true });
	setTimeout(() => {
		banksRepository.getData(payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING', false , { root: true });
				context.commit('BANKS_UPDATED', response.data.data);
			})
			// eslint-disable-next-line
			.catch(() => {
				context.commit('app/SET_IS_LOADING', false , { root: true });
			});
	}, 1500);
};

const getData = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true , { root: true });
	return new Promise((resolve, reject) => {
		banksRepository.getData(payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING', false , { root: true });
				resolve(response);
			}, error => {
				context.commit('app/SET_IS_LOADING', false , { root: true });
				reject(error);
			});
	});
};

const setEditedItems = (context,payload) => {
	context.commit('SET_EDITED_ITEM',payload);
};

const setDialog = (context,payload) => {
	context.commit('SET_DIALOG',payload);
};

const setEditedIndex = (context,payload) => {
	context.commit('SET_EDITED_INDEX',payload);
};

const setValid = (context,payload) => {
	context.commit('SET_VALID',payload);
};

const setformTitle = (context,payload) => {
	context.commit('SET_FORM_TITLE',payload);
};

export default {
	getBanks,
	saveBank,
	updateBank,
	deleteBank,
	setEditedItems,
	setDialog,
	setEditedIndex,
	setValid,
	setformTitle,
	getBankDetails,
	getDataPages,
	getData
};