// eslint-disable-next-line
import Vue from 'vue';
import { RepositoryFactory } from '../../../api_repo/RepositoryFactory';

const payeebanksRepository = RepositoryFactory.get('payeebank');

const getPayeeBanks = (context) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	setTimeout(() => {
		payeebanksRepository.get()
			.then((response) => {
				context.commit('PAYEEBANKS_UPDATED', response.data.data);
				context.commit('app/SET_IS_LOADING', false, { root: true });
			})
			.catch(() => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
			});

	}, 1500);
};

const getPayeeBankDropdown = (context) => {
	setTimeout(() => {
		payeebanksRepository.get()
			.then((response) => {

				context.commit('PAYEEBANKS_UPDATED', response.data.data);
			})
			.catch(() => {
			});

	}, 1500);
};

const savePayeeBank = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	setTimeout(() => {
		payeebanksRepository.savePayeeBank(payload)
			.then((response) => {
				context.commit('PAYEEBANKS_ADD', response.data.data);
				context.commit('app/SET_IS_LOADING', false, { root: true });
				context.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'Your work has been successful! ' + response.data.data.acct_name + '(' + response.data.data.acct_no + ') added.', 'messagetype': 'success' }, { root: true });
			})
			.catch((error) => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
				context.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'Saving failed! ' + error.response.data.message, 'messagetype': 'error' }, { root: true });
			});
	}, 1500);
};

const updatePayeeBank = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	setTimeout(() => {
		payeebanksRepository.updatePayeeBank(payload.id, payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
				context.commit('PAYEEBANKS_ITEM_UPDATE', response.data.data);
				context.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'Your work has been successful! ' + response.data.data.acct_name + '(' + response.data.data.acct_no + ') updated.', 'messagetype': 'success' }, { root: true });
			})
			.catch((error) => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
				context.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'Update failed! ' + error.response.data.message, 'messagetype': 'error' }, { root: true });
			});
	}, 1500);
};

const deletePayeeBank = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	setTimeout(() => {
		payeebanksRepository.deletePayeeBank(payload.id)
			.then((response) => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
				context.commit('PAYEEBANKS_DELETE', response.data.data);
				context.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'Your work has been successful! ' + response.data.data.acct_name + '(' + response.data.data.acct_no + ') deleted.', 'messagetype': 'success' }, { root: true });
			})
			.catch((error) => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
				context.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'Delete failed! ' + error.response.data.message, 'messagetype': 'error' }, { root: true });
			});
	}, 1500);
};

const setEditedItems = (context, payload) => {
	context.commit('SET_EDITED_ITEM', payload);
};

const setDialog = (context, payload) => {
	context.commit('SET_DIALOG', payload);
};

const setEditedIndex = (context, payload) => {
	context.commit('SET_EDITED_INDEX', payload);
};

const setformTitle = (context, payload) => {
	context.commit('SET_FORM_TITLE', payload);
};

const setValid = (context, payload) => {
	context.commit('SET_VALID', payload);
};

export default {
	getPayeeBanks,
	getPayeeBankDropdown,
	savePayeeBank,
	updatePayeeBank,
	deletePayeeBank,
	setEditedItems,
	setDialog,
	setEditedIndex,
	setformTitle,
	setValid
};