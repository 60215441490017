import Vue from 'vue';

const MODULE_UPDATED = (state, module) => {
	state.module = module;
};

const MODULE_ADD = (state, payload) => {
	state.module.push(payload);
}; 

const MODULE_DELETE = (state, payload) => {
	let index = state.module.indexOf(payload);
	state.module.splice(index,1);
};

const MODULE_ITEM_UPDATE = (state, payload) => {
	Vue.set(state.module, state.editedIndex, payload);
};

const SET_EDITED_ITEM = (state,payload) =>{
	state.editedItem = payload;
};

const SET_DIALOG = (state,payload) =>{
	state.dialog = payload;
};

const SET_EDITED_INDEX = (state,payload) =>{
	state.editedIndex = payload;
};

const SET_VALID = (state,payload) => {
	state.valid = payload;
};

const SET_FORM_TITLE = (state,payload) => {
	state.formTitle = payload;
};


export default {
	MODULE_UPDATED,
	SET_EDITED_ITEM,
	SET_DIALOG,
	SET_EDITED_INDEX,
	MODULE_ADD,
	MODULE_DELETE,
	SET_VALID,
	SET_FORM_TITLE,
	MODULE_ITEM_UPDATE
};