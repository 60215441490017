// eslint-disable-next-line
import Vue from 'vue';
import { RepositoryFactory } from '../../../api_repo/RepositoryFactory';

const bpbanksRepository = RepositoryFactory.get('bpbank');

const getBpbanks = (context) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	setTimeout(() => {
		bpbanksRepository.get()
			.then((response) => {
				context.commit('BPBANKS_UPDATED', response.data.data);
				context.commit('app/SET_IS_LOADING', false, { root: true });
			})
			.catch(() => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
			});

	}, 1500);
};

const getBpbankDropdown = (context) => {
	setTimeout(() => {
		bpbanksRepository.get()
			.then((response) => {
				context.commit('BPBANKS_UPDATED', response.data.data);
			})
			.catch(() => {
			});

	}, 1500);
};

const saveBpbank = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	setTimeout(() => {
		bpbanksRepository.saveBpbank(payload)
			.then((response) => {
				context.commit('BPBANKS_ADD', response.data.data);
				context.commit('app/SET_IS_LOADING', false, { root: true });
				context.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'Your work has been successful! ' + response.data.data.acct_name + '(' + response.data.data.acct_no + ') added.', 'messagetype': 'success' }, { root: true });
			})
			.catch((error) => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
				context.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'Saving failed! ' + error.response.data.message, 'messagetype': 'error' }, { root: true });
			});
	}, 1500);
};

const updateBpbank = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	setTimeout(() => {
		bpbanksRepository.updateBpbank(payload.id, payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
				context.commit('BPBANKS_ITEM_UPDATE', response.data.data);
				context.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'Your work has been successful! ' + response.data.data.acct_name + '(' + response.data.data.acct_no + ') updated.', 'messagetype': 'success' }, { root: true });
			})
			.catch((error) => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
				context.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'Update failed! ' + error.response.data.message, 'messagetype': 'error' }, { root: true });
			});
	}, 1500);
};

const deleteBpbank = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	setTimeout(() => {
		bpbanksRepository.deleteBpbank(payload.id)
			.then((response) => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
				context.commit('BPBANKS_DELETE', response.data.data);
				context.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'Your work has been successful! ' + response.data.data.acct_name + '(' + response.data.data.acct_no + ') deleted.', 'messagetype': 'success' }, { root: true });
			})
			.catch((error) => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
				context.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'Delete failed! ' + error.response.data.message, 'messagetype': 'error' }, { root: true });
			});
	}, 1500);
};
const getData = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true , { root: true });
	return new Promise((resolve, reject) => {
		bpbanksRepository.getData(payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING', false , { root: true });
				resolve(response);
			}, error => {
				context.commit('app/SET_IS_LOADING', false , { root: true });
				reject(error);
			});
	});
};

const setEditedItems = (context, payload) => {
	context.commit('SET_EDITED_ITEM', payload);
};

const setDialog = (context, payload) => {
	context.commit('SET_DIALOG', payload);
};

const setEditedIndex = (context, payload) => {
	context.commit('SET_EDITED_INDEX', payload);
};

const setformTitle = (context, payload) => {
	context.commit('SET_FORM_TITLE', payload);
};

const setValid = (context, payload) => {
	context.commit('SET_VALID', payload);
};

export default {
	getBpbanks,
	getBpbankDropdown,
	saveBpbank,
	updateBpbank,
	deleteBpbank,
	setEditedItems,
	setDialog,
	setEditedIndex,
	setformTitle,
	setValid,
	getData
};