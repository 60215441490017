export default{
	acctperiodmains: [],
	headers: [
		{
			text: 'Record ID',
			align: 'left',
			sortable: false,
			value: 'id',
		},
		{
			text: 'Fiscal Year',
			align: 'left',
			sortable: false,
			value: 'fiscal_year',
		},
		{
			text: 'Period No',
			align: 'left',
			sortable: false,
			value: 'period',
		},
		{
			text: 'Disbursment Status',
			align: 'left',
			sortable: false,
			value: 'disbursement',
		},
		{
			text: 'Cash Receipt Status',
			align: 'left',
			sortable: false,
			value: 'cash_receipt',
		},
		{
			text: 'General Journal Status',
			align: 'left',
			sortable: false,
			value: 'general_journal',
		},
		{ text: 'Actions', value: 'action', sortable: false },
	],
	columnHeaders: [
		{ label: 'Record ID', field: 'id' },
		{ label: 'Fiscal Year', field: 'fiscal_year' },
		{ label: 'Period No', field: 'period' },
		{ label: 'Disbursment Status', field: 'disbursement' },
		{ label: 'Cash Receipt Status', field: 'cash_receipt' },
		{ label: 'General Journal Status', field: 'general_journal' },
	],
	fiscalYear: [
		{ value: 2019, text: '2019'},
		{ value: 2020, text: '2020'},
		{ value: 2021, text: '2021'},
		{ value: 2022, text: '2022'},
		{ value: 2023, text: '2023'}, 
	],
	periodNo: [
		{ value: 1, text: 'January'},
		{ value: 2, text: 'February'},
		{ value: 3, text: 'March'},
		{ value: 4, text: 'April'},
		{ value: 5, text: 'May'},
		{ value: 6, text: 'June'},
		{ value: 7, text: 'July'},
		{ value: 8, text: 'August'},
		{ value: 9, text: 'September'},
		{ value: 10, text: 'October'},
		{ value: 11, text: 'November'},
		{ value: 12, text: 'December'}
	],
	disbursement: [
		{ value: 0, text: 'Closed'},
		{ value: 1, text: 'Open'}
	],
	general_journal: [
		{ value: 0, text: 'Closed'},
		{ value: 1, text: 'Open'}
	],
	cash_receipt: [
		{ value: 0, text: 'Closed'},
		{ value: 1, text: 'Open'}
	],     
	editedItem: {
		id: '',
		fiscal_year: '',
		period_no: '',
		disbursement: 1,
		cash_receipt: 1,
		general_journal: 1,
		input_by: '',
		edit_by: ''
	},
	defaultItem: {
		id: '',
		fiscal_year: '',
		period_no: '',
		disbursement: '',
		cash_receipt: '',
		general_journal: '',
		input_by: '',
		edit_by: ''
	},
	dialog: false,
	editedIndex: -1,
	valid: true,
	formTitle: 'Edit entry'
};