import { RepositoryFactory } from '../../../api_repo/RepositoryFactory';
const currenciesRepository = RepositoryFactory.get('currency');

const getCurrencies = (context) => {
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	currenciesRepository.get()
		.then((response) => {
			context.commit('CURRENCIES_UPDATED', response.data.data);
			context.commit('app/SET_IS_LOADING',false,{ root: true });
		})
		.catch(() => {
			context.commit('app/SET_IS_LOADING',false,{ root: true });
            
		});
};


const saveCurrency = (context,payload) => {
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		currenciesRepository.saveCurrency(payload)
			.then((response) => {
				context.commit('CURRENCIES_ADD',response.data.data);    
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ' + response.data.data.cur_desc + '(' + response.data.data.cur_code + ') added.','messagetype':'success'},{ root: true });
			})
			.catch((error) => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Saving failed! ' + error.response.data.message,'messagetype':'error'},{ root: true });  
			});
	}, 1500);
};

const updateCurrency = (context,payload) => {
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		currenciesRepository.updateCurrency(payload.id,payload)
			.then((response) => {
				context.commit('CURRENCIES_ITEM_UPDATE',response.data.data);
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ' + response.data.data.cur_desc + '(' + response.data.data.cur_code + ') updated.','messagetype':'success'},{ root: true });            
			})
			.catch((error) => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Update failed! ' + error.response.data.message,'messagetype':'error'},{ root: true });  
			});
	}, 1500);
};


const deleteCurrency = (context,payload) => {
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		currenciesRepository.deleteCurrency(payload.id)
			.then((response) => {
				context.commit('CURRENCIES_DELETE',response.data.data);
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ' + response.data.data.cur_desc + '(' + response.data.data.cur_code + ') deleted.','messagetype':'success'},{ root: true });            
			})
			.catch((error) => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Delete failed! ' + error.response.data.message,'messagetype':'error'},{ root: true });  
			});
	}, 1500);
};

const setEditedItems = (context,payload) => {
	context.commit('SET_EDITED_ITEM',payload);
};

const setDialog = (context,payload) => {
	context.commit('SET_DIALOG',payload);
};

const setEditedIndex = (context,payload) => {
	context.commit('SET_EDITED_INDEX',payload);
};

const setValid = (context,payload) => {
	context.commit('SET_VALID',payload);
};

const setformTitle = (context,payload) => {
	context.commit('SET_FORM_TITLE',payload);
};

export default {
	getCurrencies,
	saveCurrency,
	updateCurrency,
	deleteCurrency,
	setEditedItems,
	setDialog,
	setEditedIndex,
	setValid,
	setformTitle
};