import { RepositoryFactory } from '../../../api_repo/RepositoryFactory';
import router from '../../../router';
import Vue from 'vue';

const trxdisbsRepository = RepositoryFactory.get('trxdisb');
const getTrxdisbs = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	trxdisbsRepository.get(payload)
		.then((response) => {
			context.commit('TRXDISBS_UPDATED', response.data.data);
			context.commit('app/SET_IS_LOADING', false, { root: true });
		})
		.catch(() => {
			context.commit('app/SET_IS_LOADING', false, { root: true });
		});
};

const getTrxdisb = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	context.commit('trxdisb/TRXDISBS_DETAIL_MODAL_VIEW', false, { root: true });
	setTimeout(() => {
		trxdisbsRepository.getTrxdisb(payload)
			.then((response) => {
				context.commit('trxdisb/TRXDISBS_DETAIL_MODAL_VIEW', true, { root: true });
				context.commit('TRXDISBS_SHOW_DETAIL', response.data.data);
				context.commit('app/SET_IS_LOADING', false, { root: true });
			})
			.catch(() => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
			});
	}, 1500);
};

const getTransaction = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	setTimeout(() => {
		trxdisbsRepository.getTrxdisb(payload)
			.then((response) => {
				context.commit('TRX_SEARCH_RESULT', response.data.data);				
				context.commit('app/SET_IS_LOADING', false, { root: true });
			})
			.catch(() => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
			});
	}, 1500);
};

const getTrxdisbPrintDM = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	context.commit('trxdisb/TRXDISBS_DETAIL_MODAL_VIEW', false, { root: true });
	setTimeout(() => {
		trxdisbsRepository.getPrintData(payload)
			.then((response) => {
				context.commit('trxdisb/TRXDISBS_DETAIL_MODAL_VIEW', true, { root: true });
				context.commit('TRXDISBS_SHOW_PRINT_DETAIL', response.data);
				context.commit('app/SET_IS_LOADING', false, { root: true });
			})
			.catch(() => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
			});
	}, 1500);
};

const getArchivedTrxdisb = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	setTimeout(() => {
		trxdisbsRepository.getTrxdisb(payload)
			.then((response) => {
				context.commit('TRXDISBS_SHOW_DETAIL', response.data.data);
				context.commit('app/SET_IS_LOADING', false, { root: true });
			})
			.catch(() => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
			});
	}, 1500);
};

const editTrxdisb = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	context.commit('trxdisb/TRXDISBS_EDIT_MODAL_VIEW', false, { root: true });
	setTimeout(() => {
		trxdisbsRepository.getTrxdisb(payload)
			.then((response) => {
				let headers = response.data.data;
				let response_data = response.data.data;

				if ((headers[0].document_type === 'CW' && headers[0].is_pdc === 1) || (headers[0].document_type === 'CV' && headers[0].is_pdc === 1)) {
					headers[0].number_of_cheques = headers.length - 1;
					response_data = [headers[0]];
				}

				context.commit('TRXDISBS_SHOW_DETAIL', response_data);
				context.commit('trxdisb/TRXDISBS_EDIT_MODAL_VIEW', true, { root: true });
				// context.commit('app/SET_IS_LOADING', false, { root: true });
			})
			.catch(() => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
			});
	}, 1500);
};

const saveTrxdisb = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	setTimeout(() => {
		trxdisbsRepository.saveTrxdisb(payload.value)
			.then(() => {
				context.commit('TRXDISBS_ADD', payload.value);
				context.commit('app/SET_IS_LOADING', false, { root: true });
				context.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'The transaction entry has been added to the list successfully.', 'messagetype': 'success' }, { root: true });
				context.commit('SET_SUCCESS', true);
				let distData = payload.distData;
				const arrHead = ['debit_memo', 'cheque', 'online', 'cheque_write', 'journal_entry'];
				let item = '';
				// setTimeout(() => {
				if(payload.type !== 'JE' || payload.type !== 'CR'){
					for(let i = 0; i < arrHead.length; i++){
						if(arrHead[i] in distData){
							if('details' in distData[arrHead[i]]){
								delete distData[arrHead[i]].details;
							}
							if('headers' in distData[arrHead[i]]){
								delete distData[arrHead[i]].headers;
							}
							if('date' in distData[arrHead[i]]){
								delete distData[arrHead[i]].date;
							}
							if('bank_account_id' in distData[arrHead[i]]){
								delete distData[arrHead[i]].bank_account_id;
							}
							if('isConfidential' in distData[arrHead[i]]){
								delete distData[arrHead[i]].isConfidential;
							}
							if('disbursement_type' in distData[arrHead[i]]){
								delete distData[arrHead[i]].disbursement_type;
							}
						}
					}
				}

				if(payload.type == 'DM'){
					item = 'debit_memo';
				} else if(payload.type == 'CV'){
					item = 'cheque';
				} else if(payload.type == 'OB'){
					item = 'online';
				} else if(payload.type == 'CW'){
					item = 'cheque_write';
				} else if(payload.type == 'JE'){
					item = 'journal_entry';
				} else if(payload.type == 'CR'){
					item = 'cash_receipt';
					if(distData[item].headers[0].is_or_ar === 1){
						context.commit('SET_OR_NUMBER', '');
					} else if(distData[item].headers[0].is_or_ar === 2){
						context.commit('SET_AR_NUMBER', '');
					} else if(distData[item].headers[0].is_or_ar === 3){
						context.commit('SET_AR_NUMBER', '');
						context.commit('SET_OR_NUMBER', '');
					}
				}

				delete distData[item];
				context.commit('SET_DIST_DATA', distData);


				if (payload.type === 'JE') {
					window.location.href = '/trx-gen-journal';
				} else if (payload.type === 'CR') {
					window.location.href = '/trxcr';
				} else {
					window.location.href = '/trxdisb';
				}
				
				// }, 5000);
			}).catch((error) => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
				context.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'Saving failed! ' + error.response.data.message, 'messagetype': 'error' }, { root: true });
				var newErrorMessage = '';
				if (error.response.data.errors) {
					var myObject = error.response.data.errors;
					var newerror = [];
					for (var name in myObject) {
						var replacement = name.split('.')[2];
						if(replacement == undefined){
							replacement = name;
						}
						var errors = error.response.data.errors[name][0];
						var errorDesc = errors.replace(name, replacement);
						var replacedash = errorDesc.replace('_', ' ');
						newerror.push(replacedash);
					}
					newerror.sort();
					var current = null;
					var cnt = 0;
					for (var i = 0; i < newerror.length; i++) {
						var errormessage;
						if (newerror[i] != current) {
							if (cnt > 0) {
								errormessage +=
                  current + '<br>';
							}
							current = newerror[i];
							cnt = 1;
						} else {
							cnt++;
						}
					}
					if (cnt > 0) {
						errormessage +=
              current + '<br>';
					}
					newErrorMessage = errormessage.replace('undefined', '');
				} else {
					newErrorMessage = 'Please try again later.';
				}
				Vue.swal
					.fire({
						type: 'error',
						title: 'Failed to save!',
						html: newErrorMessage
					});
			});
	}, 1500);
	//window.location.href = '/trxdisb';
};

const updateTrxdisb = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	// context.commit('trxdisb/TRXDISBS_EDIT_MODAL_VIEW', true, { root: true });
	setTimeout(() => {
		trxdisbsRepository.updateTrxdisb(payload.batch_number, payload.value)
			.then((response) => {
				context.commit('TRXDISBS_ITEM_UPDATE', response.data.data);
				context.commit('trxdisb/TRXDISBS_EDIT_MODAL_VIEW', false, { root: true });
				// context.commit('TRXDISBS_EDIT_PROMPT_DIALOG', false, { root: true });
				context.commit('app/SET_IS_LOADING', false, { root: true });
				context.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'The transaction entry was updated successfully', 'messagetype': 'success' }, { root: true });
			})
			.catch((error) => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
				context.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'Update failed! ' + error.response.data.message, 'messagetype': 'error' }, { root: true });
			});
	}, 1500);
};


const voidTrxDisb = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	// context.commit('trxdisb/TRXDISBS_EDIT_MODAL_VIEW', true, { root: true });
	setTimeout(() => {
		trxdisbsRepository.voidTrxDisb(payload.data)
			.then((response) => {
				console.log(response);
				context.commit('app/SET_IS_LOADING', false, { root: true });
				context.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'The transaction entry was updated successfully', 'messagetype': 'success' }, { root: true });
				if (payload.type === 'JE') {
					window.location.href = '/trx-gen-journal';
				} else if (payload.type === 'CR') {
					window.location.href = '/trxcr';
				} else {
					window.location.href = '/trxdisb';
				}
			})
			.catch((error) => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
				context.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'Update failed! ' + error.response.data.message, 'messagetype': 'error' }, { root: true });
			});
	}, 1500);
};

const uploadEntries = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	setTimeout(() => {
		trxdisbsRepository.uploadEnties(payload)
			.then((response) => {
				context.commit('SET_UPLOAD_ENTRIES', response.data.data);
				context.commit('app/SET_IS_LOADING', false, { root: true });
			})
			.catch((error) => {
				console.log(error.response.data.data);
				context.commit('app/SET_IS_LOADING', false, { root: true });
				context.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'Unable to upload file! ', 'messagetype': 'error' }, { root: true });
				var newErrorMessage = '';
				if (error.response.data.data.message) {
					var errorMessageCurr = '';
					var errorMessageInact = '';
					if (error.response.data.data.message.currency) {
						var errorCurr = error.response.data.data.message.currency;
						if(errorCurr.length > 0){
							errorMessageCurr = '<h4><br>Different Currency' + '<br></h4>';
							if(errorCurr[0].data.length > 0){
								for (var i = 0; i < errorCurr[0].data.length; i++) {
									errorMessageCurr += '<p>Account Code: ' + errorCurr[0].data[i].account_code + ', Currency_id: ' + errorCurr[0].data[i].currency_id +'<br></p>';
								}
							}
						}
					}
					if (error.response.data.data.message.inactive) {
						var inactive = error.response.data.data.message.inactive;
						if(inactive.length > 0){
						
							errorMessageInact = '<h4><br>' + 'Inactive</h4>';
							inactive.forEach( data => {
								if(data.type === 'getDept'){
									errorMessageInact += '<br> Department: <br>';
									data.data.forEach((details, index) => {
										if(index > 0){
											errorMessageInact += ', ';
										}
										errorMessageInact += details.department_id+ ' - ' + details.dept_desc;
									});
								}
								if(data.type === 'getBranch'){
									errorMessageInact += '<br> Branch: <br>';
									data.data.forEach((details, index) => {
										if(index > 0){
											errorMessageInact += ', ';
										}
										errorMessageInact += details.branch_id + ' - ' + details.brnc_name;
									});
								}
								if(data.type === 'getSection'){
									errorMessageInact += '<br> Section : <br>';
									data.data.forEach((details, index) => {
										if(index > 0){
											errorMessageInact += ', ';
										}
										errorMessageInact += details.section_id;
									});
								}
								if(data.type === 'getPayee'){
									errorMessageInact += '<br> Payee Id: <br>';
									data.data.forEach((details, index) => {
										if(index > 0){
											errorMessageInact += ', ';
										}
										errorMessageInact += details.payee_id+ ' - ' + details.payee_desc;
									});
								}
								if(data.type === 'getCOA'){
									errorMessageInact += '<br> COA : <br>';
									data.data.forEach((details, index) => {
										if(index > 0){
											errorMessageInact += ', ';
										}
										errorMessageInact += details.account_code+ ' - ' + details.acct_desc;
									});
								}
							});
						}
					}
					if (error.response.data.data.message.nonexistent) {
						var nonexistent = error.response.data.data.message.nonexistent;
						if(nonexistent.length > 0){
						
							errorMessageInact = '<h4><br>' + 'Non Existent</h4>';
							nonexistent.forEach( data => {
								if(data.type === 'checkDeptId'){
									errorMessageInact += '<br> Department Id: <br>';
									data.data.forEach((details, index) => {
										if(index > 0){
											errorMessageInact += ', ';
										}
										errorMessageInact += details.department_id;
									});
								}
								if(data.type === 'checkBranchId'){
									errorMessageInact += '<br> Branch Id: <br>';
									data.data.forEach((details, index) => {
										if(index > 0){
											errorMessageInact += ', ';
										}
										errorMessageInact += details.branch_id ;
									});
								}
								if(data.type === 'checkPayeeId'){
									errorMessageInact += '<br> Payee Id: <br>';
									data.data.forEach((details, index) => {
										if(index > 0){
											errorMessageInact += ', ';
										}
										errorMessageInact += details.payee_id;
									});
								}
								if(data.type === 'checkCOAId'){
									errorMessageInact += '<br> Account Code : <br>';
									data.data.forEach((details, index) => {
										if(index > 0){
											errorMessageInact += ', ';
										}
										errorMessageInact += details.account_code;
									});
								}
							});
						}
					}
					newErrorMessage = errorMessageCurr + errorMessageInact;
				}
				Vue.swal
					.fire({
						type: 'error',
						title: 'Failed to upload!',
						html: newErrorMessage
					});
			});
	}, 1500);
};
const updateTrxdisbStatus = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	// context.commit('trxdisb/TRXDISBS_EDIT_MODAL_VIEW', true, { root: true });
	setTimeout(() => {
		trxdisbsRepository.updateTrxdisbStatus(payload.batch_number, payload.value)
			.then((response) => {
				context.commit('TRXDISBS_ITEM_UPDATE', response.data.data);
				// context.commit('trxdisb/TRXDISBS_EDIT_MODAL_VIEW', false, { root: true });
				// context.commit('TRXDISBS_EDIT_PROMPT_DIALOG', false, { root: true });
				context.commit('app/SET_IS_LOADING', false, { root: true });
				context.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'Status updated successfully', 'messagetype': 'success' }, { root: true });
				if(typeof response.data.data === 'object'){
					if(response.data.data.receipt_status === 2){
						Vue.swal
							.fire({
								title: 'No Email Setup',
								text: 'The payee doesn\'t have an email address. Do you want to print the receipt?',
								type: 'warning',
								showCancelButton: true,
								confirmButtonColor: '#3085d6',
								cancelButtonColor: '#d33',
								confirmButtonText: 'Print Receipt'
							}).then((result) => {
								if (result.value) {
									printORAR(payload.batch_number);
								}
							});
					} else {
						context.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'Receipt has been sent to payor email address.', 'messagetype': 'success' }, { root: true });
					}
				}
			})
			.catch((error) => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
				context.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'Update failed! ' + error.response.data.message, 'messagetype': 'error' }, { root: true });
			});
	}, 1500);
};

const deleteTrxdisb = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	setTimeout(() => {
		trxdisbsRepository.deleteTrxdisb(payload.id)
			.then((response) => {
				context.commit('TRXDISBS_DELETE', response.data.data);
				context.commit('app/SET_IS_LOADING', false, { root: true });
				context.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'The transaction entry has been deleted successfully', 'messagetype': 'success' }, { root: true });
			})
			.catch((error) => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
				context.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'Delete failed! ' + error.response.data.message, 'messagetype': 'error' }, { root: true });
			});
	}, 1500);
};

const getDetails = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	trxdisbsRepository.getDetail(payload)
		.then((response) => {
			let data = JSON.parse(response.data.data[0].upload_file);
			data.forEach(detail => {
				detail.seen = false;
			});
			context.commit('TRXDISBS_DETAIL', data);
			context.commit('TRXDISBS_PO_DETAILS',response.data.data[0].trx_purchase_order);
		});
};

const getDefaultBatchNumber = (context, payload) => {
	let batch_number = '';
	context.commit('app/SET_IS_LOADING', true, { root: true });
	trxdisbsRepository.getBatchNumber('/trx/batch-number', payload)
		.then((response) => {
			batch_number = response.data.data.batch_number;
			if(batch_number){
				payload['batch_number'] = batch_number;
				trxdisbsRepository.getBatchNumber('/trx/doc-number', payload)
					.then((response) => {
						let data = {};
						if(localStorage.getItem('dist_data')){
							//console.log('if');
							data = JSON.parse(localStorage.getItem('dist_data'));
							if(payload.document_type == 'DM'){
								data['debit_memo'] = {batch_number: batch_number, document_number: [response.data.data.document_number]};
							}else if(payload.document_type == 'CV'){
								data['cheque'] = {batch_number: batch_number, document_number: [response.data.data.document_number]};
							}else if(payload.document_type == 'OB'){
								data['online'] = {batch_number: batch_number, document_number: [response.data.data.document_number]};
							}else if (payload.document_type == 'JE') {
								data['journal_entry'] = {batch_number: batch_number, document_number: [response.data.data.document_number]};
							}else if (payload.document_type == 'CR') {
								data['cash_receipt'] = {batch_number: batch_number, document_number: [response.data.data.document_number]};
							}
							localStorage.setItem('dist_data', JSON.stringify(data));
							context.commit('trxdisb/SET_DIST_DATA', data, {root: true});
						}else{
							if(payload.document_type == 'DM'){
								data = { debit_memo: {batch_number: batch_number, document_number: [response.data.data.document_number]}};
							}else if (payload.document_type == 'JE') {
								data['journal_entry'] = {batch_number: batch_number, document_number: [response.data.data.document_number]};
							}else if (payload.document_type == 'CR') {
								data['cash_receipt'] = {batch_number: batch_number, document_number: [response.data.data.document_number]};
							}

							localStorage.setItem('dist_data', JSON.stringify(data));
							context.commit('trxdisb/SET_DIST_DATA', data, {root: true});
						}

						if (payload.document_type === 'JE') {
							router.push('/trx-gen-journal/create');
						} else if (payload.document_type === 'CR') {
							router.push('/trxcr/create');
						} else {
							router.push('/trxdisb/create');
						}
						
					});
			}
			context.commit('app/SET_IS_LOADING', false, { root: true });
		})
		.catch(() => {
			context.commit('app/SET_IS_LOADING', false, { root: true });
		});
};

const getBatchNumber = (context, payload) => {
	return new Promise((resolve, reject) => {
		trxdisbsRepository.getBatchNumber('/trx/batch-number', payload)
			.then((response) => {
				resolve(response);
			}, error => {
				reject(error);
			});
	});
};

const getDocumentNumber = (context, payload) => {
	return new Promise((resolve, reject) => {
		trxdisbsRepository.getBatchNumber('/trx/doc-number', payload)
			.then((response) => {
				setTimeout(() => {
					context.commit('app/SET_IS_LOADING', false, { root: true });
					resolve(response);
				}, 2000);
			}, error => {
				reject(error);
			});
	});
};

const getDocumentNumberCheque = () => {
	return new Promise((resolve, reject) => {
		trxdisbsRepository.getBatchNumber('/trx/voucher-number')
			.then((response) => {
				resolve(response);
			}, error => {
				reject(error);
			});
	});
};

const getDocumentNumberOR = ( context, payload ) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	return new Promise((resolve, reject) => {
		trxdisbsRepository.getBatchNumber('/trx/or-number', payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
				resolve(response);
			}, error => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
				reject(error);
			});
	});
};

const getDocumentNumberAR = ( context, payload ) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	return new Promise((resolve, reject) => {
		trxdisbsRepository.getBatchNumber('/trx/ar-number', payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
				resolve(response);
			}, error => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
				reject(error);
			});
	});
};

// const getORARData = (context, payload) => {
// 	context.commit('app/SET_IS_LOADING', true, { root: true });
// 	context.commit('trxdisb/TRXDISBS_DETAIL_MODAL_VIEW', false, { root: true });
// 	setTimeout(() => {
// 		trxdisbsRepository.getPrintReceiptData(payload)
// 			.then((response) => {
// 				context.commit('trxdisb/TRXDISBS_DETAIL_MODAL_VIEW', true, { root: true });
// 				context.commit('SET_PRINT_DETAILS', response.data.data);
// 				context.commit('app/SET_IS_LOADING', false, { root: true });
// 			})
// 			.catch(() => {
// 				context.commit('app/SET_IS_LOADING', false, { root: true });
// 			});
// 	}, 1500);
// };

const getORARData = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	// context.commit('trxdisb/TRXDISBS_DETAIL_MODAL_VIEW', false, { root: true });
	setTimeout(() => {
		trxdisbsRepository.getPrintReceiptData(payload)
			.then((response) => {
				// context.commit('trxdisb/TRXDISBS_DETAIL_MODAL_VIEW', true, { root: true });
				context.commit('SET_PRINT_DETAILS', response.data.data);
				context.commit('app/SET_IS_LOADING', false, { root: true });
			}).catch(() => {
				// reject(err);
				context.commit('app/SET_IS_LOADING', false, { root: true });
			});
	});
};

const getEORARData = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	return new Promise((resolve, reject) => {
		trxdisbsRepository.getPrintEReceiptData(payload)
			.then(({ data }) => {
				resolve(data);
				context.commit('app/SET_IS_LOADING', false, { root: true });
			}).catch((err) => {
				reject(err);
				context.commit('app/SET_IS_LOADING', false, { root: true });
			});
	});
};

function printORAR (payload) {
	return new Promise((resolve, reject) => {
		trxdisbsRepository.getPrintEReceiptData(payload)
			.then(({ data }) => {
				var newBlob = new Blob([data], { type: 'application/pdf' });
				var newFileURL = URL.createObjectURL(newBlob);
				window.open(newFileURL, '_blank');
			}).catch((err) => {
				reject(err);
			});
	});
}

const getBIRFormData = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	return new Promise((resolve, reject) => {
		trxdisbsRepository.getBIRFormData(payload)
			.then((response) => {
				context.commit('SHOW_FORM_2307_DETAIL', response.data.data);
				context.commit('app/SET_IS_LOADING', false, { root: true });
				resolve(response);
			}, error => {
				reject(error);
				context.commit('app/SET_IS_LOADING', false, { root: true });
			});
	});
};

const uploadHeaderIS = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	// context.commit('trxdisb/TRXDISBS_EDIT_MODAL_VIEW', true, { root: true });

	return new Promise((resolve, reject) => {
		trxdisbsRepository.uploadHeaderIS(payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
				context.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'The IS Header file was uploaded successfully', 'messagetype': 'success' }, { root: true });
				resolve(response);
			}, error => {
				reject(error);
				context.commit('app/SET_IS_LOADING', false, { root: true });
			});
	});
};

const uploadDetailIS = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	// context.commit('trxdisb/TRXDISBS_EDIT_MODAL_VIEW', true, { root: true });

	return new Promise((resolve, reject) => {
		trxdisbsRepository.uploadDetailIS(payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
				context.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'The IS Detail file was uploaded successfully', 'messagetype': 'success' }, { root: true });
				resolve(response);
			}, error => {
				reject(error);
				context.commit('app/SET_IS_LOADING', false, { root: true });
			});
	});
};

const uploadHeaderPayroll = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	// context.commit('trxdisb/TRXDISBS_EDIT_MODAL_VIEW', true, { root: true });

	return new Promise((resolve, reject) => {
		trxdisbsRepository.uploadHeaderPayroll(payload)
			.then((response) => {

				context.commit('app/SET_IS_LOADING', false, { root: true });
				context.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'The Payroll Header file was uploaded successfully', 'messagetype': 'success' }, { root: true });
				resolve(response);
			}, error => {
				reject(error);
				context.commit('app/SET_IS_LOADING', false, { root: true });
			});
	});
};

const uploadDetailPayroll = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	// context.commit('trxdisb/TRXDISBS_EDIT_MODAL_VIEW', true, { root: true });

	return new Promise((resolve, reject) => {
		trxdisbsRepository.uploadDetailPayroll(payload)
			.then((response) => {

				context.commit('app/SET_IS_LOADING', false, { root: true });
				context.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'The Payroll Detail file was uploaded successfully', 'messagetype': 'success' }, { root: true });
				resolve(response);
			}, error => {
				reject(error);
				context.commit('app/SET_IS_LOADING', false, { root: true });
			});
	});
};

const getCSVUploads = (context) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	trxdisbsRepository.getCSVUploads()
		.then((response) => {
			context.commit('SHOW_UPLOADED_CSV_FILES', response.data.data);
			context.commit('app/SET_IS_LOADING', false, { root: true });
		})
		.catch(() => {
			context.commit('app/SET_IS_LOADING', false, { root: true });
		});
};

const getData = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true , { root: true });
	return new Promise((resolve, reject) => {
		trxdisbsRepository.getData(payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING', false , { root: true });
				resolve(response);
			}, error => {
				context.commit('app/SET_IS_LOADING', false , { root: true });
				reject(error);
			});
	});
};

const getDataPages = (context,payload) => {
	context.commit('app/SET_IS_LOADING', true , { root: true });
	setTimeout(() => {
		trxdisbsRepository.getCSVUploadPages(payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING', false , { root: true });
				context.commit('SHOW_UPLOADED_CSV_FILES', response.data.data);
			})
			// eslint-disable-next-line
			.catch(() => {
				context.commit('app/SET_IS_LOADING', false , { root: true });
			});
	}, 1500);
};

const getReceiptDetail = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true , { root: true });
	setTimeout(() => {
		trxdisbsRepository.getReceiptDetail(payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING', false , { root: true });
				context.commit('SET_ERECEIPT_DETAILS', response.data.data);
			})
			.catch(() => {
				context.commit('app/SET_IS_LOADING', false , { root: true });
			});
	}, 1500);
};

const setTimer = (context) => {
	setTimeout(() => {
		context.commit('app/SET_IS_LOADING', true, { root: true });

	}, 5000);
	context.commit('app/SET_IS_LOADING', false, { root: true });
};

const setEditedItems = (context, payload) => {
	context.commit('SET_EDITED_ITEM', payload);
};

const setEditDialog = (context, payload) => {
	context.commit('TRXDISBS_EDIT_MODAL_VIEW', payload);
};

const setViewDialog = (context, payload) => {
	context.commit('TRXDISBS_DETAIL_MODAL_VIEW', payload);
};

const setAttachmentDialog = (context, payload) => {
	context.commit('TRXDISBS_ATTACHMENT_MODAL_VIEW', payload);
};

const setEditedIndex = (context, payload) => {
	context.commit('SET_EDITED_INDEX', payload);
};

const setPreparer = (context,payload) => {
	context.commit('SET_PREPARER',payload);
};

const setOR = (context,payload) => {
	context.commit('SET_OR_NUMBER',payload);
};

const setAR = (context,payload) => {
	context.commit('SET_AR_NUMBER',payload);
};

// const setValid = (context, payload) => {
// 	context.commit('SET_VALID', payload);
// };

// const setformTitle = (context, payload) => {
// 	context.commit('SET_FORM_TITLE', payload);
// };

// const setUpdatedTrxnDisbs = (context,payload)=>{
// 	context.commit('TRXDISBS_UPDATED', payload);
// };

// const setSelectedTrxDisb = (context,payload)=>{
// 	context.commit('TRXDISBS_SHOW_DETAIL', payload);
// };

export default {
	getTrxdisbs,
	getTrxdisb,
	getTransaction,
	editTrxdisb,
	saveTrxdisb,
	updateTrxdisb,
	updateTrxdisbStatus,
	deleteTrxdisb,
	setEditedItems,
	setEditDialog,
	setViewDialog,
	setAttachmentDialog,
	setEditedIndex,
	getArchivedTrxdisb,
	setPreparer,
	getDefaultBatchNumber,
	getBatchNumber,
	getDocumentNumber,
	setTimer,
	getDocumentNumberCheque,
	getTrxdisbPrintDM,
	getDetails,
	getDocumentNumberAR,
	getDocumentNumberOR,
	getBIRFormData,
	uploadHeaderIS,
	uploadDetailIS,
	uploadHeaderPayroll,
	uploadDetailPayroll,
	getORARData,
	getCSVUploads,
	getData,
	getDataPages,
	uploadEntries,
	voidTrxDisb,
	setOR,
	setAR,
	printORAR,
	getEORARData,
	getReceiptDetail
	// setValid,
	// setformTitle,
	// setUpdatedTrxnDisbs,
	// setSelectedTrxDisb,
};