import Vue from 'vue';

const COAS_UPDATED = (state, coas) => {
	state.coas = coas;
};

const COAS_ADD = (state, payload) => {
	state.coas.data.push(payload);
};

const COAS_DELETE = state => {
	state.coas.data.splice(state.editedIndex, 1);
};

const COAS_ITEM_UPDATE = (state, payload) => {
	Vue.set(state.coas.data, state.editedIndex, payload);
};

const SET_EDITED_ITEM = (state, payload) => {
	state.editedItem = payload;
};

const SET_DIALOG = (state, payload) => {
	state.dialog = payload;
};

const SET_EDITED_INDEX = (state, payload) => {
	state.editedIndex = payload;
};

const SET_VALID = (state, payload) => {
	state.valid = payload;
};

const SET_FORM_TITLE = (state, payload) => {
	state.formTitle = payload;
};

const SET_ACCOUNT_PARENTS = (state, payload) => {
	state.accountParents = payload;
};

const SET_ACCOUNT_CODE = (state, payload) => {
	state.editedItem['acct_code'] = payload.acct_code;
};

const SET_ACCOUNT_CLASS = (state, payload) => {
	state.editedItem['acct_class'] = payload;
};

const SET_ACCOUNT_GROUP = (state, payload) => {
	state.editedItem['acct_group'] = payload;
};

const SET_ACCOUNT_DESC = (state, payload) => {
	state.editedItem['acct_desc'] = payload;
};

const SET_ACCOUNT_PARENT_DESC = (state, payload) => {
	state.editedItem['acct_parent_desc'] = payload;
};

const SET_ACCOUNT_PARENT_CODE = (state, payload) => {
	state.editedItem['acct_parent'] = payload;
};

const SET_COA_SUBS = (state, payload) => {
	state.coaSubs = payload;
};

const SET_COA_CURR = (state, payload) => {
	state.coaCurr = payload;
};

const SET_COA_DATA = (state, payload) => {
	state.coaData = payload;
};

export default {
	COAS_UPDATED,
	SET_EDITED_ITEM,
	SET_DIALOG,
	SET_EDITED_INDEX,
	COAS_ADD,
	COAS_DELETE,
	SET_VALID,
	SET_FORM_TITLE,
	COAS_ITEM_UPDATE,
	SET_ACCOUNT_PARENTS,
	SET_ACCOUNT_CODE,
	SET_ACCOUNT_CLASS,
	SET_ACCOUNT_GROUP,
	SET_ACCOUNT_DESC,
	SET_ACCOUNT_PARENT_DESC,
	SET_ACCOUNT_PARENT_CODE,
	SET_COA_SUBS,
	SET_COA_CURR,
	SET_COA_DATA
};
