import Repository from '../Repository';

const resource = '/trx/ar-alloc';

export default {
	get() {
		return Repository.get(`${resource}`);
	},

	getAcknowledgementReceipt(id) {
		return Repository.get(`${resource}/${id}`);
	},

	saveAcknowledgementReceipt(payload) {
		return Repository.post(`${resource}`, payload);
	},

	updateAcknowledgementReceipt(id, payload) {
		return Repository.put(`${resource}/${id}`, payload);
	},

	deleteAcknowledgementReceipt(id) {
		return Repository.delete(`${resource}/${id}`);
	},
	searchAcknowledgementReceipt(payload) {
		return Repository.get(`${resource}`, payload);
	},
	
	getData(payload){
		return Repository.get(`${resource}?page=`+ payload);
	} 
};