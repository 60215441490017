import Vue from 'vue';
import { RepositoryFactory } from '../../../api_repo/RepositoryFactory';

const companiesRepository = RepositoryFactory.get('company');

const getCompanies = (context) => {
	context.commit('SET_ISLOADING',true);
	setTimeout(() => {
		companiesRepository.get()
			.then((response) => {
				context.commit('COMPANIES_UPDATED', response.data.data);
				context.commit('SET_ISLOADING',false);
			})
			.catch(() => {
				context.commit('SET_ISLOADING',false);
			});

	}, 1500);
};

const saveCompany = (context,payload) => {
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	companiesRepository.saveCompany(payload)
		.then((response) => {
			console.log(response);
			context.commit('COMPANIES_ADD', payload);   
			context.commit('app/SET_IS_LOADING',false,{ root: true });
			context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ' + payload.company_name + '(' + payload.company_code + ') added.','messagetype':'success'},{ root: true });

		})
		.catch((error) => {
			context.commit('app/SET_IS_LOADING',false,{ root: true });
			Vue.swal.fire({
				type: 'error',
				title: 'Failed getting data',
				text: error.response.data.message,
				timer: 2000
			});
		});
};

const updateCompany = (context,payload) => {
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	companiesRepository.updateCompany(payload.id,payload)
		.then((response) => {
			console.log(response);
			context.commit('app/SET_IS_LOADING',false,{ root: true });
			context.commit('COMPANIES_ITEM_UPDATE',payload);
			context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ' + payload.company_name + '(' + payload.company_code + ') updated.','messagetype':'success'},{ root: true });
		})
		.catch((error) => {
			context.commit('app/SET_IS_LOADING',false,{ root: true });
			context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Update failed! ' + error.response.data.message,'messagetype':'error'},{ root: true });
		});
};

const deleteCompany = (context,payload) => {
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	companiesRepository.deleteCompany(payload.id)
		.then((response) => {
			console.log(response);
			context.commit('app/SET_IS_LOADING',false,{ root: true });
			context.commit('COMPANIES_DELETE');
			context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ' + payload.company_name + '(' + payload.company_code + ') deleted.','messagetype':'success'},{ root: true });
		})
		.catch((error) => {
			context.commit('app/SET_IS_LOADING',false,{ root: true });
			context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Delete failed! ' + error.response.data.message,'messagetype':'error'},{ root: true });
		});
};

const getDetails = (context, payload) => {
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	companiesRepository.getDetails(payload)
		.then((response) => {
			context.commit('SET_EDITED_ITEM', response.data.data);
			context.commit('app/SET_IS_LOADING',false,{ root: true });
		})
		.catch(() => {
			context.commit('app/SET_IS_LOADING',false,{ root: true });
		});    
};

const setEditedItems = (context,payload) => {
	context.commit('SET_EDITED_ITEM',payload);
};

const setDialog = (context,payload) => {
	context.commit('SET_DIALOG',payload);
};

const setEditedIndex = (context,payload) => {
	context.commit('SET_EDITED_INDEX',payload);
};

const setValid = (context,payload) => {
	context.commit('SET_VALID',payload);
};

const setformTitle = (context,payload) => {
	context.commit('SET_FORM_TITLE',payload);
};

const setisLoading = (context,payload) => {
	context.commit('SET_ISLOADING',payload);
};

export default {
	getCompanies,
	saveCompany,
	updateCompany,
	deleteCompany,
	setEditedItems,
	setDialog,
	setEditedIndex,
	setValid,
	setformTitle,
	setisLoading,
	getDetails
};