export default {
	bplocations: [],
	headers: [
		{
			text: 'Record ID',
			align: 'left',
			sortable: false,
			value: 'id',
		},
		{
			text: 'Location ID No',
			align: 'left',
			sortable: false,
			value: 'loc_idno',
		},
		{ text: 'BP ID No', value: 'bp_idno' },
		{ text: 'Location Name', value: 'loc_name' },
		{ text: 'Status', value: 'status' },
		// { text: 'Address1', value: 'address1' },
		// { text: 'Address2', value: 'address2' },
		// { text: 'Address3', value: 'address3' },
		// { text: 'City', value: 'city' },
		// { text: 'Province', value: 'province' },
		// { text: 'Phone1', value: 'phone1' },
		// { text: 'Phone2', value: 'phone2' },
		// { text: 'Phone3', value: 'phone3' },
		// { text: 'Fax', value: 'fax' },
		// { text: 'Contact Person', value: 'contact_person' },
		// { text: 'API Acct', value: 'api_acct' },
		// { text: 'APZ Acct', value: 'apz_acct' },
		// { text: 'Inbound', value: 'inbound' },
		// { text: 'Outbound', value: 'outbound' },
		// { text: 'PC Equip', value: 'pc_equip' },
		// { text: 'Full', value: 'full' },
		// { text: 'Atmtd Loc ', value: 'atmtd_loc' },
		// { text: 'Con Date From', value: 'con_date_from' },
		// { text: 'Con Date To', value: 'con_date_to' },
		// { text: 'Peso IMT', value: 'peso_imt' },
		// { text: 'Peso DMT', value: 'peso_dmt' },
		// { text: 'Dollar IMT', value: 'dollar_imt' },
		// { text: 'Dollar DMT', value: 'dollar_dmt' },
		// { text: 'Branch Name Voyager', value: 'ral_status' },
		// { text: 'Borrowed Rep ID No', value: 'sol_idno' },
		{ text: 'Actions', value: 'action', sortable: false },
	],
	columnHeaders: [
		{ label: 'Record ID', field: 'id' },
		{ label: 'Location ID No', field: 'loc_idno' },
		{ label: 'BP ID No', field: 'bp_idno' },
		{ label: 'Location Name', field: 'loc_name' },
		{ label: 'Status', field: 'status' },
	],
	activeStatus: [
		{ value: '1', text: 'Active'},
		{ value: '0', text: 'Inactive'}
	],
	editedItem: {
		loc_idno: '',
		bp_idno: '',
		loc_name: '',
		status: '',
		address1: '',
		address2: '',
		address3: '',
		city: '',
		province: '',
		phone1: '',
		phone2: '',
		phone3: '',
		fax: '',
		contact_person: '',
		api_acct: '',
		apz_acct: '',
		inbound: '',
		outbound: '',
		pc_equip: '',
		full: '',
		atmtd_loc: '',
		con_date_from: '',
		con_date_to: '',
		peso_imt: '',
		peso_dmt: '',
		dollar_imt: '',
		dollar_dmt: '',
		ral_status: '',
		sol_idno: ''
	},
	defaultItem: {
		loc_idno: '',
		bp_idno: '',
		loc_name: '',
		status: '',
		address1: '',
		address2: '',
		address3: '',
		city: '',
		province: '',
		phone1: '',
		phone2: '',
		phone3: '',
		fax: '',
		contact_person: '',
		api_acct: '',
		apz_acct: '',
		inbound: '',
		outbound: '',
		pc_equip: '',
		full: '',
		atmtd_loc: '',
		con_date_from: '',
		con_date_to: '',
		peso_imt: '',
		peso_dmt: '',
		dollar_imt: '',
		dollar_dmt: '',
		ral_status: '',
		sol_idno: ''
	},
	dialog: false,
	editedIndex: -1,
	valid: true,
	formTitle: 'Edit entry',
};