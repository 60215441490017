import Vue from 'vue';
import { RepositoryFactory } from '../../../api_repo/RepositoryFactory';
const approvingofficersRepository = RepositoryFactory.get('approvingofficer');

const getApprovingOfficers = (context) => {
	context.commit('SET_ISLOADING',true);
	setTimeout(() => {
		approvingofficersRepository.get()
			.then((response) => {
				context.commit('SET_ISLOADING',false);
				context.commit('APPROVINGOFFICER_UPDATED', response.data.data);
			})
			// eslint-disable-next-line
			.catch(() => {
				context.commit('SET_ISLOADING',false);
			});
	}, 1500);
};

const saveApprovingOfficer = (context,payload) => {
	context.commit('SET_ISLOADING',true);
	approvingofficersRepository.saveApprovingOfficer(payload.data)
		.then((response) => {
			const {user_id, currency_id, level, min_amount, max_amount, edit_by, updated_at, created_at, id} = response.data.data;
			const {user_first_name, user_middle_name, user_last_name} = payload.users.filter(user => user.id == user_id)[0];
			const approver_name = `${user_first_name} ${user_middle_name} ${user_last_name}`;

			context.commit('SET_ISLOADING', false);
			context.commit('SET_VALID', false);
			context.commit('APPROVINGOFFICER_ADD', {
				approver_name,
				user_id,
				currency_id,
				level,
				min_amount,
				max_amount,
				edit_by,
				updated_at,
				created_at,
				id
			});

			//Updates LocalStorage data
			let currentUserItem = JSON.parse(localStorage['auth-data']);
			let currentUserData = currentUserItem.user[0];

			if (currentUserData.id === user_id) {
				currentUserData.min_amount = min_amount;
				currentUserData.max_amount = max_amount;
				currentUserData.level = level;
				localStorage.setItem('auth-data',JSON.stringify(currentUserItem));
			} 

			Vue.swal.fire({
				type: 'success',
				title: 'Create successful',
				text: 'An Approving Officer has been added.',
				timer: 2000,
			});
		})
		.catch(() => {
			context.commit('SET_ISLOADING', false);
			Vue.swal.fire({
				type: 'error',
				title: 'Saving failed!',
				text: 'Unable to create an Approving Officer',
				timer: 2000
			});
		});
};

const updateApprovingOfficer = (context,payload) => {
	context.commit('SET_ISLOADING',true);
	approvingofficersRepository.updateApprovingOfficer(payload.data.id, payload.data)
		.then((response) => {
			const {user_id, currency_id, level, min_amount, max_amount, edit_by, updated_at, created_at, id} = response.data.data;
			const {user_first_name, user_middle_name, user_last_name} = payload.users.filter(user => user.id == user_id)[0];
			const approver_name = `${user_first_name} ${user_middle_name} ${user_last_name}`;

			context.commit('SET_ISLOADING',false);
			context.commit('APPROVINGOFFICER_ITEM_UPDATE', {
				data: {
					approver_name,
					user_id,
					currency_id,
					level,
					min_amount,
					max_amount,
					edit_by,
					updated_at,
					created_at,
					id
				},
				index: payload.index
			});

			//Updates LocalStorage data
			let currentUserItem = JSON.parse(localStorage['auth-data']);
			let currentUserData = currentUserItem.user[0];

			currentUserData.min_amount = min_amount;
			currentUserData.max_amount = max_amount;
			currentUserData.level = level;
			localStorage.setItem('auth-data',JSON.stringify(currentUserItem));

			context.commit('SET_EDITED_INDEX', -1);
			Vue.swal.fire({
				type: 'success',
				title: 'Your work has been updated',
				text: 'An Approving Officer has been updated.',
				timer: 2000,
			});
		})
		.catch(() => {
			context.commit('SET_ISLOADING',false);
			Vue.swal.fire({
				type: 'error',
				title: 'Update fail',
				text: 'Unable to edit an Approving Officer',
				timer: 2000
			});
		});
};

const deleteApprovingOfficer = (context, payload) => {  
	context.commit('SET_ISLOADING', true);
	approvingofficersRepository.deleteApprovingOfficer(payload)
		.then((response) => {
			context.commit('SET_ISLOADING', false);
			context.commit('APPROVINGOFFICER_DELETE', response.data.data);

			let currentUserItem = JSON.parse(localStorage['auth-data']);
			let currentUserData = currentUserItem.user[0];

			if (currentUserData.id === payload.user_id) {
				currentUserData.min_amount = null;
				currentUserData.max_amount = null;
				currentUserData.level = null;
				localStorage.setItem('auth-data',JSON.stringify(currentUserItem));
			} 

			Vue.swal.fire({
				type: 'error',
				title: 'Delete successful',
				text: 'An Approving Officer has been deleted.',
				timer: 2000
			});
			context.commit('SET_EDITED_INDEX', -1);
		})
		.catch(() => {
			context.commit('SET_ISLOADING',false);
			Vue.swal.fire({
				type: 'error',
				title: 'Delete fail',
				text: 'Unable to delete an Approving Officer',
				timer: 2000
			});
		});
};

const getDetails = (context, payload) => {
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	approvingofficersRepository.getDetails(payload)
		.then((response) => {
			context.commit('SET_EDITED_ITEM', response.data.data);
			context.commit('app/SET_IS_LOADING',false,{ root: true });
		})
		.catch(() => {
			context.commit('app/SET_IS_LOADING',false,{ root: true });
		});    
};

const getDataPages = (context,payload) => {
	context.commit('SET_ISLOADING',true);
	setTimeout(() => {
		approvingofficersRepository.getData(payload)
			.then((response) => {
				context.commit('SET_ISLOADING',false);
				context.commit('APPROVINGOFFICER_UPDATED', response.data.data);
			})
			// eslint-disable-next-line
			.catch(() => {
				context.commit('SET_ISLOADING',false);
			});
	}, 1500);
};

const getData = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true , { root: true });
	return new Promise((resolve, reject) => {
		approvingofficersRepository.getData(payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING', false , { root: true });
				resolve(response);
			}, error => {
				context.commit('app/SET_IS_LOADING', false , { root: true });
				reject(error);
			});
	});
};

const setEditedItems = (context,payload) => {
	context.commit('SET_EDITED_ITEM',payload);
};

const setDialog = (context,payload) => {
	context.commit('SET_DIALOG',payload);
};

const setEditedIndex = (context,payload) => {
	context.commit('SET_EDITED_INDEX',payload);
};

const setValid = (context,payload) => {
	context.commit('SET_VALID',payload);
};

const setformTitle = (context,payload) => {
	context.commit('SET_FORM_TITLE',payload);
};

const setisLoading = (context,payload) => {
	context.commit('SET_ISLOADING',payload);
};

export default {
	getApprovingOfficers,
	saveApprovingOfficer,
	updateApprovingOfficer,
	deleteApprovingOfficer,
	setEditedItems,
	setDialog,
	setEditedIndex,
	setValid,
	setformTitle,
	setisLoading,
	getDetails,
	getDataPages,
	getData
};