import Repository from '../Repository';

const resource = '/useraccessprivilege';

export default {
	get() {
		return Repository.get(`${resource}`);
	},

	getUseraccessprivilege(id) {
		return Repository.get(`${resource}/${id}`);
	},

	saveUseraccessprivilege(payload) {
		return Repository.post(`${resource}`, payload);
	},

	updateUseraccessprivilege(id, payload) {
		return Repository.put(`${resource}/${id}`, payload);
	},

	deleteUseraccessprivilege(id) {
		return Repository.delete(`${resource}/${id}`);
	},

	searchUseraccessprivilege(payload){
		return Repository.get(`${resource}`,payload);
	}
};