import { RepositoryFactory } from '../../../api_repo/RepositoryFactory';

const billingInvoiceRepository = RepositoryFactory.get('billinginvoice');

const getBillingInvoice = (context) => {
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	billingInvoiceRepository.get()
		.then((response) => {
			context.commit('BILLING_INVOICE_UPDATED', response.data.data);
			context.commit('app/SET_IS_LOADING',false,{ root: true });
		})
		.catch(() => {
			context.commit('app/SET_IS_LOADING',false,{ root: true });
            
		});
};

const saveBillingInvoice = (context,payload) => {
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		billingInvoiceRepository.saveBillingInvoice(payload)
			.then((response) => {
				context.commit('BILLING_INVOICE_ADD',response.data.data);    
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! Billing Invoice from ' + response.data.data.series_from + ' to ' + response.data.data.series_to + ' added.','messagetype':'success'},{ root: true });
			})
			.catch((error) => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Saving failed! ' + error.response.data.data.message,'messagetype':'error'},{ root: true });  
			});
	}, 1500);
};


const updateBillingInvoice = (context,payload) => {
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		billingInvoiceRepository.updateBillingInvoice(payload.id,payload)
			.then((response) => {
				context.commit('BILLING_INVOICE_ITEM_UPDATE',response.data.data);
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ' + response.data.data.user_id + '(' + response.data.data.prog_code + ') updated.','messagetype':'success'},{ root: true });            
			})
			.catch((error) => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Update failed! ' + error.response.data.message,'messagetype':'error'},{ root: true });  
			});
	}, 1500);
};

const deleteBillingInvoice = (context,payload) => {
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		billingInvoiceRepository.deleteBillingInvoice(payload.id)
			.then((response) => {
				context.commit('BILLING_INVOICE_DELETE',response.data.data);
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful!  Billing Invoice from ' + response.data.data.series_from + ' to ' + response.data.data.series_to + ' deleted.','messagetype':'success'},{ root: true });            
			})
			.catch((error) => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Delete failed! ' + error.response.data.message,'messagetype':'error'},{ root: true });  
			});
	}, 1500);
};

const getDataPages = (context,payload) => {
	context.commit('app/SET_IS_LOADING', true , { root: true });
	setTimeout(() => {
		billingInvoiceRepository.getData(payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING', false , { root: true });
				context.commit('BILLING_INVOICE_UPDATED', response.data.data);
			})
			// eslint-disable-next-line
			.catch(() => {
				context.commit('app/SET_IS_LOADING', false , { root: true });
			});
	}, 1500);
};


const setEditedItems = (context,payload) => {
	context.commit('SET_EDITED_ITEM',payload);
};

const setDialog = (context,payload) => {
	context.commit('SET_DIALOG',payload);
};

const setEditedIndex = (context,payload) => {
	context.commit('SET_EDITED_INDEX',payload);
};

const setValid = (context,payload) => {
	context.commit('SET_VALID',payload);
};

const setformTitle = (context,payload) => {
	context.commit('SET_FORM_TITLE',payload);
};


export default {
	getBillingInvoice,
	saveBillingInvoice,
	updateBillingInvoice,
	deleteBillingInvoice,
	setEditedItems,
	setDialog,
	setEditedIndex,
	setValid,
	setformTitle,
	getDataPages
};
