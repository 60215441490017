export default {
	companies: [],
	isloading: false,
	headers: [
		{
			text: 'Record ID',
			align: 'left',
			sortable: false,
			value: 'id',
		},
		{
			text: 'Company Code',
			align: 'left',
			sortable: false,
			value: 'company_code',
		},
		{ text: 'Company Name', value: 'company_name' },
		{ text: 'Company Status', value: 'company_status' },
		{ text: 'Actions', value: 'action', sortable: false },
	],
	companyStatus: [
		{ value: '1', text: 'Active'},
		{ value: '0', text: 'Inactive'}
	],
	editedItem: {
		id: '',
		company_code: '',
		company_name: '',
		company_status: ''
	},
	defaultItem: {
		id: '',
		company_code: '',
		company_name: '',
		company_status: ''
	},
	dialog: false,
	editedIndex: -1,
	valid: true,
	formTitle: 'Edit entry',
};