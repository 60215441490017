import { RepositoryFactory } from '../../../api_repo/RepositoryFactory';

const alphataxcodesRepository = RepositoryFactory.get('alphataxcode');

const getAlphataxcodes = (context) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	setTimeout(() => {
		alphataxcodesRepository.get()
			.then((response) => {
				context.commit('ALPHATAXCODES_UPDATED', response.data.data);

				context.commit('app/SET_IS_LOADING', false, { root: true });
			})
			.catch(() => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
			});

	}, 1500);
};

const getAlphataxcodeDropdown = ({commit}) => new Promise((resolve, reject) => {
	setTimeout(() => {
		alphataxcodesRepository.get()
			.then((response) => {
				commit('ALPHATAXCODES_UPDATED', response.data.data);
				resolve(response.data.data);
			})
			.catch((error) => {
				commit('app/SET_IS_LOADING', false, { root: true });
				reject(error);
			});
	}, 1500);
});

const saveAlphataxcode = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	setTimeout(() => {
		alphataxcodesRepository.saveAlphataxcode(payload)
			.then((response) => {
				context.commit('ALPHATAXCODES_ADD', response.data.data);
				context.commit('app/SET_IS_LOADING', false, { root: true });
				context.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'Your work has been successful! ' + response.data.data.atc_desc + '(' + response.data.data.atc_code + ') added.', 'messagetype': 'success' }, { root: true });

			})
			.catch((error) => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
				context.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'Saving failed! ' + error.response.data.message, 'messagetype': 'error' }, { root: true });
			});
	}, 1500);
};

const updateAlphataxcode = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	setTimeout(() => {
		alphataxcodesRepository.updateAlphataxcode(payload.id, payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
				context.commit('ALPHATAXCODES_ITEM_UPDATE', response.data.data);
				context.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'Your work has been successful! ' + response.data.data.atc_desc + '(' + response.data.data.atc_code + ') updated.', 'messagetype': 'success' }, { root: true });
			})
			.catch((error) => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
				context.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'Update failed! ' + error.response.data.message, 'messagetype': 'error' }, { root: true });
			});
	}, 1500);
};
const deleteAlphataxcode = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	setTimeout(() => {
		alphataxcodesRepository.deleteAlphataxcode(payload.id)
			.then((response) => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
				context.commit('ALPHATAXCODES_DELETE', response.data.data);
				context.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'Your work has been successful! ' + response.data.data.atc_desc + '(' + response.data.data.atc_code + ') deleted.', 'messagetype': 'success' }, { root: true });
			})
			.catch((error) => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
				context.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'Delete failed! ' + error.response.data.message, 'messagetype': 'error' }, { root: true });
			});
	}, 1500);
};

const getData = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true , { root: true });
	return new Promise((resolve, reject) => {
		alphataxcodesRepository.getData(payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING', false , { root: true });
				resolve(response);
			}, error => {
				context.commit('app/SET_IS_LOADING', false , { root: true });
				reject(error);
			});
	});
};

const getDataPages = (context,payload) => {
	context.commit('app/SET_IS_LOADING', true , { root: true });
	setTimeout(() => {
		alphataxcodesRepository.getData(payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING', false , { root: true });
				context.commit('ALPHATAXCODES_UPDATED', response.data.data);
			})
			// eslint-disable-next-line
			.catch(() => {
				context.commit('app/SET_IS_LOADING', false , { root: true });
			});
	}, 1500);
};


const setEditedItems = (context, payload) => {
	context.commit('SET_EDITED_ITEM', payload);
};

const setDialog = (context, payload) => {
	context.commit('SET_DIALOG', payload);
};

const setEditedIndex = (context, payload) => {
	context.commit('SET_EDITED_INDEX', payload);
};

const setValid = (context, payload) => {
	context.commit('SET_VALID', payload);
};

const setformTitle = (context, payload) => {
	context.commit('SET_FORM_TITLE', payload);
};

export default {
	getAlphataxcodes,
	getAlphataxcodeDropdown,
	saveAlphataxcode,
	updateAlphataxcode,
	deleteAlphataxcode,
	setEditedItems,
	setDialog,
	setEditedIndex,
	setValid,
	setformTitle,
	getData,
	getDataPages
};
