export default {
	trxintegrated: [],
	headers: [
		{
			text: 'Batch Number',
			align: 'left',
			sortable: false,
			value: 'batch_number',
		},
		{ text: 'Date Encoded', value: 'date_encoded' },
		{ text: 'Payee', value: 'payee' },
		{ text: 'Type of Document', value: 'document_type' },
		{ text: 'Amount', value: 'total_amount', align: 'right' },
		{ text: 'Actions', value: 'action', sortable: false },
	],
	dialog: false,
	editDialog: false,
	viewDialog: false,
	attachmentDialog: false,
	editedIndex: -1,
	valid: true,
	formTitle: 'Edit entry',
	preparer: '',
	dist_data: '',
	trxintegratedDetails: [],
	success: false,
	printDetails: []
};