import Repository from '../Repository';

const resource = '/acctperiodmain';

export default {
	get() {
		return Repository.get(`${resource}`);
	},

	getAcctperiodmain(id) {
		return Repository.get(`${resource}/${id}`);
	},

	saveAcctperiodmain(payload) {
		return Repository.post(`${resource}`, payload);
	},

	updateAcctperiodmain(id, payload) {
		return Repository.put(`${resource}/${id}`, payload);
	},

	deleteAcctperiodmain(id) {
		return Repository.delete(`${resource}/${id}`);
	},

	searchAcctperiodmain(payload){
		return Repository.get(`${resource}`,payload);
	},

	getData(payload) {
		return Repository.get(`${resource}?page=`+ payload);
	} 
};


