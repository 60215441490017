import { RepositoryFactory } from '../../../api_repo/RepositoryFactory';

const moduleRepository = RepositoryFactory.get('module');

const getModule = (context) => {
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	moduleRepository.get()
		.then((response) => {
			context.commit('MODULE_UPDATED', response.data.data);
			context.commit('app/SET_IS_LOADING',false,{ root: true });
		})
		.catch(() => {
			context.commit('app/SET_IS_LOADING',false,{ root: true });
            
		});
};

const saveModule = (context,payload) => {
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		moduleRepository.saveModule(payload)
			.then((response) => {
				console.log(response);
				context.commit('MODULE_ADD',response.data.data);    
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ' + response.data.data.module_name + ' added.','messagetype':'success'},{ root: true });
			})
			.catch((error) => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Saving failed! ' + error.response.data.message,'messagetype':'error'},{ root: true });  
			});
	}, 1500);
};


const updateModule = (context,payload) => {
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		moduleRepository.updateModule(payload.id,payload)
			.then((response) => {
				context.commit('MODULE_ITEM_UPDATE',response.data.data);
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ' + response.data.data.module_name +' updated.','messagetype':'success'},{ root: true });            
			})
			.catch((error) => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Update failed! ' + error.response.data.message,'messagetype':'error'},{ root: true });  
			});
	}, 1500);
};

const deleteModule = (context,payload) => {
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		moduleRepository.deleteModule(payload.id)
			.then((response) => {
				context.commit('MODULE_DELETE',response.data.data);
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ' + response.data.data.module_name + ' deleted.','messagetype':'success'},{ root: true });            
			})
			.catch((error) => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Delete failed! ' + error.response.data.message,'messagetype':'error'},{ root: true });  
			});
	}, 1500);
};

const getDataPages = (context,payload) => {
	context.commit('app/SET_IS_LOADING', true , { root: true });
	setTimeout(() => {
		moduleRepository.getData(payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING', false , { root: true });
				context.commit('MODULE_UPDATED', response.data.data);
			})
			// eslint-disable-next-line
			.catch(() => {
				context.commit('app/SET_IS_LOADING', false , { root: true });
			});
	}, 1500);
};

const setEditedItems = (context,payload) => {
	context.commit('SET_EDITED_ITEM',payload);
};

const setDialog = (context,payload) => {
	context.commit('SET_DIALOG',payload);
};

const setEditedIndex = (context,payload) => {
	context.commit('SET_EDITED_INDEX',payload);
};

const setValid = (context,payload) => {
	context.commit('SET_VALID',payload);
};

const setformTitle = (context,payload) => {
	context.commit('SET_FORM_TITLE',payload);
};


export default {
	getModule,
	saveModule,
	updateModule,
	deleteModule,
	setEditedItems,
	setDialog,
	setEditedIndex,
	setValid,
	setformTitle,
	getDataPages
};