export default {
	payefiles: [
		{
			id: 1,
			payee_type: '1',
			payee_code: '',
			payee_desc: 'This is a test',
			//  payee_type: '',
			brnc_code: '',
			dept_code: '',
			section_code: '',
			house_unit: '',
			street: '',
			barangay: '',
			municipality: '',
			city: '',
			region: '',
			zip_code: '',
			contact_num: '',
			fax: '',
			email_add: '',
			tin: '',
			business_type: '1',
			vat: '1',
			atc_code: '',
			emp_idno: '',
			sss_no: '',
			philhealth_no: '',
			hdmf_no: '',
			position: '',
			emp_status: '',
			trader_name: '',
			class_code: '',
			sub_agent_status: ''
		}
	],
	headers: [

		{
			text: 'Payee ID',
			align: 'left',
			sortable: true,
			value: 'id',
		},
		{
			text: 'Payee Code',
			align: 'left',
			sortable: true,
			value: 'payee_code',
		},

		{ text: 'Payee Name', value: 'payee_desc' },
		{ text: 'Payee Type', value: 'paye_type' },
		{ text: 'Status', value: 'payee_status' },
		// { text: 'Branch Code', value: 'brnc_code' },
		// { text: 'Department Code', value: 'dept_code' },
		// { text: 'Section Code', value: 'section_code' },
		// { text: 'House Unit', value: 'house_unit' },
		// { text: 'Street', value: 'street' },
		// { text: 'Barangay', value: 'barangay' },
		// { text: 'Municipality', value: 'municipality' },
		//  { text: 'City', value: 'city' },
		// { text: 'Region', value: 'region' },
		// { text: 'Zip Code', value: 'zip_code' },
		//  { text: 'Contact No.', value: 'contact_num' },
		//  { text: 'Fax', value: 'fax' },
		//  { text: 'Email Add', value: 'email_add' },
		// { text: 'TIN', value: 'tin' },
		// { text: 'Business Type', value: 'business_type' },
		// { text: 'VAT Type', value: 'vat' },
		// { text: 'ATC Code', value: 'atc_code' },
		// { text: 'Employee IDNo', value: 'emp_idno' },
		// { text: 'SSS No.', value: 'sss_no' },
		//  { text: 'Philhealth No.', value: 'philhealth_no' },
		//  { text: 'HDMF No.', value: 'hdmf_no' },
		// { text: 'Position', value: 'position' },
		// { text: 'Employee Status', value: 'emp_status' },
		// { text: 'Trader Name', value: 'trader_name' },
		//  { text: 'Class Code', value: 'class_code' },
		// {
		// 	text: 'Sub-Agent Status',
		// 	align: 'left',
		// 	sortable: true,
		// 	value: 'sub_agent_status',
		// },
		{ text: 'Actions', value: 'action', sortable: false },
	],
	columnHeaders: [

		{ label: 'Payee ID', field: 'id' },
		{ label: 'Payee Code', field: 'payee_code' },
		{ label: 'Payee Name', field: 'payee_desc' },
		{ label: 'Payee Type', field: 'paye_type' },
		{ label: 'Branch Code', field: 'brnc_code' },
		{ label: 'Department Code', field: 'dept_code' },
		{ label: 'Section Code', field: 'section_code' },
		{ label: 'Status', field: 'payee_status' }
	],
	payeeType: [
		// { value: '1', text: 'Bank' },
		// { value: '2', text: 'Business Partner' },
		// { value: '3', text: 'Branch' },
		{ value: '4', text: 'Customer' },
		{ value: '5', text: 'Employee' },
		{ value: '6', text: 'Supplier' },
	],
	otherType: [
		// { value: '1', text: 'Bank' },
		{ value: '2', text: 'Business Partner' },
		{ value: '3', text: 'Branch' },
		{ value: '4', text: 'Customer' },
		{ value: '5', text: 'Employee' },
		{ value: '6', text: 'Supplier' },
	],
	vatType: [
		{ value: '0', text: 'NA' },
		{ value: '1', text: 'Vatable' },
		{ value: '2', text: 'Vat Exempt' },
		{ value: '3', text: 'Zero Rated' },
	],
	businessType: [
		{ value: '1', text: 'Branch' },
		{ value: '2', text: 'Corporate' },
		{ value: '3', text: 'GPP' },
		{ value: '4', text: 'Individual' },
		{ value: '5', text: 'Sole Proprietorship' },
	],
	empStatus: [
		{ value: '0', text: 'Active' },
		{ value: '1', text: 'Inactive' },
		{ value: '2', text: 'Resigned' },
	],
	classCode: [
		{ value: 'BC', text: 'Business Center' },
		{ value: 'CB', text: 'Commercial Banks' },
		{ value: 'CO', text: 'Company Owned' },
		{ value: 'CP', text: 'Cooperatives' },
		{ value: 'DS', text: 'Department Store' },
		{ value: 'FS', text: 'Financial Services' },
		{ value: 'HT', text: 'Hotel' },
		{ value: 'IC', text: 'Insurance Company' },
		{ value: 'MC', text: 'Money Changer' },
		{ value: 'PS', text: 'Pawnshops' },
		{ value: 'PM', text: 'Pharmacy' },
		{ value: 'RA', text: 'Recruitment Agency' },
		{ value: 'BR', text: 'Rural Bank' },
		{ value: 'SC', text: 'School' },
		{ value: 'SM', text: 'Supermarket' },
		{ value: 'TB', text: 'Thrifts Banks' },
		{ value: 'TA', text: 'Travel Agency' },
	],
	subagentStatus: [
		{ value: '0', text: 'Active' },
		{ value: '1', text: 'Inactive' },
	],
	editedItem: {
		id: '',
		payee_type: '',
		payee_code: '',
		payee_desc: '',
		//  payee_type: '',
		brnc_code: '',
		dept_code: '',
		section_code: '',
		house_unit: '',
		street: '',
		barangay: '',
		municipality: '',
		city: '',
		region: '',
		zip_code: '',
		contact_num: '',
		fax: '',
		email_add: '',
		tin: '',
		business_type: '',
		vat: '',
		// atc_code: '',
		emp_idno: '',
		sss_no: '',
		philhealth_no: '',
		hdmf_no: '',
		position: '',
		emp_status: '',
		trader_name: '',
		class_code: '',
		sub_agent_status: '',
		other_type: [],
		payee_banks: []
	},
	defaultItem: {
		id: '',
		payee_type: '',
		payee_code: '',
		payee_desc: '',
		//  payee_type: '',
		brnc_code: '',
		dept_code: '',
		section_code: '',
		house_unit: '',
		street: '',
		barangay: '',
		municipality: '',
		city: '',
		region: '',
		zip_code: '',
		contact_num: '',
		fax: '',
		email_add: '',
		tin: '',
		business_type: '',
		vat: '',
		// atc_code: '',
		emp_idno: '',
		sss_no: '',
		philhealth_no: '',
		hdmf_no: '',
		position: '',
		emp_status: '',
		trader_name: '',
		class_code: '',
		sub_agent_status: '',
		other_type: [],
		payee_banks: []
	},
	payeeDetails: {},
	dialog: false,
	editedIndex: -1,
	valid: true,
	formTitle: 'Edit entry',
	payeeBank: {
		payee_account_id: '',
		payee_code: '',
		bank_id: '',
		bank_branch: '',
		account_number: '',
		currency: ''
	},
	currency: [
		{ value: 1, text: 'Peso' },
		{ value: 2, text: 'Dollar' },
	],
	payeeBankDialog: false,
};


