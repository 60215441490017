<template>
  <v-card>
    <v-card-title
      class="headline primary dark white--text"
      primary-title
    >
      Batch No. {{batchDetails.batch_number}} details
      <v-spacer/>
      <v-btn icon dark @click="closeDialog(batchDetails.batch_number)">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text v-if="batchDetails">
      <v-layout>
        <v-flex xs12 sm12 md4>
			<br>
            <h2>Transaction Info:</h2>
            <br>
            <h3>Batch No: {{batchDetails.batch_number}}</h3>
            <h3>Document Type: {{getDocuType(batchDetails.document_type)}}</h3>
			<br>
        </v-flex>
      </v-layout>
      <v-layout>
        <v-flex xs12 sm12 md4>
        </v-flex>
      </v-layout>
      <h2>Accounting Entries</h2>
      <v-container class="doc-items-container" grid-list-md>
        <p class="caption"></p>
        <v-row justify="center" v-if="trxintegratedDetails.length > 0">
          <v-container outlined>
            <v-card outlined
              v-for="(doc_detail) in trxintegratedDetails" :key="doc_detail.key"
            >
              <template>
                <v-card-title dark class="document-card-title grey lighten-2">
                  Document No. {{doc_detail.document_number}}
                </v-card-title>
              </template>
              <v-container>
                <v-container grid-list-md>
				<v-layout>
				</v-layout>
                  <v-layout wrap>
                    <v-flex xs12 sm6 md6>
                      <h4>Document Number: {{doc_detail.document_number}}</h4>
                      <h4>Cheque Number: {{doc_detail.cheque_number}}</h4>
                      <h4>Payee: {{ getValue(payees, "id", doc_detail.payee_id, "payee_desc") }}</h4>
                      <h4>Document Date: {{ doc_detail.document_date }}</h4>
                    </v-flex>
                    <v-flex xs12 sm6 md6>
                      <h4>ATC Code: {{ getValue(dataAtc, "atc_code", doc_detail.atc_code, "atc_desc") }}</h4>
                      <h4>ATC Rate: {{ getValue(dataAtc, "atc_code", doc_detail.atc_code, "atc_rate") }}</h4>
                    </v-flex>
                    
                    <v-flex>
                      <h4>Remarks: {{doc_detail.remarks}}</h4>
                    </v-flex>
                  </v-layout>
                </v-container>
                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Analysis</th>
                        <th>Department</th>
                        <th>Branch</th>
                        <th>Section</th>
                        <th>Chart of Accounts</th>
                        <th>Debit</th>
                        <th>Credit</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(detail, detail_key) in doc_detail.details"
                        :key="detail.detail_key"
                      >
                        <td>{{ detail_key + 1 }}</td>
                        <td>
                          {{ getValue(payees, "id", detail.payee_id, "payee_desc") }}
                        </td>
                        <td>
                          {{ getValue(dataDepts, "id", detail.department_id, "dept_code") }}
                        </td>
                        <td>
                          {{ getValue(branches, "id", detail.branch_id, "brnc_code") }}
                        </td>
                        <td>
                          {{ getValue(dataSections, "id", detail.section_id, "section_code") }}
                        </td>
                        <td>
                          {{ getValue(coaData, "acct_code", detail.account_code, "acct_desc") }}
                        </td>
                        <td class="text-right">
                          {{formatAmount(detail.debit_amount)}}
                        </td>
                        <td class="text-right">
                          {{formatAmount(detail.credit_amount)}}
                        </td>
                      </tr>
                      <tr>
                        <td colspan="4">
                          <b>Total</b>
                        </td>
                        <td>&nbsp;</td>
                        <td class="text-right">
                        </td>
                        <td class="text-right">
                          <b>{{formatAmount(doc_detail.overallDebit)}}</b>
                        </td>
                        <td class="text-right">
                          <b>{{formatAmount(doc_detail.overallCredit)}}</b>
                        </td>
                        <td>&nbsp;</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-container>
            </v-card>
          </v-container>
        </v-row>
      </v-container>
      <br/>
    </v-card-text>
    <v-divider></v-divider>
	<v-card-actions>
      <v-spacer></v-spacer>
      <v-btn v-if="role_access > 3"
        color="error"
        dark
        @click="deleteDialog = true"
      >
      Delete Transaction
      </v-btn>
    </v-card-actions>
	<div class="text-center">
		<v-dialog
			v-model="deleteDialog"
			persistent max-width="600"
		>
			<v-card>
				<v-card-title
					class="headline primary white--text"
					primary-title
				>
					Delete Transaction
				</v-card-title>
				<br>
				<v-card-text>
					Are you sure you want to delete <b>{{batchDetails.batch_number}}</b> ?
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="primary"
						text
						@click="deleteIntegratedTransaction(batchDetails.batch_number, batchDetails.status)"
					>
						Yes
					</v-btn>
					<v-btn
						color="error"
						text
						@click="deleteDialog = false"
					>
						No
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex';
import numeral from 'numeral';
export default {
	props: {
		batchDetails: Array,
		role_access: Number
	},
	data() {
		return {
			dataAtc: [],
			dataDepts: [],
			dataSections: [],
			nextPageSections: '',
			nextPageAtc: '',
			nextPageDepts: '',
			deleteDialog: false
		};
	},
	computed: {
		...mapGetters({
			depts: 'dept/depts',
			branches: 'branch/branches',
			sections: 'section/sections',
			payees: 'payefile/payefiles',
			alphataxcodes: 'alphataxcode/alphataxcodes',
			currUser: 'auth/currUser',
			viewDialog: 'trxintegrated/viewDialog',
			trxintegratedDetails: 'trxintegrated/trxintegratedDetails',
			coaData: 'coa/coaData',
		})
	},
	watch: {
		batchDetails: {
			handler(val) {
				this.$emit('update:batchDetails', val);
			},
			deep: true
		},
		depts: {
			handler() {
				this.getDataDepts();
			}
		},
		alphataxcodes: {
			handler() {
				this.getDataAlphataxcodes();
			}
		},
		sections: {
			handler() {
				this.getDataSections();
			}
		},
	},
	mounted() {
		this.$store.dispatch('dept/getDepts');
		this.$store.dispatch('branch/getBranches');
		this.$store.dispatch('section/getSections');
		this.$store.dispatch('payefile/getPayefiles');
		this.$store.dispatch('alphataxcode/getAlphataxcodes');
	},
	methods: {
		closeDialog() {
			this.$store.dispatch('trxintegrated/setViewDialog', false);
		},
		formatAmount(amount){
			return numeral(amount).format('0,0.00');
		},
		getValue(tableData, columnId, id, column){
			for (var i=0; i < tableData.length; i++) {
				if (tableData[i][columnId] === id) {
					if (columnId === 'acct_code'){
						return tableData[i][columnId] + ' - ' + tableData[i][column];
					} else {
						return tableData[i][column];
					}
				}
			}
			
		},
		getDocuType(type){
			switch(type){
			case 'DM':
				return 'Debit Memo';
			case 'CV':
				return 'Cheque Voucher';
			case 'CW':
				return 'Cheque Write';
			case 'OB':
				return 'Online Banking';
			}
		},
		getLabel(status){
			if(status == 6){
				return 'Release';
			} else {
				return 'Approve';
			}
		},
		async getDataAlphataxcodes(){
			this.dataAtc = this.alphataxcodes.data;
			this.nextPageAtc= this.alphataxcodes.next_page_url;
			let page = 2;

			while (this.nextPageAtc !== null){
				await this.$store.dispatch('alphataxcode/getData', page).then(response => {
					this.nextPageAtc = response.data.data.next_page_url;
					response.data.data.data.forEach(details => {
						this.dataAtc.push(details);
					});
				});
				page++;
			}
		},
		async getDataDepts(){
			this.dataDepts = this.depts.data;
			this.nextPageDepts= this.depts.next_page_url;
			let page = 2;

			while (this.nextPageDepts !== null){
				await this.$store.dispatch('dept/getData', page).then(response => {
					this.nextPageDepts = response.data.data.next_page_url;
					response.data.data.data.forEach(details => {
						this.dataDepts.push(details);
					});
				});
				page++;
			}
		},
		async getDataSections(){
			this.dataSections = this.sections.data;
			this.nextPageSections= this.sections.next_page_url;
			let page = 2;

			while (this.nextPageSections !== null){
				await this.$store.dispatch('section/getData', page).then(response => {
					this.nextPageSections = response.data.data.next_page_url;
					response.data.data.data.forEach(details => {
						this.dataSections.push(details);
					});
				});
				page++;
			}
		},
		deleteIntegratedTransaction(batch_number, status){
			let data = {
				'batch_number': batch_number,
				'deleted_by': this.currUser.id,
				'status' : status

			};
			this.$store.dispatch('trxintegrated/deleteIntegratedTransaction', data);
			this.deleteDialog = false;
		}
	}
};
</script>
