export default {
	collections: [],
	collectionDetails: [],
	collectionPrintDetails: [],
	transactionResults: [],
	birFormDetails: [],
	csvFileUploads: [],
	headersCollection: [
		{
			text: 'Collection Number',
			align: 'left',
			sortable: false,
			value: 'collection_number',
		},
		{
			text: 'Invoice Number',
			align: 'left',
			sortable: false,
			value: 'billing_invoice_no',
		},
		{ text: 'Customer Name', value: 'customer_name' },
		{ text: 'Status', value: 'status' },
		{ text: 'Billing Amount', value: 'billing_amount', align: 'right' },
		{ text: 'Collected Amount', value: 'collected_amount', align: 'right' },
		{ text: 'Actions', value: 'action', sortable: false },
	],
	documentType: [
		{ value: 'AE', text: 'Adjusting Entry' },
		{ value: 'CD', text: 'Check Disbursement' },
		{ value: 'CR', text: 'Cash Receipts' },
		{ value: 'JV', text: 'Journal Voucher' },
	],
	dialog: false,
	editDialog: false,
	viewDialog: false,
	attachmentDialog: false,
	editedIndex: -1,
	valid: true,
	formTitle: 'Edit entry',
	preparer: '',
	dist_data: '',
	collectionDetailed: '',
	success: false,
	printDetails: [],
	collectionNumber: ''
};