<template>
	<v-container>
		<v-card>
			<v-card-title align="left" class="headline primary disbursement-title">
				<h5>Archived Entries</h5>
			</v-card-title>
			<!-- <v-tabs background-color="primary accent-4" show-arrows center-active dark centered>	  -->
				<!-- <v-tab @click="trxnStatus = 0; trxnStatusName = 'For Disbursement'">For Disbursement</v-tab> -->
				<!-- <v-tab @click="trxnStatus = 1; trxnStatusName = 'Drafting'">Drafting</v-tab>
				<v-tab @click="trxnStatus = 2; trxnStatusName = 'Reg (Approval)'">Reg (Approval)</v-tab>
				<v-tab @click="trxnStatus = 3; trxnStatusName = 'Approval 1'">Approval 1</v-tab>
				<v-tab @click="trxnStatus = 4; trxnStatusName = 'Approval 2'">Approval 2</v-tab>
				<v-tab @click="trxnStatus = 5; trxnStatusName = 'Approval 3'">Approval 3</v-tab>
				<v-tab @click="trxnStatus = 6; trxnStatusName = 'For Releasing'">For Releasing</v-tab>
				<v-tab @click="trxnStatus = 7; trxnStatusName = 'Released'">Released</v-tab> -->
				<!-- <v-tab @click="trxnStatus = 8; trxnStatusName = 'Confidential'">Confidential</v-tab> -->
			<!-- </v-tabs> -->
			<v-data-table
				:headers="headers"
				:items="filteredItems"
				sort-by="document_number"
				class="elevation-1"
				:search="search"
			>
				<template v-slot:top>
					<v-toolbar flat color="white">
						<v-toolbar-title>{{trxnStatusName}}</v-toolbar-title>
						<v-spacer></v-spacer>
						<v-layout wrap>
						<v-flex md3 />
						<v-spacer/>
						<v-flex xs12 sm6 md3>
							<v-text-field append-icon="mdi-calendar" label="Month and Year" required v-model="month_year" @click="month_year_picker = true" hide-details />
							<v-dialog width="25%" v-if="month_year_picker" v-model="month_year_picker"> 
								<v-date-picker
									v-model="month_year"
									type="month"
									@change="month_year_picker = false; getData()"
								></v-date-picker>
							</v-dialog>
						</v-flex>
						<v-spacer/>
						<v-flex xs12 sm6 md5>
							<v-text-field
								v-model="search"
								append-icon="search"
								label="Search"
								single-line
								hide-details
							></v-text-field>
						</v-flex>
						</v-layout>
					</v-toolbar>
				</template>
				<template v-slot:item.total_amount="{ item }">
					<span>{{formatAmount(item.total_amount)}}</span>
				</template>
				<template v-slot:item.action="{ item }">
					<v-icon color="primary" v-if="trxnStatus !== 7 && trxnStatus !== 1 && role_access > 2" class="mr-2" small @click="restoreItemSelect(item)"
						>mdi-restore</v-icon
					>
					<v-icon
						color="grey"
						class="mr-2"
						small
						v-else
					>
						mdi-pencil-off
					</v-icon>
					<!-- <v-icon small color="red" @click="deleteItem(item)">delete</v-icon> -->
				</template>
			</v-data-table>
			<div class="text-center">
				<v-dialog
					v-model="restoreDisbursement"
					persistent max-width="600"
				>
					<v-card>
						<v-card-title
							class="headline primary white--text"
							primary-title
						>
							Restore Transaction
						</v-card-title>
						<br>
						<v-card-text>
							Are you sure you want to restore this transaction?
						</v-card-text>

						<v-divider></v-divider>

						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn
								color="primary"
								text
								@click="restore(selectedBatch, selectedDocType,selectedDate)"
							>
								Yes
							</v-btn>
							<v-btn
								color="red"
								text
								@click="closeDialog(selectedBatch, selectedDocType)"
							>
								No
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
				<v-dialog
					v-model="ChangeCV"
					persistent max-width="600"
				>
					<v-card>
						<v-card-title
							class="headline primary white--text"
							primary-title
						>
							Restore Disbursement
						</v-card-title>
						<br>
						<v-card-text>
							Do you want to restore this disbursement with new Voucher Number?
						</v-card-text>

						<v-divider></v-divider>

						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn
								color="primary"
								text
								@click="restoreNewCV(selectedBatch, selectedDocType,selectedDate)"
							>
								Yes
							</v-btn>
							<v-btn
								color="red"
								text
								@click="restoreOldCV(selectedBatch, selectedDocType,selectedDate)"
							>
								No
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</div>
			
			<v-snackbar
				v-model="snackbar"
				right
				>
				{{ error_message }}
				<v-btn
					color="pink"
					text
					@click="snackbar = false"
				>
					Close
				</v-btn>
			</v-snackbar>
		</v-card>
	</v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { format, getYear, getMonth, parseISO } from 'date-fns';
// import io from 'socket.io-client'; 
import numeral from 'numeral';
import jsonToFormData from 'json-form-data';

export default {
	data() {
		return {
			module_name: 'Archived Transactions',
			role_access: 1,
			ChangeCV: false,
			trxnStatus:20,
			trxnStatusName: 'List',
			search: '',
			month_year: '',
			month_year_picker: false,
			isApprover: false,
			restoreDisbursement: false,
			isSamePreparer: false,
			selectedBatch: '',
			selectedDocType: '',
			selectedDate: '',
			batchDetails: [],
			snackbar: false,
			error_message: '',
			dataAcctmainperiod: [],
			nextPageAcctmainperiod:'',
			documentDetails:[],
			fileDetails: [],
			data: [],
			nextPage: '',
			nextPageTrx: '',
			detailHeaders:[
				{ text: 'Analysis', value: 'payee_id' },
				{ text: 'Department', value: 'department_id' },
				{ text: 'Branch', value: 'branch_id' },
				{ text: 'Section', value: 'section_id' },
				{ text: 'Chart of Accounts', value: 'account_code' },
				{ text: 'Debit', value: 'debit_amount' },
				{ text: 'Credit', value: 'credit_amount' },
			],
			months: [
				{name: 'January', value: 1},
				{name: 'February', value: 2},
				{name: 'March', value: 3},
				{name: 'April', value: 4},
				{name: 'May', value: 5},
				{name: 'June', value: 6},
				{name: 'July', value: 7},
				{name: 'August', value: 8},
				{name: 'September', value: 9},
				{name: 'October', value: 10},
				{name: 'November', value: 11},
				{name: 'December', value: 12},
			],
			selectedYear: null,
			selectedMonth: null,
			batch_number_rules: [
				v => !!v || 'Batch Number is required',
			],
			date_rules: [
				v => !!v || 'Date is required',
			],
			payee_rules: [
				v => !!v || 'Payee is required',
			],
			bank_rules: [
				v => !!v || 'Bank is required',
			]
		};
	},
	computed: {
		...mapGetters({
			trxdisbs: 'trxdisb/trxdisbs',
			trxdisbDetails: 'trxdisb/trxdisbDetails',
			headers: 'trxdisb/headers',
			valid: 'trxdisb/valid',
			currUser: 'auth/currUser',
			approvingofficers: 'approvingofficer/approvingofficers',
			editedIndex: 'trxdisb/editedIndex',
			acctperiodmains: 'acctperiodmain/acctperiodmains',
		}),
		years() {
			const year = new Date().getFullYear();
			const generateYears = Array.from({ length: year - 1900 },(value, index) => 1901 + index);
			generateYears.sort();
			return generateYears.reverse();
		},
		filteredItems() {
			let app = this;
			let txn = this.trxdisbs;
			let txnFiltered = [];
			let listArchived = [];

			txn = Object.values(txn);

			txn.filter(transaction => {
				if (this.isApprover === false) {
					return transaction.is_confidential !== 1;
				}
			});

			if (txn !== undefined) {
				txn.forEach(element => {

					if (element !== null) {
						if(element.processing < 1){
							switch(element.status)
							{
							case 20:
								listArchived.push(element);
								break;
							}
						}
					}
					

					switch(app.trxnStatus)
					{
					case 20:
						txnFiltered = listArchived;
						break;
					} 
				});	
			}
			txnFiltered.forEach(i => {
				i.date_encoded = format(parseISO(i.date_encoded), 'yyyy-MM-dd');
				if (i.document_date !== undefined) {
					var count = i.document_date.split('-').length;
					if(count > 3){
						i.date = 'Have multiple dates';
					} else {
						i.date = i.document_date.replace(/,/g,'');
					}
				}
			});
			
			return txnFiltered.filter((i) => {				
				if (!this.selectedMonth && this.selectedYear) {
					return format(new Date(i.document_date), 'yyyy') === this.selectedYear.toString();
				} else if (!this.selectedYear && this.selectedMonth) {
					return format(new Date(i.document_date), 'M') === this.selectedMonth.toString();
				} else if (this.selectedYear && this.selectedMonth) {
					return format(new Date(i.document_date), 'yyyy') === this.selectedYear.toString() 
					&& format(new Date(i.document_date),'M') === this.selectedMonth.toString();
				} else {
					return !this.selectedYear || !this.selectedMonth;
				}
			});
		}
	},
	watch: {
		trxdisbs: {
			handler() {
			},
			deep: true
		},
		editedIndex: {
			handler(val) {
				this.$emit('update:editedIndex', val);
			}
		},
		approvingofficers: {
			handler(val) {
				let data = val.data;
				let nextPageAppOfficer= val.next_page_url;
				let page = 2;

				while (nextPageAppOfficer !== null){
					this.$store.dispatch('approvingofficer/getData', page).then(response => {
						nextPageAppOfficer = response.data.data.next_page_url;
						response.data.data.data.forEach(details => {
							data.push(details);
						});
					});
					page++;
				}

				const checkApprover = data.filter((officer) => {
					return officer.user_id === this.currUser.id;
				});

				if (checkApprover.length > 0) {
					this.isApprover = true;
				} else {
					this.isApprover = false;
				}
			}
		},
		acctperiodmains: {
			handler() {
				this.getAcctperiodmain();
			}
		},
		trxdisbDetails: {
			handler(val) {
				val.forEach(header => {
					if (header.edit_by === this.currUser.id) {
						this.$data.isSamePreparer = true;
					} else {
						this.$data.isSamePreparer = false;
					}

					if (this.isSamePreparer === true) {
						this.restoreDisbursement = true;
					} else {
						this.$store.commit('app/SET_SNACK_BAR', { 
							'status': true, 
							'message': 'The Current User is not the Preparer.', 
							'messagetype': 'danger' 
						}, { 
							root: true 
						});
					}
				});
				
			}
		}
	},
	mounted() {
		const that = this;
		let date = format(new Date(), 'yyyy-MM-dd');
		let month = getMonth(parseISO(date)) + 1;
		let year = getYear(parseISO(date));
		let i = (month.toString().length == 2 ? '-' : '-0');
		that.month_year = year + i + month;
		this.$store.dispatch('trxdisb/getTrxdisbs', {document_date: that.month_year + '-01'});
		this.$store.dispatch('approvingofficer/getApprovingOfficers');
		this.$store.dispatch('acctperiodmain/getAcctperiodmains');

		if (this.$route.params.batch_number) {
			let currentItem = this.trxdisbs.filter(item => item.batch_number === this.$route.params.batch_number);
			
			//console.log(currentItem[0], this.$route.params.batch_number);

			if (this.$route.params.action === 'restore') {
				this.restoreItemSelect(currentItem[0]);
			}
			
			setTimeout(function() {
				that.$route.params.batch_number = '';
			}, 2000);
		}
		
		let roles = this.currUser.roleaccess;
		if(roles.length > 0){
			let roleaccess = roles.filter(r => r.module_name === this.module_name);
			if(roleaccess.length > 0){
				this.role_access = roleaccess[0].access_level;
			}
		}
		// this.socket = io(process.env.VUE_APP_SOCKET_URL); 
	},
	methods: {
		restoreItemSelect(item) {
			let disbList = this.trxdisbs;
			disbList = Object.values(this.trxdisbs);

			this.$store.dispatch('trxdisb/setEditedIndex', disbList.indexOf(item));
			this.$store.dispatch('trxdisb/getArchivedTrxdisb', item.batch_number);
			
			this.$data.selectedBatch = item.batch_number;
			this.$data.selectedDocType = item.document_type;
			this.$data.selectedDate = item.document_date;
			// this.updateSocket(item);
		},
		restore(batch_number, document_type, date) {
			let details = this.trxdisbDetails;
			let data = {};

			if(details[0].document_type == 'CV'){
				this.ChangeCV = true;
			}else{
				let openBook = this.getAcctgPeriod(date, document_type);
				if(openBook){
					data.batch_number = batch_number;
					data.value = {
						status: 1,
						processing: 0,
						approved_remarks: '',
						document_type: document_type,
						_method: 'PUT'
						// headers: headers,
						// details: dataDetail,
					};
					
					var options = {
						initialFormData: new FormData(),
						showLeafArrayIndexes: true,
						includeNullValues: false,
						mapping: function(value) {
							if (typeof value === 'boolean') {
								return +value ? '1': '0';
							}
							return value;
						}
					};
					let convertedFormData = jsonToFormData(data.value, options);
					this.$store.dispatch('trxdisb/updateTrxdisbStatus', {batch_number: data.batch_number, value: convertedFormData});
				}
			}
			this.restoreDisbursement = false;
		},
		async restoreNewCV(batch_number, selectedDocType, date){
			this.$store.commit('app/SET_IS_LOADING', true, { root: true });
			let data = {};
			let headers = [];
			let dataDetail = [];
			let documentNumber = [];
			let details = this.trxdisbDetails;
			let dateFormat = format(new Date(), 'yyyy-MM-d');

			await this.$store.dispatch('trxdisb/getDocumentNumberCheque').then(response => {
				documentNumber = response.data.data;
			}, error => {
				//console.error(error);
				return error;
			});
			if(documentNumber.length != 0){
				let openBook = this.getAcctgPeriod(date, selectedDocType);
				if(openBook) {
					details.forEach(detail => {
					//detail.batch_number = batchNumber;
						detail.status = 1;
						detail.processing = 0;
						detail.document_date = dateFormat;
						detail.edit_by = this.currUser.id;
						detail.ar_number = 0;
						detail.or_number = 0;
						detail.document_number = documentNumber[0].voucher_number;
						documentNumber.shift();
						
						detail.details.forEach(det => {
							det.document_number = detail.document_number;
							// det.batch_number = detail.batch_number;
							dataDetail.push(det);
						});
						delete detail.details;
						delete detail.edit_date;
						headers.push(detail);
					});

					data.batch_number = batch_number;
					data.value = {
					// status: 1,
					// processing: 0,
						headers: headers,
						details: dataDetail,
						document_type: selectedDocType,
						_method: 'PUT'
					};
					var options = {
						initialFormData: new FormData(),
						showLeafArrayIndexes: true,
						includeNullValues: false,
						mapping: function(value) {
							if (typeof value === 'boolean') {
								return +value ? '1': '0';
							}
							return value;
						}
					};
					let convertedFormData = jsonToFormData(data.value, options);
					this.$store.dispatch('trxdisb/updateTrxdisb', {batch_number: data.batch_number, value: convertedFormData});
				}
				//this.$store.dispatch('trxdisb/removeStorage', {distData: distData, type: 'CV'});
				//this.$store.dispatch('trxdisb/updateTrxdisbStatus', data);
			} else {
				this.$store.commit('app/SET_SNACK_BAR', { 
					'status': true, 
					'message': 'Please create Voucher Series first.', 
					'messagetype': 'danger' 
				}, { 
					root: true 
				});
			}
			this.ChangeCV = false;
			this.$store.commit('app/SET_IS_LOADING', false, { root: true });
		},
		restoreOldCV(batch_number, selectedDocType, date) {
			let openBook = this.getAcctgPeriod(date, selectedDocType);
			if(openBook) {
				let data = {};
				data.batch_number = batch_number;
				data.value = {
					status: 1,
					processing: 0,
					document_type: selectedDocType,
					// headers: headers,
					// details: dataDetail,
					_method: 'PUT'
				};
				var options = {
					initialFormData: new FormData(),
					showLeafArrayIndexes: true,
					includeNullValues: false,
					mapping: function(value) {
						if (typeof value === 'boolean') {
							return +value ? '1': '0';
						}
						return value;
					}
				};
				let convertedFormData = jsonToFormData(data.value, options);
				this.$store.dispatch('trxdisb/updateTrxdisbStatus', {batch_number: data.batch_number, value: convertedFormData});
				this.ChangeCV = false;
			}
		},
		closeDialog(batch_number, doc_type) {
			let data = {};
			data.batch_number = batch_number;
			data.value = {
				status: 20,
				processing: 0,
				document_type: doc_type,
				_method: 'PUT'
			};

			var options = {
				initialFormData: new FormData(),
				showLeafArrayIndexes: true,
				includeNullValues: false,
				mapping: function(value) {
					if (typeof value === 'boolean') {
						return +value ? '1': '0';
					}
					return value;
				}
			};
			let convertedFormData = jsonToFormData(data.value, options);
			this.$store.dispatch('trxdisb/updateTrxdisbStatus', {batch_number: data.batch_number, value: convertedFormData});
			//this.$store.dispatch('trxdisb/updateTrxdisbStatus', data);
			this.restoreDisbursement = false;
			this.ChangeCV = false;
			this.$data.isSamePreparer = false;
		},
		formatAmount(amount){
			return numeral(amount).format('0,0.00');
		},
		updateSocket(item) {
			/* 
			to use the lock mechanism
			set the processing propery of the editedItem to 1
			when the value is 1, the current user can modify the details, without interfering of others.
			EXAMPLE: app.trxdisbs[app.editedIndex].processing = 1;

			to display back the item to the data table
			set the processing property to 0;
			EXAMPLE: app.trxdisbs[app.editedIndex].processing = 0;

			to transfer the data to the other tab
			change the value property depends on the destination of the tab

			0 - For disbursement
			1 - Drafting
			2 - Reg Approval
			3 - Approval 1
			4 - Approval 2
			5 - Approval 3
			6 - For Release
			7 - Released
			8 - Confidential
			

			*/
			// /* EXAMPLE */
			// this.trxdisbs[this.editedIndex].processing = 1;			
			// this.socket.emit('updatedTrxDisb',this.trxdisbs); 

			/* update the data in the API  */
			let data = {};
			data.batch_number = item.batch_number;
			data.value = {
				processing : 1,
				document_type : item.document_type,
				status : item.status,
				_method: 'PUT'
			};

			var options = {
				initialFormData: new FormData(),
				showLeafArrayIndexes: true,
				includeNullValues: false,
				mapping: function(value) {
					if (typeof value === 'boolean') {
						return +value ? '1': '0';
					}
					return value;
				}
			};

			//this.$store.dispatch('trxdisb/updateTrxdisbStatus', data);  
			let convertedFormData = jsonToFormData(data.value, options);
			this.$store.dispatch('trxdisb/updateTrxdisbStatus', {batch_number: data.batch_number, value: convertedFormData});
		},
		getData(){
			this.$store.dispatch('trxdisb/getTrxdisbs', {document_date: this.month_year + '-01'});
		},
		getAcctgPeriod(doc_date, document_type){
			let openBook = true;
			const dates = doc_date.split(', ');
			const date = dates[0];
			const doc_type = this.getDocType(document_type);
			if (date) {
				let month = getMonth(parseISO(date));
				let year = getYear(parseISO(date));
				this.dataAcctmainperiod.forEach(account_period => {
					if (account_period.period_no === (month + 1) && account_period.fiscal_year === year) {
						if (account_period[doc_type] === 'CLOSED') {
							this.snackbar = true;
							this.$data.error_message = 'Accounting Period for this date is already Closed.';
							openBook = false;
						}
					}
				});
			}
			return openBook;
		},
		getDocType(type){
			switch (type) {
			case 'JE':
				return 'general_journal';
			case 'CR':
				return 'cash_receipt';
			default: 
				return 'disbursement';
			}
		},
		async getAcctperiodmain(){
			this.dataAcctmainperiod = this.acctperiodmains.data;
			this.nextPageAcctmainperiod= this.acctperiodmains.next_page_url;
			let page = 2;

			while (this.nextPageAcctmainperiod !== null){
				await this.$store.dispatch('acctperiodmain/getData', page).then(response => {
					this.nextPageAcctmainperiod = response.data.data.next_page_url;
					response.data.data.data.forEach(details => {
						this.dataAcctmainperiod.push(details);
					});
				});
				page++;
			}
		},
	},
};



</script>

<style scoped>
	.disbursement-title h5 {
		color: #FFF;
		font-size: 25px;
	}
	
	.date-filter {
		display: flex;
		flex-direction: row;
		position: relative;
		top: 10.5px; 
		right: 10px;
	}
	.date-filter-picker {
		width: 150px;
	}
	.date-filter-picker:first-child {
		margin-right: 10px;
	}

	.document-card-title {
		padding: 0;
	}
</style>
