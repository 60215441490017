import Repository from '../Repository';

const resource = '/trx/voucher-alloc';

export default {
	get() {
		return Repository.get(`${resource}`);
	},

	getVoucherSeries(id) {
		return Repository.get(`${resource}/${id}`);
	},

	saveVoucherSeries(payload) {
		return Repository.post(`${resource}`, payload);
	},

	updateVoucherSeries(id, payload) {
		return Repository.put(`${resource}/${id}`, payload);
	},

	deleteVoucherSeries(id) {
		return Repository.delete(`${resource}/${id}`);
	},
	searchVoucherSeries(payload) {
		return Repository.get(`${resource}`, payload);
	},
	
	getData(payload){
		return Repository.get(`${resource}?page=`+ payload);
	} 
};