import Repository from '../Repository';
import axios from 'axios';

const resource = '/login';

export default {
	login(payload) {
		return Repository.post(`${resource}`, payload);
	},

	verifyToken(payload) {
		return Repository.post(`${resource}`, payload);
	},

	get_current_user(){
		return Repository.post('getuser');
	},

	logout(){
		return Repository.post('logout');
	},

	loginHRIS(payload) {
		return axios.post(process.env.VUE_APP_HRIS_BASE_URL + '/auth/login', payload);
	},
	
	loginOkta() {
		return Repository.get('/okta-login');
		// return axios.get('https://erpapi.dev.perahub.com.ph/erp/okta-login');
	},
	loginEmail() {
		return Repository.get('/google-login');
	} 
};