// eslint-disable-next-line
import Vue from 'vue';
import { RepositoryFactory } from '../../../api_repo/RepositoryFactory';

const businesspartnersRepository = RepositoryFactory.get('businesspartner');

const getBusinesspartners = (context) => {
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		businesspartnersRepository.get()
			.then((response) => {
				context.commit('BUSINESSPARTNERS_UPDATED', response.data.data);
				context.commit('app/SET_IS_LOADING',false,{ root: true });
			})
			.catch(() => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
			});

	}, 1500);
};

const saveBusinesspartner = (context,payload) => {
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		businesspartnersRepository.saveBusinesspartner(payload)
			.then((response) => {
				context.commit('BUSINESSPARTNERS_ADD',response.data.data[0]); 
				context.commit('SET_DIALOG', false);   
				context.commit('app/SET_IS_LOADING',false,{ root: true });  
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ' + response.data.data[0].bp_code + '(' + response.data.data[0].name + ') added.','messagetype':'success'},{ root: true });
			})
			.catch((error) => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Saving failed! ' + error.response.data.message,'messagetype':'error'},{ root: true });
				var newErrorMessage = '';
				if (error.response.data.errors) {
					newErrorMessage = getError(error.response.data.errors);
				} else {
					newErrorMessage = error.response.data.message;
				}
				Vue.swal
					.fire({
						type: 'error',
						title: 'Saving failed!!',
						html: newErrorMessage
					}); 
			});
	}, 1500);
};

const updateBusinesspartner = (context,payload) => {
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		businesspartnersRepository.updateBusinesspartner(payload.id,payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('SET_DIALOG', false);
				context.commit('BUSINESSPARTNERS_ITEM_UPDATE',response.data.data[0]);
				context.commit('payefile/SET_PAYEEBANK_DIALOG', false, {root: true});
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ' + response.data.data[0].bp_code + '(' + response.data.data[0].name + ') updated.','messagetype':'success'},{ root: true });
			})
			.catch((error) => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				payload.updated.map(details =>{
					context.commit('SET_BUSIPART_PAYEEBANKS',details);
				});
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Update failed! ' + error.response.data.message,'messagetype':'error'},{ root: true });
				var newErrorMessage = '';
				if (error.response.data.errors) {
					newErrorMessage = getError(error.response.data.errors);
				} else {
					newErrorMessage = error.response.data.message;
				}
				Vue.swal
					.fire({
						type: 'error',
						title: 'Saving failed!!',
						html: newErrorMessage
					}); 
			});
	}, 1500);
};

const deleteBusinesspartner = (context,payload) => {  
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		businesspartnersRepository.deleteBusinesspartner(payload.id)
			.then((response) => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('BUSINESSPARTNERS_DELETE',response.data.data);
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ' + response.data.data.bp_code + '(' + response.data.data.name + ') deleted.','messagetype':'success'},{ root: true });
			})
			.catch((error) => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Delete failed! ' + error.response.data.message,'messagetype':'error'},{ root: true });
			});
	}, 1500);
};

const getDetail = (context, payload) => {
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		businesspartnersRepository.getBusinesspartner(payload)
			.then((response) => {
				context.commit('SET_EDITED_ITEM',response.data.data[0]);
				context.commit('app/SET_IS_LOADING',false,{ root: true });
			})
			.catch(() => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
			});
	});
};

const getDataPages = (context,payload) => {
	context.commit('app/SET_IS_LOADING', true , { root: true });
	setTimeout(() => {
		businesspartnersRepository.getData(payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING', false , { root: true });
				context.commit('BUSINESSPARTNERS_UPDATED', response.data.data);
			})
			// eslint-disable-next-line
			.catch(() => {
				context.commit('app/SET_IS_LOADING', false , { root: true });
			});
	}, 1500);
};

const getData = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true , { root: true });
	return new Promise((resolve, reject) => {
		businesspartnersRepository.getData(payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING', false , { root: true });
				resolve(response);
			}, error => {
				context.commit('app/SET_IS_LOADING', false , { root: true });
				reject(error);
			});
	});
};

const getError = (payload) => {
	var myObject = payload;
	var newerror = [];
	for (var name in myObject) {
		var replacement = name.split('.')[2];
		if(replacement == undefined){
			replacement = name;
		}
		var errors = payload[name][0];
		var errorDesc = errors.replace(name, replacement);
		var replacedash = errorDesc.replace('_', ' ');
		newerror.push(replacedash);
	}
	newerror.sort();
	var current = null;
	var cnt = 0;
	for (var i = 0; i < newerror.length; i++) {
		var errormessage;
		if (newerror[i] != current) {
			if (cnt > 0) {
				errormessage +=current + '<br>';
			}
			current = newerror[i];
			cnt = 1;
		} else {
			cnt++;
		}
	}
	if (cnt > 0) {
		errormessage +=
						current + '<br>';
	}
	return errormessage.replace('undefined', '');
};

const setEditedItems = (context,payload) => {
	context.commit('SET_EDITED_ITEM',payload);
};

const setDialog = (context,payload) => {
	context.commit('SET_DIALOG',payload);
};

const setEditedIndex = (context,payload) => {
	context.commit('SET_EDITED_INDEX',payload);
};

const setformTitle = (context,payload) => {
	context.commit('SET_FORM_TITLE',payload);
};

const setValid = (context,payload) => {
	context.commit('SET_VALID',payload);
};

export default {
	getBusinesspartners,
	saveBusinesspartner,
	updateBusinesspartner,
	deleteBusinesspartner,
	setEditedItems,
	setDialog,
	setEditedIndex,
	setformTitle,
	setValid,
	getError,
	getDetail,
	getDataPages,
	getData
};