import Vue from 'vue';

const DOLLARRATES_UPDATED = (state, dollarrates) => {
	state.dollarrates = dollarrates;
};

const DOLLARRATES_ADD = (state, payload) => {
	state.dollarrates.data.push(payload);
}; 

const DOLLARRATES_DELETE = (state, payload) => {
	let index = state.dollarrates.data.indexOf(payload);
	state.dollarrates.data.splice(index,1);
};

const DOLLARRATES_ITEM_UPDATE = (state, payload) => {
	Vue.set(state.dollarrates.data, state.editedIndex, payload);
};

const SET_EDITED_ITEM = (state,payload) =>{
	state.editedItem = payload;
};

const SET_DIALOG = (state,payload) =>{
	state.dialog = payload;
};

const SET_EDITED_INDEX = (state,payload) =>{
	state.editedIndex = payload;
};


const SET_VALID = (state,payload) => {
	state.valid = payload;
};

const SET_FORM_TITLE = (state,payload) => {
	state.formTitle = payload;
};

export default {
	DOLLARRATES_UPDATED,
	SET_EDITED_ITEM,
	SET_DIALOG,
	SET_EDITED_INDEX,
	DOLLARRATES_ADD,
	DOLLARRATES_DELETE,
	SET_VALID,
	SET_FORM_TITLE,
	DOLLARRATES_ITEM_UPDATE
};