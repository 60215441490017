import CoaRepository from './modules/coaRepository';
import DeptRepository from './modules/deptRepository';
import UserRepository from './modules/userRepository';
import BranchRepository from './modules/branchRepository';
import BusinesspartnerRepository from './modules/businesspartnerRepository';
import BpbankRepository from './modules/bpbankRepository';
import BplocationRepository from './modules/bplocationRepository';
import CurrencyRepository from './modules/currencyRepository';
import RegionalheadRepository from './modules/regionalheadRepository';
import BankheaderRepository from './modules/bankheaderRepository';
import DollarrateRepository from './modules/dollarrateRepository';
import AcctperiodmainRepository from './modules/acctperiodmainRepository';
import UseraccessprivilegeRepository from './modules/useraccessprivilegeRepository';
import PayefileRepository from './modules/payefileRepository';
import BankRepository from './modules/bankRepository';
import CompanyRepository from './modules/companyRepository';
import SectionRepository from './modules/sectionRepository';
import AlphataxcodeRepository from './modules/alphataxcodeRepository';
import TransactionRepository from './modules/transactionRepository';
import AuthRepository from './modules/authRepository';
import TrxdisbRepository from './modules/trxdisbRepository';
import TrxbillingRepository from './modules/trxbillingRepository';
import TrxcollectionRepository from './modules/trxcollectionRepository';
import Trxpurchaseorder from './modules/trxPurchaseOrderRepository';
import RegularTxnsRepository from './modules/regularTxnsRepository';
import TrxcheckRepository from './trxmodules/trxcheckRepository';
import ApprovingOfficerRepository from './modules/approvingofficerRepository';
import ApprovingLevelRepository from './modules/approvinglevelRepository';
import VoucherSeriesRepository from './modules/voucherseriesRepository';
import OfficialReceiptRepository from './modules/officialreceiptRepository';
import AcknowledgementReceiptRepository from './modules/acknowledgementreceiptRepository';
import PayeeBanksRepository from './modules/payeeBanksRepository';
import ReportRepository from './modules/reportRepository';
import RoleRepository from './modules/roleRepository';
import RoleAccessRepository from './modules/roleAccessRepository';
import ServicesRepository from './modules/servicesRepository';
import ModuleRepository from './modules/moduleRepository';
import BillingInvoiceRepository from './modules/billinginvoiceRepository';
import BillingOfficersRepository from './modules/billingOfficersRepository';
import TrxIntegratedRepository from './modules/trxintegratedRepository';

const repositories = {
	coa: CoaRepository,
	dept: DeptRepository,
	bank: BankRepository,
	user: UserRepository,
	branch: BranchRepository,
	businesspartner: BusinesspartnerRepository,
	bpbank: BpbankRepository,
	bplocation: BplocationRepository,
	company: CompanyRepository,
	section: SectionRepository,
	currency: CurrencyRepository,
	regionalhead: RegionalheadRepository,
	bankheader: BankheaderRepository,
	dollarrate: DollarrateRepository,
	acctperiodmain: AcctperiodmainRepository,
	useraccessprivilege: UseraccessprivilegeRepository,
	payefile: PayefileRepository,
	alphataxcode: AlphataxcodeRepository,
	transaction: TransactionRepository,
	trxdisb: TrxdisbRepository,
	trxcheck: TrxcheckRepository,
	trxpurchaseorder: Trxpurchaseorder,
	auth: AuthRepository,
	approvingofficer: ApprovingOfficerRepository,
	approvinglevel: ApprovingLevelRepository,
	regulartrx: RegularTxnsRepository,
	voucherseries: VoucherSeriesRepository,
	payeebank: PayeeBanksRepository,
	report: ReportRepository,
	officialreceipt: OfficialReceiptRepository,
	acknowledgementreceipt: AcknowledgementReceiptRepository,
	role: RoleRepository,
	roleaccess: RoleAccessRepository,
	module: ModuleRepository,
	billingservices: ServicesRepository,
	billinginvoice:BillingInvoiceRepository,
	billingofficers:BillingOfficersRepository,
	billing: TrxbillingRepository,
	collection: TrxcollectionRepository,
	integrated: TrxIntegratedRepository
};


export const RepositoryFactory = {
	get: name => repositories[name]
};