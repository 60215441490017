import Vue from 'vue';
import { RepositoryFactory } from '../../../api_repo/RepositoryFactory';
const approvinglevelsRepository = RepositoryFactory.get('approvinglevel');

const getApprovingLevels = (context) => {
	context.commit('SET_ISLOADING',true);
	setTimeout(() => {
		approvinglevelsRepository.get()
			.then((response) => {
				context.commit('SET_ISLOADING',false);
				context.commit('APPROVINGLEVEL_UPDATED', response.data.data);
			})
			// eslint-disable-next-line
			.catch(() => {
				context.commit('SET_ISLOADING',false);
			});
	}, 1500);
};

const saveApprovingLevel = (context,payload) => {
	context.commit('SET_ISLOADING',true);
	approvinglevelsRepository.saveApprovingLevel(payload)
		.then((response) => {
			context.commit('SET_ISLOADING',false);
			context.commit('SET_VALID',false);
			context.commit('APPROVINGLEVEL_ADD', response.data.data);
			Vue.swal.fire({
				type: 'success',
				title: 'Create successful',
				text: 'An Approving Level has been added.',
				timer: 2000,
			});
		})
		.catch(() => {
			context.commit('SET_ISLOADING',false);
			Vue.swal.fire({
				type: 'error',
				title: 'Saving failed!',
				text: 'Unable to create an Approving Level',
				timer: 2000
			});
		});
};

const updateApprovingLevel = (context, payload) => {
	context.commit('SET_ISLOADING',true);
	approvinglevelsRepository.updateApprovingLevel(payload.data.id, payload.data)
		.then((response) => {
			context.commit('SET_ISLOADING',false);
			context.commit('APPROVINGLEVEL_ITEM_UPDATE', {data: response.data.data, index: payload.index});
			context.commit('SET_EDITED_INDEX', -1);
			Vue.swal.fire({
				type: 'success',
				title: 'Your work has been updated',
				text: 'An Approving Level has been updated.',
				timer: 2000,
			});
		})
		.catch(() => {
			context.commit('SET_ISLOADING',false);
			Vue.swal.fire({
				type: 'error',
				title: 'Update fail',
				text: 'Unable to edit an Approving Level',
				timer: 2000
			});
		});
};

const deleteApprovingLevel = (context, payload) => {  
	context.commit('SET_ISLOADING', true);
	approvinglevelsRepository.deleteApprovingLevel(payload)
		.then((response) => {
			context.commit('SET_ISLOADING', false);
			context.commit('APPROVINGLEVEL_DELETE', response.data.data);
			Vue.swal.fire({
				type: 'error',
				title: 'Delete successful',
				text: 'An Approving Level has been deleted.',
				timer: 2000
			});
			context.commit('SET_EDITED_INDEX', -1);
		})
		.catch(() => {
			context.commit('SET_ISLOADING',false);
			Vue.swal.fire({
				type: 'error',
				title: 'Delete fail',
				text: 'Unable to delete an Approving Level',
				timer: 2000
			});
		});
};


const getDetails = (context, payload) => {
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	approvinglevelsRepository.getDetails(payload)
		.then((response) => {
			context.commit('SET_EDITED_ITEM', response.data.data);
			context.commit('app/SET_IS_LOADING',false,{ root: true });
		})
		.catch(() => {
			context.commit('app/SET_IS_LOADING',false,{ root: true });
		});    
};
const setEditedItems = (context,payload) => {
	context.commit('SET_EDITED_ITEM',payload);
};

const setDialog = (context,payload) => {
	context.commit('SET_DIALOG',payload);
};

const setEditedIndex = (context,payload) => {
	context.commit('SET_EDITED_INDEX',payload);
};

const setValid = (context,payload) => {
	context.commit('SET_VALID',payload);
};

const setformTitle = (context,payload) => {
	context.commit('SET_FORM_TITLE',payload);
};

const setisLoading = (context,payload) => {
	context.commit('SET_ISLOADING',payload);
};

export default {
	getApprovingLevels,
	saveApprovingLevel,
	updateApprovingLevel,
	deleteApprovingLevel,
	setEditedItems,
	setDialog,
	setEditedIndex,
	setValid,
	setformTitle,
	setisLoading,
	getDetails
};