<template>
	<v-app light>
		<div v-if="!isAuthenticated">
			<v-app-login></v-app-login>
		</div>
		<div v-else-if="isAuthenticated">
			<!-- <v-app-toolbar></v-app-toolbar>
			<v-app-nav></v-app-nav> -->
			<v-app-navigation v-show="getShow($route.name)"></v-app-navigation>
			<v-app-loader v-show="getShow($route.name)"></v-app-loader>
			<v-app-content v-show="getShow($route.name)"></v-app-content>
			<router-view name="printRouter" />
		</div>
		<v-dialog v-model="showDialog" width="30%" persistent>
			<v-card>
              <v-card-text>
				<div class="text-h4 pt-6 font-weight-light text-center"><v-icon x-large color="primary">mdi-tab</v-icon></div>
                <div class="text-h4 pa-3 font-weight-light text-center">Already open in another tab.</div>
              </v-card-text>
            </v-card>
		</v-dialog>
		<v-app-snackbar />
	</v-app>
</template>
<script>
// import Navbar from '@/components/partials/Navbar.vue';
import Navigation from '@/components/partials/Navigation.vue';
// import AppToolBar from './components/partials/AppToolBar.vue';
import AppContent from './components/partials/AppContent.vue';
import LoginVue from './views/Login.vue';
import { mapGetters } from 'vuex';
import AppSnackBar from './components/partials/AppSnackBar.vue';
import AppLoader from './components/partials/AppLoader.vue'; 

export default {
	name: 'App',
	data() {
		return	{
			showDialog: false
		};
	},
	components: {
		// 'v-app-nav': Navbar,
		// 'v-app-toolbar': AppToolBar,
		'v-app-content': AppContent,
		'v-app-login': LoginVue,
		'v-app-snackbar': AppSnackBar,
		'v-app-loader': AppLoader,
		'v-app-navigation': Navigation,
	},
	computed: {
		...mapGetters({
			isAuthenticated: 'auth/isAuthenticated',
		})
	},
	created() {
		// window.addEventListener('load', this.registerOpenTab);
		// window.addEventListener('beforeunload', this.unregisterOpenTab);
	},
	methods: {
		getShow(routeName){
			let route = ['preview-trx', 'preview-trxdisb', 'preview-cheque', 'preview-voucher','preview-receipt','preview-billing','preview-collection-or','preview-collection-ar','preview-OR','preview-AR', 'report-trialbalance', 'report-subsidiary','report-araging','report-araging-invoice','report-billing-list', 'report-subsidiary-analysis', 'report-profitability','purchase-order-print','report-rfa','report-cibSubsidiary', 'report-boa','report-auditTrail', 'report-salesJournal','preview-ereceipt','preview-ereceipt-billing'];
			let show = true;
			route.forEach(data => {
				if(data == routeName){
					show = false;
				}
			});
			return show;
		},
		registerOpenTab() {
			let route = ['preview-trx', 'preview-trxdisb', 'preview-cheque', 'preview-voucher','preview-receipt','preview-billing','preview-collection-or','preview-collection-ar','preview-OR','preview-AR', 'report-trialbalance', 'report-subsidiary','report-araging','report-araging-invoice','report-billing-list', 'report-subsidiary-analysis', 'report-profitability','preview-ereceipt','preview-ereceipt-billing'];
			let routeName = this.$route.name;
			let findRoute = route.indexOf(routeName);
			if(findRoute == -1){
				let Opentab = 1;
				while(localStorage.getItem('openTab'+ Opentab) !== null){
					Opentab++;
				}
				localStorage.setItem('openTab' + Opentab, 'Open');
				if(localStorage.getItem('openTab2') !== null){
					// this.$swal.fire({
					// 	text: 'Window already opened in another Tab!',
					// 	type: 'warning',
					// 	showConfirmButton: false,
					// 	allowOutsideClick: false,
					// 	allowEscapeKey: false
					// });
					this.showDialog = true;
				}
			}
		},
		unregisterOpenTab() {
			let Opentab = 1;
			while(localStorage.getItem('openTab'+ Opentab) !== null){
				Opentab++;
			}
			localStorage.removeItem('openTab' + (Opentab - 1));
		}
	}
};
</script>

<style lang="scss">
	@import '@/styles/index.scss';

	body {
		background-color: #eeeeee !important;
	}

	.v-date-picker-table .v-btn {
		padding: 0;
	}
</style>
