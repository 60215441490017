const services = state => state.services;
const headers = state => state.headers;
const columnHeaders = state => state.columnHeaders;
const editedItem = state => state.editedItem;
const defaultItem = state => state.defaultItem;
const dialog = state => state.dialog;
const editedIndex = state => state.editedIndex;
const valid = state => state.valid;
const formTitle = state => state.formTitle;

export default {
	services,
	columnHeaders,
	headers,
	editedItem,
	defaultItem,
	dialog,
	editedIndex,
	valid,
	formTitle
};