import Vue from 'vue';

const BANKS_UPDATED = (state, banks) => {
	state.banks = banks;
};
  
const BANKS_ADD = (state, payload) => {
	state.banks.data.push(payload);
};

const BANKS_DELETE = (state) => {
	state.banks.data.splice(state.editedIndex,1);
};

const BANKS_ITEM_UPDATE = (state, payload) => {
	Vue.set(state.banks.data, state.editedIndex, payload);
};

const SET_EDITED_ITEM = (state,payload) =>{
	state.editedItem = payload;
};

const SET_DIALOG = (state,payload) =>{
	state.dialog = payload;
};

const SET_EDITED_INDEX = (state,payload) =>{
	state.editedIndex = payload;
};

const SET_VALID = (state,payload) => {
	state.valid = payload;
};

const SET_FORM_TITLE = (state,payload) => {
	state.formTitle = payload;
};


export default {
	BANKS_UPDATED,
	SET_EDITED_ITEM,
	SET_DIALOG,
	BANKS_DELETE,
	BANKS_ITEM_UPDATE,
	SET_EDITED_INDEX,
	BANKS_ADD,
	SET_VALID,
	SET_FORM_TITLE
};