// eslint-disable-next-line
import Vue from 'vue';
import { RepositoryFactory } from '../../../api_repo/RepositoryFactory';

const bplocationsRepository = RepositoryFactory.get('bplocation');

const getBplocations = (context) => {
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		bplocationsRepository.get()
			.then((response) => {
				context.commit('BPLOCATIONS_UPDATED', response.data.data);
				context.commit('app/SET_IS_LOADING',false,{ root: true });
			})
			.catch(() => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
			});

	}, 1500);
};

const saveBplocation = (context,payload) => {
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		bplocationsRepository.saveBplocation(payload)
			.then((response) => {
				context.commit('BPLOCATIONS_ADD',response.data.data);    
				context.commit('app/SET_IS_LOADING',false,{ root: true });  
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ' + response.data.data.loc_name + '(' + response.data.data.loc_idno + ') added.','messagetype':'success'},{ root: true });
			})
			.catch((error) => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Saving failed! ' + error.response.data.message,'messagetype':'error'},{ root: true });
			});
	}, 1500);
};

const updateBplocation = (context,payload) => {
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		bplocationsRepository.updateBplocation(payload.id,payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('BPLOCATIONS_ITEM_UPDATE',response.data.data);
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ' + response.data.data.loc_name + '(' + response.data.data.loc_idno + ') updated.','messagetype':'success'},{ root: true });
			})
			.catch((error) => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Update failed! ' + error.response.data.message,'messagetype':'error'},{ root: true });
			});
	}, 1500);
};

const deleteBplocation = (context,payload) => {  
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		bplocationsRepository.deleteBplocation(payload.id)
			.then((response) => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('BPLOCATIONS_DELETE',response.data.data);
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ' + response.data.data.loc_name + '(' + response.data.data.loc_idno + ') deleted.','messagetype':'success'},{ root: true });
			})
			.catch((error) => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Delete failed! ' + error.response.data.message,'messagetype':'error'},{ root: true });
			});
	}, 1500);
};

const getDetails = (context, payload) => {
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	bplocationsRepository.getBplocation(payload)
		.then((response) => {
			context.commit('SET_EDITED_ITEM', response.data.data[0]);
			context.commit('app/SET_IS_LOADING',false,{ root: true });
		})
		.catch(() => {
		});
};

const getDataPages = (context,payload) => {
	context.commit('app/SET_IS_LOADING', true , { root: true });
	setTimeout(() => {
		bplocationsRepository.getData(payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING', false , { root: true });
				context.commit('BPLOCATION_UPDATED', response.data.data);
			})
			// eslint-disable-next-line
			.catch(() => {
				context.commit('app/SET_IS_LOADING', false , { root: true });
			});
	}, 1500);
};

const setEditedItems = (context,payload) => {
	context.commit('SET_EDITED_ITEM',payload);
};

const setDialog = (context,payload) => {
	context.commit('SET_DIALOG',payload);
};

const setEditedIndex = (context,payload) => {
	context.commit('SET_EDITED_INDEX',payload);
};

const setformTitle = (context,payload) => {
	context.commit('SET_FORM_TITLE',payload);
};

const setValid = (context,payload) => {
	context.commit('SET_VALID',payload);
};

export default {
	getBplocations,
	saveBplocation,
	updateBplocation,
	deleteBplocation,
	setEditedItems,
	setDialog,
	setEditedIndex,
	setformTitle,
	setValid,
	getDataPages,
	getDetails
};