const tbalData = state => state.tbalData;
const subsData = state => state.subsData;
const subsAnalysisData = state => state.subsAnalysisData;
const profData = state => state.profData;
const profDataSummary = state => state.profDataSummary;
const billingList = state => state.billingList;
const arAgingInv = state => state.arAgingInv;
const arAgingCust = state => state.arAgingCust;
const rfaData = state => state.rfaData;
const cibSubData = state => state.cibSubData;
const boaData = state => state.boaData;
const auditData = state => state.auditData;
const salesData = state => state.salesData;

export default {
	tbalData,
	subsData,
	subsAnalysisData,
	profData,
	arAgingCust,
	arAgingInv,
	billingList,
	profDataSummary,
	rfaData,
	cibSubData,
	boaData,
	salesData,
	auditData
};