import Repository from '../Repository';

const resource = '/trxcheck';

export default {
	get() {
		return Repository.get(`${resource}`);
	},

	getTrxcheck(id) {
		return Repository.get(`${resource}/${id}`);
	},

	saveTrxcheck(payload) {
		return Repository.post(`${resource}`, payload);
	},

	updateTrxcheck(id, payload) {
		return Repository.put(`${resource}/${id}`, payload);
	},

	deleteTrxcheck(id) {
		return Repository.delete(`${resource}/${id}`);
	},

	searchTrxcheck(payload) {
		return Repository.get(`${resource}`, payload);
	}
};