const transactions = state => state.transactions;
const headers = state => state.headers;
const trnsCode = state => state.trnsCode;
const documentType = state => state.documentType;
const trnsDesc = state => state.trnsDesc;
const editedItem = state => state.editedItem;
const defaultItem = state => state.defaultItem;
const dialog = state => state.dialog;
const editedIndex = state => state.editedIndex;
const valid = state => state.valid;
const formTitle = state => state.formTitle;


export default {
	transactions,
	headers,
	trnsCode,
	documentType,
	trnsDesc,
	editedItem,
	defaultItem,
	dialog,
	editedIndex,
	valid,
	formTitle
};