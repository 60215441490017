<template>
	<div>
		<div id="printAction">
			<v-btn color="primary" @click="printDocument"><v-icon left>mdi-printer</v-icon> Print This Form</v-btn>
		</div>
		<div id="printArea">
			<div class="container">
				<div style="height: 50px">
					<div class="left-container">
						<h2>PETNET, Inc.</h2>
						<div class="divider" style="width:90%"></div>
					</div>
					<div class="right-container">
						<p class="text-header" style="text-align: right">Customer COPY</p>
					</div>
				</div>
				<div class="container-full" style="height: 60px">
					<div class="left-container">
						<p class="text-header">
							Head Office<br>
							114 Aguirre Street, Legazpi Village, Makati City 1229<br>
							Tel No.: (+632)812-9209<br>
							VAT Reg TIN: 201-200-777-00000<br>
						</p>
					</div>
					<div class="right-container">
						<p class="text-header">
							Accreditation No: <br>
							MIN: <br>
							Serial No:
						</p>
					</div>
				</div>
				<div class="divider"></div>
				<div class="container-full" style="height: 90px">
					<div class="left-container">
						<p class="text-header" style="text-align:center">ACKNOWLEDGEMENT RECEIPT</p>
						<p class="text-header">
							Receipt No.
						</p>
						<p class="text-header">FOREX TRADING</p>
					</div>
					<div class="right-container">
						<p class="text-header" style="text-align:center">OFFICIAL RECEIPT</p>
						<p class="text-header">
							Receipt No.
						</p>
					</div>
				</div>
				<div class="container-full" style="height: 120px;">
					<div class="left-container">
						<p class="text-content">
						<br> <br> <br> <br>
							SUB-TOTAL:
						</p>
					</div>
					<div class="right-container">
						<p class="text-content">
							Vatable Service Fee <br>
							Vat Exempt Fee <br>
							Zero Rated Fee <br>
							Vat Amount <br>
							SUB-TOTAL:
						</p>
					</div>
				</div>
				<div>
					-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
					-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
				</div>
				<div class="container-full" style="height: 180px;">
					<div class="left-container">
						<p class="text-header">*THIS ACKNOWLEDGEMENT RECEIPT IS NOT VALID FOR CLAIM OF INPUT TAX</p>
						<p class="text-content">
						Name: <br>
						TIN: <br>
						Business Style: <br>
						Customer Address: <br>
						OSCA / PWD ID No: <br>
						Signature: 
						</p>
					</div>
					<div class="right-container">
						<p class="text-header">THIS SERVES AS YOUR OFFICIAL RECEIPT</p>
						<p class="text-content">
							<br>
							CASHIERS <br> <br>
							WEBSITE:  www.perahub.com.ph <br>
							CUSTOMER CARE:  (+632) 812 9209 | (+632) 810 5086 <br>
						</p>
					</div>
				</div>
				<div class="container-full" style="height: 170px">
					<p class="text-header" style="text-align:center">
						BIR Final PTU#:___________ BIR ACCREDITATION No:___________ Date Issued: ___/___/_____ Valid Until: ___/___/_____
					</p>
					<p class="text-header" style="text-align:center">
						*THIS INVOICE/RECEIPT SHALL BE VALID FOR FIVE(5) YEARS FROM THE DATEOF THE PERMITTO USE*
					</p>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { mapGetters } from 'vuex';
import numeral from 'numeral';
import { format } from 'date-fns';
import numbertoWords from 'number-to-words';

export default {
	name: 'printChequeTrxdisb',
	data() {
		return {
			disbDetail:{},
			documentHeaders: []
		};
	},
	watch: {
		trxdisbDetails: {
			handler(val) {
				this.documentHeaders = val;
			}
		}
	},
	computed: {
		...mapGetters({
		}),
	},
	created() {
		// var app = this;
		// app.disbDetail = JSON.parse(atob(app.$route.params.data)); 
	},
	mounted() {
		this.init();
	},
	methods: {
		printDocument() {
			window.print();
		},
		init(){
			// this.$store.dispatch('payefile/getPayefileDropdown');
			// this.$store.dispatch('trxdisb/getTrxdisb', this.disbDetail.batch_number);
		},
		getDateFormat(date){
			return format(new Date(date), 'MM-d-yyyy');
		},
		getNumberFormat(amount){
			return numeral(amount).format('0,0.00');
		},
		getAmountinWords(amount){
			let amountWord = numbertoWords.toWords(amount); 
			let newAmount = amountWord.replace(/,/g, '');
			return newAmount.toUpperCase();
		},
		getPayeeName(id){
			const getPayee = this.payees.filter(payee=> payee.id=== id);

			if (getPayee) {
				return getPayee[0].payee_desc;
			}
		}
	}
};
</script>
<style scoped>
	.container {
		margin: 3px;
		width: 850px;
	}
	.left-container {
		width: 60%;
		float: left;
		
	}
	.right-container {
		width: 40%;
		float: right;
	}
	.container-full {
		width: 100%;
		float: inherit;
		/* outline: 1px solid black; */
	}
	.text-header {
		font-size: 14px;
		line-height: 14px;
		font-weight: bold;
	}
	.text-content {
		font-size: 14px;
		line-height: 20px;
		font-weight: bold;
	}
	.divider {
		outline: 1px solid black;
	}
	@media print {
		body #printAction { 
			display:none; 
		}

    body #printArea { 
			display:block; 
		}
	}
</style>