import Vue from 'vue';

const USERACCESSPRIVILEGES_UPDATED = (state, useraccessprivileges) => {
	state.useraccessprivileges = useraccessprivileges;

};

const USERACCESSPRIVILEGES_ADD = (state, payload) => {
	state.useraccessprivileges.push(payload);
};

const USERACCESSPRIVILEGES_DELETE = (state, payload) => {
	let index = state.useraccessprivileges.indexOf(payload);
	state.useraccessprivileges.splice(index,1);
};

const USERACCESSPRIVILEGES_ITEM_UPDATE = (state, payload) => {
	Vue.set(state.useraccessprivileges, state.editedIndex, payload);
};

const SET_EDITED_ITEM = (state,payload) =>{
	state.editedItem = payload;
};

const SET_DIALOG = (state,payload) =>{
	state.dialog = payload;
};

const SET_EDITED_INDEX = (state,payload) =>{
	state.editedIndex = payload;
};

const SET_VALID = (state,payload) => {
	state.valid = payload;
};

const SET_FORM_TITLE = (state,payload) => {
	state.formTitle = payload;
};


export default {
	USERACCESSPRIVILEGES_UPDATED,
	SET_EDITED_ITEM,
	SET_DIALOG,
	SET_EDITED_INDEX,
	USERACCESSPRIVILEGES_ADD,
	USERACCESSPRIVILEGES_DELETE,
	SET_VALID,
	SET_FORM_TITLE,
	USERACCESSPRIVILEGES_ITEM_UPDATE
};


