const bpbanks = state => state.bpbanks;
const headers = state => state.headers;
const columnHeaders = state => state.columnHeaders;
const acctType = state => state.acctType;
const acctCur = state => state.acctCur;
const editedItem = state => state.editedItem;
const defaultItem = state => state.defaultItem;
const dialog = state => state.dialog;
const editedIndex = state => state.editedIndex;
const formTitle = state => state.formTitle;
const valid = state => state.valid;

export default {
	bpbanks,
	acctType,
	acctCur,
	columnHeaders,
	headers,
	editedItem,
	defaultItem,
	dialog,
	editedIndex,
	formTitle,
	valid
};