import Repository from '../Repository';

const resource = '/currency';

export default {
	get() {
		return Repository.get(`${resource}`);
	},

	getCurrency(id) {
		return Repository.get(`${resource}/${id}`);
	},

	saveCurrency(payload) {
		return Repository.post(`${resource}`, payload);
	},

	updateCurrency(id, payload) {
		return Repository.put(`${resource}/${id}`, payload);
	},

	deleteCurrency(id) {
		return Repository.delete(`${resource}/${id}`);
	},

	searchCurrency(payload){
		return Repository.get(`${resource}`,payload);
	}
};