import Vue from 'vue';

const PAYEFILES_UPDATED = (state, payefiles) => {
	state.payefiles =payefiles;
};

const PAYEFILES_ADD = (state, payload) => {
	state.payefiles.push(payload);
}; 

const PAYEFILES_DELETE = (state, payload) => {
	let index = state.payefiles.indexOf(payload);
	state.payefiles.splice(index,1);
};

const PAYEFILES_ITEM_UPDATE = (state, payload) => {
	Vue.set(state.payefiles, state.editedIndex, payload);
};

const SET_EDITED_ITEM = (state,payload) =>{
	state.editedItem = payload;
};

const SET_DIALOG = (state,payload) =>{
	state.dialog = payload;
};

const SET_EDITED_INDEX = (state,payload) =>{
	state.editedIndex = payload;
};


const SET_VALID = (state,payload) => {
	state.valid = payload;
};

const SET_FORM_TITLE = (state,payload) => {
	state.formTitle = payload;
};

const SET_ISLOADING = (state,payload) => {
	state.isloading = payload;
};

const SET_PAYEE_DETAILS = (state, payload) => {
	state.payeeDetails = payload;
};

const SET_PAYEEBANK_DIALOG = (state, payload) => {
	state.payeeBankDialog = payload;
};

const SET_EDITEDITEM_PAYEEBANK = (state, payload) => {
	state.payeeBank = payload;
};

const SET_PAYEE_PAYEEBANKS = (state, payload) => {
	state.editedItem.payee_banks.push(payload);
};

const SET_DEFAULT_ITEMS = (state) => {
	state.editedIndex = state.defaultItem;
};

export default {
	PAYEFILES_UPDATED,
	SET_EDITED_ITEM,
	SET_DIALOG,
	SET_EDITED_INDEX,
	PAYEFILES_ADD,
	PAYEFILES_DELETE,
	SET_VALID,
	SET_FORM_TITLE,
	PAYEFILES_ITEM_UPDATE,
	SET_ISLOADING,
	SET_PAYEE_DETAILS,
	SET_PAYEEBANK_DIALOG,
	SET_EDITEDITEM_PAYEEBANK,
	SET_PAYEE_PAYEEBANKS,
	SET_DEFAULT_ITEMS
};