import Repository from '../Repository';

const resource = '/coa';

export default {
	get() {
		return Repository.get(`${resource}`);
	},

	getCoa(id) {
		return Repository.get(`${resource}/${id}`);
	},

	saveCoa(payload) {
		return Repository.post(`${resource}`, payload);
	},

	updateCoa(id, payload) {
		return Repository.put(`${resource}/${id}`, payload);
	},

	deleteCoa(id) {
		return Repository.delete(`${resource}/${id}`);
	},

	searchCoa(payload){
		return Repository.get(`${resource}`,payload);
	},
    
	getParents(payload) {
		return Repository.post(`${resource}/getparents`,payload);
	},

	getAccountCode(payload){
		return Repository.post(`${resource}/genacct`,payload);
	},

	getSubAccount(payload){
		return Repository.get(`${resource}/byaccttype`, payload);
		//return Repository.get(`${resource}`, payload);
	},

	getDetails(id) {
		return Repository.get(`${resource}/${id}`);
	},

	getData(payload){
		return Repository.get(`${resource}?page=`+ payload);
	} 
};