import Repository from '../Repository';

const resource = '/rpt';

export default {
	getTrialBalance(payload) {
		return Repository.get(`${resource}/tbal`, {params: payload});
	},

	getSubsidiary(payload) {
		return Repository.get(`${resource}/subledgerReport`, {params: payload});
	},

	getSubsidiaryAnalysis(payload) {
		return Repository.get(`${resource}/subledgerAnalysis`, {params: payload});
	},

	getProfitability(payload) {
		return Repository.get(`${resource}/pnl`, {params: payload});
	},

	getRfa(payload) {
		return Repository.get(`${resource}/RFAReport`, {params: payload});
	},

	getCibsubschedReport(payload) {
		return Repository.get(`${resource}/cibsubschedReport`, {params: payload});
	},

	getBoaje(payload) {
		return Repository.get(`${resource}/boaje`, {params: payload});
	},

	getBoaDisb(payload) {
		return Repository.get(`${resource}/boadisb`, {params: payload});
	},

	getBoaCr(payload) {
		return Repository.get(`${resource}/boacr`, {params: payload});
	},

	getBoaPo(payload) {
		return Repository.get(`${resource}/boapo`, {params: payload});
	},

	getAuditTrail(payload) {
		return Repository.get(`${resource}/audit`, {params: payload});
	},

	getSalesJournal(payload) {
		return Repository.get(`${resource}/salesrep`, {params: payload});
	},

	getData(path, payload){
		return Repository.get(`${resource}`+path,{params: payload});
	}
	
};