const coas = state => state.coas;
const headers = state => state.headers;
const columnHeaders = state => state.columnHeaders;
const accountGroup = state => state.accountGroup;
const accountType = state => state.accountType;
const accountClass = state => state.accountClass;
const editedItem = state => state.editedItem;
const defaultItem = state => state.defaultItem;
const dialog = state => state.dialog;
const editedIndex = state => state.editedIndex;
const valid = state => state.valid;
const formTitle = state => state.formTitle;
const accountParents = state => state.accountParents;
const editBy_default = state => state.defaultItem.edit_by;
const editBy = state => state.editedItem.edit_by;
const coaSubs = state => state.coaSubs;
const coaCurr = state => state.coaCurr;
const coaData = state => state.coaData;

export default {
	coas,
	columnHeaders,
	headers,
	accountGroup,
	accountType,
	accountClass,
	editedItem,
	defaultItem,
	dialog,
	editedIndex,
	valid,
	formTitle,
	accountParents,
	editBy_default,
	editBy,
	coaSubs,
	coaCurr,
	coaData,
};
