import { RepositoryFactory } from '../../../api_repo/RepositoryFactory';

const acctperiodmainsRepository = RepositoryFactory.get('acctperiodmain');

const getAcctperiodmains = (context) => {
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	acctperiodmainsRepository.get().then((response) => {
		context.commit('ACCTPERIODMAINS_UPDATED', response.data.data);
		context.commit('app/SET_IS_LOADING',false,{ root: true });
	}).catch(() => {
		context.commit('app/SET_IS_LOADING',false,{ root: true });
	});
};

const getAcctperiodmainDetail = (context, payload) => {
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	acctperiodmainsRepository.getAcctperiodmain(payload).then((response) => {
		context.commit('SET_EDITED_ITEM', response.data.data);
		context.commit('app/SET_IS_LOADING',false,{ root: true });
	}).catch(() => {
		context.commit('app/SET_IS_LOADING',false,{ root: true });
	});
};

const saveAcctperiodmain = (context,payload) => {
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		acctperiodmainsRepository.saveAcctperiodmain(payload).then((response) => {
			response.data.data.cash_receipt = (response.data.data.cash_receipt === 1 ? 'OPEN' : 'CLOSED');
			response.data.data.disbursement = (response.data.data.disbursement === 1 ? 'OPEN' : 'CLOSED');
			response.data.data.general_journal = (response.data.data.general_journal === 1 ? 'OPEN' : 'CLOSED');
			context.commit('ACCTPERIODMAINS_ADD',response.data.data);    
			context.commit('app/SET_IS_LOADING',false,{ root: true });
			context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ' + response.data.data.fiscal_year + '(' + response.data.data.period_no + ') added.','messagetype':'success'},{ root: true });
		}).catch((error) => {
			context.commit('app/SET_IS_LOADING',false,{ root: true });
			context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Saving failed! ' + error.response.data.message,'messagetype':'error'},{ root: true });  
		});
	}, 1500);
};

const updateAcctperiodmain = (context,payload) => {
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		acctperiodmainsRepository.updateAcctperiodmain(payload.id,payload).then((response) => {
			response.data.data.cash_receipt = (response.data.data.cash_receipt === 1 ? 'OPEN' : 'CLOSED');
			response.data.data.disbursement = (response.data.data.disbursement === 1 ? 'OPEN' : 'CLOSED');
			response.data.data.general_journal = (response.data.data.general_journal === 1 ? 'OPEN' : 'CLOSED');
			context.commit('ACCTPERIODMAINS_ITEM_UPDATE',response.data.data);
			context.commit('app/SET_IS_LOADING',false,{ root: true });
			context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ' + response.data.data.fiscal_year + '(' + response.data.data.period_no + ') updated.','messagetype':'success'},{ root: true });            
			context.commit('SET_DIALOG', false);
			context.commit('SET_VALID', false);
		}).catch((error) => {
			context.commit('app/SET_IS_LOADING',false,{ root: true });
			context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Update failed! ' + error.response.data.message,'messagetype':'error'},{ root: true });  
		});
	}, 1500);
};

const deleteAcctperiodmain = (context,payload) => {
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		acctperiodmainsRepository.deleteAcctperiodmain(payload.id).then((response) => {
			context.commit('ACCTPERIODMAINS_DELETE',response.data.data);
			context.commit('app/SET_IS_LOADING',false,{ root: true });
			context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ' + response.data.data.fiscal_year + '(' + response.data.data.period_no + ') deleted.','messagetype':'success'},{ root: true });            
		}).catch((error) => {
			context.commit('app/SET_IS_LOADING',false,{ root: true });
			context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Delete failed! ' + error.response.data.message,'messagetype':'error'},{ root: true });  
		});
	}, 1500);
};

const getDataPages = (context,payload) => {
	context.commit('app/SET_IS_LOADING', true , { root: true });
	setTimeout(() => {
		acctperiodmainsRepository.getData(payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING', false , { root: true });
				context.commit('ACCTPERIODMAINS_UPDATED', response.data.data);
			})
			// eslint-disable-next-line
			.catch(() => {
				context.commit('app/SET_IS_LOADING', false , { root: true });
			});
	}, 1500);
};

const getData = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true , { root: true });
	return new Promise((resolve, reject) => {
		acctperiodmainsRepository.getData(payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING', false , { root: true });
				resolve(response);
			}, error => {
				context.commit('app/SET_IS_LOADING', false , { root: true });
				reject(error);
			});
	});
};

const setEditedItems = (context,payload) => {
	context.commit('SET_EDITED_ITEM',payload);
};

const setDialog = (context,payload) => {
	context.commit('SET_DIALOG',payload);
};

const setEditedIndex = (context,payload) => {
	context.commit('SET_EDITED_INDEX',payload);
};

const setValid = (context,payload) => {
	context.commit('SET_VALID',payload);
};

const setformTitle = (context,payload) => {
	context.commit('SET_FORM_TITLE',payload);
};

export default {
	getAcctperiodmains,
	getAcctperiodmainDetail,
	saveAcctperiodmain,
	updateAcctperiodmain,
	deleteAcctperiodmain,
	setEditedItems,
	setDialog,
	setEditedIndex,
	setValid,
	setformTitle,
	getDataPages,
	getData
};