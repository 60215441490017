import '@fortawesome/fontawesome-free/css/all.css';
import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import 'vuetify/dist/vuetify.min.css';
import colors from 'vuetify/lib/util/colors';
import { Ripple } from 'vuetify/lib/directives';

// import 'font-awesome/css/font-awesome.min.css'

Vue.use(Vuetify, {
	directives: {
		Ripple
	}
});

export default new Vuetify({
	icons: {
		iconfont: 'mdi', // default - only for display purposes
	},
	theme: {
		themes: {
			light: {
				//primary: '#82B1FF',
				secondary: '#82B1FF', //5287fa
				tertiary: '#495057',
				accent: '#82B1FF',
				error: '#f55a4e',
				info: '#00d3ee',
				success: '#5cb860',
				warning: '#ffa21a',
				primary: colors.blue.accent3,
				//primary: colors.deepOrange.accent2,
				//secondary: colors.grey.darken1,
				// accent: colors.shades.black,
				// error: colors.red.accent3,
			}
		}
	},
	// icons: {
	//   iconfont: 'fa4' // 'md' || 'mdi' || 'fa' || 'fa4'
	// },
	// theme: {
	//   primary: '#9652ff',
	//   success: '#3cd1c2',
	//   info: '#ffaa2c',
	//   error: '#f83e70'
	// }
});
