import Repository from '../Repository';

const resource = '/roleaccess';

export default {
	get() {
		return Repository.get(`${resource}`);
	},

	getRoleAccess(id) {
		return Repository.get(`${resource}/${id}`);
	},

	saveRoleAccess(payload) {
		return Repository.post(`${resource}`, payload);
	},

	updateRoleAccess(id, payload) {
		return Repository.put(`${resource}/${id}`, payload);
	},

	deleteRoleAccess(id) {
		return Repository.delete(`${resource}/${id}`);
	},

	searchRoleAccess(payload){
		return Repository.get(`${resource}`,payload);
	},

	getData(payload){
		return Repository.get(`${resource}?page=`+ payload);
	}
};