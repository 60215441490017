import Vue from 'vue';
// eslint-disable-next-line
import { Stat }  from 'fs';

const COMPANIES_UPDATED = (state, companies) => {
	state.companies = companies;
};
  
const COMPANIES_ADD = (state, payload) => {
	state.companies.push(payload);
};

const COMPANIES_DELETE = (state) => {
	state.companies.splice(state.editedIndex,1);
};

const COMPANIES_ITEM_UPDATE = (state, payload) => {
	Vue.set(state.companies, state.editedIndex, payload);
};

const SET_EDITED_ITEM = (state,payload) =>{
	state.editedItem = payload;
};

const SET_DIALOG = (state,payload) =>{
	state.dialog = payload;
};

const SET_EDITED_INDEX = (state,payload) =>{
	state.editedIndex = payload;
};

const SET_VALID = (state,payload) => {
	state.valid = payload;
};

const SET_FORM_TITLE = (state,payload) => {
	state.formTitle = payload;
};

const SET_ISLOADING = (state,payload) => {
	state.isLoading = payload;
};


export default {
	COMPANIES_UPDATED,
	SET_EDITED_ITEM,
	SET_DIALOG,
	COMPANIES_DELETE,
	COMPANIES_ITEM_UPDATE,
	SET_ISLOADING,
	SET_EDITED_INDEX,
	COMPANIES_ADD,
	SET_VALID,
	SET_FORM_TITLE
};