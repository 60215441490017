const currencies = state => state.currencies;
const headers = state => state.headers;
const columnHeaders = state => state.columnHeaders;
const currencyCode = state => state.currencyCode;
const currencyDesc = state => state.currencyDesc;
const editedItem = state => state.editedItem;
const defaultItem = state => state.defaultItem;
const dialog = state => state.dialog;
const editedIndex = state => state.editedIndex;
const valid = state => state.valid;
const formTitle = state => state.formTitle;


export default {
	currencies,
	columnHeaders,
	headers,
	currencyCode,
	currencyDesc,
	editedItem,
	defaultItem,
	dialog,
	editedIndex,
	valid,
	formTitle
};