<template>
  <div>
    <div id="printAction" class="action">
      <v-row justify="center" align="center">
        <v-spacer class="hidden-sm-and-down"></v-spacer>
        <v-col cols="6">
          <h4>Report Name: SUBSIDIARY BY ANALYSIS</h4>
        </v-col>
        <v-col cols="4">
          <!-- <v-btn color="primary"  @click="print"><v-icon left>mdi-printer</v-icon> Print</v-btn> -->
           <v-btn color="error" @click="exportReport"><v-icon left>mdi-file-pdf-outline</v-icon>Export</v-btn>
          <v-btn color="success" @click="exportExcel('printArea', 'subsidiary-analysis')"><v-icon left>mdi-microsoft-excel</v-icon> Export</v-btn>
        </v-col>
      </v-row>
    </div>
    <div id="printArea" ref="content" style="width: 80%; font-size: 12px; margin: auto;">
      <v-row>
        <v-col :cols="3">
          <p>
            Run time:
            <br>
            Run Date:
            <br>
            Run By:
            </p>
        </v-col>
        <v-col cols="6" class="text-center">
          <p>
            <br>
            PETNET, INC.
            <br>
            32nd Floor East Offices Aguirre St. Legaspi Village Makati City
          </p>
        </v-col>
        <v-col :cols="3">
          <!-- <p>
            Page:
            <br>
            Status:
            </p> -->
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-center">
          <p>
            SUBSIDIARY BY ANALYSIS
            <br>
            For the Period of {{getDateFormat(detail.date_from)}} to {{getDateFormat(detail.date_to)}}
          </p>
        </v-col>
      </v-row>
      <!-- <div style="outline: 1px solid black;width: 99%; margin: auto;"></div> -->
		<div>
			<table id="table" ref="table" style="empty-cells:show;">
				<tr>
				<th>BRANCH</th>
				<th>DATE</th>
				<th>DOC No.</th>
				<th>DESCRIPTION</th>
				<th>DEBIT</th>
				<th>CREDIT</th>
				</tr>
				<tr style="outline: 1px solid black; width: 100%; margin: 10px;">
				</tr>
				<tr>
				<th style="text-align:left">ANALYSIS:   {{getAnalysis(detail.analysis)}}</th>
				</tr>
						<tbody v-for="(sub, index) in subsAnalysisData.data1" :key="index">
							<tr>
								<td style="text-align:left" colspan="6">
									ACCOUNT CHARGE: {{index}}
								</td>
							</tr>	
							<tr v-for="det in sub[0]" :key="det.id">
								<td>{{det.branch}}</td>
								<td>{{det.date}}</td>
								<td>{{det.document_no}}</td>
								<td class="description" >{{det.description}}</td>
								<td class="amount">{{getAmountFormat(det.debit)}}</td>
								<td class="amount">{{getAmountFormat(det.credit)}}</td>
							</tr>
							<tr>
								<th colspan="4" style="text-align:right">Total: </th>
								<th class="amount">{{getAmountFormat(sub.total_debit)}} </th>
								<th class="amount">{{getAmountFormat(sub.total_credit)}}</th>
							</tr>
							<tr></tr>
						</tbody>
						<tr>
							<th colspan="4" style="text-align:right">Grand Total: </th>
							<th class="amount">{{getAmountFormat(subsAnalysisData.data2.grand_total_debit)}} </th>
							<th class="amount">{{getAmountFormat(subsAnalysisData.data2.grand_total_credit)}}</th>
						</tr>
			</table>
		</div>
    </div>
  </div>
</template>
<script>
// import jsPDF from 'jspdf';
import html2pdf from 'html2pdf.js';
//import 'jspdf-autotable';
import { mapGetters } from 'vuex';
import numeral from 'numeral'; 
import { format } from 'date-fns';
export default {
	data: () => ({
		detail: {
			year: '',
			month: ''
		},
	}),
	mounted() {
		this.$store.dispatch('report/getSubsAnalysisData', this.detail);
		this.$store.dispatch('payefile/getPayefileDropdown'); 
	},
	created() {
		var app = this;
		app.detail = JSON.parse(atob(app.$route.params.data)); 
		// app.detail = {
		// 	'analysis': 'EM5039',
		// 	'account': 1,
		// 	'date_from': '2020-08-24',
		// 	'date_to': '2021-10-14'
		// };
	},
	computed: {
		...mapGetters({
			subsAnalysisData: 'report/subsAnalysisData',
			payees: 'payefile/payefiles'
		}),
	},
	methods:{
		print() {
			window.print();
		},
		exportReport(){
			const contentHtml = this.$refs.table;
			const date = Math.floor(Date.now() / 1000);
			//var source = document.body;
			html2pdf()
				.from(contentHtml)
				.set({
					margin:       [100, 10, 30, 35],
					setFontSize: 	5,
					filename:     'subsidiary-analysis' + date +'.pdf',
					// image:        { type: 'jpeg',quality: 0.98 },
					// html2canvas:  { dpi: 96, scale: 1, scrollX: 0, scrollY: 0, backgroundColor: '#FFF' },
					jsPDF:        { unit: 'pt', format: 'a4', orientation: 'p' },
					//pagebreak: { before: '.page-break', avoid: 'img' }
				})
				.toPdf()
				.get('pdf').then(function (pdf) {
					var totalPages = pdf.internal.getNumberOfPages();
                      
					for (let i = 1; i <= totalPages; i++) {
						pdf.setPage(i);
						pdf.setFontSize(9);
						pdf.text('Run Time: ', 30, 40);
						pdf.text('Run Date: ', 30, 50);
						pdf.text('Run By: ', 30, 60);
						pdf.text('Page:   ' + i , pdf.internal.pageSize.getWidth() - 80, 50);
						// pdf.text('Status: ', pdf.internal.pageSize.getWidth() - 80, 60);
						pdf.text('PETNET INC.', 273, 50);
						pdf.text('32nd Floor East Offices Aguirre St. Legaspi Village Makati City', 180, 60);
						pdf.text('SUBSIDIARY BY ANALYSIS', 240, 80);
						pdf.text('FOR THE PERIOD OF TO', 245, 90);
						//pdf.text('width' + pdf.internal.pageSize.getWidth(), 50, 90); //595.28
						//pdf.text('Page ' + i + ' of ' + totalPages, pdf.internal.pageSize.getWidth() - 100, pdf.internal.pageSize.getHeight() - 40);
					} 
				}).save();
		},
		exportExcel(tableID, filename = '') {
			const date = Math.floor(Date.now() / 1000);
			var downloadLink;
			var dataType = 'application/vnd.ms-excel';

			// var x = document.getElementsByClassName('amount');
			// var i;
			// for (i = 0; i < x.length; i++) {
			// 	x[i].innerHTML = '\'' + x[i].innerHTML;
			// }
			
			var x = document.getElementsByClassName('description');
			var i;
			for (i = 0; i < x.length; i++) {
				x[i].innerHTML = x[i].innerHTML.replace(/[`~!@#$%^&*()|+=?;:'"<>]/gi, ' ');
			}
			var tableSelect = document.getElementById(tableID);
			var tableHTML = tableSelect.outerHTML.replace(/ /g, '%20');


			filename = filename ? filename + date + '.xls' : 'excel_data.xls';
			downloadLink = document.createElement('a');
			document.body.appendChild(downloadLink);

			if (navigator.msSaveOrOpenBlob) {
				var blob = new Blob(['\ufeff', tableHTML], {
					type: dataType
				});
				navigator.msSaveOrOpenBlob(blob, filename);
			} else {
				downloadLink.href = 'data:' + dataType + ', ' + tableHTML;
				downloadLink.download = filename;
				downloadLink.click();
			}
			
			// for (i = 0; i < x.length; i++) {
			// 	x[i].innerHTML = x[i].innerHTML.replace('\'', '');
			// }
		},
		getAmountFormat(amount){
			return numeral(amount).format('0,0.00');
		},
		getMonth(){
			var month = new Array();
			month[1] = 'January';
			month[2] = 'February';
			month[3] = 'March';
			month[4] = 'April';
			month[5] = 'May';
			month[6] = 'June';
			month[7] = 'July';
			month[8] = 'August';
			month[9] = 'September';
			month[10] = 'October';
			month[11] = 'November';
			month[12] = 'December';
			var n = month[this.detail.month];
			return n;
		},
		getDateFormat(date){
			var month = format(new Date(date), 'MMM');
			var day = format(new Date(date), 'dd');
			var year = format(new Date(date), 'yyyy');
			return month + ' ' + day +', '+ year;
		},
		getAnalysis(code){
			let name = '';
			this.payees.filter(det => {
				if(det.payee_code == code){
					name = det.payee_desc;
				}
			});
			return name;
		}
	}
};
</script>
<style scoped>
.report-template {
  width: 768px;
  /* border: 1px solid black; */
  font-size: 12px;
  margin: auto;
}
.tbl {
  width: 95%;
  margin: auto;
}
.code {
  text-align: center;
}
.amount {
  text-align: right;
}
.header {
  outline: 1px solid black;
  width: 100%;
  margin: 10px;
}
.total {
  text-align: right;
  font-weight: bold;
}
.action {
  padding: 20px;
}
.description {
	word-wrap:break-word; 
	white-space:normal;
}
.title {
  text-justify: center;
}
	@media print {
		body #printAction { 
			display:none; 
		}
		body #printArea { 
			display:block; 
		}
	} 
</style>