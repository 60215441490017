<template>
	<v-container>
		<v-card>
			<v-card-title align="left" class="headline primary disbursement-title">
				<h5>Integrated Transactions</h5>
			</v-card-title>
			<v-data-table
				:headers="headers"
				:items="filteredItems"
				sort-by="document_number"
				class="elevation-1"
			hide-default-footer
			:search="search"
			:items-per-page="50"
			>
				<template v-slot:top>
					<v-toolbar flat color="white">
						<v-toolbar-title>{{trxnStatusName}}</v-toolbar-title>
						<v-spacer></v-spacer>
						<v-layout wrap>
						<v-flex md3 />
						<v-spacer/>
						<v-flex xs12 sm6 md3>
							<v-text-field append-icon="mdi-calendar" label="Month and Year" required v-model="month_year" @click="month_year_picker = true" hide-details />
							<v-dialog width="25%" v-if="month_year_picker" v-model="month_year_picker"> 
								<v-date-picker
									v-model="month_year"
									type="month"
									@change="month_year_picker = false; getData()"
								></v-date-picker>
							</v-dialog>
						</v-flex>
						<v-spacer/>
						<v-flex xs12 sm6 md5>
							<v-text-field
								v-model="search"
								append-icon="search"
								label="Search"
								single-line
								hide-details
							></v-text-field>
						</v-flex>
						</v-layout>
					</v-toolbar>
				</template>
				<template v-slot:item.total_amount="{ item }">
					<span>{{formatAmount(item.total_amount)}}</span>
				</template>
				<template v-slot:item.action="{ item }">
					<v-icon color="primary" class="mr-2" small @click="viewItem(item)">mdi-eye</v-icon>
					<v-icon color="red" class="mr-2" v-if="role_access===4" @click="deleteTransaction(item)" small >mdi-delete</v-icon>
					<v-icon color="gray" class="mr-2" v-else small >mdi-delete-off</v-icon>
				</template>
				<template v-slot:body.append>
					<tr>
						<td colspan="8">
							<v-layout>
								<v-spacer></v-spacer>
								<v-flex md1>
									<v-layout justify-center>
										<v-btn
											:disabled="parseInt(trxintegrated.current_page)==1"
											@click="changePrevData"
											class="ma-2"
											text
											icon
										>
											<v-icon :disabled="parseInt(trxintegrated.current_page)==1">mdi-chevron-left</v-icon>
										</v-btn>
									</v-layout>
								</v-flex>
								<v-flex md2 style="padding-top:15px;text-align: center">
									<p class="font-weight-light text--primary body-2">
										Page {{trxintegrated.current_page}} ( {{trxintegrated.from}} - {{trxintegrated.to}} )
									</p>
								</v-flex>
								<v-flex md1>
									<v-layout justify-center>
										<v-btn
											@click="changeNextData"
											class="ma-2"
											:disabled="!trxintegrated.next_page_url"
											text
											icon
										>
											<v-icon :disabled="!trxintegrated.next_page_url">mdi-chevron-right</v-icon>
										</v-btn>
									</v-layout>
								</v-flex>
							</v-layout>
						</td>
					</tr>
				</template>
			</v-data-table>
			<div class="text-center">
				<v-dialog
					persistent
					v-model="viewDialog"
					:items="batchDetails"
					width="1200"
				>
					<v-trx-detail ref="detailTrxdisb" :role_access.sync="role_access" :batchDetails.sync="batchDetails" :viewDialog.sync="viewDialog"></v-trx-detail>
				</v-dialog>
			</div>
			<div class="text-center">
				<v-dialog
					v-model="deleteDialog"
					persistent max-width="600"
				>
					<v-card>
						<v-card-title
							class="headline primary white--text"
							primary-title
						>
							Delete Transaction
						</v-card-title>
						<br>
						<v-card-text>
							Are you sure you want to delete <b>{{selectedItem.batch_number}}</b> ?
						</v-card-text>

						<v-divider></v-divider>

						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn
								color="primary"
								text
								@click="deleteIntegratedTransaction()"
							>
								Yes
							</v-btn>
							<v-btn
								color="error"
								text
								@click="deleteDialog = false"
							>
								No
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</div>
		</v-card>
	</v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { format, getYear, getMonth, parseISO } from 'date-fns';
import numeral from 'numeral';
import detailIntegratedVue from './detailIntegrated.vue';

export default {
	components: {
		'v-trx-detail': detailIntegratedVue,
	},
	data() {
		return {
			module_name: 'Integrated Transactions',
			role_access: 1,
			search: '',
			month_year: '',
			month_year_picker: false,
			fileDetails: [],
			deleteDialog: false,
			trxnStatusName: 'List',
			selectedItem: {},
			batchDetails : [],
		};
	},
	computed: {
		...mapGetters({
			trxintegrated: 'trxintegrated/trxintegrated',
			headers: 'trxintegrated/headers',
			valid: 'trxintegrated/valid',
			currUser: 'auth/currUser',
			editedIndex: 'trxintegrated/editedIndex',
			viewDialog: 'trxintegrated/viewDialog',
		}),
		years() {
			const year = new Date().getFullYear();
			const generateYears = Array.from({ length: year - 1900 },(value, index) => 1901 + index);
			generateYears.sort();
			return generateYears.reverse();
		},
		filteredItems() {
			let txn = [];
			txn = this.trxintegrated.data;
			if(txn.length !== 0){
				txn.forEach(i => {
					i.date_encoded = format(parseISO(i.date_encoded), 'yyyy-MM-dd');
				});
			}
			console.log(txn);
			return txn;
		}
	},
	watch: {
		editedIndex: {
			handler(val) {
				this.$emit('update:editedIndex', val);
			}
		},
	},
	mounted() {
		const that = this;
		let date = format(new Date(), 'yyyy-MM-dd');
		let month = getMonth(parseISO(date)) + 1;
		let year = getYear(parseISO(date));
		let i = (month.toString().length == 2 ? '-' : '-0');
		that.month_year = year + i + month;
		let payload = {document_date: that.month_year + '-01', department_id : this.currUser.dept_id};
		this.$store.dispatch('trxintegrated/getIntegratedTransaction', payload);

		let roles = this.currUser.roleaccess;
		if(roles.length > 0){
			let roleaccess = roles.filter(r => r.module_name === this.module_name);
			if(roleaccess.length > 0){
				this.role_access = roleaccess[0].access_level;
			}
		}
	},
	methods: {
		async viewItem(item) {
			console.log(item);
			this.batchDetails = [];
			this.batchDetails.batch_number = item.batch_number;
			this.batchDetails.total_amount = item.total_amount;
			this.batchDetails.document_type = item.document_type;
			this.batchDetails.status = item.status;

			let disbList = this.trxintegrated.data;
			disbList = Object.values(this.trxintegrated.data);
			this.$store.dispatch('trxintegrated/setEditedIndex', disbList.indexOf(item));
			this.$store.dispatch('trxintegrated/getDetails', item.batch_number);
			// await this.$store.dispatch('trxdisb/getDetails', item.batch_number);
		},
		formatAmount(amount){
			return numeral(amount).format('0,0.00');
		},
		getData(){
			this.$store.dispatch('trxintegrated/getIntegratedTransaction', {document_date: this.month_year + '-01', department_id : this.currUser.dept_id});
		},
		deleteTransaction(item){
			this.deleteDialog = true;
			this.$store.dispatch('trxintegrated/setEditedIndex', this.trxintegrated.data.indexOf(item));
			this.selectedItem = item; 
		},
		deleteIntegratedTransaction(){
			let item = this.selectedItem;
			let data = {
				'batch_number': item.batch_number,
				'deleted_by': this.currUser.id,
				'status' : item.status
			};
			this.$store.dispatch('trxintegrated/deleteIntegratedTransaction', data);
			this.deleteDialog = false;
		},
		changeNextData() {
			var nextPage = this.trxintegrated.current_page + 1;
			this.$store.dispatch('trxintegrated/getDataPages', {page: nextPage,document_date: this.month_year + '-01', department_id : this.currUser.dept_id});
		},

		changePrevData() {
			var prevPage = this.trxintegrated.current_page - 1;
			this.$store.dispatch('trxintegrated/getDataPages', {page: prevPage,document_date: this.month_year + '-01', department_id : this.currUser.dept_id});
		}
	},
};



</script>

<style scoped>
	.disbursement-title h5 {
		color: #FFF;
		font-size: 25px;
	}
	
	.date-filter {
		display: flex;
		flex-direction: row;
		position: relative;
		top: 10.5px; 
		right: 10px;
	}
	.date-filter-picker {
		width: 150px;
	}
	.date-filter-picker:first-child {
		margin-right: 10px;
	}

	.document-card-title {
		padding: 0;
	}
</style>
