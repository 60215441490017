const SET_EDITED_INDEX = (state, payload) => {
	state.editedIndex = payload;
};

const SET_SUCCESS = (state, payload) => {
	state.success = payload;
};

const SET_DETAIL_MODAL_VIEW = (state,payload) => {
	state.viewDialog = payload;
};

const TRX_DELETE_INTEGRATED = (state) => {
	state.trxintegrated.data.splice(state.editedIndex, 1);
};

const TRX_INTEGRATED = (state, payload) => {
	state.trxintegrated = payload;
};

const TRX_INTEGRATED_DETAIL = (state, payload) => {
	state.trxintegratedDetails = payload;
};

export default {
	SET_EDITED_INDEX,
	SET_SUCCESS,
	TRX_DELETE_INTEGRATED,
	TRX_INTEGRATED,
	SET_DETAIL_MODAL_VIEW,
	TRX_INTEGRATED_DETAIL
};