import Repository from '../Repository';

const resource = '/billingservices';

export default {
	get() {
		return Repository.get(`${resource}`);
	},

	getServices(id) {
		return Repository.get(`${resource}/${id}`);
	},

	saveServices(payload) {
		return Repository.post(`${resource}`, payload);
	},

	updateServices(id, payload) {
		return Repository.put(`${resource}/${id}`, payload);
	},

	deleteServices(id) {
		return Repository.delete(`${resource}/${id}`);
	},

	searchServices(payload){
		return Repository.get(`${resource}`,payload);
	},

	getData(payload){
		return Repository.get(`${resource}?page=`+ payload);
	}
};