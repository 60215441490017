import Repository from '../Repository';
import RepositoryDownload from '../RepositoryDownload';

const resource = '/trx/header';

export default {
	get(payload) {
		return Repository.get(`${resource}`, {params: payload});
	},

	getTrxdisb(id) {
		return Repository.post(`${resource}/${id}`);
	},
	saveTrxdisb(payload) {
		return Repository.post(`${resource}`, 
			payload,
			{
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}
		);
	},
	updateTrxdisb(id, payload) {
		return Repository.post(`${resource}/${id}`,
			payload,
			{
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}
		);
	},
	voidTrxDisb(payload){
		return Repository.post('/trx/void',
			payload,
			{
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}
		);
	},
	updateTrxdisbStatus(id, payload) {
		return Repository.post(`${resource}/${id}`,payload);
	},
	deleteTrxdisb(id) {
		return Repository.delete(`${resource}/${id}`);
	},
	searchTrxdisb(payload) {
		return Repository.get(`${resource}`, payload);
	},
	getBIRFormData(batch_number) {
		return Repository.post(`${'/trx/print-2307'}/${batch_number}`);
	},
	getBatchNumber(resource, payload){
		return Repository.post(`${resource}`, payload);
	},
	getDocumentNumber(resource, payload){
		return Repository.post(`${resource}`, payload);
	},
	getDocumentNumberCheque(resource){
		return Repository.post(`${resource}`);
	},
	getPrintData(payload){
		return Repository.post(`${'/trx/print-dm'}/${payload}`);
	},
	getPrintEReceiptData(payload){
		return RepositoryDownload.post(`${'/trx/print-orar'}/${payload}`);
	},
	getPrintReceiptData(payload){
		return RepositoryDownload.post(`${'/trx/print-orar'}/${payload}`);
	},
	getDetail(payload){
		return Repository.post(`${resource}/${payload}`);
	},
	uploadHeaderIS(payload) {
		return Repository.post(`${'/trx/up-header-is'}`, payload,
			{
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}
		);
	},
	uploadDetailIS(payload) {
		return Repository.post(`${'/trx/up-detail-is'}`, payload,
			{
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}
		);
	},
	uploadHeaderPayroll(payload) {
		return Repository.post(`${'/trx/up-header-p'}`, payload,
			{
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}
		);
	},
	uploadDetailPayroll(payload) {
		return Repository.post(`${'/trx/up-detail-p'}`, payload,
			{
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}
		);		
	},
	uploadEnties(payload) {
		return Repository.post(`${'/trx/up-entries'}`, payload,
			{
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}
		);		
	},
	getCSVUploads() {
		return Repository.get(`${'/trx/csv-upload'}`);
	},
	
	getData(payload){
		return Repository.get(`${resource}?page=`+ payload);
	},
	
	getCSVUploadPages(payload) {
		return Repository.get(`${'/trx/csv-upload?page='}` + payload);
	},

	getIntegratedTransaction(payload) {
		return Repository.get(`${'/trx/isfis'}`, {params: payload});
	},

	deleteIntegratedTransaction(payload) {
		return Repository.post(`${'/trx/deltrx'}`, payload);
	},

	getReceiptDetail(payload) {
		return Repository.post(`${payload.url}/${payload.batch_number}`);
	},
	
};