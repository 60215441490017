export default {
	billings: [],
	billingDetails: [],
	billingPrintDetails: [],
	headersBilling: [
		{
			text: 'Request Number',
			align: 'left',
			sortable: false,
			value: 'billing_request_no',
		},
		{
			text: 'Invoice Number',
			align: 'left',
			sortable: false,
			value: 'billing_invoice_no',
		},
		{ text: 'Customer Name', value: 'payee_desc' },
		{ text: 'Status', value: 'status' },
		{ text: 'Request Date', value: 'request_date' },
		{ text: 'Collection Status', value: 'collection_status' },
		{ text: 'Amount', value: 'total_amount', align: 'right' },
		{ text: 'Actions', value: 'action', sortable: false },
	],
	headers: [
		{
			text: 'Request Number',
			align: 'left',
			sortable: false,
			value: 'billing_request_no',
		},
		
		{ text: 'Customer Name', value: 'payee_desc' },
		{ text: 'Status', value: 'status' },
		{ text: 'Request Date', value: 'request_date' },
		{ text: 'Amount', value: 'total_amount', align: 'right' },
		{ text: 'Actions', value: 'action', sortable: false },
	],
	dialog: false,
	editDialog: false,
	viewDialog: false,
	attachmentDialog: false,
	editedIndex: -1,
	editedItem: {},
	valid: true,
	formTitle: 'Edit entry',
	preparer: '',
	dist_data: '',
	billingDetailed: '',
	success: false,
	printDetails: [],
	uncollectedBilling: [],
	billingRequestNumber: '',
	billingNumber: ''
};