/* eslint-disable */

import Vue from "vue";
import Router from "vue-router";
import store from "./store/index";

import PreviewTrx from './components/pages/trx/printTrx.vue';
import PurchaseOrder from './components/pages/trx/purchasing/PurchaseOrder.vue';
import PrintCheque from './components/pages/trx/disbursement/printCheque.vue';
import PrintVoucher from './components/pages/trx/disbursement/printVoucher.vue';
import PrintReceipt from './components/pages/trx/cashreceipt/printReceipt.vue';
import PrintEReceipt from './components/pages/trx/cashreceipt/printEReceipt.vue';
import PrintEReceiptBilling from './components/pages/trx/billing/printInvoice.vue';
// import PrintColEReceipt from './components/pages/trx/cashreceipt/printColEReceipt.vue';
import PrintOR from './components/pages/trx/cashreceipt/printOfficialReceipt.vue';
import PrintAR from './components/pages/trx/cashreceipt/printAcknowledgementReceipt.vue';
import PrintBilling from './components/pages/trx/billing/printBilling.vue';
import PrintCollOR from './components/pages/trx/collection/printOfficialReceipt.vue';
import PrintCollAR from './components/pages/trx/collection/printAcknowledgementReceipt.vue';
import tableTrxArchive from './components/pages/trx/tableTrxArchive.vue';
import tableTrxActive from './components/pages/trx/tableActiveTrx.vue';
import tableTrxIntegrated from './components/pages/trx/integrated/tableIntegratedTrx.vue';
import ReportTrialBalance from './components/pages/report/trialbalance/trialbalance.vue';
import ReportSubsidiary from './components/pages/report/subsidiary/subsidiary.vue';
import ReportBillingList from './components/pages/report/billingList/billingList.vue';
import ReportARAging from './components/pages/report/arAging/arAgingCustomer.vue';
import ReportARAgingInvoice from './components/pages/report/arAging/arAgingInvoice.vue';
import ReportSubsidiaryByAnalysis from './components/pages/report/subsidiarybyanalysis/subsidiaryByAnalysis.vue';
import ReportRfa from './components/pages/report/rfa/rfa.vue';
import ReportCibSubsidiary from './components/pages/report/cibSubsidiary/cibSubsidiary.vue';
import ReportBoa from './components/pages/report/boa/boa.vue';
import ReportAuditTrail from './components/pages/report/auditTrail/auditTrail.vue';
import ReportSalesJournal from './components/pages/report/salesJournal/salesJournal.vue';
import ReportProfitability from './components/pages/report/profitability/profitability.vue';

Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: "/",
      name: "dashboard",
      component: () => import("./views/Dashboard.vue"),
      meta: { requiresAuth: true, breadcrumb: [{ text: "Dashboard", disabled: false }] }
    },
    {
      path: "/about",
      name: "about",
      component: () =>
        import(/* webpackChunkName: "about" */ "./views/About.vue")
    },
    {
      path: "/coa",
      name: "coa",
      component: () => import("./views/maint/Coa.vue"),
      meta: {
        requiresAuth: true,
        breadcrumb: [
          { text: "File Maintenance", disabled: false, href: "" },
          { text: "Chart of Accounts", disabled: false, href: "" }
        ]
      }
    },
    {
      path: "/sections",
      name: "sections",
      component: () => import("./views/maint/Sections.vue"),
      meta: {
        requiresAuth: true,
        breadcrumb: [
          { text: "File Maintenance", disabled: false, href: "" },
          { text: "Sections", disabled: false, href: "" }
        ]
      }
    },
    // {
    //   path: '/branches/add',
    //   name: 'addbranches',
    //   component: () => import('./components/pages/branch/newBranch.vue'),
    //   meta: { requiresAuth: true }
    // },
    {
      path: "/branches",
      name: "branches",
      component: () => import("./views/maint/Branches.vue"),
      meta: {
        requiresAuth: true,
        breadcrumb: [
          { text: "File Maintenance", disabled: false, href: "" },
          { text: "Branches", disabled: false, href: "" }
        ]
      }
    },
    {
      path: "/depts",
      name: "depts",
      component: () => import("./views/maint/Departments.vue"),
      meta: {
        requiresAuth: true,
        breadcrumb: [
          { text: "File Maintenance", disabled: false, href: "" },
          { text: "Departments", disabled: false, href: "" }
        ]
      }
    },
    {
      path: "/companies",
      name: "companies",
      component: () => import("./views/maint/Companies.vue"),
      meta: {
        requiresAuth: true,
        breadcrumb: [
          { text: "File Maintenance", disabled: false, href: "" },
          { text: "Companies", disabled: false, href: "" }
        ]
      }
    },
    {
      path: "/businesspartners",
      name: "businesspartners",
      component: () => import("./views/maint/Businesspartners.vue"),
      meta: {
        requiresAuth: true,
        breadcrumb: [
          { text: "File Maintenance", disabled: false, href: "" },
          { text: "Business Partners", disabled: false, href: "" }
        ]
      }
    },
    {
      path: "/bplocations",
      name: "bplocations",
      component: () => import("./views/maint/Bplocations.vue"),
      meta: {
        requiresAuth: true,
        breadcrumb: [
          { text: "File Maintenance", disabled: false, href: "" },
          { text: "BP Locations", disabled: false, href: "" }
        ]
      }
    },
    {
      path: "/bpbanks",
      name: "bpbanks",
      component: () => import("./views/maint/Bpbanks.vue"),
      meta: {
        requiresAuth: true,
        breadcrumb: [
          { text: "File Maintenance", disabled: false, href: "" },
          { text: "BP Banks", disabled: false, href: "" }
        ]
      }
    },
    {
      path: "/banks",
      name: "banks",
      component: () => import("./views/maint/Banks.vue"),
      meta: {
        requiresAuth: true,
        breadcrumb: [
          { text: "File Maintenance", disabled: false, href: "" },
          { text: "Banks", disabled: false, href: "" }
        ]
      }
    },
    {
      path: "/users",
      name: "users",
      component: () => import("./views/maint/Users.vue"),
      meta: {
        requiresAuth: true,
        breadcrumb: [
          { text: "File Maintenance", disabled: false, href: "" },
          { text: "Users", disabled: false, href: "" }
        ]
      }
    },
    {
      path: "/bankheader",
      name: "bankheader",
      component: () => import("./views/maint/Bankheader.vue"),
      meta: {
        requiresAuth: true,
        breadcrumb: [
          { text: "File Maintenance", disabled: false, href: "" },
          { text: "Bank Headers", disabled: false, href: "" }
        ]
      }
    },
    {
      path: "/currency",
      name: "currency",
      component: () => import("./views/maint/Currency.vue"),
      meta: {
        requiresAuth: true,
        breadcrumb: [
          { text: "File Maintenance", disabled: false, href: "" },
          { text: "Currencies", disabled: false, href: "" }
        ]
      }
    },
    {
      path: "/regionalhead",
      name: "regionalhead",
      component: () => import("./views/maint/Regionalhead.vue"),
      meta: {
        requiresAuth: true,
        breadcrumb: [
          { text: "File Maintenance", disabled: false, href: "" },
          { text: "Regional Head", disabled: false, href: "" }
        ]
      }
    },
    {
      path: "/roles",
      name: "roles",
      component: () => import("./views/maint/Roles.vue"),
      meta: {
        requiresAuth: true,
        breadcrumb: [
          { text: "File Maintenance", disabled: false, href: "" },
          { text: "Roles", disabled: false, href: "" }
        ]
      }
    },
    {
      path: "/roleaccess",
      name: "roles-access",
      component: () => import("./views/maint/RoleAccess.vue"),
      meta: {
        requiresAuth: true,
        breadcrumb: [
          { text: "File Maintenance", disabled: false, href: "" },
          { text: "Role Access", disabled: false, href: "" }
        ]
      }
    },
    {
      path: "/services",
      name: "billing-services",
      component: () => import("./views/maint/BillingServices"),
      meta: {
        requiresAuth: true,
        breadcrumb: [
          { text: "File Maintenance", disabled: false, href: "" },
          { text: "Billing Services", disabled: false, href: "" }
        ]
      }
    },{
      path: "/billing-invoice",
      name: "billing-invoice",
      component: () => import("./views/maint/BillingInvoice"),
      meta: {
        requiresAuth: true,
        breadcrumb: [
          { text: "File Maintenance", disabled: false, href: "" },
          { text: "Billing Invoice", disabled: false, href: "" }
        ]
      }
    },
    {
      path: "/module",
      name: "module",
      component: () => import("./views/maint/Module.vue"),
      meta: {
        requiresAuth: true,
        breadcrumb: [
          { text: "File Maintenance", disabled: false, href: "" },
          { text: "Module", disabled: false, href: "" }
        ]
      }
    },
    {
      path: "/dollarrate",
      name: "dollarrate",
      component: () => import("./views/maint/DollarRate.vue"),
      meta: {
        requiresAuth: true,
        breadcrumb: [
          { text: "File Maintenance", disabled: false, href: "" },
          { text: "Rates", disabled: false, href: "" }
        ]
      }
    },
    {
      path: "/acctperiodmain",
      name: "acctperiodmain",
      component: () => import("./views/maint/Acctperiodmain.vue"),
      meta: {
        requiresAuth: true,
        breadcrumb: [
          { text: "File Maintenance", disabled: false, href: "" },
          { text: "Accounting Period", disabled: false, href: "" }
        ]
      }
    },
    {
      path: "/useraccessprivilege",
      name: "useraccessprivilege",
      component: () => import("./views/maint/Useraccessprivilege.vue"),
      meta: {
        requiresAuth: true,
        breadcrumb: [
          { text: "File Maintenance", disabled: false, href: "" },
          { text: "User Roles", disabled: false, href: "" }
        ]
      }
    },
    {
      path: "/alphataxcode",
      name: "alphataxcode",
      component: () => import("./views/maint/Alphataxcode.vue"),
      meta: {
        requiresAuth: true,
        breadcrumb: [
          { text: "File Maintenance", disabled: false, href: "" },
          { text: "ATC Codes", disabled: false, href: "" }
        ]
      }
    },
    {
      path: "/payefile",
      name: "payefile",
      component: () => import("./views/maint/Payefile.vue"),
      meta: {
        requiresAuth: true,
        breadcrumb: [
          {
            text: "File Maintenance",
            disabled: false,
            href: ""
          },
          {
            text: "Payee Details",
            disabled: false,
            href: ""
          }
        ]
      }
    },
    {
      path: "/transaction",
      name: "transaction",
      component: () => import("./views/maint/Transaction.vue"),
      meta: {
        requiresAuth: true,
        breadcrumb: [
          {
            text: "File Maintenance",
            disabled: false,
            href: ""
          },
          {
            text: "Transaction Type",
            disabled: false,
            href: ""
          }
        ]
      }
    },
    {
      path: "/approvingofficers",
      name: "Approving Officers",
      component: () => import("./views/maint/ApprovingOfficers.vue"),
      meta: {
        requiresAuth: true,
        breadcrumb: [
          {
            text: "File Maintenance",
            disabled: false,
            href: ""
          },
          {
            text: "Approving Officers",
            disabled: false,
            href: ""
          }
        ]
      }
    },
    ,
    {
      path: "/billing-officers",
      name: "BillingApproving Officers",
      component: () => import("./views/maint/BillingApprovingOfficers.vue"),
      meta: {
        requiresAuth: true,
        breadcrumb: [
          {
            text: "File Maintenance",
            disabled: false,
            href: ""
          },
          {
            text: "Billing Approving Officers",
            disabled: false,
            href: ""
          }
        ]
      }
    },
    {
      path: "/approvinglevels",
      name: "Approving Levels",
      component: () => import("./views/maint/ApprovingLevels.vue"),
      meta: {
        requiresAuth: true,
        breadcrumb: [
          {
            text: "File Maintenance",
            disabled: false,
            href: ""
          },
          {
            text: "Approving Levels",
            disabled: false,
            href: ""
          }
        ]
      }
    },
    {
      path: "/regulartxns",
      name: "Regular Transactions",
      component: () => import("./views/maint/RegularTransactions.vue"),
      meta: {
        requiresAuth: true,
        breadcrumb: [
          {
            text: "File Maintenance",
            disabled: false,
            href: ""
          },
          {
            text: "Regular Transactions",
            disabled: false,
            href: ""
          }
        ]
      }
    },
    {
      path: "/voucher",
      name: "voucher",
      component: () => import("./views/maint/VoucherSeries.vue"),
      meta: {
        requiresAuth: true,
        breadcrumb: [
          { text: "File Maintenance", disabled: false, href: "" },
          { text: "Voucher Series", disabled: false, href: "" }
        ]
      }
    },
    {
      path: "/official_reciept",
      name: "official_reciept",
      component: () => import("./views/maint/OfficialReceipt.vue"),
      meta: {
        requiresAuth: true,
        breadcrumb: [
          { text: "File Maintenance", disabled: false, href: "" },
          { text: "Official Reciept", disabled: false, href: "" }
        ]
      }
    },
    {
      path: "/acknowledgement_receipt",
      name: "acknowledgement_receipt",
      component: () => import("./views/maint/AcknowledgementReceipt.vue"),
      meta: {
        requiresAuth: true,
        breadcrumb: [
          { text: "File Maintenance", disabled: false, href: "" },
          { text: "Acknowlegdement Receipt", disabled: false, href: "" }
        ]
      }
    },
    {
      path: "/trxdisb",
      props: true,
      component: () => import("./views/trx/Trxdisb.vue"),
      meta: {
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Transactions",
            disabled: false,
            href: ""
          },
          {
            text: "Disbursements",
            disabled: false,
            href: ""
          }
        ]
      },
      children: [
        {
          path: '',
          name: "trxdisb",
          component: () => import("./components/pages/trx/disbursement/tableTrxdisb.vue"),
          meta: {
            requiresAuth: true,
            breadcrumb: [
              {
                text: "Transactions",
                disabled: false,
                href: ""
              },
              {
                text: "Disbursements",
                disabled: false,
                href: ""
              }
            ]
          }
        },
        { 
          path: 'create', 
          component: () => import("./components/pages/trx/disbursement/create/createTrxDisb.vue"),
          meta: {
            requiresAuth: true,
            breadcrumb: [
              {
                text: "Transactions",
                disabled: false,
                href: ""
              },
              {
                text: "Disbursements",
                disabled: false,
                href: "/trxdisb"
              },
              {
                text: "Create",
                disabled: false,
                href: ""
              }
            ]
          },
        },
      ]
    },
    {
      path: "/trxcr",
      props: true,
      component: () => import("./views/trx/Trxcr.vue"),
      meta: {
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Transactions",
            disabled: false,
            href: ""
          },
          {
            text: "Cash Receipt",
            disabled: false,
            href: ""
          }
        ]
      },
      children: [
        {
          path: '',
          name: "trxcr",
          component: () => import("./components/pages/trx/cashreceipt/tableCashReceipt.vue"),
          meta: {
            requiresAuth: true,
            breadcrumb: [
              {
                text: "Transactions",
                disabled: false,
                href: ""
              },
              {
                text: "Cash Receipt",
                disabled: false,
                href: ""
              }
            ]
          }
        },
        { 
          path: 'create', 
          component: () => import("./components/pages/trx/cashreceipt/create/createCashReceipt.vue"),
          meta: {
            requiresAuth: true,
            breadcrumb: [
              {
                text: "Transactions",
                disabled: false,
                href: ""
              },
              {
                text: "Cash Receipt",
                disabled: false,
                href: "/trxcr"
              },
              {
                text: "Create",
                disabled: false,
                href: ""
              }
            ]
          },
        },
      ]
    },
    {
      path: "/trx-billing",
      props: true,
      component: () => import("./views/trx/TrxBilling.vue"),
      meta: {
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Transactions",
            disabled: false,
            href: ""
          },
          {
            text: "Billing",
            disabled: false,
            href: ""
          }
        ]
      },
      children: [
        {
          path: '',
          name: "trx-billing",
          component: () => import("./components/pages/trx/billing/tableBilling.vue"),
          meta: {
            requiresAuth: true,
            breadcrumb: [
              {
                text: "Transactions",
                disabled: false,
                href: ""
              },
              {
                text: "Billing",
                disabled: false,
                href: ""
              }
            ]
          }
        },
        { 
          path: 'create', 
          component: () => import("./components/pages/trx/billing/create/createBilling.vue"),
          meta: {
            requiresAuth: true,
            breadcrumb: [
              {
                text: "Transactions",
                disabled: false,
                href: ""
              },
              {
                text: "Billing",
                disabled: false,
                href: "/trx-billing"
              },
              {
                text: "Create",
                disabled: false,
                href: ""
              }
            ]
          },
        },
      ]
    },
    {
      path: "/trx-collection",
      props: true,
      component: () => import("./views/trx/TrxCollection.vue"),
      meta: {
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Transactions",
            disabled: false,
            href: ""
          },
          {
            text: "Colletion",
            disabled: false,
            href: ""
          }
        ]
      },
      children: [
        {
          path: '',
          name: "trx-collection",
          component: () => import("./components/pages/trx/collection/tableCollection.vue"),
          meta: {
            requiresAuth: true,
            breadcrumb: [
              {
                text: "Transactions",
                disabled: false,
                href: ""
              },
              {
                text: "Collection",
                disabled: false,
                href: ""
              }
            ]
          }
        },
        { 
          path: 'create', 
          component: () => import("./components/pages/trx/collection/create/createCollection.vue"),
          meta: {
            requiresAuth: true,
            breadcrumb: [
              {
                text: "Transactions",
                disabled: false,
                href: ""
              },
              {
                text: "Collection",
                disabled: false,
                href: "/trx-collection"
              },
              {
                text: "Create",
                disabled: false,
                href: ""
              }
            ]
          },
        },
      ]
    },
    { 
      path: '/trial-balance', 
      name: 'trial-balance',
      component: () => import("./views/reports/TrialBalance.vue"),
      meta: {
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Reports",
            disabled: false,
            href: ""
          },
          {
            text: "Trial Balance",
            disabled: false,
            href: ""
          }
        ]
      },
    },
    { 
      path: '/ar-aging', 
      name: 'ar-aging',
      component: () => import("./views/reports/ARAging.vue"),
      meta: {
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Reports",
            disabled: false,
            href: ""
          },
          {
            text: "AR Aging",
            disabled: false,
            href: ""
          }
        ]
      },
    },
    { 
      path: '/billing-list', 
      name: 'billing-list',
      component: () => import("./views/reports/BillingList.vue"),
      meta: {
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Reports",
            disabled: false,
            href: ""
          },
          {
            text: "Billing List",
            disabled: false,
            href: ""
          }
        ]
      },
    },,
    { 
      path: '/subsidiary', 
      name: 'subsidiary',
      component: () => import("./views/reports/Subsidiary.vue"),
      meta: {
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Reports",
            disabled: false,
            href: ""
          },
          {
            text: "Subsidiary",
            disabled: false,
            href: ""
          }
        ]
      },
    },
    { 
      path: '/subsidiary-analysis', 
      name: 'subsidiary-analysis',
      component: () => import("./views/reports/SubsidiaryAnalysis.vue"),
      meta: {
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Reports",
            disabled: false,
            href: ""
          },
          {
            text: "Subsidiary Analysis",
            disabled: false,
            href: ""
          }
        ]
      },
    },{ 
      path: '/rfa', 
      name: 'rfa',
      component: () => import("./views/reports/Rfa.vue"),
      meta: {
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Reports",
            disabled: false,
            href: ""
          },
          {
            text: "Revolving Fund Account",
            disabled: false,
            href: ""
          }
        ]
      },
    },{ 
      path: '/cibSubsidiary', 
      name: 'cibSubsidiary',
      component: () => import("./views/reports/CibSubsidiary.vue"),
      meta: {
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Reports",
            disabled: false,
            href: ""
          },
          {
            text: "CIB Subsidiary",
            disabled: false,
            href: ""
          }
        ]
      },
    },{ 
      path: '/boa', 
      name: 'boa',
      component: () => import("./views/reports/Boa.vue"),
      meta: {
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Reports",
            disabled: false,
            href: ""
          },
          {
            text: "Books Of Account",
            disabled: false,
            href: ""
          }
        ]
      },
    },
    { 
      path: '/auditTrail', 
      name: 'auditTrail',
      component: () => import("./views/reports/AuditTrail.vue"),
      meta: {
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Reports",
            disabled: false,
            href: ""
          },
          {
            text: "Audit Trail",
            disabled: false,
            href: ""
          }
        ]
      },
    },
    { 
      path: '/salesJournal', 
      name: 'salesJournal',
      component: () => import("./views/reports/SalesJournal.vue"),
      meta: {
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Reports",
            disabled: false,
            href: ""
          },
          {
            text: "Sales Journal",
            disabled: false,
            href: ""
          }
        ]
      },
    },
    { 
      path: '/profitability', 
      name: 'profitability',
      component: () => import("./views/reports/Profitability.vue"),
      meta: {
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Reports",
            disabled: false,
            href: ""
          },
          {
            text: "Profitability",
            disabled: false,
            href: ""
          }
        ]
      },
    },
    {
      path: '/purchase-order-print/:data',
      name: 'purchase-order-print',
      components: {
       printRouter: PurchaseOrder
      },
    },
    {
      path: "/trx-payment",
      props: true,
      component: () => import("./views/trx/TrxPayment.vue"),
      meta: {
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Transactions",
            disabled: false,
            href: ""
          },
          {
            text: "Payments",
            disabled: false,
            href: ""
          }
        ]
      },
      children: [
        {
          path: '',
          name: "trx-payment",
          component: () => import("./components/pages/trx/payment/tablePayment.vue"),
          meta: {
            requiresAuth: true,
            breadcrumb: [
              {
                text: "Transactions",
                disabled: false,
                href: ""
              },
              {
                text: "Payments",
                disabled: false,
                href: ""
              }
            ]
          }
        },]
    },
    {
      path: "/trx-purchase-order",
      props: true,
      component: () => import("./views/trx/TrxPurchaseOrder.vue"),
      meta: {
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Transactions",
            disabled: false,
            href: ""
          },
          {
            text: "Purchase Orders",
            disabled: false,
            href: ""
          }
        ]
      },
      children: [
        {
          path: '',
          name: "trx-purchase-order",
          component: () => import("./components/pages/trx/purchasing/tablePurchaseOrder.vue"),
          meta: {
            requiresAuth: true,
            breadcrumb: [
              {
                text: "Transactions",
                disabled: false,
                href: ""
              },
              {
                text: "Purchase Orders",
                disabled: false,
                href: ""
              }
            ]
          }
        },
        { 
          path: 'create', 
          component: () => import("./components/pages/trx/purchasing/create/createPurchaseOrder.vue"),
          meta: {
            requiresAuth: true,
            breadcrumb: [
              {
                text: "Transactions",
                disabled: false,
                href: ""
              },
              {
                text: "Purchase Orders",
                disabled: false,
                href: "/trx-purchase-order"
              },
              {
                text: "Add Purchase Order",
                disabled: false,
                href: ""
              }
            ]
          },
        },
        { 
          path: 'edit/:data', 
          component: () => import("./components/pages/trx/purchasing/edit/editPurchaseOrder.vue"),
          meta: {
            requiresAuth: true,
            breadcrumb: [
              {
                text: "Transactions",
                disabled: false,
                href: ""
              },
              {
                text: "Purchase Orders",
                disabled: false,
                href: "/trx-purchase-order"
              },
              {
                text: "Edit Purchase Order",
                disabled: false,
                href: ""
              }
            ]
          },
        },
      ]
    },
    {
      path: '/preview-trx/:data',
      name: 'preview-trx',
      components: {
       printRouter: PreviewTrx
      },
    },
    {
      path: '/preview-cheque/:data',
      name: 'preview-cheque',
      components: {
       printRouter: PrintCheque
      },
    },
    {
      path: '/preview-voucher/:data',
      name: 'preview-voucher',
      components: {
       printRouter: PrintVoucher
      },
    },
    {
      path: '/preview-receipt',
      name: 'preview-receipt',
      components: {
       printRouter: PrintReceipt
      },
    },
    {
      path: '/preview-ereceipt/:data',
      name: 'preview-ereceipt',
      components: {
       printRouter: PrintEReceipt
      },
    },
    {
      path: '/preview-bill-ereceipt/:data',
      name: 'preview-ereceipt-billing',
      components: {
       printRouter: PrintEReceiptBilling
      },
    },
    // {
    //   path: '/preview-col-ereceipt/:data',
    //   name: 'preview-col-ereceipt',
    //   components: {
    //    printRouter: PrintColEReceipt
    //   },
    // },
    {
      path: '/preview-OR/:data',
      name: 'preview-OR',
      components: {
       printRouter: PrintOR
      },
    },
    {
      path: '/preview-AR/:data',
      name: 'preview-AR',
      components: {
       printRouter: PrintAR
      },
    },
    {
      path: '/preview-billing/:data',
      name: 'preview-billing',
      components: {
       printRouter: PrintBilling
      },
    },
    {
      path: '/preview-collection-ar/:data',
      name: 'preview-collection-ar',
      components: {
       printRouter: PrintCollAR
      },
    },
    {
      path: '/preview-collection-or/:data',
      name: 'preview-collection-or',
      components: {
       printRouter: PrintCollOR
      },
    },
    {
      path: '/report-trialbalance/:data',
      name: 'report-trialbalance',
      components: {
       printRouter: ReportTrialBalance
      }
    },
    {
      path: '/report-subsidiary/:data',
      name: 'report-subsidiary',
      components: {
       printRouter: ReportSubsidiary
      }
    },
    {
      path: '/report-billing-list/:data',
      name: 'report-billing-list',
      components: {
       printRouter: ReportBillingList
      }
    },
    {
      path: '/report-araging/:data',
      name: 'report-araging',
      components: {
       printRouter: ReportARAging
      }
    },
    {
      path: '/report-araging-invoice/:data',
      name: 'report-araging-invoice',
      components: {
       printRouter: ReportARAgingInvoice
      }
    },
    {
      path: '/report-subsidiary-analysis/:data',
      name: 'report-subsidiary-analysis',
      components: {
       printRouter: ReportSubsidiaryByAnalysis
      }
    },
    {
      path: '/report-rfa/:data',
      name: 'report-rfa',
      components: {
       printRouter: ReportRfa
      }
    },
    {
      path: '/report-cibSubsidiary/:data',
      name: 'report-cibSubsidiary',
      components: {
       printRouter: ReportCibSubsidiary
      }
    },
    {
      path: '/report-boa/:data',
      name: 'report-boa',
      components: {
       printRouter: ReportBoa
      }
    },
    {
      path: '/report-auditTrail/:data',
      name: 'report-auditTrail',
      components: {
       printRouter: ReportAuditTrail
      }
    },
    {
      path: '/report-salesJournal/:data',
      name: 'report-salesJournal',
      components: {
       printRouter: ReportSalesJournal
      }
    },
    {
      path: '/report-profitability/:data',
      name: 'report-profitability',
      components: {
       printRouter: ReportProfitability
      }
    },
    {
      path: '/trx-archive',
      name: 'trx-archive',
      props: true,
      component: tableTrxArchive,
      meta: {
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Transactions",
            disabled: false,
            href: ""
          },
          {
            text: "Archived",
            disabled: false,
            href: ""
          }
        ]
      }
    },
    {
      path: '/trx-active',
      name: 'trx-active',
      props: true,
      component: tableTrxActive,
      meta: {
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Transactions",
            disabled: false,
            href: ""
          },
          {
            text: "Active",
            disabled: false,
            href: ""
          }
        ]
      }
    },
    {
      path: '/trx-integrated',
      name: 'trx-integrated',
      props: true,
      component: tableTrxIntegrated,
      meta: {
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Transactions",
            disabled: false,
            href: ""
          },
          {
            text: "Integrated",
            disabled: false,
            href: ""
          }
        ]
      }
    },
    {
      path: "/trxcheck",
      name: "trxcheck",
      component: () => import("./views/trx/Trxcheck.vue"),
      meta: {
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Transactions",
            disabled: false,
            href: ""
          },
          {
            text: "Disbursements",
            disabled: false,
            href: ""
          }
        ]
      }
    },
    {
      path: "/trx-gen-journal",
      props: true,
      component: () => import("./views/trx/TrxGenJournal.vue"),
      meta: {
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Transactions",
            disabled: false,
            href: ""
          },
          {
            text: "Journal Entries",
            disabled: false,
            href: ""
          }
        ]
      },
      children: [
        {
          path: '',
          name: "trx-gen-journal",
          component: () => import("./components/pages/trx/general-journal/tableGenJournal.vue"),
          meta: {
            requiresAuth: true,
            breadcrumb: [
              {
                text: "Transactions",
                disabled: false,
                href: ""
              },
              {
                text: "Journal Entries",
                disabled: false,
                href: ""
              }
            ]
          }
        },
        { 
          path: 'create', 
          component: () => import("./components/pages/trx/general-journal/create/createJournal.vue"),
          meta: {
            requiresAuth: true,
            breadcrumb: [
              {
                text: "Transactions",
                disabled: false,
                href: ""
              },
              {
                text: "Journal Entries",
                disabled: false,
                href: "/trx-gen-journal"
              },
              {
                text: "Add Entries",
                disabled: false,
                href: ""
              }
            ]
          },
        },
      ]
    },
    {
      path: '/search-transaction',
      props: true,
      meta: {
        requiresAuth: true,
      },
      name: 'search-transaction',
      component: () => import("./views/SearchResult.vue"),
    },
    {
      path: "/bir-form",
      name: "bir-form",
      component: () => import("./views/reports/BIRForm.vue"),
      props: true,
      meta: {
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Reports",
            disabled: false,
            href: ""
          },
          {
            text: "BIR Form 2307",
            disabled: false,
            href: ""
          }
        ]
      }
    },
    {
      path: "/trx-upload-is",
      component: () => import("./views/trx/TrxUploadFromIS.vue"),
      meta: {
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Transactions",
            disabled: false,
            href: ""
          },
          {
            text: "Upload from IS",
            disabled: false,
            href: ""
          }
        ]
      }
    },
    {
      path: "*",
      name: "unauthorize",
      //redirect: '/login',
      component: () => import("./views/Unauthorize.vue")
    }
  ],
  mode: "history"
  // base: process.env.BASE_URL,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    let loggedin = JSON.parse(localStorage.getItem("auth-data"));
    //let loggedin = Vue.$cookies.get("auth-data");
    // console.log(Vue.$cookies.get("auth-data"))
    if (loggedin) {
      store.dispatch("auth/setCurrUser", loggedin.user);
      next();
    } //else window.location = "login";
  } else {
    next();
  }
});

export default router;
