import Vue from 'vue';

const PAYEEBANKS_UPDATED = (state, payeebanks) => {
	state.payeebanks = payeebanks;

};
  
const PAYEEBANKS_ADD = (state, payload) => {
	state.payeebanks.push(payload);
};

const PAYEEBANKS_DELETE = (state) => {
	state.payeebanks.splice(state.editedIndex,1);
};

const SET_EDITED_ITEM = (state,payload) =>{
	state.editedItem = payload;
};

const SET_DIALOG = (state,payload) =>{
	state.dialog = payload;
};

const SET_EDITED_INDEX = (state,payload) =>{
	state.editedIndex = payload;
};

const PAYEEBANKS_ITEM_UPDATE = (state, payload) => {
	Vue.set(state.payeebanks, state.editedIndex, payload);
};

const SET_VALID = (state,payload) => {
	state.valid = payload;
};

const SET_FORM_TITLE = (state,payload) => {
	state.formTitle = payload;
};

export default {
	PAYEEBANKS_UPDATED,
	SET_EDITED_ITEM,
	SET_DIALOG,
	SET_EDITED_INDEX,
	SET_VALID,
	SET_FORM_TITLE,
	PAYEEBANKS_ADD,
	PAYEEBANKS_DELETE,
	PAYEEBANKS_ITEM_UPDATE
};