const regionalheads = state => state.regionalheads;
const headers = state => state.headers;
const rhCode = state => state.rhCode;
const rhName = state => state.rhName;
const editedItem = state => state.editedItem;
const defaultItem = state => state.defaultItem;
const dialog = state => state.dialog;
const editedIndex = state => state.editedIndex;
const valid = state => state.valid;
const formTitle = state => state.formTitle;

export default {
	regionalheads,
	headers,
	rhCode,
	rhName,
	editedItem,
	defaultItem,
	dialog,
	editedIndex,
	valid,
	formTitle
};