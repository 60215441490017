const dollarrates = state => state.dollarrates;
const headers = state => state.headers;
const columnHeaders = state => state.columnHeaders;
const fiscalYear = state => state.fiscalYear;
const periodNo = state => state.periodNo;
const dolrRate = state => state.dolrRate;
const currencyCode = state => state.currencyCode;
const accountClass = state => state.accountClass;
const editedItem = state => state.editedItem;
const defaultItem = state => state.defaultItem;
const dialog = state => state.dialog;
const editedIndex = state => state.editedIndex;
const valid = state => state.valid;
const formTitle = state => state.formTitle;

export default {
	dollarrates,
	columnHeaders,
	headers,
	fiscalYear,
	periodNo,
	dolrRate,
	currencyCode,
	accountClass,
	editedItem,
	defaultItem,
	dialog,
	editedIndex,
	valid,
	formTitle
};