import Repository from '../Repository';

const resource = '/payeebank';

export default {
	get() {
		return Repository.get(`${resource}`);
	},

	getBpbank(id) {
		return Repository.get(`${resource}/${id}`);
	},

	saveBpbank(payload) {
		return Repository.post(`${resource}`, payload);
	},

	updateBpbank(id, payload) {
		return Repository.put(`${resource}/${id}`, payload);
	},

	deleteBpbank(id) {
		return Repository.delete(`${resource}/${id}`);
	},
	searchBpbank(payload) {
		return Repository.get(`${resource}`, payload);
	}
};