export default {
	services: [],
	headers: [
		{
			text: 'Record ID',
			align: 'left',
			sortable: false,
			value: 'id',
		},
		{
			text: 'Payee',
			align: 'left',
			sortable: false,
			value: 'payee_name',
		},
		{
			text: 'Service',
			align: 'left',
			sortable: false,
			value: 'billing_service_name',
		},
		{ text: 'Actions', value: 'action', sortable: false },
	],
	columnHeaders: [
		{ label: 'Record ID', field: 'id' },
		{ label: 'Payee', field: 'payee_name' },
		{ label: 'Service', field: 'billing_service_name' }
	],
  
	editedItem: {
		id: '',
		roles: ''
	},
	defaultItem: {
		id: '',
		roles: ''
	},
	dialog: false,
	editedIndex: -1,
	valid: true,
	formTitle: 'Edit entry',
};