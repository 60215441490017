<template>
	<v-container fluid>
		<div v-for="(item, index) in poDataRender" :key="index">
			<div>
				<div class="float-container">
					<div class="float-child">
						<img src="@/assets/petnets.png" alt="">
					</div>
					<div class="float-child" style="text-align:right;">
						<div><p style="font-size: 30px; font-weight:bold;">PURCHASE ORDER</p></div>
						<table style="float:right;">
							<tr style="line-height: 20%">
								<td>
									<p>Date:</p>
								</td>
								<td>
									<p>{{formatDate(poData.po_date)}}</p>
								</td>
							</tr>
							<tr style="line-height: 20%">
								<td>
									<p>PO Number:</p>
								</td>
								<td>
									<p style="font-size: 25px;font-weight:bold">{{poData.po_number}}</p>
								</td>
							</tr>
						</table>
					</div>
				</div>
				<div class="float-container">
					<div class="float-child">
						<table style="table-layout: fixed; word-wrap: break-word;">
							<tr style="background-color:gray">
								<td colspan="7" style="color:white; font-weight:bold; padding-left:4px;">VENDOR</td>
							</tr>
							<tr>
								<td>Name:</td>
								<td>{{ supplier_name }}</td>
							</tr>
							<tr>
								<td>Contact Person:</td>
								<td>{{ poData.contact_person }}</td>
							</tr>
							<tr>
								<td>Address:</td>
								<td>{{ address }}</td>
							</tr>
							<tr>
								<td>Business Style:</td>
								<td>{{ business_style }}</td>
							</tr>
							<tr>
								<td>TIN:</td>
								<td>{{ tin }}</td>
							</tr>
							<tr>
								<td>Contact Number:</td>
								<td>{{ contact_number }}</td>
							</tr>
							<tr>
								<td>Email Address:</td>
								<td>{{ email_add }}</td>
							</tr>
						</table>
					</div>
					<div class="float-child">
						<table style="float:right;">
							<tr style="background-color:gray">
								<td colspan="7" style="color:white; font-weight:bold; padding-left:4px;">DELIVER TO</td>
							</tr>
							<tr>
								<td>Name:</td>
								<td>PETNET, Inc.</td>
							</tr>
							<tr>
								<td>Contact Person:</td>
								<td>{{ getUser(poData.edit_by) }}</td>
							</tr>
							<tr>
								<td>Address:</td>
								<td>114 East Offices Bldg, Makati City</td>
							</tr>
							<tr>
								<td>Business Style:</td>
								<td>PETNET, Inc.</td>
							</tr>
							<tr>
								<td>TIN:</td>
								<td>201-200-777-000</td>
							</tr>
							<tr>
								<td>Contact Number:</td>
								<td>{{ contact_number_env }}</td>
							</tr>
							<tr>
								<td>Email Address:</td>
								<td>{{ poData.email_add }}</td>
							</tr>
						</table>
					</div>
				</div>
				<div style="padding: 10px;">		
					<table style="width: 100%; justify-content: center; text-align: center; border: 1px solid black;">
						<tr style="background-color:gray; color:white;">
							<th>PAYMENT TERM</th>
							<th>SHIPPING DATE</th>
							<th>SUBJECT TO VAT</th>
						</tr>
						<tr>
							<td>{{poData.payment_term}} days from delivery</td>
							<td>{{formatDate(poData.shipping_date)}}</td>
							<td>{{poData.subject_to_vat ? 'Yes' : 'No'}}</td>
						</tr>
					</table>
				</div>
				<div style="padding: 10px;">
					<table class="hello" style="width: 100%; justify-content: center; text-align: center; border: 1px solid black; empty-cells:show;">
						<tr class="hello" style="background-color:gray; color:white;">
							<th class="hello">COUNT</th>
							<th class="hello">DESCRIPTION</th>
							<th class="hello">QUANTITY</th>
							<th class="hello">UNIT PRICE</th>
							<th class="hello">TOTAL</th>
						</tr>
						<tr v-for="(item, index) in item.details" :key="index">
							<td class="hello">{{item.count}}</td>
							<td class="hello" style="word-wrap:break-word; white-space:normal;">{{item.item_description}}</td>
							<td style="text-align:right;" class="hello">{{item.quantity}}</td>
							<td style="text-align:right;" class="hello">{{formatAmount(item.unit_cost)}}</td>
							<td style="text-align:right;" class="hello">{{formatAmount(item.total_amount)}}</td>
						</tr>
						<tr v-for="n in 13 - item.details.length" :key="n">
							<td class="hello2"></td>
							<td class="hello2"></td>
							<td style="text-align:right;" class="hello2"></td>
							<td style="text-align:right;" class="hello2"></td>
							<td style="text-align:right;" class="hello2"></td>
						</tr>
					
					
					</table>
				</div>
				<div class="float-container">
					<div class="float-child" style="width:70%;">
						<div style="border: 1px black solid; height:140px; padding:10px;">
							<h3>NOTES/REMARKS</h3>
							<p style="word-wrap: break-word;">{{poData.remarks}}</p>
						</div>
					</div>
					<div class="float-child" style="width:30%;">
						<table style="float:right; width:100%; height:100px;">
							<tr>
								<td class="hello">VATable</td>
								<td class="hello" style="text-align:right;" v-if="poData.vat_type == 1">{{formatAmount(VATable)}}</td>
								<td class="hello" style="text-align:right;" v-else>0.00</td>
							</tr>
							<tr>
								<td class="hello">VAT Exempt</td>
								<td class="hello" style="text-align:right;" v-if="poData.vat_type == 2">{{formatAmount(total)}}</td>
								<td class="hello" style="text-align:right;" v-else>0.00</td>
							</tr>
							<tr>
								<td class="hello">Zero-rated:</td>
								<td class="hello" style="text-align:right;" v-if="poData.vat_type == 3">{{formatAmount(total)}}</td>
								<td class="hello" style="text-align:right;" v-else>0.00</td>
							</tr>
							<tr>
								<td class="hello">Value Added Tax</td>
								<td class="hello" style="text-align:right;" v-if="poData.vat_type == 1">{{formatAmount(VAT)}}</td>
								<td class="hello" style="text-align:right;" v-else>0.00</td>
							</tr>
							<tr v-if="item.page === totalPages">
								<td class="hello" style="">TOTAL AMOUNT</td>
								<td class="hello" style="text-align:right;">{{formatAmount(total)}}</td>
							</tr>
							<tr style="color: white;" v-else>
								<td class="hello" style="">TOTAL AMOUNT</td>
								<td class="hello" style="text-align:right;">{{total}}</td>
							</tr>
						</table>
					</div>
				</div>
				<div style="padding:10px; font-size:15px;">
					<p>PETNET, Inc. serves the right to cancel Purchase Order if not fully delivered on the agreed date and if goods/items do not conform with the specifications and standards required or impose corresponding penalties</p>
				</div>
				<div class="float-container">
					<div class="float-child" style="width:60%">
						<table style="width:100%; height:100px;">
							<tr>
								<td>PREPARED BY:</td>
								<td style="text-align:right;">{{getUser(poData.edit_by)}}, Purchasing</td>
							</tr>
							<tr>
								<td>RECOMMENDED BY:</td>
								<td style="text-align:right;">{{recommended_by}}, Purchasing Head</td>
							</tr>
							<tr>
								<td>APPROVED BY:</td>
								<td style="text-align:right;">{{approved_by}}, SVP - Finance</td>
							</tr>
							<tr>
								<td>DATE APPROVED:</td>
								<td style="text-align:right;">{{formatDate(poData.po_date)}}</td>
							</tr>
						</table>
					</div>
					<div class="float-child" style="font-size:10px; text-align:center; width:40%; border:solid 1px black; float:right;">
						<span>PETNET, INC.</span><br>
						<span>114 East Offices Building Aguirre St.</span><br>
						<span>Legaspi Village, Makati City</span><br>
						<span>VAT Reg TIN: 201-200-777-000</span><br>
						<br>
						<span>CAS Acknowledgment Certificate No. {{certificate_number_env}}</span><br>
						<span>Issue Date: {{issue_date_env}}</span><br>
						<span>Series: PO0000001-PO9999999</span>
					</div>
				</div>
				<div style="padding-top: 150px; text-align:center; font-size:12px;">
					<p>THIS DOCUMENT SHALL BE VALID FOR FIVE (5) YEARS FROM THE DATE OF ACKNOWLEDGMENT CERTIFICATE.</p>
					<p>THIS DOCUMENT IS NOT VALID FOR CLAIM OF INPUT TAX.</p>
				</div>
				<div>
					<p style="float:left; font-weight: bold; font-size:20px;">PAGE {{item.page}} OF {{totalPages}}</p>
					<p style="float:right;font-size:12px;"><i>This is a system generated document</i></p>
				</div>
			</div>
			<div v-if="totalPages > 1" style="page-break-after: always; height: 50px;"></div>
		</div>
	</v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import numeral from 'numeral';
import { format } from 'date-fns';

export default {
	data () {
		return {
			poData: '',
			poDataRender: [],
			total: 0,
			tin: '',
			supplier_name: '',
			trader_name: '',
			business_style: '',
			contact_number: '',
			contact_number_env: '',
			issue_date_env: '',
			certificate_number_env: '',
			email_add: '',
			address: '',
			VAT: '',
			totalPages: 0,
			totalCount: 0,
			VATable: '',
			userData: [],
			recommended_by: '',
			approved_by: ''
		};
	},
	watch: {
		users: {
			handler(){
				this.getData();
			}
		}
	},
	computed: {
		...mapGetters({
			users: 'user/users',
		}),
	},
	async created () {
		this.contact_number_env = process.env.VUE_APP_PO_CONTACT_NUMBER;
		this.issue_date_env = process.env.VUE_APP_ISSUE_DATE;
		this.certificate_number_env = process.env.VUE_APP_CAS_NO;
		this.recommended_by = process.env.VUE_APP_PO_RECOMMENDED;
		this.approved_by = process.env.VUE_APP_PO_APPROVED;
		this.$store.dispatch('user/getUsers');
		const resHeader = await this.$store.dispatch('trxpurchaseorder/getPO', this.$route.params.data);
		this.poData = resHeader.data.data[0];
		this.computeTotalAmount();
		this.payees_on_change(resHeader.data.data[0].payee_id);
		this.computeVAT();
		this.computeVATable();
		this.init();
	},
	methods: {
		getUser(id){
			let user = this.userData.filter(det => det.id === id);
			return user[0].user_first_name + ' ' + user[0].user_last_name;
		},
		async getData(){
			this.userData = this.users.data;
			this.nextPage = this.users.next_page_url;
			let page = 2;

			while (!this.users.data){
				await this.$store.dispatch('users/getData', page).then(response => {
					this.nextPage = response.data.data.next_page_url;
					response.data.data.data.forEach(details => {
						this.userData.push(details);
					});
				});
				page++;
			}
		},
		formatDate(date){
			return format(new Date(date), 'yyyy-MM-dd');
		},
		init () {
			const self = this;
			self.totalCount = this.poData.po_details.length;
			var i,j, chunk = 13, pageCount = 0, finalArr = this.poData.po_details, objToSend = {};
			if(self.totalCount > 13){
				for (i = 0,j = finalArr.length; i < j; i++){
					finalArr[i].count = i + 1;
				}
				for (i = 0,j = finalArr.length; i < j; i += chunk) {
					self.totalPages++;
				}
				for (i = 0,j = finalArr.length; i < j; i += chunk) {
					pageCount++;
					objToSend.details = finalArr.slice(i, i + chunk);
					// objToSend.headers = header;
					objToSend.page = pageCount;
					self.poDataRender.push(objToSend);
					objToSend = {};
				}
			} else {
				for (i = 0,j = finalArr.length; i < j; i++){
					finalArr[i].count = i + 1;
				}
				objToSend.details = finalArr;
				// objToSend.headers = header;
				objToSend.page = '1';
				self.totalPages = '1';
				self.poDataRender.push(objToSend);
			}
		},
		async payees_on_change(value){
			const res = await this.$store.dispatch('trxpurchaseorder/getPayeeFile', value);
			this.tin = res.data.data[0].tin;
			this.supplier_name = res.data.data[0].payee_desc;
			this.trader_name = res.data.data[0].trader_name;
			this.business_style = res.data.data[0].business_style;
			this.contact_number = res.data.data[0].contact_num;
			this.email_add = res.data.data[0].email_add;
			this.address = res.data.data[0].house_unit + ' ' + res.data.data[0].street + ', ' +  res.data.data[0].city + ' ' + res.data.data[0].zip_code;
		},
		formatAmount (amount) {
			return numeral(amount).format('0,0.00');
		},
		computeTotalAmount () {
			const self = this;
			self.poData.po_details.forEach(function(e){
				self.total += parseFloat(e.total_amount);
			});
		},
		computeVAT () {
			var result = this.total / 1.12;
			this.VAT = result * 0.12;
		},
		computeVATable () {
			this.VATable = (this.total - this.VAT);
		}
	}
};
</script>

<style>
.float-container {
    border: 3px solid #fff;
    padding: 5px;
}

.float-child {
    width: 50%;
    float: left;
    padding: 5px;
}
table.hello, th.hello, td.hello {
	border: 1px solid black;
}
.hello2 {
    height: 26px;
	border: 1px solid black;
}
</style>
