import Repository from '../Repository';

const resource = '/module';

export default {
	get() {
		return Repository.get(`${resource}`);
	},

	getModule(id) {
		return Repository.get(`${resource}/${id}`);
	},

	saveModule(payload) {
		return Repository.post(`${resource}`, payload);
	},

	updateModule(id, payload) {
		return Repository.put(`${resource}/${id}`, payload);
	},

	deleteModule(id) {
		return Repository.delete(`${resource}/${id}`);
	},

	searchModule(payload){
		return Repository.get(`${resource}`,payload);
	},

	getData(payload){
		return Repository.get(`${resource}?page=`+ payload);
	}
};