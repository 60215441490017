import Vue from 'vue';

const COLLECTION_SHOW_DETAIL = (state, payload) => {
	state.collectionDetails = payload;
};

const COLLECTION_UPDATED = (state, payload) => {
	state.collections = payload;
};

const COLLECTION_ADD = (state, payload) => {
	state.collections =  Object.values(state.collections);
	state.collections.push(payload);
};

const COLLECTION_DELETE = (state, payload) => {
	let index = state.collections.indexOf(payload);
	state.collections.splice(index, 1);
};

const COLLECTION_ITEM_UPDATE = (state, payload) => {
	Vue.set(state.collections, state.editedIndex, payload);
};

const COLLECTION_EDIT_MODAL_VIEW = (state,payload) => {
	state.editDialog = payload;
};

const COLLECTION_DETAIL_MODAL_VIEW = (state,payload) => {
	state.viewDialog = payload;
};

const COLLECTION_ATTACHMENT_MODAL_VIEW = (state,payload) => {
	state.attachmentDialog = payload;
};

const COLLECTION_EDIT_PROMPT_DIALOG = (state,payload) => {
	state.updateDisbursement = payload;
};

const SET_EDITED_INDEX = (state, payload) => {
	state.editedIndex = payload;
};

const SET_PREPARER = (state, payload) => {
	state.preparer = payload;
};

const SET_DIST_DATA = (state, payload) => {
	state.dist_data = payload;
};

const COLLECTION_SHOW_PRINT_DETAIL = (state, payload) => {
	state.collectionPrintDetails = payload;
};

const SHOW_FORM_2307_DETAIL = (state, payload) => {
	state.birFormDetails = payload;
};

const COLLECTION_DETAIL = (state, payload) => {
	state.collectionDetailed = payload;
};

const SET_SUCCESS = (state, payload) => {
	state.success = payload;
};

const TRX_SEARCH_RESULT = (state, payload) => {
	state.transactionResults = payload;
};

const SET_PRINT_DETAILS = (state, payload) => {
	state.printDetails = payload;
};

const SHOW_UPLOADED_CSV_FILES = (state, payload) => {
	state.csvFileUploads = payload;
};



const SET_DIALOG = (state,payload) =>{
	state.dialog = payload;
};
const SET_VALID = (state,payload) => {
	state.valid = payload;
};

const SET_FORM_TITLE = (state,payload) => {
	state.formTitle = payload;
};

const SET_COLLECTION_NUMBER = (state,payload) => {
	state.collectionNumber = payload;
};

export default {
	COLLECTION_UPDATED,
	COLLECTION_ADD,
	COLLECTION_DELETE,
	COLLECTION_ITEM_UPDATE,
	COLLECTION_SHOW_DETAIL,
	COLLECTION_EDIT_MODAL_VIEW,
	COLLECTION_DETAIL_MODAL_VIEW,
	COLLECTION_ATTACHMENT_MODAL_VIEW,
	COLLECTION_EDIT_PROMPT_DIALOG,
	TRX_SEARCH_RESULT,
	SET_EDITED_INDEX,
	SET_PREPARER,
	SET_DIST_DATA,
	COLLECTION_SHOW_PRINT_DETAIL,
	COLLECTION_DETAIL,
	SET_SUCCESS,
	SHOW_FORM_2307_DETAIL,
	SET_PRINT_DETAILS,
	SHOW_UPLOADED_CSV_FILES,
	SET_DIALOG,
	SET_FORM_TITLE,
	SET_VALID,
	SET_COLLECTION_NUMBER
};