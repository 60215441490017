export default{
	transactions: [],
	headers: [
    
		{
			text: 'Transaction Code',
			align: 'left',
			sortable: true,
			value: 'trns_code',
		},
    
		{
			text: 'Document Type',
			align: 'left',
			sortable: true,
			value: 'document_type',
		},

		{ text: 'Transaction Description', value: 'trns_desc' },
  
		{ text: 'Actions', value: 'action', sortable: false },
	],
	documentType: [
		{ value: 'AE', text: 'Adjusting Entry'},
		{ value: 'CD', text: 'Cash Disbursement'},
		{ value: 'CR', text: 'Cash Receipt'},
		{ value: 'JV', text: 'Journal Voucher'},
	],
	editedItem: {
		id: '',
		trns_code: '',
		document_type: '',
		trns_desc: ''
	},
	defaultItem: {
		id: '',
		trns_code: '',
		document_type: '',
		trns_desc: ''
	},

	dialog: false,
	editedIndex: -1,
	valid: true,
	formTitle: 'Edit entry',
};


