import Repository from '../Repository';
import RepositoryDownload from '../RepositoryDownload';

const resource = 'trx/collection';

export default {
	get(payload) {
		return Repository.get(`${resource}`, {params: payload});
	},

	getCollection(id) {
		return Repository.post(`${resource}-header/${id}`);
	},
	saveCollection(payload) {
		return Repository.post(`${resource}`, 
			payload,
			{
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}
		);
	},
	updateCollection(id, payload) {
		return Repository.post(`${resource}/${id}`,
			payload,
			{
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}
		);
	},
	// updateBillingStatus(id, payload) {
	// 	return Repository.post(`${resource}/${id}`,payload);
	// },
	deleteCollection(id) {
		return Repository.delete(`${resource}/${id}`);
	},
	getNumber(resource, payload){
		return Repository.post(`${resource}`, payload);
	},
	updateCollectionStatus(id, payload) {
		return Repository.post(`${resource}/${id}`,payload);
	},
	updateCollectionAccountingStatus(id, payload) {
		return Repository.put(`${'/updateCollAcctngStat'}/${id}`,payload);
	},
	searchCollection(payload) {
		return Repository.get(`${resource}`, payload);
	},
	getBIRFormData(batch_number) {
		return Repository.post(`${'/trx/print-2307'}/${batch_number}`);
	},
	getBatchNumber(resource, payload){
		return Repository.post(`${resource}`, payload);
	},
	getDocumentNumber(resource, payload){
		return Repository.post(`${resource}`, payload);
	},
	getDocumentNumberCheque(resource){
		return Repository.post(`${resource}`);
	},
	getPrintData(payload){
		return Repository.post(`${'/trx/print-dm'}/${payload}`);
	},
	getPrintReceiptData(payload){
		return RepositoryDownload.post(`${'/trx/print-collection-orar'}/${payload}`);
	},
	getDetail(payload){
		return Repository.post(`${resource}/${payload}`);
	},
	getByCollNo(payload) {
		return Repository.get(`${'/getCollection'}`, {params: payload});
	},
	
	getData(payload){
		return Repository.get(`${resource}?page=`+ payload);
	},

	getReceiptDetail(payload) {
		return Repository.post(`${'receipt-coldetail'}`, payload);
	},
	
	voidCollection(payload){
		return Repository.post(`${'/trx/colvoid'}`, payload,
			{
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}
		);
	}
};