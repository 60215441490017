import Repository from '../Repository';

const resource = '/trx/isfis';

export default {
	get(payload) {
		return Repository.get(`${resource}`, {params: payload});
	},

	delete(payload) {
		return Repository.post(`${'/trx/deltrx'}`, payload);
	},
	getData(payload){
		return Repository.get(`${resource}`,{params: payload});
	},
	getDetail(payload){
		return Repository.post(`${resource}/${payload}`);
	},
};