import Vue from 'vue';
import { RepositoryFactory } from '../../../api_repo/RepositoryFactory';
const usersRepository = RepositoryFactory.get('user');

const getUsers = (context) => {
	context.commit('SET_ISLOADING',true);
	setTimeout(() => {
		usersRepository.get()
			.then((response) => {
				context.commit('SET_ISLOADING',false);
				context.commit('USERS_UPDATED', response.data.data);
			})
			// eslint-disable-next-line
			.catch((error) => {
				context.commit('SET_ISLOADING',false);
			});
	}, 1500);
};

const saveUser = (context,payload) => {
	context.commit('SET_ISLOADING',true);
	usersRepository.saveUser(payload)
		.then((response) => {
			context.commit('SET_ISLOADING',false);
			context.commit('USERS_ADD',response.data.data);
		})
		.catch((error) => {
			context.commit('SET_ISLOADING',false);
			Vue.swal.fire({
				type: 'error',
				title: 'Saving failed!',
				text: error.response.data.message,
				timer: 2000
			});
		});
};

const updateUser = (context,payload) => {
	context.commit('SET_ISLOADING',true);
	usersRepository.updateUser(payload.id,payload)
		.then((response) => {
			context.commit('SET_ISLOADING',false);
			context.commit('USERS_ITEM_UPDATE',response.data.data);
			Vue.swal.fire({
				type: 'success',
				title: 'Your work has been updated',
				text: response.data.data.user_last_name + ', ' + response.data.data.user_first_name + ' updated.',
				timer: 2000,
			});
		})
		.catch((error) => {
			context.commit('SET_ISLOADING',false);
			Vue.swal.fire({
				type: 'error',
				title: 'Update fail',
				text: error.response.data.message,
				timer: 2000
			});
		});
};

const deleteUser = (context,payload) => {  
	context.commit('SET_ISLOADING',true);
	usersRepository.deleteUser(payload)
		.then((response) => {
			context.commit('SET_ISLOADING',false);
			context.commit('USERS_DELETE',response.data.data);
			Vue.swal.fire({
				type: 'error',
				title: 'Delete successful',
				text: response.data.data.user_last_name + ', ' + response.data.data.user_first_name + ' deleted.',
				timer: 2000
			});
		})
		.catch((error) => {
			context.commit('SET_ISLOADING',false);
			Vue.swal.fire({
				type: 'error',
				title: 'Delete fail',
				text: error.response.data.message,
				timer: 2000
			});
		});
};

const getDetails = (context, payload) => {
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	usersRepository.getDetails(payload)
		.then((response) => {
			context.commit('SET_EDITED_ITEM', response.data.data);
			context.commit('app/SET_IS_LOADING',false,{ root: true });
		})
		.catch(() => {
			context.commit('app/SET_IS_LOADING',false,{ root: true });
		});    
};

const getData = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true , { root: true });
	return new Promise((resolve, reject) => {
		usersRepository.getData(payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING', false , { root: true });
				resolve(response);
			}, error => {
				context.commit('app/SET_IS_LOADING', false , { root: true });
				reject(error);
			});
	});
};

const getUserHris = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true , { root: true });
	return new Promise((resolve, reject) => {
		usersRepository.getUserHRIS(payload.data, payload.token)
			.then((response) => {
				context.commit('app/SET_IS_LOADING', false , { root: true });
				resolve(response);
			}, error => {
				context.commit('app/SET_IS_LOADING', false , { root: true });
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Unable to connect in HRIS.','messagetype':'error'},{ root: true }); 
				reject(error);
			});
	});
};

const getDataPages = (context,payload) => {
	context.commit('app/SET_IS_LOADING', true , { root: true });
	setTimeout(() => {
		usersRepository.getData(payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING', false , { root: true });
				context.commit('USERS_UPDATED', response.data.data);
			})
			// eslint-disable-next-line
			.catch(() => {
				context.commit('app/SET_IS_LOADING', false , { root: true });
			});
	}, 1500);
};

const setEditedItems = (context,payload) => {
	context.commit('SET_EDITED_ITEM',payload);
};

const setDialog = (context,payload) => {
	context.commit('SET_DIALOG',payload);
};

const setEditedIndex = (context,payload) => {
	context.commit('SET_EDITED_INDEX',payload);
};

const setValid = (context,payload) => {
	context.commit('SET_VALID',payload);
};

const setformTitle = (context,payload) => {
	context.commit('SET_FORM_TITLE',payload);
};

const setisLoading = (context,payload) => {
	context.commit('SET_ISLOADING',payload);
};

export default {
	getUsers,
	saveUser,
	updateUser,
	deleteUser,
	setEditedItems,
	setDialog,
	setEditedIndex,
	setValid,
	setformTitle,
	setisLoading,
	getDetails,
	getData,
	getDataPages,
	getUserHris
};