export default {
	trxdisbs: [],
	trxdisbDetails: [],
	trxdisbPrintDetails: [],
	transactionResults: [],
	birFormDetails: [],
	csvFileUploads: [],
	ar_number: '',
	or_number: '',
	po_details: [],
	uploadEntries: [],
	headers: [
		{
			text: 'Batch Number',
			align: 'left',
			sortable: false,
			value: 'batch_number',
		},
		{ text: 'Date Encoded', value: 'date_encoded' },
		{ text: 'Document Date', value: 'date'},
		{ text: 'Preparer', value: 'preparer' },
		{ text: 'Payee', value: 'payee' },
		{ text: 'Type of Document', value: 'document_type' },
		{ text: 'Type of Transaction', value: 'regular_trx' },
		{ text: 'PETNET Bank', value: 'bank_name' },
		{ text: 'Amount', value: 'total_amount', align: 'right' },
		{ text: 'Actions', value: 'action', sortable: false },
	],
	headersCashReceipt: [
		{
			text: 'Batch Number',
			align: 'left',
			sortable: false,
			value: 'batch_number',
		},
		{ text: 'Date Encoded', value: 'date_encoded' },
		{ text: 'Document Date', value: 'date'},
		{ text: 'Preparer', value: 'preparer' },
		{ text: 'Payor', value: 'payee' },
		{ text: 'Type of Document', value: 'document_type' },
		{ text: 'Type of Transaction', value: 'regular_trx' },
		{ text: 'PETNET Bank', value: 'bank_name' },
		{ text: 'Amount', value: 'total_amount', align: 'right' },
		{ text: 'Actions', value: 'action', sortable: false },
	],
	documentType: [
		{ value: 'AE', text: 'Adjusting Entry' },
		{ value: 'CD', text: 'Check Disbursement' },
		{ value: 'CR', text: 'Cash Receipts' },
		{ value: 'JV', text: 'Journal Voucher' },
	],

	editedItem: {
		id: '',
		batch_number: '',
		document_number: '',
		document_date: '',
		document_type: '',
		atc_code: '',
		dollar_rate: '',
		vat: '',
		bank_code: '',
		bank_account: '',
		amount: '',
		payee_id: ''
	},
	defaultItem: {
		id: '',
		batch_number: '',
		document_number: '',
		document_date: '',
		document_type: '',
		atc_code: '',
		dollar_rate: '',
		vat: '',
		bank_code: '',
		bank_account: '',
		amount: '',
		payee_id: ''
	},
	dialog: false,
	editDialog: false,
	viewDialog: false,
	attachmentDialog: false,
	editedIndex: -1,
	valid: true,
	formTitle: 'Edit entry',
	preparer: '',
	dist_data: '',
	trxdisbDetailed: '',
	success: false,
	printDetails: [],
	ereceiptDetails: {}
};