import Vue from 'vue';

const BPLOCATIONS_UPDATED = (state, bplocations) => {
	state.bplocations = bplocations;

};
  
const BPLOCATIONS_ADD = (state, payload) => {
	state.bplocations.data.push(payload);
};

const BPLOCATIONS_DELETE = (state) => {
	state.bplocations.data.splice(state.editedIndex,1);
};

const SET_EDITED_ITEM = (state,payload) =>{
	state.editedItem = payload;
};

const SET_DIALOG = (state,payload) =>{
	state.dialog = payload;
};

const SET_EDITED_INDEX = (state,payload) =>{
	state.editedIndex = payload;
};

const BPLOCATIONS_ITEM_UPDATE = (state, payload) => {
	Vue.set(state.bplocations.data, state.editedIndex, payload);
};

const SET_VALID = (state,payload) => {
	state.valid = payload;
};

const SET_FORM_TITLE = (state,payload) => {
	state.formTitle = payload;
};

export default {
	BPLOCATIONS_UPDATED,
	SET_EDITED_ITEM,
	SET_DIALOG,
	SET_EDITED_INDEX,
	SET_VALID,
	SET_FORM_TITLE,
	BPLOCATIONS_ADD,
	BPLOCATIONS_DELETE,
	BPLOCATIONS_ITEM_UPDATE
};