const payefiles = state => state.payefiles;
const headers = state => state.headers;
const columnHeaders = state => state.columnHeaders;
const payeeCode = state => state.payeeCode;
const payeeType = state => state.payeeType;
const vatType = state => state.vatType;
const businessType = state => state.businessType;
const empStatus = state => state.empStatus;
const classCode = state => state.classCode;
const subagentStatus = state => state.subagentStatus;
const editedItem = state => state.editedItem;
const defaultItem = state => state.defaultItem;
const dialog = state => state.dialog;
const editedIndex = state => state.editedIndex;
const valid = state => state.valid;
const formTitle = state => state.formTitle;
const payeeDetails = state => state.payeeDetails;
const payeeBank = state => state.payeeBank;
const payeeBankDialog = state => state.payeeBankDialog;
const currency = state => state.currency;
const otherType = state => state.otherType;

export default {
	payefiles,
	headers,
	columnHeaders,
	payeeCode,
	payeeType,
	vatType,
	businessType,
	empStatus,
	classCode,
	subagentStatus,
	editedItem,
	defaultItem,
	dialog,
	editedIndex,
	valid,
	formTitle,
	payeeDetails,
	payeeBank,
	payeeBankDialog,
	currency,
	otherType
};