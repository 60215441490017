import Vue from 'vue';

const DEPTS_UPDATED = (state, depts) => {
	state.depts = depts;
};
  
const DEPTS_ADD = (state, payload) => {
	state.depts.data.push(payload);
};

const DEPTS_DELETE = (state) => {
	state.depts.data.splice(state.editedIndex,1);
};

const DEPTS_ITEM_UPDATE = (state, payload) => {
	Vue.set(state.depts.data, state.editedIndex, payload);
};

const SET_EDITED_ITEM = (state,payload) =>{
	state.editedItem = payload;
};

const SET_ISLOADING = (state,payload) => {
	state.isLoading = payload;
};

const SET_DIALOG = (state,payload) =>{
	state.dialog = payload;
};

const SET_EDITED_INDEX = (state,payload) =>{
	state.editedIndex = payload;
};

const SET_VALID = (state,payload) => {
	state.valid = payload;
};

const SET_FORM_TITLE = (state,payload) => {
	state.formTitle = payload;
};

const SET_DEPTS_SECTION = (state, payload) => {
	state.editedItem.sections.push(payload);
};

const DEPTS_SECTION_ADDED = (state, payload) => {
	state.depts.data[state.editedIndex].sections.push(payload);
};

const DEPTS_SECTION_UPDATE = (state, payload) => {
	Vue.set(state.depts.data[state.editedIndex].sections, state.editedIndexSection, payload);
};

const DEPTS_SECTION_DELETE = state => {
	state.depts.data[state.editedIndex].sections.splice(state.editedIndexSection, 1);
};

const SET_SECTION_EDITEDINDEX = (state, payload) => {
	state.editedIndexSection = payload;
};

const SET_ERROR = (state, payload) => {
	state.error = payload;
};

export default {
	DEPTS_UPDATED,
	SET_EDITED_ITEM,
	SET_DIALOG,
	DEPTS_DELETE,
	DEPTS_ITEM_UPDATE,
	SET_ISLOADING,
	SET_EDITED_INDEX,
	DEPTS_ADD,
	SET_VALID,
	SET_FORM_TITLE,
	SET_DEPTS_SECTION,
	DEPTS_SECTION_UPDATE,
	DEPTS_SECTION_DELETE,
	DEPTS_SECTION_ADDED,
	SET_SECTION_EDITEDINDEX,
	SET_ERROR
};