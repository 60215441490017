const payeebanks = state => state.payeebanks;
const headers = state => state.headers;
const acctType = state => state.acctType;
const acctCur = state => state.acctCur;
const editedItem = state => state.editedItem;
const defaultItem = state => state.defaultItem;
const dialog = state => state.dialog;
const editedIndex = state => state.editedIndex;
const formTitle = state => state.formTitle;
const valid = state => state.valid;

export default {
	payeebanks,
	acctType,
	acctCur,
	headers,
	editedItem,
	defaultItem,
	dialog,
	editedIndex,
	formTitle,
	valid
};