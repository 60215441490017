import Repository from '../Repository';

const resource = '/businesspartner';

export default {
	get() {
		return Repository.get(`${resource}`);
	},

	getBusinesspartner(id) {
		return Repository.get(`${resource}/${id}`);
	},

	saveBusinesspartner(payload) {
		return Repository.post(`${resource}`, payload);
	},

	updateBusinesspartner(id, payload) {
		return Repository.put(`${resource}/${id}`, payload);
	},

	deleteBusinesspartner(id) {
		return Repository.delete(`${resource}/${id}`);
	},

	searchBusinesspartner(payload){
		return Repository.get(`${resource}`,payload);
	},
	
	getData(payload){
		return Repository.get(`${resource}?page=`+ payload);
	} 
};