import Repository from '../Repository';

const resource = '/dept';

export default {
	get() {
		return Repository.get(`${resource}`);
	},

	getDept(id) {
		return Repository.get(`${resource}/${id}`);
	},

	saveDept(payload) {
		return Repository.post(`${resource}`, payload);
	},

	updateDept(id, payload) {
		return Repository.put(`${resource}/${id}`, payload);
	},

	deleteDept(id) {
		return Repository.delete(`${resource}/${id}`);
	},

	searchDept(payload){
		return Repository.get(`${resource}`,payload);
	},
	
	getData(payload){
		return Repository.get(`${resource}?page=`+ payload);
	} 
};