const banks = state => state.banks;
const headers = state => state.headers;
const columnHeaders = state => state.columnHeaders;
const bankStatus = state => state.bankStatus;
const acctClass = state => state.acctClass;
const acctType = state => state.acctType;
const forForex = state => state.forForex;
const forTreasury = state => state.forTreasury;
const editedItem = state => state.editedItem;
const defaultItem = state => state.defaultItem;
const dialog = state => state.dialog;
const editedIndex = state => state.editedIndex;
const valid = state => state.valid;
const formTitle = state => state.formTitle;

export default {
	banks,
	columnHeaders,
	headers,
	bankStatus,
	acctClass,
	acctType,
	forForex,
	forTreasury,
	editedItem,
	defaultItem,
	dialog,
	editedIndex,
	valid,
	formTitle
};