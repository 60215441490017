<template>
  <div class="text-center">
    <v-dialog
      :value.sync="isLoading"
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Please wait ...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	computed: {
		...mapGetters({
			isLoading: 'app/isLoading'
		})
	},
	// watch: {
	//   isLoading(newVal,oldVal){
	//     console.log('NEW VALUE' + newVal + '     OLD VALUE: ' + oldVal)
	//   }
	// }
};
</script>