const SET_TBAL_DATA = (state, payload) => {
	state.tbalData = payload;
};

const SET_SUBS_DATA = (state, payload) => {
	state.subsData = payload;
};

const SET_SUBANALYSIS_DATA = (state, payload) => {
	state.subsAnalysisData = payload;
};

const SET_PROF_DATA = (state, payload) => {
	state.profData = payload;
};

const SET_PROF_DATA_SUMMARY = (state, payload) => {
	state.profDataSummary = payload;
};

const SET_BILLING_LIST = (state, payload) => {
	state.billingList = payload;
};

const SET_AR_AGING_INV = (state, payload) => {
	state.arAgingInv = payload;
};
const SET_AR_AGING_CUST = (state, payload) => {
	state.arAgingCust = payload;
};

const SET_RFA_DATA = (state, payload) => {
	state.rfaData = payload;
};

const SET_CIB_SUB_DATA = (state, payload) => {
	state.cibSubData = payload;
};

const SET_BOA_DATA = (state, payload) => {
	state.boaData = payload;
};

const SET_AUDIT_DATA = (state, payload) => {
	state.auditData = payload;
};

const SET_SALES_DATA = (state, payload) => {
	state.salesData = payload;
};

export default {
	SET_TBAL_DATA,
	SET_SUBS_DATA,
	SET_SUBANALYSIS_DATA,
	SET_PROF_DATA,
	SET_PROF_DATA_SUMMARY,
	SET_BILLING_LIST,
	SET_AR_AGING_CUST,
	SET_AR_AGING_INV,
	SET_RFA_DATA,
	SET_CIB_SUB_DATA,
	SET_BOA_DATA,
	SET_SALES_DATA,
	SET_AUDIT_DATA
};
