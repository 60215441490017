import Repository from '../Repository';

const resource = '/bank';

export default {
	get() {
		return Repository.get(`${resource}`);
	},

	getBank(id) {
		return Repository.get(`${resource}/${id}`);
	},

	saveBank(payload) {
		return Repository.post(`${resource}`, payload);
	},

	updateBank(id, payload) {
		return Repository.put(`${resource}/${id}`, payload);
	},

	deleteBank(id) {
		return Repository.delete(`${resource}/${id}`);
	},
	searchBank(payload) {
		return Repository.get(`${resource}`, payload);
	},
	
	getData(payload){
		return Repository.get(`${resource}?page=`+ payload);
	} 
};