import Vue from 'vue';

const ACCTPERIODMAINS_UPDATED = (state, acctperiodmains) => {
	state.acctperiodmains = acctperiodmains;
};

const ACCTPERIODMAINS_ADD = (state, payload) => {
	state.acctperiodmains.data.push(payload);
}; 

const ACCTPERIODMAINS_DELETE = (state, payload) => {
	let index = state.acctperiodmains.data.indexOf(payload);
	state.acctperiodmains.data.splice(index,1);
};

const ACCTPERIODMAINS_ITEM_UPDATE = (state, payload) => {
	Vue.set(state.acctperiodmains.data, state.editedIndex, payload);
};

const SET_EDITED_ITEM = (state,payload) =>{
	state.editedItem = payload;
};

const SET_DIALOG = (state,payload) =>{
	state.dialog = payload;
};

const SET_EDITED_INDEX = (state,payload) =>{
	state.editedIndex = payload;
};

const SET_VALID = (state,payload) => {
	state.valid = payload;
};

const SET_FORM_TITLE = (state,payload) => {
	state.formTitle = payload;
};

//  const SET_EDIT_BY = (state) => {
//      let user = JSON.parse(localStorage.getItem('auth-data')).user.id;

//      state.editedItem.edit_by = user;
//      state.editedItem.input_by = user;
//      state.editedItem.edit_by = user;
//      state.defaultItem.input_by = user;
//  }

export default {
	ACCTPERIODMAINS_UPDATED,
	SET_EDITED_ITEM,
	SET_DIALOG,
	SET_EDITED_INDEX,
	ACCTPERIODMAINS_ADD,
	ACCTPERIODMAINS_DELETE,
	SET_VALID,
	SET_FORM_TITLE,
	ACCTPERIODMAINS_ITEM_UPDATE
	// SET_EDIT_BY
};