import Vue from 'vue';

const BANKHEADERS_UPDATED = (state, bankheaders) => {
	state.bankheaders =  bankheaders;
};

const BANKHEADERS_ADD = (state, payload) => {
	state.bankheaders.data.push(payload);
}; 

const BANKHEADERS_DELETE = (state, payload) => {
	let index = state.bankheaders.data.indexOf(payload);
	state.bankheaders.data.splice(index,1);
};

const BANKHEADERS_ITEM_UPDATE = (state, payload) => {
	Vue.set(state.bankheaders.data, state.editedIndex, payload);
};

const SET_EDITED_ITEM = (state,payload) =>{
	state.editedItem = payload;
};

const SET_DIALOG = (state,payload) =>{
	state.dialog = payload;
};

const SET_EDITED_INDEX = (state,payload) =>{
	state.editedIndex = payload;
};

const SET_VALID = (state,payload) => {
	state.valid = payload;
};

const SET_FORM_TITLE = (state,payload) => {
	state.formTitle = payload;
};

export default {
	BANKHEADERS_UPDATED,
	SET_EDITED_ITEM,
	SET_DIALOG,
	SET_EDITED_INDEX,
	BANKHEADERS_ADD,
	BANKHEADERS_DELETE,
	SET_VALID,
	SET_FORM_TITLE,
	BANKHEADERS_ITEM_UPDATE
};