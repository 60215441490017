import Vue from 'vue';
import { RepositoryFactory } from '../../../api_repo/RepositoryFactory';
const regularTxnsRepository = RepositoryFactory.get('regulartrx');

const getDataToCreate = (context) => {
	context.commit('SET_ISLOADING',true);
	setTimeout(()=>{
		regularTxnsRepository.getDataToCreate()
			.then(()=>{
				context.commit('SET_ISLOADING',false);
			})	
			.catch(()=>{
				context.commit('SET_ISLOADING',false);
			});
	},1500);
};


const getregularTxns = (context) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	setTimeout(() => {
		regularTxnsRepository.get()
			.then((response) => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
				context.commit('REGULARTXNS_UPDATED', response.data.data);
			})
			// eslint-disable-next-line
			.catch(() => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
			});
	}, 1500);
};

const getregularTxn = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	setTimeout(() => {
		regularTxnsRepository.getSection(payload)
			.then((response) => {
				context.commit('REGULARTXNS_SHOW_DETAIL', response.data.data);
				context.commit('app/SET_IS_LOADING', false, { root: true });
			})
			.catch(() => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
			});
	}, 1500);
};


const saveregularTxns = (context,payload) => {
	context.commit('SET_ISLOADING',true);
	setTimeout(() => {
		regularTxnsRepository.saveSection(payload)
			.then(() => {
				context.commit('SET_ISLOADING', false);
				context.commit('SET_VALID', false);
				//context.commit('REGULARTXNS_ADD', response.data.data);
				Vue.swal.fire({
					type: 'success',
					title: 'Create successful',
					text: 'A Regular transaction data has been added.',
					timer: 2000,
				});
			})
			.catch(() => {
				context.commit('SET_ISLOADING', false);
				Vue.swal.fire({
					type: 'error',
					title: 'Saving failed!',
					text: 'Unable to save a Regular Transaction',
					timer: 2000
				});
			});
	}, 1500);
};

const updateregularTxns = (context,payload) => {
	context.commit('SET_ISLOADING',true);
	setTimeout(() => {
		regularTxnsRepository.updateSection(payload.id, payload)
			.then((response) => {
				context.commit('SET_ISLOADING', false);
				context.commit('SET_VALID', false);
				context.commit('SET_EDITED_INDEX', -1);
				context.commit('REGULARTXNS_ITEM_UPDATE', response.data.data);
				Vue.swal.fire({
					type: 'success',
					title: 'Regular Transaction Update Success!',
					text: 'The Regular transaction data has been updated.',
					timer: 2000,
				});
			})
			.catch(() => {
				context.commit('SET_ISLOADING',false);
				Vue.swal.fire({
					type: 'error',
					title: 'Update fail',
					text: 'Unable to update the Regular transaction data',
					timer: 2000
				});
			});
	}, 1500);
};

const deleteregularTxns = (context, payload) => {  
	context.commit('app/SET_IS_LOADING', true, { root: true });
	regularTxnsRepository.deleteregularTxns(payload)
		.then((response) => {
			context.commit('app/SET_IS_LOADING', false, { root: true });
			context.commit('REGULARTXNS_DELETE');
			console.log(response);
			Vue.swal.fire({
				type: 'success',
				title: 'Delete successful',
				text: 'Regular Transaction has been deleted.',
				timer: 2000
			});
			context.commit('SET_EDITED_INDEX', -1);
			window.location.href = '/regulartxns';
		})
		.catch(() => {
			context.commit('app/SET_IS_LOADING', false, { root: true });
			Vue.swal.fire({
				type: 'error',
				title: 'Delete fail',
				text: 'Unable to delete Regular Transaction',
				timer: 2000
			});
		});
};

const getData = (context,payload) => {
	context.commit('app/SET_IS_LOADING', true , { root: true });
	return new Promise((resolve, reject) => {
		regularTxnsRepository.getData(payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING', false , { root: true });
				resolve(response);
			}, error => {
				context.commit('app/SET_IS_LOADING', false , { root: true });
				reject(error);
			});
	});
};

const setEditedItems = (context,payload) => {
	context.commit('SET_EDITED_ITEM',payload);
};

const setDialog = (context,payload) => {
	context.commit('SET_DIALOG',payload);
};

const setEditedIndex = (context,payload) => {
	context.commit('SET_EDITED_INDEX',payload);
};

const setValid = (context,payload) => {
	context.commit('SET_VALID',payload);
};

const setformTitle = (context,payload) => {
	context.commit('SET_FORM_TITLE',payload);
};

const setisLoading = (context,payload) => {
	context.commit('SET_ISLOADING',payload);
};

export default {
	getregularTxn,
	getregularTxns,
	saveregularTxns,
	updateregularTxns,
	deleteregularTxns,
	setEditedItems,
	setDialog,
	setEditedIndex,
	setValid,
	setformTitle,
	setisLoading,
	getDataToCreate,
	getData
};