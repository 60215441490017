import Vue from 'vue';
import { RepositoryFactory } from '../../../api_repo/RepositoryFactory';

const reportRepository = RepositoryFactory.get('report');

const getTbalData = (context, payload) => {
	reportRepository.getTrialBalance(payload)
		.then((response) => {
			context.commit('SET_TBAL_DATA', response.data.data);
		})
		.catch((error) => {
			Vue.swal.fire({
				type: 'error',
				title: 'Failed getting data',
				text: error.response.data.message,
				timer: 2000
			});
		}); 
};

const getSubsData = (context, payload) => {
	reportRepository.getSubsidiary(payload)
		.then((response) => {
			context.commit('SET_SUBS_DATA', response.data.data);
		})
		.catch((error) => {
			Vue.swal.fire({
				type: 'error',
				title: 'Failed getting data',
				text: error.response.data.message,
				timer: 2000
			});
		}); 
};

const getSubsAnalysisData = (context, payload) => {
	reportRepository.getSubsidiaryAnalysis(payload)
		.then((response) => {
			context.commit('SET_SUBANALYSIS_DATA', response.data.data);
		})
		.catch((error) => {
			Vue.swal.fire({
				type: 'error',
				title: 'Failed getting data',
				text: error.response.data.message,
				timer: 2000
			});
		}); 
};

const getProfitabilityData = (context, payload) => {
	reportRepository.getProfitability(payload)
		.then((response) => {
			context.commit('SET_PROF_DATA', response.data.data);
		})
		.catch((error) => {
			Vue.swal.fire({
				type: 'error',
				title: 'Failed getting data',
				text: error.response.data.message,
				timer: 2000
			});
		}); 
};

const getProfitabilityDataSummary = (context, payload) => {
	reportRepository.getProfitability(payload)
		.then((response) => {
			context.commit('SET_PROF_DATA_SUMMARY', response.data.data);
		})
		.catch((error) => {
			Vue.swal.fire({
				type: 'error',
				title: 'Failed getting data',
				text: error.response.data.message,
				timer: 2000
			});
		}); 
};

const getBillingList = (context, payload) => {
	reportRepository.getData('/billing/genByYearMonth',payload)
		.then((response) => {
			context.commit('SET_BILLING_LIST', response.data.data);
		})
		.catch((error) => {
			Vue.swal.fire({
				type: 'error',
				title: 'Failed getting data',
				text: error.response.data.message,
				timer: 2000
			});
		}); 
};

const getARAgingCustomer = (context, payload) => {
	reportRepository.getData('/billing/getDaysAgedByCus',payload)
		.then((response) => {
			context.commit('SET_AR_AGING_CUST', response.data.data);
		})
		.catch((error) => {
			Vue.swal.fire({
				type: 'error',
				title: 'Failed getting data',
				text: error.response.data.message,
				timer: 2000
			});
		}); 
};
const getARAgingInvoice = (context, payload) => {
	reportRepository.getData('/billing/getDaysAgedByBillNo',payload)
		.then((response) => {
			context.commit('SET_AR_AGING_INV', response.data.data);
		})
		.catch((error) => {
			Vue.swal.fire({
				type: 'error',
				title: 'Failed getting data',
				text: error.response.data.message,
				timer: 2000
			});
		}); 
};

const getRfa = (context, payload) => {
	reportRepository.getRfa(payload)
		.then((response) => {
			context.commit('SET_RFA_DATA', response.data.data);
		})
		.catch((error) => {
			Vue.swal.fire({
				type: 'error',
				title: 'Failed getting data',
				text: error.response.data.message,
				timer: 2000
			});
		}); 
};

const getCibsubschedReport = (context, payload) => {
	reportRepository.getCibsubschedReport(payload)
		.then((response) => {
			context.commit('SET_CIB_SUB_DATA', response.data.data);
		})
		.catch((error) => {
			Vue.swal.fire({
				type: 'error',
				title: 'Failed getting data',
				text: error.response.data.message,
				timer: 2000
			});
		}); 
};

const getBoaje = (context, payload) => {
	reportRepository.getBoaje(payload)
		.then((response) => {
			context.commit('SET_BOA_DATA', response.data.data);
		})
		.catch((error) => {
			Vue.swal.fire({
				type: 'error',
				title: 'Failed getting data',
				text: error.response.data.message,
				timer: 2000
			});
		}); 
};

const getBoaPo = (context, payload) => {
	reportRepository.getBoaPo(payload)
		.then((response) => {
			context.commit('SET_BOA_DATA', response.data.data);
		})
		.catch((error) => {
			Vue.swal.fire({
				type: 'error',
				title: 'Failed getting data',
				text: error.response.data.message,
				timer: 2000
			});
		}); 
};

const getBoaDisb = (context, payload) => {
	reportRepository.getBoaDisb(payload)
		.then((response) => {
			context.commit('SET_BOA_DATA', response.data.data);
		})
		.catch((error) => {
			Vue.swal.fire({
				type: 'error',
				title: 'Failed getting data',
				text: error.response.data.message,
				timer: 2000
			});
		}); 
};

const getBoaCr = (context, payload) => {
	reportRepository.getBoaCr(payload)
		.then((response) => {
			context.commit('SET_BOA_DATA', response.data.data);
		})
		.catch((error) => {
			Vue.swal.fire({
				type: 'error',
				title: 'Failed getting data',
				text: error.response.data.message,
				timer: 2000
			});
		}); 
};

const getAuditTrail = (context, payload) => {
	reportRepository.getAuditTrail(payload)
		.then((response) => {
			context.commit('SET_AUDIT_DATA', response.data.data);
		})
		.catch((error) => {
			Vue.swal.fire({
				type: 'error',
				title: 'Failed getting data',
				text: error.response.data.message,
				timer: 2000
			});
		}); 
};

const getSalesJournal = (context, payload) => {
	reportRepository.getSalesJournal(payload)
		.then((response) => {
			context.commit('SET_SALES_DATA', response.data.data);
		})
		.catch((error) => {
			Vue.swal.fire({
				type: 'error',
				title: 'Failed getting data',
				text: error.response.data.message,
				timer: 2000
			});
		}); 
};

export default{
	getTbalData,
	getSubsData,
	getSubsAnalysisData,
	getProfitabilityData,
	getProfitabilityDataSummary,
	getBillingList,
	getARAgingCustomer,
	getARAgingInvoice,
	getRfa,
	getCibsubschedReport,
	getBoaje,
	getBoaDisb,
	getBoaPo,
	getBoaCr,
	getAuditTrail,
	getSalesJournal
};
