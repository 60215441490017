import Vue from 'vue';


const TRANSACTIONS_UPDATED = (state, transactions) => {
	state.transactions =transactions;
};

const TRANSACTIONS_ADD = (state, payload) => {
	state.transactions.push(payload);
}; 

const TRANSACTIONS_DELETE = (state, payload) => {
	let index = state.transactions.indexOf(payload);
	state.transactions.splice(index,1);
};

const TRANSACTIONS_ITEM_UPDATE = (state, payload) => {
	Vue.set(state.transactions, state.editedIndex, payload);
};

const SET_EDITED_ITEM = (state,payload) =>{
	state.editedItem = payload;
};

const SET_DIALOG = (state,payload) =>{
	state.dialog = payload;
};

const SET_EDITED_INDEX = (state,payload) =>{
	//console.log(payload);
	state.editedIndex = payload;
};


const SET_VALID = (state,payload) => {
	state.valid = payload;
};

const SET_FORM_TITLE = (state,payload) => {
	state.formTitle = payload;
};

const SET_ISLOADING = (state,payload) => {
	state.isloading = payload;
};
export default {
	TRANSACTIONS_UPDATED,
	SET_EDITED_ITEM,
	SET_DIALOG,
	SET_EDITED_INDEX,
	TRANSACTIONS_ADD,
	TRANSACTIONS_DELETE,
	SET_VALID,
	SET_FORM_TITLE,
	TRANSACTIONS_ITEM_UPDATE,
	SET_ISLOADING
};