<template>
	<div>
		<div id="printAction">
			<v-btn color="primary" @click="printDocument"><v-icon left>mdi-printer</v-icon> Print This Form</v-btn>
		</div>
		<div id="printArea">
			<div class="voucher-container">
				<p id="voucherNumber" class="voucher-number">{{documentHeaders[0].document_number}}</p>
				<p id="voucherDate" class="voucher-date">{{getDateFormat(documentHeaders[0].voucher_date)}}</p>
				<p id="amountNumber" class="voucher-amount-total">{{getNumberFormat(documentHeaders[0].voucher_amount)}}</p>
				<p id="accountName" class="voucher-account-name">{{getPayeeName(documentHeaders[0].payee_id)}}</p>
				<p id="explanation" class="voucher-account-explanation">
					{{documentHeaders[0].remarks}}
				</p>
				<p id="voucher-amount" class="voucher-amount" >{{getNumberFormat(documentHeaders[0].voucher_amount)}}</p>
				<p id="cheque-number" class="cheque-number">{{documentHeaders[0].cheque_number}}</p>
				<p id="amountText" class="voucher-amount-text">{{getAmountinWords(documentHeaders[0].voucher_amount)}}</p>
				
				<div class="account-charge-container">
					<div class="account-charge-item" v-for="key in documentHeaders[0].details" :key="key.id">
						<div class="account-charge-account">{{key.account_code}}</div>
						<div class="account-charge-text">{{getAccount(key.account_code)}}</div>
						<div class="account-charge-amount" v-if="key.debit_amount != '0.0000'">{{getNumberFormat(key.debit_amount)}}</div>
						<div class="account-charge-amount" v-else>({{getNumberFormat(key.credit_amount)}})</div>
					</div>
				</div>
				<p id="approved" class="voucher-approved-by">{{getName(documentHeaders[0].approved_by)}}</p>
				<p id="prepared" class="voucher-prepared-by">{{disbDetail.preparer}}</p>
				<img class="voucher-file" src="@/assets/checks/voucher.png" />
			</div>
		</div>
	</div>
</template>
<script>
import { mapGetters } from 'vuex';
import numeral from 'numeral';
import { format } from 'date-fns';
import numbertoWords from 'number-to-words';

export default {
	name: 'printVoucherTrxdisb',
	data() {
		return {
			disbDetail:{},
			documentHeaders: [],
			dataUsers:[],
			nextPageUsers: '',
			dataCoas:[],
			nextPageCoas: '',
		};
	},
	watch: {
		trxdisbDetails: {
			handler(val) {
				this.documentHeaders = val;
			}
		},
		coas: {
			handler() {
				this.getDataCoas();
			}
		},
		users: {
			handler() {
				this.getDataUsers();
			}
		}
	},
	computed: {
		...mapGetters({
			payees: 'payefile/payefiles',
			trxdisbDetails: 'trxdisb/trxdisbDetails',
			coas: 'coa/coaSubs',
			users: 'user/users',
			coaData: 'coa/coaData'
			
		}),
	},
	created() {
		var app = this;
		app.disbDetail = JSON.parse(atob(app.$route.params.data)); 
	},
	mounted() {
		this.init();
	},
	methods: {
		printDocument() {
			window.print();
		},
		init(){
			this.$store.dispatch('coa/getCoaDropdown');
			this.$store.dispatch('payefile/getPayefileDropdown');
			this.$store.dispatch('user/getUsers');
			this.$store.dispatch('trxdisb/getTrxdisb', this.disbDetail.batch_number);
		},
		getDateFormat(date){
			return format(new Date(date), 'MM-d-yyyy');
		},
		getNumberFormat(amount){
			return numeral(amount.replace(/,/g, '')).format('0,0.00');
		},
		getAmountinWords(amount){
			let amountWord = numbertoWords.toWords(amount); 
			let newAmount = amountWord.replace(/,/g, '');

			let cent = '';
			// let total_amount = amount.toFixed(2);
			let arr = numeral(amount).format('00.00').split('.');
			if(arr[1] != '00'){
				cent = ' & '+ arr[1] + '/100';
			}
			return newAmount.toUpperCase() + ' ' + this.getCurrency() + ' ' + cent + ' ONLY.';
		},
		getPayeeName(id){
			const getPayee = this.payees.filter(payee=> payee.id=== id);

			if (getPayee) {
				return getPayee[0].payee_desc;
			}
		},
		getName(id){
			const getUser = this.dataUsers.filter(user => user.id === id);

			if (getUser.length != 0) {
				return getUser[0].user_last_name + ', ' + getUser[0].user_first_name;
			}
		},
		getAccount(acct_code){
			const getAccount = this.coaData.filter(detail => detail.acct_code === acct_code);
			
			if (getAccount.length != 0){
				return getAccount[0].acct_desc;
			}

		},
		async getDataCoas(){
			if(this.coaData.length === 0){
				this.dataCoas = this.coas.data;
				this.nextPageCoas = this.coas.next_page_url;
				let page = 2;

				while (this.nextPageCoas !== null){
					await this.$store.dispatch('coa/getCoaData', page).then(response => {
						this.nextPageCoas = response.data.data.next_page_url;
						response.data.data.data.forEach(details => {
							this.dataCoas.push(details);
						});
					});
					page++;
				}
				this.$store.commit('coa/SET_COA_DATA', this.dataCoas);
			}
		},
		async getDataUsers(){
			this.dataUsers = this.users.data;
			this.nextPageUsers = this.users.next_page_url;
			let page = 2;

			while (this.nextPageUsers !== null){
				await this.$store.dispatch('user/getData', page).then(response => {
					this.nextPageUsers = response.data.data.next_page_url;
					response.data.data.data.forEach(details => {
						this.dataUsers.push(details);
					});
				});
				page++;
			}
		},
		getCurrency(){
			let curr_id = this.documentHeaders[0].currency_id;
			let currency = 'PESOS';
			switch (curr_id) {
			case 2:
				currency = 'DOLLARS';
				break;
			case 4:
				currency = 'YENS';
				break;
			case 5:
				currency = 'SINGAPOREAN DOLLAR';
				break;
			default:
				currency =  'PESOS';
				break;
			}

			return currency;
		}
	}
};
</script>
<style scoped>
	.voucher-file {
		width: 100%;
	}

	.voucher-container {
		position: relative;
		width: 729px;
	}
	
	.voucher-date,
	.voucher-amount-total,
	.voucher-account-name,
	.cheque-number,
	.voucher-number,
	.voucher-amount-text {
		position: absolute;
		font-weight: bolder;
	}

	.voucher-number {
		right: 80px;
		top: 100px;
		font-size: 15px;
	}

	.voucher-date {
		right: 80px;
    top: 160px;
    font-size: 15px;
	}

	.voucher-account-explanation {
		position: absolute;
		top: 240px;
    left: 20px;
		width: 520px;
	}

	.voucher-amount {
		position: absolute;
		top: 240px;
		right: 95px;
    font-size: 15px;
	}

	.voucher-amount-total {
		right: 95px;
    top: 355px;
    font-size: 15px;
	}

	.voucher-account-name {
		top: 140px;
    left: 85px;
	}

	.voucher-amount-text {
		top: 360px;
    left: 175px;
    font-size: 10px;
	width: 350px;
	line-height: .8;
	margin-top: 5px;
	}

	.cheque-number {
		top: 360px;
		left: 15px;
		font-size: 13px;
	}

	/* .voucher-date {
		right: 260px;
		top: 130px;
    font-size: 25px;
    font-family: arial;
	} */

	.account-charge-container {
		position: absolute;
    top: 403px;
    left: 10px;
    font-size: 15.5px;
	}

	.account-charge-item {
		display: flex;
    flex-direction: row;
		width: 340px;
		margin-top: 6px;
	}

	.account-charge-text {
		position: relative;
		left: 10px;	
		font-size: 11px;
	}
	.account-charge-account {
		position: relative;
		font-size: 11px;
	}


	.account-charge-amount {
		position: absolute;
		right: 0;
		font-size: 11px;
	}

	.voucher-approved-by,
	.voucher-prepared-by {
		font-size: 12px;
	}

	.voucher-approved-by {
		position: absolute;
		bottom: 2px;
    left: 175px;
    font-weight: bolder;
	}

	.voucher-prepared-by {
		position: absolute;
    bottom: 0;
    right: 278px;
		font-weight: bolder;
	}

	@media print {
		body #printAction { 
			display:none; 
		}
		body #printArea { 
			display:block; 
		}
		body .voucher-file {
			display: none;
		}
	}
</style>