<template>
  <v-snackbar :value.sync="shown" :color="type" multi-line top>
    {{ message }}
    <v-btn text color="white" @click="close">Exit</v-btn>
  </v-snackbar>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	name : 'Notify',
	computed: {
		...mapGetters({
			message: 'app/message',
			shown: 'app/shown',
			type: 'app/messagetype'
		})
	},
	// provide() {
	// 	return {
	// 		close: this.close
	// 	};
	// },
	watch:{
		shown(newVal){
			if(newVal){
				setTimeout(() => {
					this.close();
				}, 6000);
			}
		}
	},
	methods: {
		close() {
			this.$store.dispatch('app/setSnackBar',{'status': false,'message':'','messagetype':''});
		}
	}
};
</script>