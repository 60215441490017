export default {
	banks: [],
	headers: [
		{
			text: 'Record ID',
			align: 'left',
			sortable: false,
			value: 'id',
		},
		// {
		// 	text: 'Bank Account',
		// 	align: 'left',
		// 	sortable: true,
		// 	value: 'bank_acct',
		// },
		//{ text: 'Account Type', value: 'acct_type' },
		// { text: 'Account Code', value: 'acct_code' },
		//{ text: 'Rep Form', value: 'rep_form' },
		{ text: 'Account Description', value: 'acct_desc'},
		{ text: 'Bank Name', value: 'bank_name' },
		//{ text: 'Branch', value: 'bank_branch' },
		//{ text: 'Bank Code', value: 'bank_code' },
		//{ text: 'Bank Address', value: 'bank_address' },
		// { text: 'Account Class', value: 'acct_clss' },
		//{ text: 'For Forex', value: 'for_forex' },
		//{ text: 'For Treasury', value: 'for_treasury' },
		{ text: 'Actions', value: 'action', sortable: false },
	],
	columnHeaders: [
		{ label: 'Record ID', field: 'id' },
		{ label: 'Account Description', field: 'acct_desc'},
		{ label: 'Bank Name', field: 'bank_name' }
	],
	acctType: [
		{ value: 'SA', text: 'Savings Account'},
		{ value: 'CA', text: 'Checking Account'}
	],
	bankStatus: [
		{ value: '1', text: 'Active'},
		{ value: '0', text: 'Inactive'}
	],
	forForex: [
		{ value: 'Y', text: 'Yes'},
		{ value: 'N', text: 'No'}
	],      
	forTreasury: [
		{ value: 'Y', text: 'Yes'},
		{ value: 'N', text: 'No'}
	],
	acctClass: [
		{ value: 'P', text: 'Peso'},
		{ value: 'D', text: 'Dollar'}
	],            
	editedItem: {
		id: '',
		dept_code: '',
		dept_abbreviation: '',
		dept_desc: '',
		div_code: '',
		org_level: '',
		group_id: '',
		dept_status: '',
	},
	defaultItem: {
		id: '',
		dept_code: '',
		dept_abbreviation: '',
		dept_desc: '',
		div_code: '',
		org_level: '',
		group_id: '',
		dept_status: '',
	},
	dialog: false,
	editedIndex: -1,
	valid: true,
	formTitle: 'Edit entry',
};